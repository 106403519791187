import React, { Suspense } from "react";
import { useReg } from "../../apiHooks";
import { regRequestKeys } from "../../constants";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../system/reducers";
import { apiErrors } from "../../../../system/helpers/apiErrorHelper";
import Loader from "../../../../components/atoms/Loader";
import { dataLayerRegCreateAccount } from "../../../../system/dataLayer";
import { useABtest } from "../../../../system/helpers/hooks";
import config from "../../../../system/config";

const RegForm = React.lazy(() => import("../forms/RegForm"));

type RegModalProps = {
  redirectTo?: string;
  enableCaptcha?: boolean;
  showOnSuccess?: boolean;
};

const RegModal: React.FC<RegModalProps> = ({ redirectTo, showOnSuccess }) => {
  const [regHandler, { isLoading, error }] = useReg(redirectTo, showOnSuccess);
  const { variant } = useABtest(config.abExperimentIds.captcha);
  const enableCaptcha = variant === "1";
  const responseError = apiErrors(error);
  const { info } = useSelector(
    (state: ApplicationState) => state.user,
    shallowEqual
  );
  const { currencyInfo, countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append(regRequestKeys.username, values.username);
    formData.append(regRequestKeys.phone, values.phone.replace(/[^\d]/g, ""));
    formData.append(regRequestKeys.email, values.email);
    formData.append(regRequestKeys.password, values.password);
    formData.append(regRequestKeys.country, countryInfo.iso2Code);
    formData.append(regRequestKeys.currency, values.currency.value);
    formData.append(regRequestKeys.receiveEmail, "1");
    formData.append(regRequestKeys.override_flag, values.override_flag);
    formData.append(
      regRequestKeys.agreeTermAndConditions,
      `${+values.agreeTermAndConditions}`
    );
    formData.append(regRequestKeys.recaptcha, values.recaptcha);
    regHandler(formData);
    dataLayerRegCreateAccount();
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <RegForm
        defaultCurrency={currencyInfo.isoCode}
        iso2Code={countryInfo.iso2Code}
        responseError={responseError}
        onSubmit={onSubmit}
        isLoading={isLoading || info.isLoading}
        enableCaptcha={enableCaptcha}
        allResErrors={error?.error.data.systemMessages}
      />
    </Suspense>
  );
};

RegModal.defaultProps = {
  showOnSuccess: config.isShowAfterLoginModal,
};

export default RegModal;
