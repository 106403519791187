import { useMemo, useContext, useCallback } from "react";
import { useMenuLinks } from "../header/apiHooks";
import { UseChatResult } from "./constants";
import { useModal } from "../modals/hooks";
import { chatModal } from "../modals";
import { chatClick } from "./helpers";
import ChatContext from "./components/ChatContext";

export const useChat = (): UseChatResult => {
  const { loaded } = useContext(ChatContext);
  const { data } = useMenuLinks("chat-links");
  const { showModal } = useModal();
  const links = useMemo(() => data?.links || [], [data]);
  const multiple = useMemo<boolean>(() => links?.length > 0, [links]);
  const visible = useMemo<boolean>(() => loaded, [loaded]);

  const openChat = useCallback(() => {
    if (multiple) {
      showModal(chatModal);
    } else {
      chatClick();
    }
  }, [multiple]);

  return { links, multiple, visible, loaded, openChat };
};
