import classNames from "classnames";
import React, { useEffect, useRef, useMemo, useState } from "react";
import Loader from "../Loader";
import { useIntersectionObserver } from "../../../system/hooks/useIntersectionObserver";

export type LazyType = {
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  observerOptions?: IntersectionObserverInit;
};

const Lazy: React.FC<LazyType> = ({
  tag,
  className,
  style,
  children,
  observerOptions,
}) => {
  const Tag: any = tag;
  const ref = useRef(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const options = useMemo<IntersectionObserverInit>(
    () => ({ rootMargin: "100px", ...observerOptions }),
    [observerOptions]
  );
  const { shown, setTarget } = useIntersectionObserver(options);

  useEffect(() => {
    ref && setTarget(ref);
  }, [ref, setTarget]);

  useEffect(() => {
    if (shown) {
      setLoaded(true);
    }
  }, [shown]);

  return (
    <Tag className={classNames("lazy", className)} style={style} ref={ref}>
      {loaded ? children : <Loader center fullHeight minHeight={280} />}
    </Tag>
  );
};

Lazy.defaultProps = {
  tag: "div",
};

export default Lazy;
