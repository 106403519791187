import React, { useRef } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import Btn from "../Btn";
import { _t } from "../../../system/translations/InjectIntlContext";
import { useCheckClickOutside } from "../../../system/helpers/checkClickOutsideHook";
import { ReactComponent as HotGameIcon } from "../../../images/icons/hot.svg";
import { ReactComponent as PlayIcon } from "../../../images/icons/play.svg";
import { ReactComponent as StarIcon } from "../../../images/icons/star.svg";
import { ReactComponent as StarActiveIcon } from "../../../images/icons/star-active.svg";
import { history } from "../../../system/store";
import { isLoggedIn } from "../../../system/helpers/userHelper";
import { loginModalWithRedirect } from "../../../modules/modals";
import { dataLayerRegModal } from "../../../system/dataLayer";
import LazyImg from "../LazyImg";
import { providersIcons } from "../../molecules/CasinoProviders/constants";
import { useModal } from "../../../modules/modals/hooks";

export type GameCardProps = {
  image: string;
  playForRealLink: string;
  playForFunLink?: string;
  name: string;
  desc?: string;
  title?: string;
  status?: string;
  hotLabel?: boolean;
  platformCode?: string;
  jackpot?: string;
  isFavorite?: boolean;
  isFullProfile?: boolean;
  afterClick?: () => any;
  onClickAddFavorite?: () => void;
  onClickRemoveFavorite?: () => void;
};

const GameCard: React.FC<GameCardProps> = ({
  image,
  playForRealLink,
  playForFunLink,
  name,
  desc,
  title,
  platformCode,
  status,
  hotLabel,
  jackpot,
  isFavorite,
  onClickAddFavorite,
  onClickRemoveFavorite,
  isFullProfile,
  afterClick,
}) => {
  const { showModal } = useModal();
  const gameCardRef = useRef(null);
  const isUser = isLoggedIn();
  const [showButtons, setShowButtons] = useCheckClickOutside(
    gameCardRef,
    false
  );

  const handleClick = () => {
    if (isMobile) {
      setShowButtons(true);
    }
  };

  const handleShowLoginModal = () => {
    showModal(
      loginModalWithRedirect({
        redirectTo: playForRealLink,
        showOnSuccess: false,
      })
    );
    dataLayerRegModal("game_card");
  };
  const openGame = (link) => {
    history.push(link, history.location.pathname);
  };

  const linkClick = (type, link) => {
    if (type === "real") {
      !isLoggedIn() ? handleShowLoginModal() : openGame(link);
    } else {
      history.push(link, history.location.pathname);
    }
    afterClick && afterClick();
  };

  return (
    <div
      ref={gameCardRef}
      className={classNames(
        "game-card",
        { "game-card--touch": isMobile },
        { "game-card--active": showButtons }
      )}
      onClick={handleClick}
    >
      <div className={"game-card__inner"}>
        <LazyImg className={"game-card__bg"} imgSrc={image} bg />
        {(status || hotLabel || jackpot) && (
          <div className={"game-card__labels"}>
            {status && (
              <span className={"game-card__status-label"}>{status}</span>
            )}
            {jackpot && (
              <span className={"game-card__jackpot-label"}>{jackpot}</span>
            )}
            {hotLabel && (
              <span className={"game-card__hot-label"}>
                <HotGameIcon />
              </span>
            )}
          </div>
        )}
        <div className={"game-card__buttons"}>
          <Btn
            className={"game-card__play-for-real"}
            type={"play"}
            size={"round"}
            icon={<PlayIcon />}
            onClick={() => linkClick("real", playForRealLink)}
          />
          {name && <span className={"game-card__name"}>{name}</span>}
          {playForFunLink && (
            <Btn
              className={"game-card__play-for-fun"}
              onClick={() => linkClick("demo", playForFunLink)}
              type={"demo"}
              label={_t("play_for_fun")}
            />
          )}
          {isUser && onClickAddFavorite && onClickRemoveFavorite && (
            <Btn
              className={classNames("game-card__favorite", {
                "game-card__favorite--active": isFavorite,
              })}
              onClick={isFavorite ? onClickRemoveFavorite : onClickAddFavorite}
              type={"star"}
              icon={isFavorite ? <StarActiveIcon /> : <StarIcon />}
            />
          )}
        </div>
      </div>
      {(desc || title) && (
        <div className={"game-card__content"}>
          <div className={"game-card__content-img"}>
            <LazyImg
              className={"game-card__bg-icon"}
              imgSrc={providersIcons[platformCode]}
            />
          </div>
          <div className={"game-card__content-wrap"}>
            {title && <span className={"game-card__title"}>{title}</span>}
            {desc && <span className={"game-card__desc"}>{desc}</span>}
          </div>
        </div>
      )}
    </div>
  );
};

export default GameCard;
