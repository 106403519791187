import React, { Suspense, useEffect, useState } from "react";
import { _t } from "../../../../system/translations/InjectIntlContext";
import Btn from "../../../../components/atoms/Btn";
import SliderControl from "../../../../components/atoms/SliderControl";
import CustomSlider from "../../../../components/molecules/CustomSlider";
import { useGetMostPopularGames } from "../../../casino/apiHooks";
import Loader from "../../../../components/atoms/Loader";
import { path } from "../../../../system/helpers/pathHelper";
import GameCardContainer from "../../../casino/components/GameCardContainer";
import { useGetFavoriteGames, useMatchingGames } from "../../apiHooks";
import { useGetBanner } from "../../../banners/apiHooks";
import { currencyListForReplacing, welcomePromoSlug } from "../../constants";
import { settingSlider } from "../../constants";
import { settingSliderPromo } from "../../constants";
import AfterLoginPromoItem from "../../../../components/atoms/AfterLoginPromoItem";
import { useModal } from "../../../modals/hooks";
import SportLinksSlider from "../../../../components/molecules/SportLinks/SportLinksSlider";
import { getCurrencySign } from "../../../../system/helpers/currencies";
import { useBalanceData } from "../../hooks";

const UserProgress = React.lazy(
  () => import("../../../../components/molecules/UserProgress")
);

type AfterLoginModalProps = {
  username: string;
  levelStatus: string;
  progressValue: number;
  leftToNextLevel: number;
};

const AfterLoginModal: React.FC<AfterLoginModalProps> = () => {
  const { hideModal } = useModal();
  const handleHideModal = () => hideModal();
  const { userInfo, getUserBalance } = useBalanceData(true);
  const {
    username,
    balance: { currentVIPLevelTrans, loyaltyPoint, totalBalance, currency },
    loyaltyPointsStatus: { nextVIPLevelTrans, totalPointsForNextLevel },
  } = userInfo;

  const {
    isLoading: isLoadingWelcomePromo,
    data: welcomePromoData,
  } = useGetBanner(welcomePromoSlug);

  const {
    data: mostPopularGames,
    isLoading: isLoadingMostPopularGames,
  } = useGetMostPopularGames();

  const {
    dataModified: favoriteGames,
    isLoading: isLoadingFavorite,
  } = useGetFavoriteGames();

  const dataMostPopularModified = useMatchingGames(
    mostPopularGames?.hottestGames,
    favoriteGames
  );
  const isLoadingModifiedMostPopular =
    isLoadingMostPopularGames || isLoadingFavorite;

  const replacedCurrency = currencyListForReplacing[currency]
    ? getCurrencySign(currency)
    : currency;

  const [sliderRefMostPopular, setSliderRefMostPopular] = useState(null);
  const getSliderRefMostPopular = (ref: React.MutableRefObject<any>) =>
    setSliderRefMostPopular(ref);

  const renderCustomSliderItems = (sliderRef, data = [], isLoading) => {
    return (
      <div className="custom-slider-container">
        <CustomSlider getSliderRef={sliderRef} settings={settingSlider}>
          {isLoading ? (
            <Loader />
          ) : (
            data.map((item) => (
              <GameCardContainer
                key={item.imageUrl}
                item={item}
                afterClick={handleHideModal}
              />
            ))
          )}
        </CustomSlider>
      </div>
    );
  };

  return (
    <div className="user-after-login-container">
      <div className={"main-sliders"}>
        <div className="user-current-progress">
          <div className="user-welcome">
            <span>
              {_t("after_login_modal_hello")}
              <span className="user-welcome__username">
                {", " + username + "!"}
              </span>
            </span>
            <span className="user-welcome__welcome-back">
              {_t("after_login_modal_welcome")}
            </span>
          </div>
          <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
            <UserProgress
              username={username}
              nextVIPLevelTrans={nextVIPLevelTrans}
              levelStatus={currentVIPLevelTrans}
              loyaltyPoint={loyaltyPoint}
              leftToNextLevel={"0"}
              totalPointsToNextLevel={totalPointsForNextLevel}
              userTotalBalanceValue={totalBalance}
              getUserBalance={getUserBalance}
              userTotalBalanceCurrency={replacedCurrency}
              showTotalBalance
              enableLevelStatus={true}
            />
          </Suspense>
        </div>
        <div className="go-play-games">
          <div className="custom-slider-container">
            <div className={"promotions-sm-container"}>
              {isLoadingWelcomePromo ? (
                <div className={"promotions-sm__loader"}>
                  <Loader center />
                </div>
              ) : welcomePromoData?.banners?.length ? (
                <div className={"promotions-sm"}>
                  <CustomSlider settings={settingSliderPromo}>
                    {welcomePromoData?.banners?.map((item) => {
                      return (
                        <AfterLoginPromoItem
                          className={"promotions-sm"}
                          link={item.link}
                          image={item.imageUrl}
                          title={item.name}
                          teaser={item.teaser}
                        />
                      );
                    })}
                  </CustomSlider>
                </div>
              ) : null}
            </div>
          </div>
          <SportLinksSlider />
          <div className="go-play-games__most-popular">
            <div className={"most-popular__top-panel"}>
              <div className={"most-popular__block-title"}>
                {_t("after_login_popular")}
              </div>
              <div className={"most-popular__slider-control"}>
                <SliderControl
                  btnNextOnClick={() => {
                    sliderRefMostPopular.current.slickNext();
                  }}
                  btnPrevOnClick={() => {
                    sliderRefMostPopular.current.slickPrev();
                  }}
                  showControlsBtn={
                    mostPopularGames?.hottestGames?.length >
                    settingSlider.slidesToShow
                  }
                  className="slider__most-popular"
                  label={_t("after_login_show_more")}
                  onClick={handleHideModal}
                  labelLink={path("/casino/most-popular")}
                />
              </div>
            </div>
            {renderCustomSliderItems(
              getSliderRefMostPopular,
              dataMostPopularModified,
              isLoadingModifiedMostPopular
            )}
            <div className="btn-link-block">
              <Btn
                type={"transparent-bordered"}
                label={_t("after_login_modal_to_games")}
                linkTo={path("/casino")}
                onClick={handleHideModal}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"promotions-container"}>
        {isLoadingWelcomePromo ? (
          <div className={"promotions__loader"}>
            <Loader center />
          </div>
        ) : welcomePromoData?.banners?.length ? (
          <div className={"promotions"}>
            <span className="promotions__block-title">
              {_t("after_login_promo")}
            </span>
            <>
              {welcomePromoData?.banners?.slice(0, 4).map((item) => {
                return (
                  <AfterLoginPromoItem
                    className={"promotions"}
                    isAdditionalClassName
                    link={item.link}
                    image={item.imageUrl}
                    title={item.name}
                    teaser={item.teaser}
                  />
                );
              })}
              <div className="btn-link-block">
                <Btn
                  type={"transparent-bordered"}
                  label={_t("after_login_to_bonuses")}
                  linkTo={path("/bonuses")}
                  onClick={handleHideModal}
                />
              </div>
            </>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AfterLoginModal;
