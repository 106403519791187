import React, { ReactElement } from "react";
import Btn from "../../atoms/Btn";
import FormattedText from "../../atoms/FormattedText";
import LazyImg from "../../atoms/LazyImg";
import { _t } from "../../../system/translations/InjectIntlContext";

type DescriptionModalProps = {
  text: string;
  img: string;
  linkTo: string;
  btnText: string;
  btnIcon?: ReactElement;
  handleClick: any;
};

const DescriptionModal: React.FC<DescriptionModalProps> = ({
  text,
  img,
  linkTo,
  btnText,
  btnIcon,
  handleClick,
}) => (
  <div className={"description-modal"}>
    {img && (
      <LazyImg imgSrc={img} alt={""} className={"description-modal__img"} />
    )}
    {text && (
      <FormattedText className={"description-modal__text"} html={_t(text)} />
    )}
    {linkTo && btnText && (
      <div className={"description-modal__btn"}>
        <Btn
          icon={btnIcon}
          label={_t(btnText)}
          size={"lg"}
          type={"action"}
          linkTo={linkTo}
          onClick={handleClick}
          block
        />
      </div>
    )}
  </div>
);

export default DescriptionModal;
