import React, { useCallback, useMemo, useState } from "react";
import Loader from "../../../../components/atoms/Loader";
import { _t } from "../../../../system/translations/InjectIntlContext";
import { useGetQuizInfo, useGetAnswers } from "../api/apiHooks";
import { QuizHistoryModalProps } from "./types";
import {
  ModalBody,
  RoundSelect,
  Label,
  Title,
  Question,
  Answers,
  AnswerButton,
  AnswerButtonText,
  AnswerLabel,
} from "./styles";

const QuizHistoryModal: React.FC<QuizHistoryModalProps> = ({ quizzes }) => {
  const [selectedQuiz, setSelectedQuiz] = useState<number>(0);
  const quiz = useMemo(() => quizzes?.[selectedQuiz], [quizzes, selectedQuiz]);
  const { data: quizInfo, isLoading } = useGetQuizInfo(quiz?.slug);
  const { data } = useGetAnswers(quiz?.id);
  const userAnswerId = useCallback<(questionId: number) => number>(
    (questionId) => {
      const answer = data?.find((a) => a.question_id === questionId);
      return answer?.answer_id;
    },
    [data]
  );
  const options = useMemo(
    () =>
      quizzes?.map((item, index) => ({
        label: item?.title,
        value: index,
      })),
    [quizzes]
  );

  const selectQuiz = useCallback((value: number | string) => {
    setSelectedQuiz(Number(value));
  }, []);

  return (
    <ModalBody>
      <RoundSelect
        name={"quiz"}
        options={options}
        value={selectedQuiz}
        label={_t("quiz_modal_round")}
        onChange={selectQuiz}
      />
      {isLoading ? (
        <Loader minHeight={100} center />
      ) : (
        <>
          {quizInfo?.questions?.map((question) => (
            <Question key={question.id}>
              <Label>{question?.title}</Label>
              {question?.description && (
                <Title
                  dangerouslySetInnerHTML={{
                    __html: question?.description,
                  }}
                />
              )}
              <Answers>
                {question?.answers?.map((answer) => (
                  <AnswerButton
                    key={answer?.title}
                    success={answer?.correct}
                    disabled
                  >
                    <AnswerButtonText>{answer?.title}</AnswerButtonText>
                    {userAnswerId(question?.id) === answer?.id && (
                      <AnswerLabel>{_t("quiz_modal_your_answer")}</AnswerLabel>
                    )}
                  </AnswerButton>
                ))}
              </Answers>
            </Question>
          ))}
        </>
      )}
    </ModalBody>
  );
};

export default QuizHistoryModal;
