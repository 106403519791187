import React, { useEffect, useState } from "react";
import SliderControl from "../../atoms/SliderControl";
import { _t } from "../../../system/translations/InjectIntlContext";
import SportLinks from "./index";
import { useGetSportLinks } from "../../../modules/sportbook/apiHooks";
import { isMobile } from "react-device-detect";
import { checkIsSportLinksSliderScrolling } from "../../../modules/sportbook/helpers";
import classNames from "classnames";

type SportLinksSliderProps = {
  transSlug?: string;
};

const SportLinksSlider: React.FC<SportLinksSliderProps> = ({ transSlug }) => {
  const [isSportLinksArrow, setIsSportLinksArrow] = useState(false);
  const [sliderRefSbQuickLinks, setSliderRefSbQuickLinks] = useState(null);
  useEffect(() => {
    if (sliderRefSbQuickLinks) {
      const state = sliderRefSbQuickLinks.current.innerSlider.state;
      const isArrow = checkIsSportLinksSliderScrolling(
        state.listWidth,
        state.slideWidth,
        state.slideCount
      );
      setIsSportLinksArrow(isArrow);
    }
  }, [sliderRefSbQuickLinks?.current?.innerSlider.state.trackWidth]);

  const getSliderRefSbQuickLinks = (ref: React.MutableRefObject<any>) =>
    setSliderRefSbQuickLinks(ref);

  const { isLoading, data } = useGetSportLinks();

  const showControlsBtn = !isMobile && data?.links.length > 6;

  return (
    <div className="go-play-games__sport-links">
      <SliderControl
        showControlsBtn={showControlsBtn}
        btnNextOnClick={() => {
          sliderRefSbQuickLinks.current.slickNext();
        }}
        btnPrevOnClick={() => {
          sliderRefSbQuickLinks.current.slickPrev();
        }}
        className={classNames(
          !isSportLinksArrow && "slider__sb-quick-link-arrow-button",
          "slider__sb-quick-link"
        )}
        label={
          <span className="sport-links__block-title">
            {_t(transSlug + "_sb_quick_links")}
          </span>
        }
      />
      <SportLinks
        data={data}
        isLoading={isLoading}
        getSliderRef={getSliderRefSbQuickLinks}
      />
    </div>
  );
};

SportLinksSlider.defaultProps = {
  transSlug: "after_login",
};

export default SportLinksSlider;
