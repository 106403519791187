import React from "react";
import { useModal } from "../../../modules/modals/hooks";
import { _t } from "../../../system/translations/InjectIntlContext";

type EventCalendarModalProps = {
  date: string;
  name: string;
  desc: string;
};

const EventCalendarModal: React.FC<EventCalendarModalProps> = ({
  date,
  name,
  desc,
}) => {
  const { hideModal } = useModal();
  const onCloseModal = () => hideModal();

  return (
    <div className={"event-calendar-modal-body"}>
      <div className={"event-calendar-modal-body__date"}>{date}</div>
      <div className={"event-calendar-modal-body__name"}>{name}</div>
      <div className={"event-calendar-modal-body__desc"}>{desc}</div>
      <button
        className={"event-calendar-modal-body__btn"}
        onClick={onCloseModal}
      >
        {_t("event_calendar_modol_close")}
      </button>
    </div>
  );
};

export default EventCalendarModal;
