import { addToast } from "./toast";

export const apiErrors = (responceError, responceFormat = "") => {
  const errData = responceError?.error?.data?.systemMessages?.errors;
  if (responceFormat === "notify" && !!errData && Array.isArray(errData)) {
    errData.forEach((error) =>
      addToast(error.message, {
        type: "error",
      })
    );
  }

  return errData;
};
