import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import { rootReducer } from "./reducers";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";
import axiosMiddleware from "./middleware/axiosMiddleware";

export const history = createHistory();
const routerRedux = routerMiddleware(history);

export default function configureStore(initialState = {}) {
  const middleware = [routerRedux, ReduxThunk, axiosMiddleware];
  const devMode = process.env.NODE_ENV === "development";
  let enhancer;

  if (devMode) {
    const logger = createLogger();
    middleware.push(logger);
    const composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = compose(applyMiddleware(...middleware));
  }

  return createStore(rootReducer(history), initialState, enhancer);
}
