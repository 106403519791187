import React, { Suspense } from "react";
import { useRouteMatch } from "react-router-dom";
import Loader from "../../components/atoms/Loader";
import MainTemplate from "../../templates/Main";
import { Helmet } from "react-helmet";
import { _t, makeKey } from "../../system/translations/InjectIntlContext";
import { APP_ROUTES } from "../../system/router/constants";
import { RouteParams } from "../../modules/casino/constants";
import { CasinoContextProvider } from "../../modules/casino/components/CasinoContext";
import { getPageTitle } from "../../system/helpers/titleHelper";
import config from "../../system/config";

const CasinoTemplate = React.lazy(() => import("./CasinoTemplate"));

const CasinoPage: React.FC = () => {
  const matchCategory = useRouteMatch<RouteParams>(
    APP_ROUTES.casinoPage.category
  );
  const matchGameTagSlug = useRouteMatch<RouteParams>(
    APP_ROUTES.casinoPage.gameTag
  );
  const categorySlug = matchCategory?.params?.categorySlug;
  const gameTagSlug = matchGameTagSlug?.params?.gameTagSlug;

  return (
    <>
      <Helmet>
        <title>
          {_t(
            getPageTitle(
              makeKey("casino_page_title", categorySlug),
              config.viptTitleKey
            )
          )}
        </title>
        <meta
          name="description"
          content={_t(makeKey("casino_page_description", categorySlug))}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--casino"}
        seoPanelProps={{
          keySlug: makeKey("casino", categorySlug, gameTagSlug),
        }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <CasinoContextProvider>
            <CasinoTemplate />
          </CasinoContextProvider>
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default CasinoPage;
