import React from "react";
import { seoLinkTypes } from "../casino/constants";
import { userInfoType } from "../user/constants";
import * as Yup from "yup";
import { isValidIBANNumber, unSupportedIBAN } from "./helper";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../system/reducers";
import { isValidEmail } from "../../system/helpers/validationHelper";
import CashboxMethodAdditionalInfo from "../../components/atoms/CashboxMethod/CashboxMethodAdditionalInfo";

export type methodItem = {
  assetUrl: string;
  code: string;
  conversionRequired: boolean;
  depositAmounts: [];
  feeRate: string;
  frontendInvocation: string;
  isAllowQuickSignUp: boolean;
  isVoucher: boolean;
  maximumDeposit?: string;
  maximumWithdrawal?: string;
  methodRoute: string;
  minimumDeposit?: string;
  minimumWithdrawal?: string;
  name: string;
  paymentMethod: string;
  position: number;
};

export type methodsPayloadData = {
  depositMethods?: methodItem[];
  withdrawalMethods?: methodItem[];
  methodCollection: {
    depositMethods: methodItem[];
  };
  activeBonuses: [];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
  user: userInfoType;
};

export type fieldsSettingsItemType = {
  [key: string]: {
    disabled?: boolean;
    value?: any;
    hidden?: boolean;
  };
};

export type fieldsSettingsType = {
  [key: string]: fieldsSettingsItemType;
};

export type bonusType = {
  bonusId: string;
  bonusUrl?: string;
  description?: string;
  bonusType?: string;
  bonusTitle?: string;
  imageUrl?: string;
  teaser?: string;
};

export type propertiesItems = {
  attr: { [key: string]: string };
  autocomplete: string;
  propertyOrder: number;
  title: string;
  type: string;
  enum?: string[];
  enum_titles?: string[];
};

export type processFormSchemaProperties = {
  [key: string]: propertiesItems;
};

export type processFormSchema = {
  properties: processFormSchemaProperties;
  required: string[];
  title: string;
  type: string;
  attr?: { [key: string]: string | boolean };
};

export type processDetailsPayload = {
  form: string;
  formSchema: processFormSchema;
  formType: string;
  method: string;
  status: string;
  template: string;
  submitUrl: string;
};

export type getAmountPayload = {
  conversionRate: string | number;
  depositMethod: methodItem;
  currencies: { amountToSelect: string; amountToAccount: string };
  depositInfo: null | string;
  form: string;
  formSchema: processFormSchema;
  formType: string;
  method: string;
  status: string;
  processUrl: string;
};

export type methodProcessConfirmation = {
  paymentId: string;
  continueUrl: string;
  dateLimit: null | string;
  depositFields: [];
  entity: null;
  reference: null;
};

export type methodProcessPayload = {
  confirmation: methodProcessConfirmation;
  confirmationUrl: string;
  form: string;
  status: string;
  systemMessages: {
    errors: [{ field: string; message: string }];
  };
};

export type methodWithdrawalProcessPayload = {
  continueUrl: string;
  paymentId: string;
  confirmation?: methodProcessConfirmation;
  confirmationUrl: string;
  form: string;
  status: string;
  systemMessages: {
    errors: [{ field: string; message: string }];
  };
};

export type postMethodAmountPayload = {
  conversionRate: number;
  depositInfo: null;
  depositMethod: methodItem;
  form: string;
  formSchema: processFormSchema;
  method: string;
  processUrl: string;
  status: string;
};

export type bonusesPayloadData = {
  bonuses: bonusType[];
};

export type cashboxPaymentType = "deposit" | "withdrawal";

export const exceptionInDetailsWithdrawal = ["amount", "blackbox"];

export const methodsWithCompletedModal: string[] = [
  "TRPAY_BANKWIRE",
  "TRPAY_BANKWIRE2",
];

export const depositFieldsValidation = {
  MONEYBOOKERS: (_t) =>
    Yup.object().shape({
      email: isValidEmail(_t).required(_t("required_email")),
    }),
  NETELLER_V2: (_t) =>
    Yup.object().shape({
      accountId: isValidEmail(_t).required(_t("required")),
    }),
  TRPAY_BANKWIRE: (_t) =>
    Yup.object().shape({
      bankName: Yup.string().required(_t("required")),
    }),
  TRPAY_BANKWIRE2: (_t) =>
    Yup.object().shape({
      bankName: Yup.string().required(_t("required")),
    }),
};

export const withdrawalFieldsValidation = {
  MONEYBOOKERS: (_t) =>
    Yup.object().shape({
      skrillAccountEmail: isValidEmail(_t).required(_t("required")),
    }),
  INPAY: (_t) =>
    Yup.object().shape({
      bankAddress: Yup.string()
        .min(4, _t("error_bankAddress_to_short"))
        .max(100, _t("error_bankAddress_to_long"))
        .required(_t("required")),
      bankName: Yup.string()
        .min(4, _t("error_bankName_to_short"))
        .max(30, _t("error_bankName_to_long"))
        .required(_t("required")),
      branchCode: Yup.number()
        .typeError("error_branchCode_must_be_number")
        .required(_t("required")),
      swift: Yup.string()
        .matches(
          /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
          _t("error_swift_no_match_pattern")
        )
        .required(_t("required")),
      iban: Yup.string()
        .required(_t("required"))
        .test("validIban", _t("error_iban_invalid"), (val) =>
          isValidIBANNumber(val)
        )
        .test("notSupportedIban", _t("error_iban_not_supported"), (val) =>
          unSupportedIBAN(val)
        ),
    }),
};

export const useWithdrawalFieldsSettings = (): fieldsSettingsType => {
  const userInfo = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  return {
    "withdrawal.TRPAY_BANKWIRE": {
      cellPhone: {
        value: "0000000000",
      },
      tcIdentNumber: {
        value: "00000000000",
      },
      ibanNumber: {
        value: "0000000000",
      },
    },
    "withdrawal.TRPAY_BANKWIRE2": {
      cellPhone: {
        value: "0000000000",
      },
      tcIdentNumber: {
        value: "00000000000",
      },
      ibanNumber: {
        value: "0000000000",
      },
    },
    "withdrawal.MONEYBOOKERS": {
      skrillAccountEmail: {
        disabled: true,
        value: userInfo.email,
        hidden: false,
      },
    },
  };
};

export const withdrawalsSemiKYCRestrictions: string[] = [
  "IS_QR",
  "IS_JETON",
  "IS_CRYPTOPAY_BTC",
  "IS_COMMUNITYBANKING_2",
  "IS_PAPARA",
  "IS_COMMUNITYBANKING",
  "TRPAY_CEPBANK",
  "TRPAY_BANKWIRE",
  "ASTROPAY",
  "TRPAY_BANKWIRE2",
  "IS_CMT",
  "ECO_PAYZ",
  "IS_CEPBANK",
  "IS_COMMUNITYBANKING_8",
  "IS_COMMUNITYBANKING_9",
  "IS_PAPARA_2",
  "IS_PAPARA_3",
  "IS_PAPARA_4",
  "IS_PAPARA_5",
];
export const withdrawalKYCAmount: number = 5000;
export const withdrawalKYCCurrency: string = "TRY";

export const depositMinBonusAmounts: { [key: string]: number } = {
  EUR: 15,
  TRY: 150,
  USD: 50,
  JPY: 2000,
  INR: 50,
  NOK: 1000,
  CAD: 15,
  CLP: 850,
  PLN: 100,
};

export const notSupportedIbanISO = ["LT"];

export const wdStatuses = {
  completed: "COMPLETED",
  pending: "PENDING",
  cancelled: "CANCELLED",
  rejected: "REJECTED",
};

export const IS_OPEN_IN_IFRAME = [
  "IS_COMMUNITYBANKING_7",
  "IS_PAPARA_2",
  "IS_MULTIBANCO_2",
];
export const METHODS_PRESETS = {
  IS_MULTIBANCO_2: ["10", "20", "50", "100", "500"],
  IS_SKRILL: ["10", "20", "50", "100", "500"],
  NETELLER_V2: ["10", "20", "50", "100", "500"],
  IS_CREDITCARD_6: ["10", "20", "50", "100", "500"],
};

export const METHODS_PRESETS_IN = {
  IS_UPI_3: ["250", "500", "1000", "2500", "5000"],
  IS_PHONEPE: ["250", "500", "1000", "2500", "5000"],
  IS_PAYTM: ["250", "500", "1000", "2500", "5000"],
  IS_GPAY: ["250", "500", "1000", "2500", "5000"],
  IS_IMPS_2: ["250", "500", "1000", "2500", "5000"],
  IS_SKRILL: ["500", "1000", "2500", "5000"],
  IS_CRYPTOPAY_BTC: ["500", "1000", "2500", "5000"],
  IS_ASTROPAY: ["500", "1000", "2500", "5000"],
  IS_CASHTOCODE: ["300", "500", "1000", "2500", "5000"],
  ECO_PAYZ: ["500", "1000", "2500", "5000"],
};

export const MULTIBANCO_COUNTRIES = ["PT"];
export const MULTIBANCO_PAYMENT_METHOD = "IS_MULTIBANCO_2";

// export const MULTIBANCO_COUNTRIES = ["UA", "DE"];
// export const MULTIBANCO_PAYMENT_METHOD = "ECO_PAYZ";

export type DepositViewVersion = "v1" | "v2" | "v3";

export type paymentAdditionalInfo = {
  IS_MBWAYHP: {
    topComponent: React.ReactElement;
  };
  IS_CASHLIB: {
    topComponent: React.ReactElement;
  };
};

export const paymentAdditionalInfo: paymentAdditionalInfo = {
  IS_MBWAYHP: {
    topComponent: (
      <CashboxMethodAdditionalInfo title={"withdrawal_is_mbwayhp"} />
    ),
  },
  IS_CASHLIB: {
    topComponent: (
      <CashboxMethodAdditionalInfo
        linkTo={"https://point66.com"}
        title={"deposit_is_cashlib"}
        label={"deposit_is_cashlib_text_link"}
      />
    ),
  },
};

export type lastDepositItemType = {
  img: string;
  name: string;
  amount: number;
};
