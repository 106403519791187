import enFlag from "../../images/icons/flags/EN.svg";
import deFlag from "../../images/icons/flags/DE.svg";
import esFlag from "../../images/icons/flags/ES.svg";
import hiFlag from "../../images/icons/flags/HI.svg";
import itFlag from "../../images/icons/flags/IT.svg";
import jaFlag from "../../images/icons/flags/JA.svg";
import noFlag from "../../images/icons/flags/NO.svg";
import ptFlag from "../../images/icons/flags/PT.svg";
import fiFlag from "../../images/icons/flags/SU.svg";
import svFlag from "../../images/icons/flags/SV.svg";
import trFlag from "../../images/icons/flags/TR.svg";
import caFlag from "../../images/icons/flags/CA.svg";

export type translationData = {
  [key: string]: {
    [key: string]: string;
  };
};

export type translationResponse = {
  translations: translationData;
};

export type bootstrapResponse = {
  cdn: string;
  ws: string;
  country: string;
  constants: [];
  language: string;
  languages: string[];
  currency: string;
  currencies: string[];
  country_language_map: { [key: string]: string };
  country_currency_map: { [key: string]: string };
  socket: {
    brand: string;
  };
  features: {
    tfa: {
      email: boolean;
      sms: boolean;
      google: boolean;
      trusona: boolean;
    };
    verification: {
      phone: boolean;
    };
    zing: { active: boolean };
    captcha: { active: boolean };
  };
};

export type languagesData = {
  code: string;
  imageUrl: string;
  name: string;
  url: string;
};

export type languagesResponse = {
  languages: languagesData[];
};

export type currencyInfo = {
  isDefault?: boolean | null;
  isoCode?: string;
  name?: string;
  numberDecimalPoint?: number | null;
  numericCode?: number | null;
  symbol?: string;
  symbolCode?: number | null;
};

export type countryInfo = {
  iso2Code?: string;
  name?: string;
  phonePrefix?: string;
};

export type countryInfoResponse = {
  countryInfo: countryInfo;
  currencyInfo: currencyInfo;
};

export type generalReducerType = {
  selectedLang: string;
  languages: languagesData[];
  availableLangs: string[];
  countryInfo: countryInfo;
  currencyInfo: currencyInfo;
  pwaDomain: string;
  blackbox: string;
};

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const GET_COUNTRY_INFO_SUCCESS = "GET_COUNTRY_INFO_SUCCESS";
export const GET_AVAILABLE_LANGUAGES_SUCCESS =
  "GET_AVAILABLE_LANGUAGES_SUCCESS";
export const GET_PWA_DOMAIN_SUCCESS = "GET_PWA_DOMAIN_SUCCESS";
export const GET_PWA_DOMAIN_ERROR = "GET_PWA_DOMAIN_ERROR";
export const SET_BLACKBOX = "SET_BLACKBOX";

export const flags = {
  en: enFlag,
  de: deFlag,
  es: esFlag,
  hi: hiFlag,
  it: itFlag,
  ja: jaFlag,
  no: noFlag,
  pt: ptFlag,
  fi: fiFlag,
  sv: svFlag,
  tr: trFlag,
  ca: caFlag,
};

export const countryToAvailableLocales = { IN: { en: "en", hi: "hi" } };
