import { useDispatch } from "react-redux";

type ApiRequestParamsType = { [key: string]: any };
type ApiRequestResConfigType = { [key: string]: any };

export const useApiRequest = () => {
  const dispatch: any = useDispatch();
  const apiRequest = (
    params: ApiRequestParamsType,
    resConfig: ApiRequestResConfigType = {}
  ) =>
    dispatch(params).then((data: any) =>
      !!resConfig.normalize ? resConfig.normalize(data.payload) : data.payload
    );

  return [apiRequest];
};
