import { bannersPayloadData, bannerTypes } from "./constants";
import { generatePath } from "react-router";
import { getLanguage } from "../../system/translations/helper";
import { isMobile } from "react-device-detect";

export const BANNER_URL = `/:lang/banner/:banner/9999/9999/bxslider.json`;

export const getBannerAction = (banner: bannerTypes) => (
  dispatch: any
): bannersPayloadData => {
  const lang = getLanguage();

  return dispatch({
    type: "GET_BANNERS",
    request: {
      url: generatePath(BANNER_URL, { lang, banner }),
      method: "GET",
    },
  }).then((data: any) => {
    const { banners, ...rest } = data.payload;
    return {
      banners: banners?.filter((banner) =>
        isMobile ? banner.mobile : banner.desktop
      ),
      ...rest,
    };
  });
};
