import React from "react";
import classNames from "classnames";

export type ItemType = {
  title?: string;
  text?: string;
};

export type TournamentListProps = {
  className?: string;
  variant?: "default" | "timeline";
  items: ItemType[];
};

const TournamentList: React.FC<TournamentListProps> = ({
  className,
  variant,
  items,
}) => (
  <ul
    className={classNames(
      "tournament-list",
      `tournament-list--${variant}`,
      className
    )}
  >
    {items.map((item, idx) => (
      <li className={"tournament-list__item"} key={idx}>
        {item.title && (
          <h5 className={"tournament-list__title"}>{item.title}</h5>
        )}
        {item.text && <p className={"tournament-list__text"}>{item.text}</p>}
      </li>
    ))}
  </ul>
);

TournamentList.defaultProps = {
  variant: "default",
};

export default TournamentList;
