import { isMobile } from "react-device-detect";

export const sliderSettings = {
  slidesToShow: isMobile ? 2 : 3,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  rows: 2,
  responsive: [
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1.5,
      },
    },
  ],
};
