import { useQuery, useMutation, queryCache } from "react-query";
import { useDispatch } from "react-redux";

import {
  getMethodsAction,
  getMethodAmountAction,
  getMethodBonusesAction,
  postMethodAmountAction,
  getMethodProcessAction,
  postMethodProcessAction,
  postMethodBonusesAction,
  getWithdrawalProcessAction,
  postWithdrawalProcessAction,
} from "./apiActions";
import { addToast } from "../../system/helpers/toast";
import { apiErrors } from "../../system/helpers/apiErrorHelper";
import { getLanguage } from "../../system/translations/helper";
import { PAYMENT_HISTORY_LIST } from "../user/constants";

export const useGetMethods = (type: string, enabled: boolean = true) => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(
    [`cashbox_methods_${type}`, lang],
    () => getMethodsAction(type)(dispatch),
    { enabled }
  );
};

export const useGetMethodAmount = (method: string, type: string) => {
  const dispatch = useDispatch();
  return useMutation(() => getMethodAmountAction(method, type)(dispatch));
  /*  return useQuery(`cahsbox_amount_${method}`, () =>
    getMethodAmountAction(method, type)(dispatch)
  );*/
};
export const useGetMethodProcess = (method: string) => {
  const dispatch = useDispatch();
  return useMutation((processUrl: any) =>
    getMethodProcessAction(method, processUrl)(dispatch)
  );
};

export const useGetMethodBonuses = (method: string) => {
  const dispatch = useDispatch();
  return useMutation((data: any) => getMethodBonusesAction(method)(dispatch));
};

export const usePostMethodAmount = (method: string, type: string) => {
  const dispatch = useDispatch();
  return useMutation((data: any) =>
    postMethodAmountAction(data, method, type)(dispatch)
  );
};

export const usePostMethodProcess = () => {
  const dispatch = useDispatch();
  return useMutation(
    (data: any) =>
      postMethodProcessAction(data?.requestData, data?.submitUrl)(dispatch),
    {
      onSuccess: (data) => {
        const errors = data?.systemMessages?.errors;
        if (!!errors && errors.length) {
          errors.forEach((item) => {
            if (item?.message) {
              addToast(item.message, {
                type: "error",
              });
            }
          });
        }
      },
      onError: (err: any) => {
        apiErrors(err, "notify");
      },
    }
  );
};

export const usePostMethodBonuses = (method: string) => {
  const dispatch = useDispatch();
  return useMutation((bonusId: string) =>
    postMethodBonusesAction(method, bonusId)(dispatch)
  );
};
export const useGetWithdrawal = (method: string) => {
  const dispatch = useDispatch();
  return useMutation(() => getWithdrawalProcessAction(method)(dispatch));
};

export const usePostWithdrawalProcess = (method) => {
  const lang = getLanguage();
  const dispatch = useDispatch();
  return useMutation(
    (data: any) => postWithdrawalProcessAction(data, method)(dispatch),
    {
      onSuccess: () => {
        queryCache.invalidateQueries([PAYMENT_HISTORY_LIST, lang]);
      },
      onError: (err: any) => {
        apiErrors(err, "notify");
      },
    }
  );
};
