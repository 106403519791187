import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDimensions } from "react-recipes";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import { SeoPanelProps } from "../../components/atoms/SeoPanel";
import HeaderWrap from "../../modules/header/components";
import {
  useCheckResetPassword,
  useCookieConsent,
  usePrivacyConsent,
} from "../../modules/user/hooks";
import Loader from "../../components/atoms/Loader";
import { pushLocationChange } from "../../system/dataLayer";
import Lazy from "../../components/atoms/Lazy";
import { useCheckModal } from "../../modules/modals/hooks";
import { path } from "../../system/helpers/pathHelper";
import { APP_ROUTES } from "../../system/router/constants";
import TextLink from "../../components/atoms/TextLink";
import { _t } from "../../system/translations/InjectIntlContext";
import { useRootScroll } from "../../system/hooks/useRootScroll";

const FooterWrap = React.lazy(
  () =>
    new Promise((resolve, reject) => {
      return setTimeout(
        () =>
          import("../../modules/footer/components").then((res: any) => {
            resolve(res);
          }),
        3000
      );
    })
);
const SeoPanel = React.lazy(() => import("../../components/atoms/SeoPanel"));

declare global {
  interface Window {
    ga: any;
  }
}

type MainTemplateProps = {
  withoutHeader?: boolean;
  children: React.ReactNode;
  withoutFooter?: boolean;
  withoutSeoPanel?: boolean;
  prefixClass?: string;
  seoPanelProps?: SeoPanelProps;
  fixedHeader?: boolean;
};

const MainTemplate: React.FC<MainTemplateProps> = ({
  withoutHeader,
  children,
  withoutFooter,
  prefixClass,
  withoutSeoPanel,
  seoPanelProps,
  fixedHeader,
}) => {
  const [headerRef, { height: headerHeight }] = useDimensions();
  const { pageYOffset } = useRootScroll();
  const location = useLocation();
  const checkModal = useCheckModal();

  const doNotScrollTopRoutes = [
    APP_ROUTES.casinoPage.allGames,
    `${APP_ROUTES.casinoPage.allGames}/`,
  ];

  const allowScrollTop =
    !doNotScrollTopRoutes.some((url) =>
      location.pathname.includes(path(url))
    ) || !location.state?.["fromCasinoPage"];

  useEffect(() => {
    if (allowScrollTop) {
      window.scrollTo(0, 0);
    }
    checkModal(location);
    if (!!window.ga) {
      window.ga("set", "page", `${location.pathname}.html`);
      window.ga("send", "pageview");
    }
    pushLocationChange(location.pathname);
  }, [location.pathname, location.state]);
  useCheckResetPassword();

  const linkToCookiePolicy = (
    <TextLink linkTo={path("/info/cookies_policy")} label={_t("learn_more")} />
  );
  useCookieConsent(linkToCookiePolicy);
  usePrivacyConsent();

  return (
    <div
      className={classNames("main-template", prefixClass, {
        "main-template--fixed-header": fixedHeader,
      })}
    >
      {!withoutHeader && (
        <div
          className={classNames("main-template__header", {
            "main-template__header-top-attached": pageYOffset === 0,
          })}
          ref={headerRef}
        >
          <HeaderWrap />
        </div>
      )}
      <div
        className={classNames("main-template__container")}
        style={{
          paddingTop: fixedHeader ? headerHeight : 0,
        }}
      >
        {children}
      </div>
      {!withoutSeoPanel && (
        <Suspense fallback={<Loader center minHeight={300} fullHeight />}>
          <SeoPanel {...seoPanelProps} />
        </Suspense>
      )}
      {!withoutFooter && (
        <div className={"main-template__footer"}>
          <Suspense
            fallback={
              <Loader center fullHeight minHeight={isMobile ? 568 : 667} />
            }
          >
            <Lazy>
              <FooterWrap />
            </Lazy>
          </Suspense>
        </div>
      )}
    </div>
  );
};

MainTemplate.defaultProps = {
  withoutHeader: false,
  withoutFooter: false,
  withoutSeoPanel: false,
  fixedHeader: true,
};

export default MainTemplate;
