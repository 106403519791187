import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../system/helpers/titleHelper";
import config from "../../system/config";
const LeagueBettiltTemplate = React.lazy(
  () => import("../../modules/leagueBettilt/components/index")
);

const LeagueBettiltPage = () => {
  return (
    <>
      <Helmet>
        <title>
          {_t(
            getPageTitle("event_leaderboard_page_title", config.viptTitleKey)
          )}
        </title>
        <meta
          name="description"
          content={_t("event_leaderboard_page_description")}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--eventLeaderboardPage"}
        seoPanelProps={{ keySlug: "sport_leagues" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <LeagueBettiltTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default LeagueBettiltPage;
