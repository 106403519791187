import React from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./constants";
import PrivateRoute from "../helpers/PrivateRoute";
import PageNotFound from "../../pages/errorPages/404";
import StartPage from "../../pages/startPage";
import Promo from "../../pages/promo";
import CasinoPage from "../../pages/casino";
import LiveGames from "../../pages/liveGames";
import InfoPage from "../../pages/info";
import GamePage from "../../pages/game";
import UserPage from "../../pages/user";
import SportPage from "../../pages/sportPage";
import pwaLandingPage from "../../pages/pwaLanding";
import SignInPage from "../../pages/signIn";
import Euro2020Page from "../../pages/euro2020";
import SignUpPage from "../../pages/signUpPage";
import VipBettiltPage from "../../pages/vipBettilt";
import LeagueBettiltPage from "../../pages/leagueBettilt";
import TournamentsPage from "../../pages/tournaments";
import TournamentInfoPage from "../../pages/tournamentInfo";
import GiftsCalendarPage from "../../pages/giftsCalendar";
import EventsCalendarPage from "../../pages/eventsCalendar";
import LoyaltySystemPage from "../../pages/loyaltySystem";
import GiveawayPage from "../../pages/giveaway";
import QuizPage from "../../pages/quiz";

export default (
  <Switch>
    <PrivateRoute exact path={APP_ROUTES.startPage} component={StartPage} />
    <PrivateRoute exact path={APP_ROUTES.resetPassword} component={StartPage} />
    <PrivateRoute exact path={APP_ROUTES.sportsbook} component={SportPage} />
    <PrivateRoute exact path={APP_ROUTES.inPlay} component={SportPage} />
    <PrivateRoute exact path={APP_ROUTES.euro2020} component={Euro2020Page} />
    <PrivateRoute
      exact
      path={APP_ROUTES.vipBettilt}
      component={VipBettiltPage}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.leagueBettilt}
      component={LeagueBettiltPage}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.loyaltySystem}
      component={LoyaltySystemPage}
    />
    <PrivateRoute
      exact
      path={APP_ROUTES.pwaLanding}
      component={pwaLandingPage}
    />
    <PrivateRoute exact path={APP_ROUTES.promo} component={Promo} />
    <PrivateRoute
      exact
      path={APP_ROUTES.liveGames.gamesPage}
      component={LiveGames}
    />
    <PrivateRoute exact path={APP_ROUTES.infoPages} component={InfoPage} />
    <PrivateRoute
      path={APP_ROUTES.userPage.profilePage}
      profile
      component={UserPage}
    />
    <PrivateRoute
      path={APP_ROUTES.casinoPage.allGames}
      component={CasinoPage}
    />
    <PrivateRoute path={"/:lang/games"} component={CasinoPage} />
    <PrivateRoute path={APP_ROUTES.gamePage} component={GamePage} />
    <Route path={APP_ROUTES.signIn} component={SignInPage} />
    <Route path={APP_ROUTES.signUp} component={SignUpPage} />
    <Route path={APP_ROUTES.tournamentInfo} component={TournamentInfoPage} />
    <Route path={APP_ROUTES.tournaments} component={TournamentsPage} />
    <Route path={APP_ROUTES.giftsCalendar} component={GiftsCalendarPage} />
    <Route path={APP_ROUTES.eventsCalendar} component={EventsCalendarPage} />
    <Route path={APP_ROUTES.giveaway} component={GiveawayPage} />
    <Route path={APP_ROUTES.quiz} component={QuizPage} />
    <Route component={PageNotFound} />
  </Switch>
);
