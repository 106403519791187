import { getQueryParams } from "../../system/helpers/urlHelper";
import { useRouteMatch, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../system/router/constants";
import { history } from "../../system/store";
import { cookieModal, privacyModal, resetPasswordModal } from "../modals";
import { useCallback, useMemo, useEffect, useState } from "react";
import { gameItem } from "../casino/constants";
import {
  useAddFavoriteGame,
  useApplyCashback,
  useGetCashbackAmount,
  useRemoveFavoriteGame,
  useGetShareUserInfo,
  useGetShareUserProfile,
} from "./apiHooks";
import { queryCache } from "react-query";
import { useModal } from "../modals/hooks";
import { balanceFormat, getBalance } from "../../system/helpers/currencies";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../system/reducers";
import { _t } from "../../system/translations/InjectIntlContext";
import { calcTimeLeft } from "./helpers";
import {
  excludedPrivacyCountry,
  excludedCookieCountry,
  minAllowedCashbackAmount,
  PRIVACY_WAIT_PERIOD,
} from "./constants";
import { isLoggedIn } from "../../system/helpers/userHelper";
import { isMobile } from "react-device-detect";

type ResetPasswordRouteParams = {
  email: string;
  confirmationCode: string;
};

export const useCheckResetPassword = () => {
  const location = useLocation();
  const params = getQueryParams(location.search);
  const match = useRouteMatch<ResetPasswordRouteParams>(
    APP_ROUTES.resetPassword
  );
  const beforeClose = () => {
    history.push("/");
  };
  const { showModal } = useModal();
  useEffect(() => {
    if (match?.isExact || location.hash === "#reset-password") {
      showModal(
        resetPasswordModal({
          email: params.get("email"),
          confirmationCode: params.get("confirmationCode"),
          beforeClose,
        })
      );
    }
  }, [match, location.hash]);
};

export const useFavoriteGameMethods = (game: gameItem) => {
  const [addFavoriteGameHandler, data] = useAddFavoriteGame();
  const [removeFavoriteGameHandler, removingData] = useRemoveFavoriteGame();
  const [isFavorite, setIsFavorite] = useState(game?.isFavorite);

  useEffect(() => {
    if (
      (data.isError && !data.isLoading) ||
      (removingData.isError && !removingData.isLoading)
    ) {
      setIsFavorite(game.isFavorite);
      queryCache.invalidateQueries(["get_favoriteGames"]);
    }
  }, [data.isLoading, removingData.isLoading]);

  useEffect(() => {
    if (game) {
      setIsFavorite(game.isFavorite);
    }
  }, [game?.isFavorite]);

  const addFavorite = () => {
    const formData = new FormData();
    formData.append("gamingtec_add_favorite_games[gameId]", game.v3.game.slug);
    formData.append(
      "gamingtec_add_favorite_games[platformCode]",
      game.v3.game.platform.gameLaunchPlatformCode
    );
    const reqData = {
      data: formData,
      gameData: game,
    };
    setIsFavorite(true);
    addFavoriteGameHandler(reqData);
  };

  const removeFavorite = () => {
    const formData = new FormData();
    formData.append(
      "gamingtec_remove_favorite_games[gameId]",
      game.v3.game.slug
    );
    formData.append(
      "gamingtec_remove_favorite_games[platformCode]",
      game.v3.game.platform.gameLaunchPlatformCode
    );
    const reqData = {
      data: formData,
      gameData: game,
    };
    setIsFavorite(false);
    removeFavoriteGameHandler(reqData);
  };
  return {
    addFavorite,
    removeFavorite,
    isFavorite,
  };
};

export const useBalanceData = (isFixedStrict: boolean = false) => {
  const { user } = useSelector(
    (state: ApplicationState) => state,
    shallowEqual
  );
  const {
    balance: { currency },
  } = user.info.user;

  const getUserBalance = (balance: number | string) => {
    if (isFixedStrict) {
      return getBalance(balance, currency);
    } else {
      return balanceFormat(balance, currency);
    }
  };

  return { userInfo: user.info.user, getUserBalance };
};

export const useGetShareUserPrivacy = () => {
  const { hideModal, showModal } = useModal();
  const privacyMessage = _t("privacy_statements_text", {
    privacyStatementsTitle: `<span>${_t("privacy_statements_title")}.</span>`,
  });
  const [userInfoHandler] = useGetShareUserInfo();
  const onHandleApproveClick = (data) => {
    userInfoHandler(data);
    hideModal("privacy-modal");
  };
  const onHandleRejectClick = () => {
    hideModal("privacy-modal");
    localStorage.setItem("agree_with_policy_date", Date.now().toString());
  };
  return useCallback(
    (data: any) => {
      const agreeWithPolicyDate = JSON.parse(
        localStorage.getItem("agree_with_policy_date")
      );

      const isAllowPrivacyModal = data?.marketing_email && data?.marketing_sms;

      const isTimeLeft =
        !agreeWithPolicyDate ||
        calcTimeLeft(PRIVACY_WAIT_PERIOD, agreeWithPolicyDate);

      if (!isAllowPrivacyModal && isTimeLeft) {
        setTimeout(() => {
          showModal(
            privacyModal({
              onHandleApproveClick: () =>
                onHandleApproveClick({
                  sessionKey: data.sessionKey,
                  marketing_email: true,
                  marketing_sms: true,
                }),
              onHandleRejectClick,
              textWithTags: privacyMessage,
            }),
            "privacy-modal"
          );
        }, 1000);
      }
    },
    [privacyMessage]
  );
};

export const usePrivacyConsent = () => {
  const getShareUserProfile = useGetShareUserPrivacy();
  const { sessionKey, country } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  const { data } = useGetShareUserProfile({ sessionKey });
  const isUser = isLoggedIn();

  useEffect(() => {
    if (
      isUser &&
      data &&
      country &&
      !excludedPrivacyCountry.includes(country)
    ) {
      getShareUserProfile({
        sessionKey,
        marketing_email: data?.marketing_email,
        marketing_sms: data?.marketing_sms,
      });
    }
  }, [isUser, data, country]);
};

export const useCookieConsent = (linkTo) => {
  const { showModal, hideModal } = useModal();
  const country = useSelector(
    (state: ApplicationState) => state.general?.countryInfo?.iso2Code,
    shallowEqual
  );
  const onHandleClick = (isAllow) => {
    localStorage.setItem("allowCookies", isAllow);
    hideModal("cookie-modal");
  };

  const cookieModalText = _t("cookies_policy_label", {
    linkToCookiePolicy: linkTo,
  });
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("allowCookies")) === null &&
      !excludedCookieCountry.includes(country) &&
      !isMobile
    ) {
      setTimeout(() => {
        showModal(
          cookieModal({
            onHandleApproveClick: () => onHandleClick("true"),
            onHandleRejectClick: () => onHandleClick("false"),
            text: cookieModalText,
          }),
          "cookie-modal"
        );
      }, 1000);
    }
  }, [linkTo]);
};

export const useCashbacks = (sessionKey: string) => {
  const {
    data: cashbackData,
    isLoading: isLoadingCashback,
  } = useGetCashbackAmount({ sessionKey });
  const [pendingClaimButton, setPendingClaimButton] = useState(false);

  const [onApplyCashback] = useApplyCashback();

  const cashbacksData = useMemo(() => {
    const cashbacksData = {
      cashbacks: {
        sportCashbackData: null,
        casinoCashbackData: null,
      },
      currency: "",
    };
    cashbackData?.cashbacks.forEach((item) => {
      if (/@Sport$/.test(item.bonus_plan_name)) {
        cashbacksData.cashbacks.sportCashbackData = item;
      }
      if (/@Casino$/.test(item.bonus_plan_name)) {
        cashbacksData.cashbacks.casinoCashbackData = item;
      }
    });
    cashbacksData.currency = cashbackData?.currency;
    return cashbacksData;
  }, [cashbackData?.cashbacks]);

  const allowedCashbacks = useMemo(() => {
    const isAllowedCasinoCashback =
      cashbacksData?.cashbacks?.casinoCashbackData?.amount / 100 >=
      minAllowedCashbackAmount[cashbacksData?.currency];
    const isAllowedSportCashback =
      cashbacksData?.cashbacks?.sportCashbackData?.amount / 100 >=
      minAllowedCashbackAmount[cashbacksData?.currency];
    return { isAllowedCasinoCashback, isAllowedSportCashback };
  }, [cashbackData?.cashbacks]);

  const onSubmitCashback = (id, cashbackAmount) => {
    onApplyCashback({ sessionKey, id, cashbackAmount, setPendingClaimButton });
    setPendingClaimButton(true);
  };

  return {
    cashbacksData,
    allowedCashbacks,
    onSubmitCashback,
    isLoadingCashback,
    pendingClaimButton,
  };
};
