import React from "react";
import RegModal from "../../modules/user/components/modals/RegModal";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
import { isPublicDomains } from "../../system/helpers/urlHelper";
import config from "../../system/config";

const SignUpPage = () => {
  return (
    <>
      {!isPublicDomains() && (
        <Helmet>
          <title>{_t(config.viptTitleKey)}</title>
        </Helmet>
      )}
      <div className={"sign-up"}>
        <div className={"sign-up__form"}>
          <RegModal />
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
