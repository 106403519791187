import { useEffect, useMemo } from "react";
import { isPopupBlocked } from "../../system/helpers/popupBlockerChecker";
import { isExternalURL } from "../../system/helpers/urlHelper";
import {
  disablePopupBlockerModal,
  paymentCompletedModal,
  processIframeModal,
} from "../modals";
import {
  MULTIBANCO_COUNTRIES,
  MULTIBANCO_PAYMENT_METHOD,
  methodsWithCompletedModal,
  DepositViewVersion,
  methodItem,
  lastDepositItemType,
  METHODS_PRESETS_IN,
} from "./constants";
import {
  dataLayerDepositResponse,
  dataLayerWithdrawalResponse,
} from "../../system/dataLayer";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../system/reducers";
import { isLoggedIn } from "../../system/helpers/userHelper";
import { useIsOddPartyId } from "../../system/hooks/userHooks";
import { useGetMethods } from "./apiHooks";
import { useModal } from "../modals/hooks";
import { format, parse } from "date-fns";
import { DATE_FORMAT, paymentHistoryRequestKeys } from "../user/constants";
import { usePaymentHistoryQuery } from "../user/apiHooks";
import {
  isDeposit,
  randomNumber,
  randomAmount,
  normalizeMinMax,
  removeMinMaxPoint,
  round10,
  iframeModalStrategy,
  isFirstWd,
  isSecondWd,
} from "./helper";

export const useOpenProcess = (
  processResponceData,
  method,
  completedModalTexts,
  onBackClick,
  successfulModal,
  paymentType,
  version: DepositViewVersion = "v1"
) => {
  const { showModal, hideModal } = useModal();
  const confirmation = processResponceData?.confirmation;
  const completedModal = (arg) => paymentCompletedModal(arg);
  const processModal = (arg) => processIframeModal(arg);
  const isWebApp = localStorage.getItem("isWebApp");
  useEffect(() => {
    if (!processResponceData) {
      return;
    }
    if (!!confirmation?.continueUrl) {
      hideModal();
      if (iframeModalStrategy(method.paymentMethod)) {
        showModal(processModal({ url: confirmation?.continueUrl }));
      } else {
        if (isWebApp) {
          window.location.assign(confirmation?.continueUrl);
          return;
        }
        const newWindow = window.open(confirmation?.continueUrl);
        if (isPopupBlocked(newWindow)) {
          const link = isExternalURL(confirmation?.continueUrl)
            ? confirmation?.continueUrl
            : window.location.origin + confirmation?.continueUrl;
          showModal(disablePopupBlockerModal({ link }));
        }
      }

      if (version === "v3") {
        localStorage.setItem("hasFastDeposit", "true");
      }

      onBackClick(method);
    } else if (!!confirmation?.paymentId) {
      if (methodsWithCompletedModal.includes(method?.paymentMethod)) {
        showModal(
          completedModal({
            paymentId: confirmation?.paymentId,
            ...completedModalTexts,
          })
        );
      } else {
        showModal(successfulModal);
      }
      onBackClick(method);
    }
    if (isDeposit(paymentType)) {
      dataLayerDepositResponse();
    } else {
      dataLayerWithdrawalResponse();
    }
  }, [processResponceData]);
};

export const useDepositViewVersion = (ver?: DepositViewVersion) => {
  // const isOddPartyId = useIsOddPartyId();
  const version = ver || "v1";

  const isDepositV1 = version === "v1";
  const isDepositV2 = false; // version === "v2"
  const isDepositV3 = version === "v3";

  return { version, isDepositV1, isDepositV2, isDepositV3 };
};

export const useShowFastDepositBanner = () => {
  const isUser = isLoggedIn();
  const isOddPartyId = useIsOddPartyId();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const isAvailable = useMemo<boolean>(
    () =>
      isUser &&
      isOddPartyId &&
      MULTIBANCO_COUNTRIES.includes(countryInfo?.iso2Code),
    [isUser, isOddPartyId, countryInfo]
  );
  const { data } = useGetMethods("deposit", isAvailable);
  const hasMethod = useMemo<boolean>(
    () =>
      data?.depositMethods?.some(
        (item) => item.paymentMethod === MULTIBANCO_PAYMENT_METHOD
      ),
    [data]
  );
  const hasFastDeposit = !!localStorage.getItem("hasFastDeposit");

  return useMemo<boolean>(() => isAvailable && hasMethod && !hasFastDeposit, [
    isAvailable,
    hasMethod,
    hasFastDeposit,
  ]);
};

export const useGetLastDeposits = (methods: methodItem[]) => {
  return useMemo<lastDepositItemType[]>(() => {
    const items: lastDepositItemType[] = [];
    for (let i = 0; i < 30; i++) {
      const method = methods[randomNumber(0, methods.length - 1)];
      const amount = randomAmount(parseInt(method.minimumDeposit));

      if (amount !== 0) {
        items.push({
          img: method.assetUrl,
          name: method.name,
          amount: amount,
        });
      }
    }
    return items;
  }, [methods]);
};

export const usePresets = (
  method: methodItem,
  version: DepositViewVersion,
  step: number
) => {
  const normalizedMin = normalizeMinMax(
    removeMinMaxPoint(method.minimumDeposit)
  );

  const normalizedMax = normalizeMinMax(
    removeMinMaxPoint(method.maximumDeposit)
  );

  const {
    user: {
      info: {
        user: { currency },
      },
    },
  } = useSelector((state: ApplicationState) => state, shallowEqual);

  const methodPredefinedPresets = useMemo(
    () =>
      currency === "INR" &&
      Object.keys(METHODS_PRESETS_IN).find((code) => code === method.code),
    [method]
  );

  const presets = useMemo(
    () =>
      !!methodPredefinedPresets
        ? METHODS_PRESETS_IN[methodPredefinedPresets]
        : [
            round10(2 * normalizedMin),
            round10(4 * normalizedMin),
            round10(8 * normalizedMin),
            round10(10 * normalizedMin),
            round10(20 * normalizedMin),
          ],
    [normalizedMin, methodPredefinedPresets]
  );

  const presetsOptions = useMemo(() => {
    if (version === "v3" && step === 1) {
      return [];
    }

    return presets.map((item) => ({ label: item, value: item }));
  }, [presets, version, step]);

  return useMemo(
    () => ({ presets, presetsOptions, normalizedMin, normalizedMax }),
    [presets, presetsOptions, normalizedMin, normalizedMax]
  );
};

export const useUserFirstWdCheck = (enabled) => {
  const { regDate } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  const historyRequestData = () => {
    const formData = new FormData();
    const parseRegDate = parse(regDate, "dd-MM-yyyy HH:mm:ss", new Date());
    const formatRegDate = format(parseRegDate, DATE_FORMAT);
    const formatEndDate = format(new Date(), DATE_FORMAT);
    formData.append(paymentHistoryRequestKeys.startDate, formatRegDate);
    formData.append(paymentHistoryRequestKeys.endDate, formatEndDate);
    return formData;
  };

  const {
    data: paymentHistory,
    isLoading: isLoadingPaymentHistory,
  } = usePaymentHistoryQuery(historyRequestData(), enabled);

  const isFirstUserWd = useMemo(
    () => enabled && isFirstWd(paymentHistory?.histories),
    [paymentHistory, enabled]
  );

  const isSecondUserWd = useMemo(
    () => enabled && isSecondWd(paymentHistory?.histories),
    [paymentHistory, enabled]
  );

  return { isFirstUserWd, isSecondUserWd, isLoadingPaymentHistory };
};
