import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../system/helpers/titleHelper";
import config from "../../system/config";
const PwaLandingTemplate = React.lazy(() => import("./PwaLandingTemplate"));

const PwaLandingPage = () => {
  return (
    <>
      <Helmet>
        <title>
          {_t(getPageTitle("pwa_landing_page_title", config.viptTitleKey))}
        </title>
        <meta name="description" content={_t("pwa_landing_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--pwa-landing"}
        seoPanelProps={{ keySlug: "pwalanding" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <PwaLandingTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default PwaLandingPage;
