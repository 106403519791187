import { useQuery, useMutation, useQueryCache } from "react-query";
import { generatePath } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../system/reducers";
import { getLanguage } from "../../../../system/translations/helper";
import { quizSuccessModal } from "../../../modals";
import { useApiRequest, useApiErrors } from "../../general/hooks";
import { isLoggedIn } from "../../../../system/helpers/userHelper";
import { useModal } from "../../../modals/hooks";
import {
  QuizPayload,
  QuizInfoPayload,
  AnswersData,
  AnswersPayload,
} from "../types";
import {
  QuizList,
  QuizInfo,
  AnswersList,
  QUIZ_URL,
  QUIZ_INFO_URL,
  ANSWERS_URL,
} from "../constants";

export const useGetQuiz = (topic: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  return useQuery<QuizPayload>([QuizList, topic], () =>
    apiRequest({
      type: "GET_QUIZ",
      request: {
        url: generatePath(QUIZ_URL, { topic }),
        method: "GET",
        headers: {
          "X-User-Outside": sessionKey,
          "X-Locale": lang,
        },
        withPrefix: false,
      },
    })
  );
};

export const useGetQuizInfo = (slug: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  return useQuery<QuizInfoPayload>(
    [QuizInfo, slug],
    () =>
      apiRequest({
        type: "GET_QUIZ_INFO",
        request: {
          url: generatePath(QUIZ_INFO_URL, { slug }),
          method: "GET",
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
          },
          withPrefix: false,
        },
      }),
    {
      enabled: !!slug,
    }
  );
};

export const useGetAnswers = (id: number) => {
  const [apiRequest] = useApiRequest();
  const isUser = isLoggedIn();
  const lang = getLanguage();
  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  return useQuery<AnswersPayload>(
    [AnswersList, isUser, id],
    () =>
      apiRequest({
        type: "GET_ANSWERS",
        request: {
          url: generatePath(ANSWERS_URL, { id }),
          method: "GET",
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
          },
          withPrefix: false,
        },
      }),
    {
      enabled: isUser && !!id,
    }
  );
};

export const usePostAnswers = (id: number) => {
  const queryCache = useQueryCache();
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const { hideModal, showModal } = useModal();
  const isUser = isLoggedIn();
  const lang = getLanguage();
  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  return useMutation(
    (data: AnswersData) => {
      return apiRequest({
        type: "POST_ANSWERS",
        request: {
          url: generatePath(ANSWERS_URL, { id }),
          method: "POST",
          data: data,
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
          },
          withPrefix: false,
        },
      });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries([AnswersList, isUser, id]);
        showModal(quizSuccessModal);
      },
      onError: (error: any) => {
        apiErrors(error);
        hideModal();
      },
    }
  );
};
