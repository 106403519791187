import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  getHeaderLinksAction,
  getHeaderNavMobLinksAction,
  getMenuLinksAction,
} from "./apiActions";
import { getLanguage } from "../../system/translations/helper";

export const useHeaderInfoLinks = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(["header_links", lang], () =>
    getHeaderLinksAction()(dispatch)
  );
};

export const useHeaderNavMobLinks = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(["header_nav_mob_links", lang], () =>
    getHeaderNavMobLinksAction()(dispatch)
  );
};

export const useMenuLinks = (slug: string) => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(["menu_links", slug, lang], () =>
    getMenuLinksAction(slug)(dispatch)
  );
};
