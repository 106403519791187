import React from "react";
import LazyImg from "../../../../components/atoms/LazyImg";
import { _t } from "../../../../system/translations/InjectIntlContext";
import MiniLogoImg from "../../../../images/logos/mini-logo.svg";
import IosSharePointerImg from "../../../../images/icons/ios-share-pointer.svg";
import { ReactComponent as IosShareIcon } from "../../../../images/icons/ios-share.svg";

const PwaAddToHomeModal: React.FC = () => (
  <div className={"pwa-add-to-home-modal-body"}>
    <LazyImg
      imgSrc={MiniLogoImg}
      className={"pwa-add-to-home-modal-body__img"}
      alt={""}
    />
    <h3 className={"pwa-add-to-home-modal-body__title"}>
      {_t("get_app_installer")}
    </h3>
    <p className={"pwa-add-to-home-modal-body__desc"}>
      {_t("browser_install")}
    </p>
    <p className={"pwa-add-to-home-modal-body__text"}>
      {_t("how_add_to_home", {
        icon: <IosShareIcon />,
      })}
    </p>
    <LazyImg
      imgSrc={IosSharePointerImg}
      className={"pwa-add-to-home-modal-body__pointer"}
      alt={""}
    />
  </div>
);

export default PwaAddToHomeModal;
