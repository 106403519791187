import React from "react";
import { useGetBanner } from "../apiHooks";
import ModalBanner from "../../../components/organisms/Modal/ModalBanner";
import { _t } from "../../../system/translations/InjectIntlContext";

interface INewSignUpModal {
  slug?: string;
  size?: string;
}

const SignUpModalBanner: React.FC<INewSignUpModal> = ({ slug, size }) => {
  const { isLoading, data } = useGetBanner(slug);
  const banner = data?.banners[0];

  return (
    <ModalBanner
      size={size}
      className={"sign-up-modal-banner"}
      img={banner?.imageUrl}
      title={_t("reg_banner_title")}
      desc={_t("reg_banner_description")}
      linkTo={banner?.link}
      btnText={banner?.buttonName}
      isLoading={isLoading}
    />
  );
};

export default SignUpModalBanner;
