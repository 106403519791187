import { useMutation, useQuery } from "react-query";
import {
  GamesFeedbackAction,
  GAMES_FEEDBACK_URL,
  GAMES_FEEDBACK,
  RecommendedGames,
  RECOMMENDED_GAMES,
  GET_RECOMMENDED_GAMES,
} from "../constants";
import { useApiRequest } from "../../../../system/hooks/useApiRequest";
import { jsonReplacer, transformGamesResult } from "../helpers";
import { generatePath } from "react-router-dom";
import { getLanguage } from "../../../../system/translations/helper";

export const useSendGamesFeedback = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useMutation((data: GamesFeedbackAction[]) =>
    apiRequest({
      type: GAMES_FEEDBACK,
      request: {
        url: generatePath(GAMES_FEEDBACK_URL, { lang }),
        method: "POST",
        data: JSON.stringify(data, jsonReplacer),
        headers: {
          "content-type": "application/json",
        },
      },
    })
  );
};

export const useGetRecommendedGames = (isUser) => {
  const lang = getLanguage();
  const [apiRequest] = useApiRequest();

  return useQuery(
    [RecommendedGames, lang],
    () =>
      apiRequest(
        {
          type: RECOMMENDED_GAMES,
          request: {
            url: generatePath(GET_RECOMMENDED_GAMES, { lang }),
            method: "GET",
            notifyMessage: {
              error: "ERROR_GET_LANDING_GAME_PROVIDERS",
            },
          },
        },
        {
          normalize: (gameData) =>
            transformGamesResult(gameData, gameData?.casino, "recommended"),
        }
      ),
    {
      enabled: isUser,
    }
  );
};
