import React from "react";
import MainLogo from "../MainLogo";
import classNames from "classnames";

type LoaderProps = {
  withImg?: boolean;
  text?: string;
  overflow?: boolean;
  center?: boolean;
  fullHeight?: boolean;
  minHeight?: number;
};

const Loader: React.FC<LoaderProps> = ({
  withImg,
  text,
  center,
  overflow,
  fullHeight,
  minHeight,
}) => {
  return (
    <div
      className={classNames(
        "loader",
        overflow && "loader--overflow",
        center && "loader--center",
        fullHeight && "loader--full-height"
      )}
      style={minHeight && { minHeight: `${minHeight}px` }}
    >
      {withImg && <MainLogo />}
      <div className={"loader__animation"} />
      {text && <div className={"loader__text"}>{text}</div>}
    </div>
  );
};

Loader.defaultProps = {
  withImg: false,
};

export default Loader;
