import axios from "axios";
import backendUrl from "./apiUrl";
import { pwaDetector } from "../../modules/pwa/helpers";
const host = window.location.hostname;
const isPWA = pwaDetector();
const isWithCredentials = host.includes("bettilt") || host.includes("pwa");
const axiosClient = (withPrefix: boolean = true) =>
  axios.create({
    baseURL: withPrefix ? backendUrl : backendUrl.split("/backend")[1],
    responseType: "json",
    withCredentials: isWithCredentials,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "X-Platform": isPWA ? "PWA" : "Default",
    },
  });

export default axiosClient;
