import React, { useEffect, Suspense, useMemo } from "react";
import { cashboxPaymentType } from "../constants";
import { useGetMethods } from "../apiHooks";
import { isDeposit } from "../helper";
import Loader from "../../../components/atoms/Loader";
import {
  dataLayerDepositOpen,
  dataLayerWithdrawalOpen,
} from "../../../system/dataLayer";
import { sortByFieldName } from "../../tournaments/helpers";
const CashboxPanel = React.lazy(() => import("./CashboxPanel"));

type CashboxTypes = {
  paymentType?: cashboxPaymentType;
  paymentMethod?: string;
  isGameDeposit?: boolean;
};

const Cashbox: React.FC<CashboxTypes> = ({
  paymentType,
  paymentMethod,
  isGameDeposit,
}) => {
  const { data, isLoading } = useGetMethods(paymentType);
  const methods = useMemo(
    () =>
      isDeposit(paymentType)
        ? data?.depositMethods.sort((a, b) => sortByFieldName(a, b, "position"))
        : data?.withdrawalMethods.sort((a, b) =>
            sortByFieldName(a, b, "position")
          ),
    [paymentType, data]
  );

  useEffect(() => {
    if (isDeposit(paymentType)) {
      dataLayerDepositOpen();
    } else {
      dataLayerWithdrawalOpen();
    }
  }, []);

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <Suspense fallback={<Loader center fullHeight />}>
      <CashboxPanel
        paymentsResponce={data}
        paymentType={paymentType}
        methods={methods}
        paymentMethod={paymentMethod}
        isGameDeposit={isGameDeposit}
      />
    </Suspense>
  );
};

Cashbox.defaultProps = {
  paymentType: "deposit",
};

export default Cashbox;
