import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
import { isPublicDomains } from "../../system/helpers/urlHelper";
import config from "../../system/config";

const UserPageTemplate = React.lazy(() => import("./UserPageTemplate"));

const UserPage = () => {
  return (
    <>
      {!isPublicDomains() && (
        <Helmet>
          <title>{_t(config.viptTitleKey)}</title>
        </Helmet>
      )}
      <MainTemplate
        prefixClass={"main-template--user"}
        withoutFooter
        withoutSeoPanel
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <UserPageTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default UserPage;
