import { useState, useEffect } from "react";

const defaultOptions: IntersectionObserverInit = {
  root: document.getElementById("#root"),
  rootMargin: "0px",
  threshold: 0,
};

export const useIntersectionObserver = (options?: IntersectionObserverInit) => {
  const [shown, setShown] = useState<boolean>(false);
  const [target, setTarget] = useState(null);
  const callback: IntersectionObserverCallback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setShown(true);
        observer.unobserve(target?.current);
      }
    });
  };
  const observer = !!window.IntersectionObserver
    ? new IntersectionObserver(callback, {
        ...defaultOptions,
        ...options,
      })
    : null;

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      if (target?.current) {
        observer.observe(target?.current);
      }
      return () => observer.disconnect();
    } else {
      setShown(true);
    }
  }, [target, observer, setShown]);

  return { shown, setTarget };
};
