import { format, parseISO } from "date-fns";

export const getDatePeriod = (start: string, end: string): string =>
  `${format(parseISO(start), "MM/dd/yyyy")} — ${format(
    parseISO(end),
    "MM/dd/yyyy"
  )}`;

export const getTimestamp = (date: string): number =>
  parseISO(date).getTime() / 1000;

export const sortByFieldName = (a, b, fieldName) =>
  a?.[fieldName] - b?.[fieldName];
