import React from "react";
import Btn from "../../../../components/atoms/Btn";
import { _t } from "../../../../system/translations/InjectIntlContext";
import TournamentPrizesContainer from "../TournamentPrizesContainer";
import { TournamentAward } from "../../constants";
import { useModal } from "../../../modals/hooks";

type TournamentPrizesModalProps = {
  awards: TournamentAward[];
  linkTo: string;
};

const TournamentPrizesModal: React.FC<TournamentPrizesModalProps> = ({
  awards,
  linkTo,
}) => {
  const { hideModal } = useModal();
  return (
    <div className={"tournament-prizes-modal-body"}>
      <TournamentPrizesContainer awards={awards} />
      <Btn
        label={_t("play_now")}
        type={"primary"}
        linkTo={linkTo}
        onClick={hideModal}
      />
    </div>
  );
};

export default TournamentPrizesModal;
