import React from "react";
import { _t } from "../../../system/translations/InjectIntlContext";
import LazyImg from "../../atoms/LazyImg";

type GiveawayModalProps = {
  title: string;
  desc: string;
  img: string;
  winners: string[][];
};

const GiveawayModal: React.FC<GiveawayModalProps> = ({
  title,
  desc,
  img,
  winners,
}) => (
  <div className={"giveaway-modal-body"}>
    <div className={"giveaway-modal-body__info"}>
      <div className={"giveaway-modal-body__title"}>{title}</div>
      <div className={"giveaway-modal-body__desc"}>{desc}</div>
      <LazyImg imgSrc={img} className={"giveaway-modal-body__img"} />
    </div>
    <div className={"giveaway-modal-body__winners"}>
      <div className={"giveaway-modal-body__winners-title"}>
        {_t("giveaway_modal_winners")}
      </div>
      <div className={"giveaway-modal-body__winners-list"}>
        {winners.map((winner, index) => (
          <div className={"giveaway-modal-body__winners-item"} key={index}>
            {winner[0]}
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default GiveawayModal;
