import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";

const GiveawayTemplate = React.lazy(() => import("./GiveawayTemplate"));

const GiveawayPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{_t("giveaway_page_title")}</title>
        <meta name={"description"} content={_t("giveaway_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--giveaway"}
        seoPanelProps={{ keySlug: "giveaway" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <GiveawayTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default GiveawayPage;
