import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import generalReducer from "../../modules/general/reducer";
import { generalReducerType } from "../../modules/general/constants";
import userReducer from "../../modules/user/reducer";
import { userReducerType } from "../../modules/user/constants";

export interface ApplicationState {
  router: RouterState;
  general: generalReducerType;
  user: userReducerType;
}

export const rootReducer = (history: History) =>
  combineReducers<ApplicationState>({
    router: connectRouter(history),
    general: generalReducer,
    user: userReducer,
  });

export type RootState = ReturnType<typeof rootReducer>;
