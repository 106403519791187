import axiosClient from "../api/axiosConfig";
import {
  requestStatuses,
  validateRequest,
  requestConfig,
} from "../api/requestHelper";
import { Dispatch, Action } from "redux";
import { logoutSuccessAction } from "../../modules/user/actions";
import { logOutAction } from "../../modules/user/apiActions";
import { isLoggedIn } from "../helpers/userHelper";
import { addToast } from "../helpers/toast";
import { history } from "../store";

export type requestTypes = {
  request?: requestConfig;
  types?: string[] | undefined;
  meta?: {};
};

export type requestMiddlewareAction = Action & requestTypes;

export default ({ dispatch, getState }) => (next: Dispatch) => (
  action: requestMiddlewareAction
) => {
  const state = getState();
  const actReq = action.request;
  if (!actReq) {
    return next(action);
  }
  const statuses = requestStatuses(action.type, action.types);
  validateRequest(actReq);

  next({ meta: action.meta, type: statuses.start }); //start request

  return new Promise((resolve, reject) => {
    axiosClient(actReq.withPrefix)
      .request(actReq)
      .then(
        (response: any) => {
          const successAction = {
            meta: action.meta,
            type: statuses.success,
            payload: response.data,
            resHeaders: response.headers,
          };

          resolve(successAction);

          return next(successAction); //success request
        },
        (error: any) => {
          const errorAction = {
            meta: action.meta,
            type: statuses.error,
            error: error.response,
          };
          if (error?.response?.status === 401) {
            if (isLoggedIn()) {
              logOutAction("/#login")(dispatch);
            } else if (state.user.info.user.sessionKey) {
              logoutSuccessAction()(dispatch);
              history.push("/#login");
            }
          }
          if (actReq?.notifyMessage?.error) {
            addToast(actReq.notifyMessage.error, {
              type: "error",
            }); // responce error message
          }
          reject(errorAction);

          return next(errorAction); //error request
        }
      );
  });
};
