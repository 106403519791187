import { useEffect, useState } from "react";

export const useRootScroll = () => {
  const [pageYOffset, setPageYOffset] = useState(0);
  const rootElem = document.getElementById("root");
  const scrollHandler = () => {
    setPageYOffset(rootElem?.scrollTop);
  };
  useEffect(() => {
    rootElem.addEventListener("scroll", scrollHandler, { passive: true });
    return () => {
      rootElem.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  return { pageYOffset };
};
