import styled, { css } from "styled-components/macro";
import { color } from "../../../system/styles/constants";
import BtnLoader from "./BtnLoader";
import { ButtonProps } from "./types";

export const Label = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
`;

export const Icon = styled.span`
  margin-right: 8px;
`;

export const Loader = styled(BtnLoader)`
  position: absolute;
  top: calc(50% - 20px / 2);
  left: calc(50% - 20px / 2);
  width: 20px;
  height: 20px;
  animation: rotate 2s linear infinite;

  circle {
    stroke: ${color.white};
    stroke-linecap: round;
    stroke-width: 4px;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const Button = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  height: ${({ size }) => (size === "sm" ? "30px" : "44px")};
  padding: 0 20px;
  color: ${color.white};
  border: none;
  border-radius: 44px;
  background-color: transparent;
  outline: none;
  overflow: hidden;
  pointer-events: ${({ loading }) => (loading ? "none" : "inherit")};

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${(props) =>
    props.size === "sm" &&
    css`
      padding: 0 16px;

      ${Label} {
        font-size: 13px;
        line-height: 16px;
      }
    `}

  ${(props) =>
    props.size === "md" &&
    css`
      padding: 9px 18px;
    `}

  ${(props) =>
    props.loading &&
    css`
      ${Label},
      ${Icon} {
        visibility: hidden;
      }
    `}

  ${(props) =>
    props.variant === "primary" &&
    css`
      color: ${color.dark100};
      background: ${color.yellowGradient};

      &:hover {
        background: ${color.yellowLight};
      }

      ${props.loading &&
      css`
        background: ${color.yellow};

        ${Loader} {
          circle {
            stroke: ${color.dark100};
          }
        }
      `}
    `}

  ${(props) =>
    props.variant === "secondary" &&
    css`
      color: ${color.white};
      background: ${color.greenGradient};

      &:hover {
        background: ${color.green};
      }

      ${props.loading &&
      css`
        background: ${color.greenGradient};
      `}
    `}

  ${(props) =>
    props.variant === "outline" &&
    css`
      border: 1px solid ${color.green};
      background: transparent;

      &:hover {
        border-color: ${color.green};
        background: ${color.green};
      }

      ${props.loading &&
      css`
        border-color: ${color.greenGradient};
        background: ${color.greenGradient};
      `};
    `}
`;
