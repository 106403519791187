import React from "react";
import NotificationBlock from "../NotificationBlock";
import CircleCreditCardImg from "../../../images/icons/circle-credit-card.svg";
import { _t } from "../../../system/translations/InjectIntlContext";
import { isExternalURL } from "../../../system/helpers/urlHelper";
import { useHistory } from "react-router-dom";
import { useModal } from "../../../modules/modals/hooks";

type DisablePopupBlockerModalProps = {
  link: string;
};

const DisablePopupBlockerModal: React.FC<DisablePopupBlockerModalProps> = ({
  link,
}) => {
  const { hideModal } = useModal();
  const history = useHistory();
  const onContinueClick = (e) => {
    e.preventDefault();
    if (isExternalURL(link)) {
      window.open(link, "_blank");
      hideModal();
    } else {
      history.push(link);
    }
  };

  return (
    <div className={"disable-popup-blocker"}>
      <div className={"disable-popup-blocker__tabs"}>
        <NotificationBlock
          icon={CircleCreditCardImg}
          titleKey={"disable_popup_blocker_modal_hero_title"}
          btnType={"secondary"}
          link={link}
          linkAfterBtn={
            <a
              href={link}
              className={"disable-popup-blocker__btn-link"}
              onClick={onContinueClick}
            >
              {_t("disable_popup_blocker_modal_hero_btn")}
            </a>
          }
          btnBlock
        />
      </div>
    </div>
  );
};

export default DisablePopupBlockerModal;
