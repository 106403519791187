import { FormikState, FormikValues, FormikHandlers } from "formik";

export interface useFormikHelperResult {
  error: (arg: string) => string;
  onChange: (arg: string, val: any) => any;
  onBlur: (arg: string) => any;
}

type formikType = FormikState<{}> & FormikValues & FormikHandlers;

export const useFormikHelper = (formik: formikType): useFormikHelperResult => {
  const {
    errors,
    touched,
    status,
    setFieldTouched,
    setStatus,
    setFieldValue,
  } = formik;
  const error = (name) =>
    (touched?.[name] && errors?.[name]) || (touched?.[name] && status?.[name]);
  const onChange = (name, value) => {
    setFieldValue(name, value);
    setStatus({ ...status, [name]: "" });
  };
  const onBlur = (name) => {
    setFieldTouched(name);
    setStatus({ ...status, [name]: "" });
  };
  return { error, onChange, onBlur };
};
