import React from "react";
import Btn from "../../atoms/Btn";
import NotificationBlock from "../NotificationBlock";
import checkmark from "../../../images/icons/approved_checkmark_modal.svg";
import { useModal } from "../../../modules/modals/hooks";

export type ComplatedPaymentModalProps = {
  paymentId: string;
  titleKey: string;
  textKey: string;
  textMod: string;
  descriptionText: string;
  btnText: string;
};

const ComplatedPaymentModal: React.FC<ComplatedPaymentModalProps> = ({
  paymentId,
  titleKey,
  textKey,
  textMod,
  descriptionText,
  btnText,
}) => {
  const { hideModal } = useModal();

  const notificationProps = {
    icon: checkmark,
    titleKey: titleKey,
    textKey: textKey,
    textMod: textMod || "dim",
  };

  return (
    <div className={"complated-modal"}>
      <NotificationBlock withOutTrans {...notificationProps} />
      <div className={"complated-modal__payment-id"}>{paymentId}</div>
      <div className={"complated-modal__description"}>{descriptionText}</div>
      <div className={"complated-modal__buttons"}>
        <Btn
          size={"lg"}
          type={"primary"}
          onClick={hideModal}
          label={btnText}
          block
        />
      </div>
    </div>
  );
};

export default ComplatedPaymentModal;
