import SportIcon from "../../../images/icons/categories/basketball-ball.svg";
import SportIconActive from "../../../images/icons/categories/basketball-ball-active.svg";
import InPlayIcon from "../../../images/icons/categories/in-play.svg";
import InPlayIconActive from "../../../images/icons/categories/in-play-active.svg";
import CasinoIcon from "../../../images/icons/categories/casino.svg";
import CasinoIconActive from "../../../images/icons/categories/casino-active.svg";
import Promo from "../../../images/icons/categories/promo.svg";
import PromoActive from "../../../images/icons/categories/promo-active.svg";
import SupportIcon from "../../../images/icons/categories/support.svg";
import Euro2020Icon from "../../../images/icons/categories/euro2020.svg";
import BnpBpOpenIcon from "../../../images/icons/categories/bnp-paribas-open.svg";
import LeagueIcon from "../../../images/leagueBettilt/league-bt.svg";
import FifaIcon from "../../../images/leagueBettilt/fifa.svg";
import FifaCupIcon from "../../../images/leagueBettilt/fifa-cup.svg";
import ChristmasIcon from "../../../images/christmas-calendar/christmas-icon.png";
import AviatorPlaneIcon from "../../../images/menu-icons/aviator_plane.svg";
import GiveawayIcon from "../../../images/giveaway/giveaway.svg";
import HalloweenIcon from "../../../images/events-calendar/halloween.svg";
import LoyaltyIcon from "../../../images/loyaltySystem/loyalty-system-logo.svg";
import CricketIcon from "../../../images/quiz/cricket-icon.svg";
import { path } from "../../../system/helpers/pathHelper";

export const navbarItems = [
  { name: "header_sport", url: path("/sportsbook") },
  { name: "header_in_play", url: path("/in-play") },
  /* { name: "Betgames", url: "/betgames" }, */
  { name: "header_casino", url: path("/casino") },
  { name: "header_live_casino", url: path("/casino/live-casino") },
  { name: "header_promotions", url: path("/bonuses") },
];

export const footerNavItemsMobile = {
  menuSports: { icon: SportIcon, iconActive: SportIconActive },
  menuInPlay: { icon: InPlayIcon, iconActive: InPlayIconActive },
  menuCasino: { icon: CasinoIcon, iconActive: CasinoIconActive },
  Bonuses: { icon: Promo, iconActive: PromoActive },
  support: { icon: SupportIcon, iconActive: SupportIcon },
};

export const headerNavItems = {
  euro2020: { icon: Euro2020Icon },
  leagueBettilt: { icon: LeagueIcon },
  loyaltySystem: { icon: LoyaltyIcon },
  bnpBpOpen: { icon: BnpBpOpenIcon },
  fifaLeague: { icon: FifaIcon },
  giveaway: { icon: GiveawayIcon },
  halloween: { icon: HalloweenIcon },
  aviatorIcon: { icon: AviatorPlaneIcon },
  fifaCup: { icon: FifaCupIcon },
  christmas: { icon: ChristmasIcon },
  quiz: { icon: CricketIcon },
};
