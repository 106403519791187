import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { _t } from "../../system/translations/InjectIntlContext";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { useGetBootstrap } from "../../modules/general/apiHooks";
import CountryErrorPage from "../errorPages/CountryErrorPage";
import { isMobile } from "react-device-detect";

const VipBettiltTemplate = React.lazy(() => import("./VipBettiltTemplate"));

const VipBettiltPage = () => {
  const { data, isLoading } = useGetBootstrap();

  if (isLoading) {
    return (
      <Loader withImg center fullHeight minHeight={isMobile ? 338 : 544} />
    );
  }

  // if (!data?.country || data?.country !== "IN") {
  //   return <CountryErrorPage />;
  // }

  return (
    <>
      <Helmet>
        <title>{_t("vip_bettilt__page_title")}</title>
        <meta
          name="description"
          content={_t("event_leaderboard_page_description")}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--vip-bettilt"}
        seoPanelProps={{ keySlug: "vip_bettilt" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <VipBettiltTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default VipBettiltPage;
