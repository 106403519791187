import { getBannerAction } from "./apiActions";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { bannerTypes } from "./constants";
import { getLanguage } from "../../system/translations/helper";

const BannerList = "banners";

export const useGetBanner = (banner: bannerTypes) => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([BannerList, banner, lang], () =>
    getBannerAction(banner)(dispatch)
  );
};
