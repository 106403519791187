import React from "react";
import { useHeaderInfoLinks, useHeaderNavMobLinks } from "../apiHooks";
import Header from "../../../components/organisms/Header";

const HeaderWrap = () => {
  const { data: headerInfoLinksData } = useHeaderInfoLinks();
  const { data: headerNavMobLinksData } = useHeaderNavMobLinks();

  return (
    <Header
      links={headerInfoLinksData?.links}
      linksNavMob={headerNavMobLinksData?.links}
      linksLoaded={
        !!headerInfoLinksData?.links && !!headerNavMobLinksData?.links
      }
    />
  );
};

export default HeaderWrap;
