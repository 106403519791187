import React, { Suspense } from "react";
import "normalize.css";
import "react-toastify/dist/ReactToastify.css";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import configureStore from "./system/store";
import routes from "./system/router/routes";
import { history } from "./system/store";
import { ReactQueryConfigProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import "./styles/main.scss";
import Loader from "./components/atoms/Loader";
const SystemTemplate = React.lazy(() => import("./templates/System"));

const store = configureStore();
const queryConfig = {
  queries: { refetchOnWindowFocus: false, retry: 0, staleTime: 10 * 60 * 1000 },
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ReactQueryConfigProvider config={queryConfig}>
        <ConnectedRouter history={history}>
          <Suspense fallback={<Loader center withImg fullHeight />}>
            <SystemTemplate>{routes}</SystemTemplate>
          </Suspense>
        </ConnectedRouter>
      </ReactQueryConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </Provider>
  );
};

export default App;
