export const INFO_URL = "/share/content/pages";
export const INFO_BY_SLUG_URL = "/share/content/pages/:slug";

export const INFO_LIST = "info_list";
export const INFO_BY_SLUG = "info_by_slug";

export const infoTypesArr = [
  "header-menu",
  "sidebar-menu",
  "sidebar-bottom",
  "mobile-nav-bar",
  "footer-menu",
  "footer-info",
  "welcome-sports",
  "main-sports",
  "live-games",
  "live-chat",
];

export type infoMenuTypes =
  | "header-menu"
  | "sidebar-menu"
  | "sidebar-bottom"
  | "mobile-nav-bar"
  | "footer-menu"
  | "footer-info"
  | "welcome-sports"
  | "main-sports"
  | "live-games"
  | "live-chat";

export type infoItemData = {
  slug: string;
  url: string;
  title: string;
  image?: {
    normal: string;
    active?: string;
  };
  display_condition: string;
  display_position: {
    top_menu: number;
    main_menu: number;
  };
  text?: string;
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;
  seo_panel_title?: string;
  seo_panel_text?: string;
};

export type infoPageBySlugPayloadData = {
  originData: infoItemData;
};

export type infoPagesPayloadData = {
  originData: infoItemData[];
  infoBySlug: {
    [slug in infoMenuTypes]?: infoItemData[];
  };
};

export type seoLinkTypes = {
  seoTitle: string;
  seoDescription: string;
  seoText: string;
  imageUrl: string;
};
