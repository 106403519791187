import React, { Suspense } from "react";
import { useLogin } from "../../apiHooks";
import { loginRequestKeys } from "../../constants";
import config from "../../../../system/config";
import Loader from "../../../../components/atoms/Loader";
import { dataLayerLoginAuthClick } from "../../../../system/dataLayer";
import { useFingerprint } from "../../../../system/hooks/useFingerprint";

const LoginForm = React.lazy(() => import("../forms/LoginForm"));

type loginModalTypes = {
  redirectTo?: string;
  autocomplete?: "on" | "off" | "new-password";
  showOnSuccess?: boolean;
};

const LoginModal: React.FC<loginModalTypes> = ({
  redirectTo,
  autocomplete,
  showOnSuccess,
}) => {
  const [loginHandler, { isLoading }] = useLogin(
    redirectTo || config.user.redirectAfterLogin,
    showOnSuccess
  );
  const { isLoading: isFpLoading, visitorId } = useFingerprint();
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append(loginRequestKeys.username, values.username);
    formData.append(loginRequestKeys.password, values.password);
    formData.append(loginRequestKeys.fingerprintId, visitorId);
    formData.append("override_flag", values.override_flag);
    loginHandler(formData);
    dataLayerLoginAuthClick();
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <LoginForm
        onSubmit={onSubmit}
        isLoading={isLoading || isFpLoading}
        autocomplete={autocomplete}
      />
    </Suspense>
  );
};

LoginModal.defaultProps = {
  showOnSuccess: config.isShowAfterLoginModal,
};

export default LoginModal;
