export const APP_ROUTES = {
  startPage: ["/:lang/", ""],
  sportsbook: "/:lang/sportsbook",
  inPlay: "/:lang/in-play",
  promo: ["/:lang/bonuses", "/bonuses", "/:lang/bonuses/:id", "/bonuses/:id"],
  promoInfo: ["/:lang/bonuses/:id", "/bonuses/:id"],
  tournaments: "/:lang/tournaments",
  tournamentInfo: "/:lang/tournaments/:id",
  onlineCasino: "/:lang/online-casino",
  liveGames: {
    gamesPage: "/:lang/live-games/:slug",
    betGames: "/:lang/live-games/betgames",
    kiron: "/:lang/live-games/kiron",
    tvbet: "/:lang/live-games/tvbet",
  },
  infoPages: "/:lang/info/:id",
  casinoPage: {
    allGames: "/:lang/casino",
    category: "/:lang/casino/:categorySlug",
    gameTag: "/:lang/casino/gametag/:gameTagSlug",
    favorites: "/:lang/casino/favorites",
    recommended: "/:lang/casino/recommended",
  },
  userPage: {
    profilePage: "/:lang/profile",
    profile: [
      "/:lang/profile/personal-info",
      "/:lang/profile/kyc",
      "/:lang/profile/security-settings",
    ],
    personalInfo: "/:lang/profile/personal-info",
    kyc: "/:lang/profile/kyc",
    securitySettings: "/:lang/profile/security-settings",
    wallet: [
      "/:lang/profile/wallet/deposit",
      "/:lang/profile/wallet/withdrawal",
      "/:lang/profile/wallet/transactions",
    ],
    deposit: "/:lang/profile/wallet/deposit",
    withdrawal: "/:lang/profile/wallet/withdrawal",
    transactions: "/:lang/profile/wallet/transactions",
    bonuses: "/:lang/profile/bonuses",
    history: ["/:lang/profile/history/sport", "/:lang/profile/history/casino"],
    sportHistory: "/:lang/profile/history/sport",
    casinoHistory: "/:lang/profile/history/casino",
  },
  gamePage: "/:lang/game/:gameSlug/:playType/:gameProvider",
  resetPassword: ["/:lang/reset-password", "/reset-password"],
  error404: "/:lang/404",
  pwaLanding: "/:lang/install-app",
  euro2020: "/:lang/euro2020",
  leagueBettilt: "/:lang/league-bettilt",
  vipBettilt: "/:lang/vip-bettilt",
  loyaltySystem: "/:lang/vip",
  signIn: "/:lang/sign-in",
  signUp: "/:lang/sign-up",
  giftsCalendar: "/:lang/christmas",
  eventsCalendar: "/:lang/halloween",
  giveaway: "/:lang/festival-giveaway",
  quiz: "/:lang/ipl-2023",
};

export type routerLocation = {
  hash: string;
  key: string;
  pathname: string;
  query: {};
  search: string;
  state: string | undefined;
};
