import NetentIcon from "../../../images/icons/poviders/netent.svg";
import MicrogamingIcon from "../../../images/icons/poviders/microgaming.svg";
import BetgameIcon from "../../../images/icons/poviders/betgame.svg";
import NovomaticIcon from "../../../images/icons/poviders/novomatic.svg";
import Apollo from "../../../images/icons/poviders/apollo_icon.svg";
import Fazi from "../../../images/icons/poviders/fazi.svg";
import PlayGoIcon from "../../../images/icons/poviders/play-n-go.svg";
import YggrasilIcon from "../../../images/icons/poviders/yggrasil.svg";
import Reevo from "../../../images/icons/poviders/Reevo_icon.svg";
import Fugaso from "../../../images/icons/poviders/Fugaso_icon.svg";
import TrBetTvIcon from "../../../images/icons/poviders/trbet-tv.svg";
import TomHornIcon from "../../../images/icons/poviders/tomhorngaming.svg";
import GamingCorps from "../../../images/icons/poviders/gaming-corps.svg";
import Aviatrix from "../../../images/icons/poviders/Aviatrix_icon.svg";
import Hacksaw from "../../../images/icons/poviders/hacksaw.svg";
import RelaxGamingIcon from "../../../images/icons/poviders/relax-gaming.svg";
import PragmaticIcon from "../../../images/icons/poviders/pragmatic.svg";
import OryxIcon from "../../../images/icons/poviders/oryx.svg";
import NoLimitCityIcon from "../../../images/icons/poviders/no-limit-city.svg";
import EvolutionCasinoIcon from "../../../images/icons/poviders/evolution-casino.svg";
import OnexTwoGaming from "../../../images/icons/poviders/1x2_Gaming_icon.svg";
import betgamesTV from "../../../images/icons/poviders/betgames-tv-icon.svg";
import betsoft from "../../../images/icons/poviders/betsoft_icon.svg";
import BigTimeGaming from "../../../images/icons/poviders/BigTimeGaming_iocn.svg";
import BoomingGames from "../../../images/icons/poviders/Booming_games_icon.svg";
import Boongo from "../../../images/icons/poviders/Boongo_icon.svg";
import EGT from "../../../images/icons/poviders/EGT_icon.svg";
import ELK from "../../../images/icons/poviders/ELK_icon.svg";
import Endorphina from "../../../images/icons/poviders/Endorphina_icon.svg";
import BGaming from "../../../images/icons/poviders/BGaming_icon.svg";
import EvolutionCasino from "../../../images/icons/poviders/Evolution_Casino_icon.svg";
import Evoplay from "../../../images/icons/poviders/Evoplay_icon.svg";
import EZUGI from "../../../images/icons/poviders/EZUGI_icon.svg";
import Gameart from "../../../images/icons/poviders/Gameart_icon.svg";
import gamomat from "../../../images/icons/poviders/gamomat_icon.svg";
import Genii from "../../../images/icons/poviders/Genii_icon.svg";
import givme_games from "../../../images/icons/poviders/givme_games_icon.svg";
import GoldenHero from "../../../images/icons/poviders/GoldenHero_icon.svg";
import Habanero from "../../../images/icons/poviders/Habanero_icon.svg";
import ISOFTBET from "../../../images/icons/poviders/ISOFTBET_icon.svg";
import Kalamba from "../../../images/icons/poviders/Kalamba_icon.svg";
import Kiron from "../../../images/icons/poviders/kiron_icon.svg";
import Maverick from "../../../images/icons/poviders/Maverick_icon.svg";
import MicroGaming from "../../../images/icons/poviders/MicroGaming_icon.svg";
import netent from "../../../images/icons/poviders/netent_icon.svg";
import No_Limit_City from "../../../images/icons/poviders/No_Limit_City_icon.svg";
import NYX from "../../../images/icons/poviders/NYX_icon.svg";
import oryx from "../../../images/icons/poviders/oryx_icon.svg";
import PlankGaming from "../../../images/icons/poviders/PlankGaming_icon.svg";
import PlaynGo from "../../../images/icons/poviders/PlaynGo_icon.svg";
import playson from "../../../images/icons/poviders/playson_icon.svg";
import Playtech from "../../../images/icons/poviders/playtech_icon.svg";
import PlaytechLive from "../../../images/icons/poviders/playtech_live_icon.svg";
import Pragmatic from "../../../images/icons/poviders/Pragmatic_icon.svg";
import PushGaming from "../../../images/icons/poviders/PushGaming_icon.svg";
import Quickspin from "../../../images/icons/poviders/Quickspin_icon.svg";
import Red7 from "../../../images/icons/poviders/Red7_icon.svg";
import Redrake from "../../../images/icons/poviders/Redrake_icon.svg";
import RelaxGaming from "../../../images/icons/poviders/RelaxGaming_icon.svg";
import Spinomenal from "../../../images/icons/poviders/Spinomenal_icon.svg";
import Spribe from "../../../images/icons/poviders/Spribe_icon.svg";
import Tomhorngaming from "../../../images/icons/poviders/Tomhorngaming_icon.svg";
import TVBET from "../../../images/icons/poviders/TVBET_icon.svg";
import VIG from "../../../images/icons/poviders/VIG_icon.svg";
import VivoGaming from "../../../images/icons/poviders/VivoGaming_icon.svg";
import Yggrasil from "../../../images/icons/poviders/Yggrasil_icon.svg";
import WazdanIcon from "../../../images/icons/poviders/Wazdan_icon.svg";
import PgsoftIcon from "../../../images/icons/poviders/pgsoft.svg";
import BluePrintIcon from "../../../images/icons/poviders/blueprint_icon.svg";
import Swintt from "../../../images/icons/poviders/swint.svg";
import GGames from "../../../images/icons/poviders/G-games_Logo.svg";
import GamesInc from "../../../images/icons/poviders/gameinc.svg";
import RedTiger from "../../../images/icons/poviders/RedTiger.svg";
import Amatic from "../../../images/icons/poviders/amatic.svg";
import RubyPlay from "../../../images/icons/poviders/RubyPlay_icon.svg";
import BetSolutions from "../../../images/icons/poviders/betsolutions-iсon.svg";

import OneXTwoBg from "../../../images/icons/providers-with-bg/1x2.jpg";
import BetGamesBg from "../../../images/icons/providers-with-bg/BetGames.jpg";
import BetsoftBg from "../../../images/icons/providers-with-bg/Betsoft.jpg";
import BetsolutionsBg from "../../../images/icons/providers-with-bg/Betsolutions.jpg";
import BgamingBg from "../../../images/icons/providers-with-bg/Bgaming.jpg";
import BigTimeGamingBg from "../../../images/icons/providers-with-bg/BigTimeGaming.jpg";
import BoomingBg from "../../../images/icons/providers-with-bg/Booming.jpg";
import BoongoBg from "../../../images/icons/providers-with-bg/Boongo.jpg";
import egtBg from "../../../images/icons/providers-with-bg/egt.jpg";
import EndorphonaBg from "../../../images/icons/providers-with-bg/Endorphona.jpg";
import evolutionBg from "../../../images/icons/providers-with-bg/evolution.jpg";
import evoplayBg from "../../../images/icons/providers-with-bg/evoplay.jpg";
import EzugiBg from "../../../images/icons/providers-with-bg/Ezugi.jpg";
import GameartBg from "../../../images/icons/providers-with-bg/Gameart.jpg";
import GamingCorpsBg from "../../../images/icons/providers-with-bg/Gaming Corps.jpg";
import GatomatBg from "../../../images/icons/providers-with-bg/Gatomat.jpg";
import GoldenHeroBg from "../../../images/icons/providers-with-bg/Golden Hero.jpg";
import HabaneroBg from "../../../images/icons/providers-with-bg/Habanero.jpg";
import HacksawBg from "../../../images/icons/providers-with-bg/Hacksaw.jpg";
import KalambaBg from "../../../images/icons/providers-with-bg/Kalamba.jpg";
import KironBg from "../../../images/icons/providers-with-bg/Kiron.jpg";
import microgamingBg from "../../../images/icons/providers-with-bg/microgaming.jpg";
import NolimitBg from "../../../images/icons/providers-with-bg/Nolimit.jpg";
import OryxBg from "../../../images/icons/providers-with-bg/Oryx.jpg";
import PlaysonBg from "../../../images/icons/providers-with-bg/Playson.jpg";
import PragmaticPlayBg from "../../../images/icons/providers-with-bg/Pragmatic Play.jpg";
import PragmaticPlayLiveBg from "../../../images/icons/providers-with-bg/Pragmatic Play Live.jpg";
import TvBetBg from "../../../images/icons/providers-with-bg/TvBet.jpg";
import RedrakeBg from "../../../images/icons/providers-with-bg/Redrake.jpg";
import RelaxBg from "../../../images/icons/providers-with-bg/Relax.jpg";
import SpinomenalBg from "../../../images/icons/providers-with-bg/Spinomenal.jpg";
import SpribeBg from "../../../images/icons/providers-with-bg/Spribe.jpg";
import Yggdrasil from "../../../images/icons/providers-with-bg/Yggdrasil.jpg";

export const providersIcons = {
  ONE_TWO_GAMING: OnexTwoGaming,
  GGames: GGames,
  GAMESINC: GamesInc,
  Apollo: Apollo,
  FAZI: Fazi,
  "Gaming Corps": GamingCorps,
  BETSOLUTIONS: BetSolutions,
  FUGASO: Fugaso,
  RubyPlay: RubyPlay,
  Reevo: Reevo,
  Bgaming: BGaming,
  AMATIC: Amatic,
  SWINTT: Swintt,
  RedTiger: RedTiger,
  "1x2": OnexTwoGaming,
  BETGAMES_TV: betgamesTV,
  BETSOFT: betsoft,
  BIG_TIME_GAMING: BigTimeGaming,
  BIG_TIME_GAMING_R: BigTimeGaming,
  BOOMING_GAMES: BoomingGames,
  "Booming Games": BoomingGames,
  BOONGO: Boongo,
  Booongo: Boongo,
  EGT_CAS: EGT,
  ELK: ELK,
  ENDORPHINA: Endorphina,
  EVOLUTIONCASINO: EvolutionCasino,
  EVOPLAY: Evoplay,
  EZUGI: EZUGI,
  GameArt: Gameart,
  GAM: gamomat,
  GENII: Genii,
  Genii: Genii,
  GIVME_GAMES: givme_games,
  GOLDE_NHERO: GoldenHero,
  HABANERO: Habanero,
  AVIATRIX: Aviatrix,
  Hacksaw: Hacksaw,
  ISOFTBET: ISOFTBET,
  KALAMBA: Kalamba,
  KRN: Kiron,
  MAVERICK: Maverick,
  MICROGAMING: MicroGaming,
  NETENT: netent,
  no_limit_city: No_Limit_City,
  NYX: NYX,
  ORYX: oryx,
  "PLANK GAMING": PlankGaming,
  PLAYN_GO: PlaynGo,
  PLAYSON: playson,
  PLAYTECH: Playtech,
  PLAYTECHLIVE: PlaytechLive,
  PRAGMATIC: Pragmatic,
  PUSH_GAMING: PushGaming,
  QSPIN: Quickspin,
  RED7: Red7,
  REDRAKE: Redrake,
  "Red Rake": Redrake,
  RELAX_GAMING: RelaxGaming,
  SPINOMENAL: Spinomenal,
  SPRIBE: Spribe,
  TOMHORNGAMING: Tomhorngaming,
  TVBET: TVBET,
  VIG: VIG,
  VIVO_GAMING: VivoGaming,
  YGGRASIL: Yggrasil,
  WAZDAN: WazdanIcon,
  "Push Gaming": PushGaming,
  PGSoft: PgsoftIcon,
  KLM: Kalamba,
  Habanero: Habanero,
  GHG: GoldenHero,
  Blueprint: BluePrintIcon,
  // old
  NETENT_CAS: NetentIcon,
  QFIRE_CAS: MicrogamingIcon,
  PLAYNGO: PlayGoIcon,
  VNET: NovomaticIcon,
  BETGAMES: BetgameIcon,
  YGGDRASIL: YggrasilIcon,
  TRBETTV: TrBetTvIcon,
  TOMHORN: TomHornIcon,
  RELAXGAMING: RelaxGamingIcon,
  PRAGMATIC_CASINO: PragmaticIcon,
  PRAGMATIC_LIVE: PragmaticIcon,
  ORYX_CAS: OryxIcon,
  NOLIMIT_CITY: NoLimitCityIcon,
  "No Limit City": NoLimitCityIcon,
  EVO_CAS: EvolutionCasinoIcon,
};

export const providersWithBg = {
  "1x2": OneXTwoBg,
  BETGAMES: BetGamesBg,
  BETSOFT: BetsoftBg,
  BETSOLUTIONS: BetsolutionsBg,
  Bgaming: BgamingBg,
  BIG_TIME_GAMING_R: BigTimeGamingBg,
  "Booming Games": BoomingBg,
  Booongo: BoongoBg,
  EGT_CAS: egtBg,
  ENDORPHINA: EndorphonaBg,
  EVO_CAS: evolutionBg,
  EVOPLAY: evoplayBg,
  EZUGI: EzugiBg,
  GameArt: GameartBg,
  "Gaming Corps": GamingCorpsBg,
  GAM: GatomatBg,
  GHG: GoldenHeroBg,
  Habanero: HabaneroBg,
  Hacksaw: HacksawBg,
  KLM: KalambaBg,
  KRN: KironBg,
  QFIRE_CAS: microgamingBg,
  "No Limit City": NolimitBg,
  ORYX: OryxBg,
  PLAYSON: PlaysonBg,
  PRAGMATIC_CASINO: PragmaticPlayBg,
  PRAGMATIC_LIVE: PragmaticPlayLiveBg,
  TVBET: TvBetBg,
  "Red Rake": RedrakeBg,
  RELAXGAMING: RelaxBg,
  SPINOMENAL: SpinomenalBg,
  SPRIBE: SpribeBg,
  YGGDRASIL: Yggdrasil,
};

export const hiddenFilterProviders = { VNET: true };
