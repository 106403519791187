import * as Yup from "yup";
import { transType } from "../../../../system/translations/InjectIntlContext";
import {
  isValidUsername,
  isValidPassword,
  isValidEmail,
  isValidPhone,
  isValidRecaptcha,
  isValidBirthDate,
  isValidLoginUsername,
  isValidPostalCode,
  isValidFirstLastName,
  isValidCity,
  isValidAddress,
} from "../../../../system/helpers/validationHelper";
import {
  onlyDigitsRegExp,
  requiredKYCFieldsTypes,
  userInfoType,
} from "../../constants";

export type errorsType = {
  field: string;
  message: string;
};

const KYC_FILE_SIZE = 15000 * 1024;
const KYC_SUPPORTED_FORMATS = {
  v2: ["application/pdf"],
  v1: ["image/jpg", "image/jpeg", "image/png"],
};

export const LoginSchema = (_t: transType) =>
  Yup.object().shape({
    username: isValidLoginUsername(_t).required(_t("required_username")),
    password: isValidPassword(_t).required(_t("required_password")),
  });

export const TfaProcessSchema = (_t: transType) =>
  Yup.object().shape({
    code: Yup.string().required(_t("required")),
  });

export const RegSchema = (_t: transType, restriction, numberOfDigits: number) =>
  Yup.object().shape({
    username: isValidUsername(_t).required(_t("required_username")),
    password: isValidPassword(_t).required(_t("required_password")),
    phone: isValidPhone(_t, numberOfDigits).required(_t("required_phone")),
    email: isValidEmail(_t).required(_t("required_email")),
    recaptcha: restriction.isShowCaptcha
      ? isValidRecaptcha(_t)
      : Yup.mixed().notRequired(),
    agreeTermAndConditions: Yup.boolean().oneOf(
      [true],
      _t("error_dont_accept_terms")
    ),
  });

export const ChangeTfaDataSchema = (_t: transType) =>
  Yup.object().shape({
    mobilePhone: isValidPhone(_t).required(_t("required_phone")),
    email: isValidEmail(_t).required(_t("required_email")),
  });

export const ChangePasswordSchema = (_t: transType) =>
  Yup.object().shape({
    oldPassword: isValidPassword(_t).required(_t("required")),
    password: isValidPassword(_t)
      .notOneOf([Yup.ref("oldPassword"), null], _t("passwords_must_not_match"))
      .required(_t("required")),
    repeatPassword: isValidPassword(_t)
      .oneOf([Yup.ref("password"), null], _t("passwords_must_match"))
      .required(_t("required")),
  });

export const lpCashInSchema = (
  _t: transType,
  currentLp: string,
  minAmount: number
) =>
  Yup.object().shape({
    convertAmount: Yup.string()
      .required(_t("required"))
      .test(
        "lower-than-current-lp",
        _t("not_enough_loyalty_points"),
        (value) => Number(value) <= Number(currentLp)
      )
      .test(
        "lower-than-minimum-exchange-amount",
        _t("min_amount_exchange_error", { minAmount: minAmount }),
        (value) => Number(value) >= minAmount
      ),
  });

export const ProfileSchema = (_t: transType) =>
  Yup.object().shape({
    firstName: isValidFirstLastName(_t).required(_t("required")),
    lastName: isValidFirstLastName(_t).required(_t("required")),
    gender: Yup.string().required(_t("required")),
    birthDateDay: Yup.string().required(_t("required")),
    birthDateMonth: Yup.string().required(_t("required")),
    birthDateYear: Yup.string().required(_t("required")),
    country: Yup.string().required(_t("required")),
    city: isValidCity(_t).required(_t("required")),
    postalCode: isValidPostalCode(_t),
    address: isValidAddress(_t).required(_t("required")),
    mobilePhone: isValidPhone(_t).required(_t("required")),
  });

export const CroppedProfileSchema = (_t) =>
  Yup.object().shape({
    firstName: isValidFirstLastName(_t).required(_t("required")),
    lastName: isValidFirstLastName(_t).required(_t("required")),
  });

export const ProfileEditSchema = (_t: transType) =>
  Yup.object().shape({
    username: isValidLoginUsername(_t).required(_t("required")),
    firstName: isValidFirstLastName(_t).required(_t("required")),
    lastName: isValidFirstLastName(_t).required(_t("required")),
    birthDate: isValidBirthDate(_t).required(_t("required")),
    address: isValidAddress(_t).required(_t("required")),
    city: isValidCity(_t).required(_t("required")),
    country: Yup.string().required(_t("required")),
    postalCode: isValidPostalCode(_t),
  });

export const PaymentHistorySchema = (_t: transType) =>
  Yup.object().shape({
    startDate: Yup.date()
      .max(Yup.ref("endDate"), _t("start_date_limit"))
      .required(_t("required")),
    endDate: Yup.date().required(_t("required")),
  });

export const VerifyPhoneSchema = (_t: transType) =>
  Yup.object().shape({
    code: Yup.string()
      .required(_t("verify_phone_code_required"))
      .matches(/^[0-9]+$/, _t("must_be_a_number"))
      .min(4, _t("verify_phone_require_four_digit_code"))
      .max(4, _t("verify_phone_require_four_digit_code")),
  });

export const ForgotPasswordSchema = (_t: transType) =>
  Yup.object().shape({
    email: isValidEmail(_t).required(_t("required")),
  });

export const ResetPasswordSchema = (_t: transType) =>
  Yup.object().shape({
    password: isValidPassword(_t).required(_t("required")),
    repeatPassword: isValidPassword(_t)
      .oneOf([Yup.ref("password"), null], _t("passwords_must_match"))
      .required(_t("required")),
  });

const isValidKycFile = (_t: transType, variant: string = "v1") =>
  Yup.mixed()
    .test("fileFormat", _t("unsupported_format"), (value) =>
      value && value[0]
        ? KYC_SUPPORTED_FORMATS[variant].includes(value[0].type)
        : true
    )
    .test("fileSize", _t("file_too_large"), (value) =>
      value && value[0] ? value[0].size <= KYC_FILE_SIZE : true
    );

export const KycSchema = (
  _t: transType,
  requiredFields: requiredKYCFieldsTypes,
  variant: string
) => {
  return variant === "v2"
    ? Yup.object().shape({
        frontSideCreditCardScan: requiredFields.frontSideCreditCardScan
          ? isValidKycFile(_t).required(_t("required"))
          : isValidKycFile(_t).notRequired(),
        idType: requiredFields.idType
          ? Yup.string().required(_t("required"))
          : Yup.string().notRequired(),
        idTypeScan: requiredFields.idTypeScan
          ? isValidKycFile(_t).required(_t("required"))
          : isValidKycFile(_t).notRequired(),
        idTypeScanBack: Yup.mixed().when("idType", {
          is: (idType) => idType === "form.kyc.id.aadhar_card",
          then: isValidKycFile(_t).required(_t("required")),
          otherwise: isValidKycFile(_t).notRequired(),
        }),
        documentType: requiredFields.documentType
          ? Yup.string().required(_t("required"))
          : Yup.string().when("documentTypeScan", {
              is: (documentTypeScan) => documentTypeScan,
              then: Yup.string().required(_t("required")),
              otherwise: Yup.string().notRequired(),
            }),
        documentTypeScan: requiredFields.documentTypeScan
          ? isValidKycFile(_t, variant).required(_t("required"))
          : isValidKycFile(_t, variant).notRequired(),
      })
    : Yup.object().shape({
        frontSideCreditCardScan: isValidKycFile(_t),
        backSideCreditCardScan: isValidKycFile(_t),
        idType: requiredFields.idType
          ? Yup.string().required(_t("required"))
          : Yup.string().notRequired(),
        idTypeScan: requiredFields.idTypeScan
          ? isValidKycFile(_t).required(_t("required"))
          : isValidKycFile(_t).notRequired(),
        documentType: requiredFields.documentType
          ? Yup.string().required(_t("required"))
          : Yup.string().when("documentTypeScan", {
              is: (documentTypeScan) => documentTypeScan,
              then: Yup.string().required(_t("required")),
              otherwise: Yup.string().notRequired(),
            }),
        documentTypeScan: requiredFields.documentTypeScan
          ? isValidKycFile(_t).required(_t("required"))
          : isValidKycFile(_t).notRequired(),
      });
};

export type birthDateType = {
  day: string | number;
  month: string | number;
  year: string | number;
};

export type initialValuesType = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  birthDate: Date;
  address: string;
  city: string;
  postalCode: string;
  country: string;
};

export interface formDataType extends Omit<initialValuesType, "birthDate"> {
  birthDate: birthDateType;
}

export type PersonalInfoProps = {
  onSubmit: (values: initialValuesType) => any;
  isLoading: boolean;
  formData: formDataType;
  responseError: errorsType[];
};

export type FillOutProfileInitialValuesTypes = {
  firstName: string;
  lastName: string;
  gender?: string;
  birthDateDay?: string;
  birthDateMonth?: string;
  birthDateYear?: string;
  country?: string;
  city?: string;
  postalCode?: string;
  address?: string;
  birthDate?: birthDateType;
  mobilePhone?: string;
};

export type FillOutProfileStepProps = {
  iso2Code?: string;
  currentStep: number;
  formik: any;
  isLoading: boolean;
  setStep: (step) => void;
  isFullForm?: boolean;
  initialValues?: userInfoType;
};

export type FillOutProfileFormTypes = {
  iso2Code: string;
  onSubmit: (values: FillOutProfileInitialValuesTypes) => any;
  isLoading?: boolean;
  formData: userInfoType;
  isFullForm?: boolean;
};

//export const DONT_SHOW_CAPTCHA = ["PT", "ES"];

export const CheckPhoneSchema = (_t) =>
  Yup.object().shape({
    phone: Yup.string()
      .typeError(_t("must_be_a_number"))
      .transform((value) => value.replace(onlyDigitsRegExp, ""))
      .min(8, _t("mob_phone_must_be_longer"))
      .max(15, _t("mob_phone_must_be_shorter"))
      .required(_t("required")),
  });

export const VerifyCodeSchema = (_t, length = 6) =>
  Yup.object().shape({
    code: Yup.string()
      .required(_t("verify_code_required"))
      .matches(/^[0-9]+$/, _t("must_be_a_number"))
      .transform((value) => value.replace(onlyDigitsRegExp, ""))
      .min(length, _t("verify_code_require_{length}_digit", { length }))
      .max(length, _t("verify_code_require_{length}_digit", { length })),
  });
