import styled, { css } from "styled-components/macro";
import { color, misc } from "../../../system/styles/constants";
import { rgba } from "../../../system/styles/helpers";
import { ReactComponent as ArrowDown } from "../../../images/icons/arrow-lg-down.svg";
import { SelectedProps, LabelProps, ArrowProps, ListItemProps } from "./types";

export const Label = styled.div<LabelProps>`
  position: absolute;
  top: 8px;
  left: 16px;
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: ${color.secondaryLight};
  transform-origin: left top;
  transform: translate(0, 8px);
  transition: transform 0.2s ease-in-out;
  pointer-events: none;

  ${(props) =>
    props.small &&
    css`
      transform: scale(0.8);
    `}

  ${(props) =>
    props.active &&
    css`
      color: ${color.green};
    `}

  ${(props) =>
    props.hasError &&
    css`
      color: ${color.danger500};
    `}
`;

export const Inner = styled.div`
  position: relative;
`;

export const Selected = styled.div<SelectedProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 15px 16px 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${color.white};
  border-radius: ${misc.borderRadius.small} ${misc.borderRadius.small} 0 0;
  background-color: ${color.secondaryDark};

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${rgba(color.secondaryLight, 0.5)};
    transition: height 0.2s ease-in-out;
  }

  ${(props) =>
    props.opened &&
    css`
      &:after {
        height: 2px;
        background: ${color.greenGradient};
      }
    `}

  ${(props) =>
    props.empty &&
    css`
      color: ${color.blue200};
    `}

  ${(props) =>
    props.hasError &&
    !props.opened &&
    css`
      border-color: ${color.danger500};
      background-color: ${color.danger40};
    `}

  ${(props) =>
    props.disabled &&
    css`
      /* opacity: 0.6; */
      pointer-events: none;
      cursor: default;
    `}

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `}
`;

export const Img = styled.img`
  height: 20px;
  margin-right: 10px;
`;

export const Arrow = styled(ArrowDown)<ArrowProps>`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;

  path {
    stroke: ${color.secondaryLight};
  }

  ${(props) =>
    props.flipped &&
    css`
      transform: translateY(-50%) rotate(-180deg);

      path {
        stroke: ${color.white};
      }
    `}
`;

export const List = styled.ul`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  margin: 0;
  padding: 15px;
  list-style: none;
  background: ${color.dark150};
  border-radius: ${misc.borderRadius.medium};
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
  margin: 0;
  padding: 0 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${color.secondaryLight};
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: ${misc.borderRadius.medium};
  overflow: hidden;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:hover {
    background-color: ${color.dark50};
  }
`;

export const Error = styled.span`
  display: block;
  margin-top: 5px;
  margin-left: 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: ${color.danger500};
`;

export const Container = styled.div``;
