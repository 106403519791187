import React from "react";
import classNames from "classnames";

export type PlaceType = {
  value: string | number;
  text: string;
};

type TournamentPrizesProps = {
  className?: string;
  prizes: PlaceType[];
};

const TournamentPrizes: React.FC<TournamentPrizesProps> = ({
  className,
  prizes,
}) => (
  <div className={classNames("prizes", className)}>
    <div className="prizes__podium">
      <div className="prizes__second">
        {prizes[1] && (
          <div className="prizebox">
            <div className="prizebox__icon">{prizes[1].value}</div>
            <div className="prizebox__text">{prizes[1].text}</div>
          </div>
        )}
        <div className="podium-step">2</div>
      </div>
      <div className="prizes__first">
        {prizes[0] && (
          <div className="prizebox">
            <div className="prizebox__icon">{prizes[0].value}</div>
            <div className="prizebox__text">{prizes[0].text}</div>
          </div>
        )}
        <div className="podium-step">1</div>
      </div>
      <div className="prizes__third">
        {prizes[2] && (
          <div className="prizebox">
            <div className="prizebox__icon">{prizes[2].value}</div>
            <div className="prizebox__text">{prizes[2].text}</div>
          </div>
        )}
        <div className="podium-step">3</div>
      </div>
    </div>
  </div>
);

export default TournamentPrizes;
