import React, { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../system/reducers";
import GameCard from "../../../components/atoms/GameCard";
import { hasFun } from "../../game/helpers";
import { gameItem } from "../constants";
import { gameLink } from "../helpers";
import { useFavoriteGameMethods } from "../../user/hooks";
import { useGameOpenGamesFeedback } from "../../casino/zing/hooks";
import { isMobile } from "react-device-detect";

type GameCardContainerProps = {
  item: gameItem;
  withContent?: boolean;
  afterClick?: () => void;
  position?: number;
  category?: string;
};

const GameCardContainer: React.FC<GameCardContainerProps> = ({
  item,
  withContent,
  afterClick,
}) => {
  const [onGameOpen] = useGameOpenGamesFeedback();
  const { isFullProfile } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  const { addFavorite, removeFavorite, isFavorite } = useFavoriteGameMethods(
    item
  );
  const handleAfterClick = useCallback(() => {
    onGameOpen({
      recommendationId: item.zingRecommendationId || null,
      gameId: isMobile ? item?.mobileGameId : item?.desktopGameId,
      gameProviderId: item?.v3?.game?.platform.code,
      category: item.category,
      position: item.position,
      isSortedByZing: item.isSortedByZing,
    });
    afterClick && afterClick();
  }, [afterClick, onGameOpen, item]);

  return (
    <GameCard
      key={item.imageUrl}
      image={item.imageUrl}
      name={item.name}
      status={item.status}
      jackpot={item.jackpotAmount}
      playForRealLink={gameLink(item).real}
      playForFunLink={hasFun(item) && gameLink(item).fun}
      isFullProfile={isFullProfile}
      desc={withContent && item.platformName}
      title={withContent && item.name}
      afterClick={handleAfterClick}
      onClickAddFavorite={addFavorite}
      onClickRemoveFavorite={removeFavorite}
      platformCode={item.platformCode}
      isFavorite={item?.isFavoriteRequired || isFavorite}
    />
  );
};

export default GameCardContainer;
