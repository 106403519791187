import { infoTypePayloadData } from "./constants";
import { generatePath } from "react-router";
import { getLanguage } from "../../system/translations/helper";

const INFO_URL = `/:lang/:page.json`;

export const getInfoAction = (infoPage: string) => (
  dispatch: any
): infoTypePayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_INFO_PAGE",
    request: {
      url: generatePath(INFO_URL, {
        lang,
        page: infoPage,
      }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};
