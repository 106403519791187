import React, { useCallback } from "react";
import Btn from "../../atoms/Btn";
import LazyImg from "../../atoms/LazyImg";
import { _t } from "../../../system/translations/InjectIntlContext";
import exclamationImg from "../../../images/icons/warning_exclamation_modal.svg";
import { useModal } from "../../../modules/modals/hooks";

export type ConfirmModalProps = {
  title: string;
  desc?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  desc,
  onConfirm,
  onCancel,
}) => {
  const { hideModal } = useModal();

  const handleCancel = useCallback(() => {
    hideModal();
    onCancel && onCancel();
  }, [hideModal, onCancel]);

  const handleConfirm = useCallback(() => {
    hideModal();
    onConfirm && onConfirm();
  }, [hideModal, onConfirm]);

  return (
    <div className={"confirm-modal"}>
      <LazyImg
        imgSrc={exclamationImg}
        className={"confirm-modal__icon"}
        alt={"exclamation icon"}
      />
      <h2 className={"confirm-modal__title"}>{title}</h2>
      {desc && <p className={"confirm-modal__desc"}>{desc}</p>}
      <div className={"confirm-modal__buttons"}>
        <Btn
          className={"confirm-modal__cancel"}
          onClick={handleCancel}
          label={_t("no")}
          block
        />
        <Btn
          className={"confirm-modal__confirm"}
          type={"primary"}
          onClick={handleConfirm}
          label={_t("yes")}
          block
        />
      </div>
    </div>
  );
};

export default ConfirmModal;
