import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";

const EventsCalendarTemplate = React.lazy(
  () => import("./EventsCalendarTemplate")
);

const EventsCalendarPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{_t("halloween_page_title")}</title>
        <meta name={"description"} content={_t("halloween_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--events-calendar"}
        seoPanelProps={{ keySlug: "halloween" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <EventsCalendarTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default EventsCalendarPage;
