import React from "react";
import classNames from "classnames";
import { _t } from "../../../system/translations/InjectIntlContext";
import Btn from "../../atoms/Btn";
import LazyImg from "../../atoms/LazyImg";
import { useModal } from "../../../modules/modals/hooks";

const className = "notification-block";
export type notificationBlockProps = {
  icon?: string;
  titleKey?: string;
  titleMod?: string;
  textKey?: string;
  textMod?: string;
  italicKey?: string;
  textDescription?: string;
  btnLabelKey?: string;
  btnType?: string;
  btnBlock?: boolean;
  additionalClass?: string;
  link?: string;
  linkAfterBtn?: React.ReactElement;
  refNum?: string | number;
  withOutTrans?: boolean;
  titleAlign?: string;
  onClick?: () => any;
};

const NotificationBlock: React.FC<notificationBlockProps> = ({
  icon,
  titleKey,
  titleMod,
  textKey,
  textMod,
  italicKey,
  btnLabelKey,
  btnType,
  btnBlock,
  link,
  linkAfterBtn,
  refNum,
  withOutTrans,
  textDescription,
  titleAlign,
  onClick,
  additionalClass,
}) => {
  const { hideModal } = useModal();
  const onBtnClick = () => {
    if (onClick) {
      onClick();
    } else {
      !link && hideModal();
    }
  };

  return (
    <div
      className={classNames(
        `${className}__wrap`,
        additionalClass && additionalClass
      )}
    >
      {icon && (
        <div className={`${className}__icon-wrap`}>
          <LazyImg imgSrc={icon} alt={"checkmark"} />
        </div>
      )}
      {titleKey && (
        <h3
          className={classNames(
            `${className}__title`,
            {
              [`${className}__title--${titleMod}`]: titleMod,
            },
            titleAlign && `${className}__title--${titleAlign}`
          )}
        >
          {withOutTrans ? titleKey : _t(titleKey)}
        </h3>
      )}
      {textKey && (
        <div
          className={classNames(`${className}__text`, {
            [`${className}__text--${textMod}`]: textMod,
          })}
        >
          {refNum
            ? `${withOutTrans ? textKey : _t(textKey)} Ref# ${refNum}`
            : withOutTrans
            ? textKey
            : _t(textKey)}
        </div>
      )}
      {textDescription && (
        <div className={`${className}__description`}>
          {withOutTrans ? textDescription : _t(textDescription)}
        </div>
      )}
      {italicKey && (
        <div className={`${className}__italic`}>{_t(italicKey)}</div>
      )}
      {btnLabelKey && (
        <Btn
          className={`${className}__btn`}
          label={withOutTrans ? btnLabelKey : _t(btnLabelKey)}
          type={btnType}
          onClick={onBtnClick}
          linkTo={link}
          block={btnBlock}
        />
      )}
      {!!linkAfterBtn && linkAfterBtn}
    </div>
  );
};

export default NotificationBlock;
