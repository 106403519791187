import React, { Suspense } from "react";
import { useChangePassword } from "../apiHooks";
import { changePasswordKeys } from "../constants";
import { initialValuesType } from "./forms/ChangePasswordForm";
import { apiErrors } from "../../../system/helpers/apiErrorHelper";
import Loader from "../../../components/atoms/Loader";

const ChangePasswordForm = React.lazy(
  () => import("./forms/ChangePasswordForm")
);

const ChangePassword: React.FC = () => {
  const [changePasswordHandler, { isLoading, error }] = useChangePassword();
  const responseError = apiErrors(error);

  const onSubmit = (values: initialValuesType) => {
    const formData = new FormData();
    formData.append(changePasswordKeys.oldPassword, values.oldPassword);
    formData.append("_method", "PATCH");
    formData.append(changePasswordKeys.password, values.password);
    formData.append(changePasswordKeys.repeatPassword, values.repeatPassword);
    changePasswordHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <ChangePasswordForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        responseError={responseError}
      />
    </Suspense>
  );
};

export default ChangePassword;
