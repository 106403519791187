import React from "react";
import classNames from "classnames";

export type SliderArrowProps = {
  className?: string;
  additionalClass?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const SliderArrow = (props: SliderArrowProps) => {
  const { className, onClick, additionalClass } = props;
  return (
    <button
      type="button"
      className={classNames(className, additionalClass)}
      onClick={onClick}
    />
  );
};

export default SliderArrow;
