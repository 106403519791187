import React from "react";
import InfoPage from "../InfoPage";

type InfoPageModalProps = {
  slug: string;
};

const InfoPageModal: React.FC<InfoPageModalProps> = ({ slug }) => (
  <div className={"info-modal-body"}>
    <InfoPage slug={slug} />
  </div>
);

export default InfoPageModal;
