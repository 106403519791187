import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";

const Euro2020Template = React.lazy(() => import("./Euro2020Template"));

const Euro2020Page = () => {
  return (
    <>
      <Helmet>
        <title>{_t("euro2020_page_title")}</title>
        <meta name="description" content={_t("euro2020_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--euro2020"}
        seoPanelProps={{ keySlug: "euro2020" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <Euro2020Template />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default Euro2020Page;
