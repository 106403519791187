import { useCallback, useMemo } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import { ApplicationState } from "../../../system/reducers";
import { FeedbackAction } from "./constants";
import { useSendGamesFeedback } from "./api/apiHooks";

type GameOpenCallbackProps = {
  recommendationId: string;
  gameId: string;
  gameProviderId: string;
  category: string;
  position: string | number;
  isSortedByZing: boolean;
};

export const useGameOpenGamesFeedback = () => {
  const dispatch = useDispatch();
  const { sessionKey, partyId } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  const uid = useMemo(() => nanoid(), []);
  const [sendGameFeedback] = useSendGamesFeedback();
  const callback = useCallback(
    (props: GameOpenCallbackProps) => {
      if (sessionKey) {
        sendGameFeedback([
          {
            action: FeedbackAction.GameOpen,
            uri: {
              recommendationId: props.isSortedByZing
                ? props.recommendationId || null
                : null,
            },
            parameters: {
              gameId: props.gameId,
              gameProviderId: props.gameProviderId,
              position: props.position,
              sessionKey: sessionKey,
              playerId: partyId,
              category: props.category,
              timestamp: new Date().getTime(),
            },
          },
        ]);
      }
    },
    [sessionKey, partyId, uid, dispatch]
  );

  return [callback] as const;
};
