import React, { useState, useEffect } from "react";
import { SHOW_CHAT_BTN_TIMEOUT } from "../constants";

const Context = React.createContext(null);

const ChatContextProvider = ({ children }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    let timeout = setTimeout(() => setLoaded(true), SHOW_CHAT_BTN_TIMEOUT);
    return () => clearTimeout(timeout);
  }, []);

  return <Context.Provider value={{ loaded }}>{children}</Context.Provider>;
};

export default Context;
export { ChatContextProvider };
