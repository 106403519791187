import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { useIntersectionObserver } from "../../../system/hooks/useIntersectionObserver";
import { pixel } from "./constants";

export type LazyImgType = {
  imgSrc: string;
  alt?: string;
  defaultSrc?: string;
  className?: string;
  onClick?: () => any;
  style?: any;
  animate?: boolean;
  dontObserve?: boolean;
  bg?: boolean;
  observerOptions?: IntersectionObserverInit;
};

const LazyImg: React.FC<LazyImgType> = ({
  className,
  imgSrc,
  alt,
  defaultSrc,
  onClick,
  style,
  animate,
  dontObserve,
  bg,
  observerOptions,
}) => {
  const ref = useRef(null);
  const [isLoaded, setLoaded] = useState(false);
  const [src, setSrc] = useState("");
  const options = useMemo<IntersectionObserverInit>(
    () => ({
      rootMargin: "200px",
      ...observerOptions,
    }),
    [observerOptions]
  );
  const { shown, setTarget } = useIntersectionObserver(options);

  useEffect(() => {
    ref && setTarget(ref);
  }, [ref, setTarget]);

  useEffect(() => {
    if (src && animate) {
      let img = new window.Image();
      img.src = imgSrc;
      img.onload = () => {
        setLoaded(true);
      };
    }
  }, [src, imgSrc, animate]);

  useEffect(() => {
    if (shown) {
      setSrc(imgSrc);
    }
  }, [shown, imgSrc]);

  if (bg) {
    return (
      <div
        onClick={onClick}
        className={classNames("lazy-img", className, {
          "lazy-img--loaded": isLoaded,
        })}
        style={{
          ...style,
          backgroundImage: `url(${
            dontObserve ? imgSrc : src || defaultSrc || pixel
          })`,
        }}
        ref={dontObserve ? null : ref}
      />
    );
  }

  return (
    <img
      onClick={onClick}
      className={classNames("lazy-img", className, {
        "lazy-img--loaded": isLoaded,
      })}
      src={dontObserve ? imgSrc : src || defaultSrc || pixel}
      ref={dontObserve ? null : ref}
      style={style}
      alt={alt}
    />
  );
};

LazyImg.defaultProps = {
  alt: "",
  imgSrc: "",
  defaultSrc: "",
  animate: false,
  dontObserve: false,
  bg: false,
};

export default LazyImg;
