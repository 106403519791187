import React from "react";
import { _t } from "../../../system/translations/InjectIntlContext";
import Btn from "../../atoms/Btn";
import Input from "../../atoms/Input";
import { useFormik } from "formik";
import { CashInLoyaltyPointsTypes } from "../../../pages/user/pages/bonuses/Bonuses";
import { lpCashInSchema } from "../../../modules/user/components/forms/constants";
import { useFormikHelper } from "../../../system/helpers/formikHelperHook";

export interface bonusExchangeModalProps extends CashInLoyaltyPointsTypes {
  loyaltyPoints: string;
  exchangeRateData: {
    lp: number;
    cash: number;
    currencySign: string;
    minConversionAmount: number;
  };
}

const BonusExchangeModal: React.FC<bonusExchangeModalProps> = ({
  loyaltyPoints,
  exchangeRateData,
  onSubmitLpCashIn,
  isLoadingLpCashIn,
}) => {
  const { lp, cash, minConversionAmount, currencySign } = exchangeRateData;
  const exchangeRateValid = Boolean(lp && cash);
  const formik = useFormik({
    initialValues: {
      convertAmount: "0",
    },
    validateOnChange: false,
    validationSchema: lpCashInSchema(_t, loyaltyPoints, minConversionAmount),
    onSubmit: onSubmitLpCashIn,
  });
  const { error, onBlur } = useFormikHelper(formik);
  const errorMsg = error("convertAmount");

  const convertedValue = (
    Number(formik.values.convertAmount) *
    (cash / lp)
  ).toFixed(2);

  return (
    <>
      <div className="exchange-info-block">
        <div className="lp-info">
          <span className="lp-info__value">{`${loyaltyPoints} LP`}</span>
          <span className="lp-info__content">
            {_t("bonuses_available_for_exchange")}
          </span>
        </div>
        <div className="ex-rate-info">
          {exchangeRateValid ? (
            <>
              <span className="lp-info__value">{`${lp} LP - ${cash} ${currencySign}`}</span>
              <span className="lp-info__content">{_t("exchange_rate")}</span>
            </>
          ) : (
            <span className="lp-info__content">
              {_t("exchange_rate_invalid")}
            </span>
          )}
        </div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className={"form form--in-modal exchange-form"}
      >
        <div className={"exchange-form__content"}>
          <div className={"exchange-form__lp-input-wrap"}>
            <span>LP</span>
            <Input
              onChange={formik.handleChange}
              value={formik.values.convertAmount}
              name="convertAmount"
              className={"lp-exchange-input"}
              size={"responsive"}
              mask={"9999999"}
              error={errorMsg}
              onBlur={() => onBlur("convertAmount")}
              disabled={!exchangeRateValid}
            />
          </div>
          <div className={"exchange-form__currency-display-wrap"}>
            <span>{currencySign}</span>
            <Input
              disabled={true}
              value={convertedValue}
              name="convertResult"
              className={"lp-exchange-input"}
              size={"responsive"}
            />
          </div>
        </div>
        {errorMsg && (
          <div className="exchange-form__input-error">{errorMsg}</div>
        )}
        <Btn
          label={_t("exchange_lp_btn")}
          btnType={"submit"}
          size={"lg"}
          type={"secondary"}
          loading={isLoadingLpCashIn}
          disabled={!exchangeRateValid}
        />
      </form>
      {exchangeRateValid && (
        <span className={"form-bottom-message"}>
          {_t("exchange_form_bot_message", { minAmount: minConversionAmount })}
        </span>
      )}
    </>
  );
};

export default BonusExchangeModal;
