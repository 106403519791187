import { useMemo } from "react";
import { _t, makeKey } from "../../system/translations/InjectIntlContext";
import { hideString } from "../user/helpers";
import {
  TournamentItemData,
  TournamentAward,
  AwardType,
  RankType,
} from "./constants";
import { sortByFieldName } from "./helpers";

type PrizeType = {
  name: string;
  value: number;
  rank: number;
};

type PlayerType = {
  name: string;
  points: string;
  prize: string;
};

export const useGetPrizePool = (awards: TournamentAward[]) => {
  return useMemo<PrizeType[]>(() => {
    const pool: PrizeType[] = [];

    awards?.forEach((award) => {
      if (award?.type === AwardType.LoyaltyPoint) {
        const name = _t(makeKey("tournament_prize", award?.type), {
          value: award?.loyaltyPoints,
        });
        const pointRule = award?.tournamentAwardRule?.filter(
          (rule) => rule?.rankType === RankType.TournamentPoint
        );

        for (let i = pointRule[0]?.rankStart; i <= pointRule[0]?.rankEnd; i++) {
          pool.push({
            name: name,
            value: award?.loyaltyPoints,
            rank: i,
          });
        }
      }
    });

    return pool.sort((a, b) => a.rank - b.rank);
  }, [awards]);
};

export const useGetPlayers = (
  tournament: TournamentItemData,
  limit: number
) => {
  const prizePool = useGetPrizePool(
    tournament?.omegaTournament?.tournamentAwards
  );
  const players = useMemo<PlayerType[]>(
    () =>
      tournament?.omegaTournamentRanking
        ?.sort((a, b) => sortByFieldName(a, b, "ranking"))
        ?.slice(0, limit)
        ?.map((item, index) => ({
          name: hideString(item?.userid),
          points: item?.points.toLocaleString(),
          prize: prizePool[index]?.name,
        })),
    [tournament, limit, prizePool]
  );

  return players;
};
