import { useEffect, useState } from "react";

type scriptData = {
  id: string;
  src: string;
};

export const useLoadScript = (
  data: scriptData,
  loadCallback = null,
  delay = 0,
  onErrorCallback = null
) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    window.setTimeout(function () {
      const scriptElement = document.getElementById(data.id);
      if (!!scriptElement) {
        loadCallback && loadCallback();
        setLoading(false);
        return;
      }

      var _script = document.createElement("script");
      _script.src = data.src;
      _script.id = data.id;
      _script.async = true;
      document.head.appendChild(_script);
      _script.onload = () => {
        loadCallback && loadCallback();
        setLoading(false);
      };
      _script.onerror = () => {
        onErrorCallback && onErrorCallback();
        setLoading(false);
      };
    }, delay);
  }, []);
  return [isLoading];
};
