import { gameItem, seoLinkTypes } from "../casino/constants";

export const DEFAULT_RATIO = 16 / 9;
export const PLAY_FOR_FUN = "play-for-fun";
export const PLAY_FOR_REAL = "play-for-real";
export const PROVIDERS_REAL_ONLY = [
  "PRAGMATIC_LIVE",
  "PLAYTECHLIVE",
  "EVO_CAS",
  "EZUGI",
  "NETENT_CAS&isLive=true",
];

export type RouteParams = {
  gameSlug: string;
  playType: string;
  gameProvider: string;
};

export type gamePayloadData = {
  imageBackgroundUrl: string | any;
  game_url: string;
  game: gameItem;
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
  isFavorite?: boolean;
};
