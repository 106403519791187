import React from "react";
import OpenModalLink from "../../components/molecules/OpenModalLink";
import { dataLayerRegModal, dataLayerLoginModal } from "../../system/dataLayer";
import { loginModal, forgotPasswordModal, regModalBonus } from "./index";

export const DontHaveAnAccountModalLink = () => (
  <OpenModalLink
    label={"sign_up"}
    modal={regModalBonus}
    position={"center"}
    text={"dont_have_an_account"}
    onClick={() => dataLayerRegModal("login_modal")}
  />
);

export const AlreadyHaveAnAccountModalLink = () => (
  <OpenModalLink
    label={"log_in"}
    modal={loginModal}
    position={"center"}
    text={"already_have_an_account"}
    onClick={() => dataLayerLoginModal("reg_modal")}
  />
);

export const ForgotYourPasswordModalLink = () => (
  <OpenModalLink
    label={"forgot_your_password"}
    modal={forgotPasswordModal}
    position={"center"}
  />
);
