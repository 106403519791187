import React, { lazy, ReactElement, useState } from "react";
import classNames from "classnames";
import visibilityIcon from "../../../images/icons/visibility_icon.svg";
import warningIcon from "../../../images/icons/warning_icon.svg";
import LazyImg from "../LazyImg";
const InputMask: any = lazy(() => import("react-input-mask"));

export type inputProps = {
  autocomplete?: "off" | "on" | "new-password" | "one-time-code";
  className?: string;
  currency?: string;
  disabled?: boolean;
  error?: string;
  hideShowPassword?: boolean;
  icon?: string | ReactElement;
  mask?: string;
  maskChar?: string;
  maxLength?: number;
  mobile?: boolean;
  name?: string;
  onBlur?: () => any;
  onChange?: any;
  placeholder?: string;
  size?: string;
  targetVal?: boolean;
  type?: string;
  value?: any;
  inputRef?: React.Ref<any>;
};

const icons = {
  password: visibilityIcon,
};

const Input: React.FC<inputProps> = ({
  autocomplete,
  className,
  currency,
  disabled,
  error,
  hideShowPassword,
  icon,
  mask,
  maskChar,
  maxLength,
  mobile,
  name,
  onBlur,
  onChange,
  placeholder,
  size,
  targetVal,
  type = "text",
  value,
  inputRef,
}) => {
  const [inputType, setInputType] = useState(type);
  const [inputFocus, setInputFocus] = useState(false);
  const onFocus = () => setInputFocus(true);
  const onBlurHandler = () => {
    setInputFocus(false);
    onBlur && onBlur();
  };

  const onChangeHandler = (e) => {
    const value = targetVal ? e.target.value : e;
    onChange && onChange(value);
  };

  const hideInputHandler =
    type === "password"
      ? () => {
          inputType === "text"
            ? setInputType("password")
            : setInputType("text");
        }
      : null;

  const valueLength = value.toString().length;
  let currencySignOffset = "";
  if (valueLength < 3) {
    currencySignOffset = `calc(50% + ${valueLength * 5}px)`;
  } else if (valueLength <= 5) {
    currencySignOffset = `calc(50% + ${valueLength * 6}px)`;
  } else {
    currencySignOffset = `calc(50% + ${valueLength * 6.5}px)`;
  }

  return (
    <div
      className={classNames(
        "custom-input__container",
        className,
        {
          "custom-input__container--has-error": error,
          "custom-input__container--mobile": mobile,
          "custom-input__container--disabled": disabled,
          "custom-input__container--without-placeholder": !placeholder,
        },
        size && `custom-input__container--${size}`
      )}
    >
      <div
        className={classNames("custom-input__wrap", {
          "custom-input__wrap--input-focused": inputFocus,
        })}
      >
        {currency && (
          <span
            className="custom-input__currency"
            style={{ position: "absolute", right: currencySignOffset }}
          >
            {currency}
          </span>
        )}
        <InputMask
          name={name}
          mask={mask}
          maskChar={maskChar}
          className={classNames("custom-input__input-field", {
            empty: value.length === 0,
            error: error,
          })}
          type={inputType}
          placeholder={""}
          onChange={onChangeHandler}
          value={value}
          onFocus={onFocus}
          onBlur={onBlurHandler}
          disabled={disabled}
          autoComplete={autocomplete}
          maxlength={maxLength}
          inputRef={inputRef}
        />
        <span className={"custom-input__placeholder"}>{placeholder}</span>
        {(icon || (icons[type] && !hideShowPassword) || error) && (
          <span
            className={classNames("custom-input__icon", type, {
              "warning-icon": error,
            })}
            onClick={hideInputHandler}
          >
            {error && <LazyImg imgSrc={warningIcon} alt={"icon"} />}
            {icon ||
              (icons[type] && (
                <LazyImg imgSrc={icon || icons[type]} alt={"icon"} />
              ))}
          </span>
        )}
      </div>
      {error && (
        <span className={classNames("custom-input__error")}>{error}</span>
      )}
    </div>
  );
};

Input.defaultProps = {
  size: "",
  mask: "",
  maskChar: "",
  value: "",
};

export default Input;
