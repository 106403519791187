import { isWebCache } from "../helpers/urlHelper";

const projectEnv: string = process.env.NODE_ENV || "";
const developmentUrl = "http://localhost:3000/backend"; //"https://stg3.bettilt.com"; //set url for local env
let backendUrl: string = "";
const getApiHost = !isWebCache() ? window.location.hostname : "www.bettilt.com";

switch (projectEnv) {
  case "development":
    backendUrl =
      developmentUrl ||
      `${window.location.protocol}//${window.location.hostname}/backend`;
    break;
  case "stage":
    backendUrl = `${window.location.protocol}//${window.location.hostname}/backend`;
    break;
  default:
    backendUrl = `https://${getApiHost}/backend`;
    break;
}

export default backendUrl;
