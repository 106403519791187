import React from "react";
import LazyImg from "../../atoms/LazyImg";
import GiftTop from "../../../images/christmas-calendar/box-top.png";
import GiftBot from "../../../images/christmas-calendar/box-bot.png";

type GetGiftModalProps = {
  name: string;
  text: string;
};

const GetGiftModal: React.FC<GetGiftModalProps> = ({ name, text }) => (
  <div className={"get-gift-modal-body"}>
    <LazyImg className={"get-gift-modal-body__img"} imgSrc={GiftTop} />
    <h3 className={"get-gift-modal-body__name"}>{name}</h3>
    <p className={"get-gift-modal-body__desc"}>{text}</p>
    <LazyImg className={"get-gift-modal-body__img"} imgSrc={GiftBot} />
  </div>
);

export default GetGiftModal;
