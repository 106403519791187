import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../../system/translations/InjectIntlContext";
import { chatClick, getChatIcon } from "../../../modules/chat/helpers";
import { ReactComponent as LiveIcon } from "../../../images/chat/live-modal.svg";
import SupportImg from "../../../images/chat/support.svg";
import LazyImg from "../../../components/atoms/LazyImg";
import { useChat } from "../hooks";

const ChatModal: React.FC = () => {
  const { loaded, links } = useChat();

  return (
    <div className={"chat-modal-body"}>
      <LazyImg imgSrc={SupportImg} className={"chat-modal-body__icon"} />
      <h3 className={"chat-modal-body__title"}>{_t("chat_modal_title")}</h3>
      <p className={"chat-modal-body__desc"}>{_t("chat_modal_desc")}</p>
      <div className={"chat-modal-body__list"}>
        {loaded && (
          <button className={"chat-modal-body__btn"} onClick={chatClick}>
            <span className={"chat-modal-body__btn-icon"}>
              <LiveIcon />
            </span>
            <span className={"chat-modal-body__btn-label"}>
              {_t("chat_modal_livechat")}
            </span>
          </button>
        )}
        {links?.map((item) => (
          <Link
            key={item.url}
            to={{ pathname: item.url }}
            target={"_blank"}
            className={"chat-modal-body__btn"}
          >
            <div className={"chat-modal-body__btn-icon"}>
              {getChatIcon(item.classLink)}
            </div>
            <span className={"chat-modal-body__btn-label"}>{item.name}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ChatModal;
