import { ReactComponent as IconAttention } from "../../../../images/elements/attention.svg";
import Btn from "../../../../components/atoms/Btn";
import React from "react";

interface AttentionModalProps {
  modalClassName: string;
  title: string;
  content: string;
  additionalContent?: string;
  btnType: string;
  btnLabel: string;
  handleClick: any;
}

const AttentionModal: React.FC<AttentionModalProps> = ({
  modalClassName,
  title,
  content,
  additionalContent,
  btnType,
  btnLabel,
  handleClick,
}) => {
  return (
    <div className={`${modalClassName}__attention`}>
      <IconAttention />
      <span className={`${modalClassName}__attention-title`}>{title}</span>
      <div className={`${modalClassName}__attention-content`}>{content}</div>
      {additionalContent ? (
        <div className={`${modalClassName}__attention-subcontent`}>
          {additionalContent}
        </div>
      ) : null}
      <Btn onClick={handleClick} label={btnLabel} type={btnType} />
    </div>
  );
};

export default AttentionModal;
