import React from "react";
import Btn from "../../../../components/atoms/Btn";
import { _t } from "../../../../system/translations/InjectIntlContext";
import classNames from "classnames";

type ConsentModalProps = {
  title?: string;
  text?: string;
  textWithTags?: string;
  onHandleApproveClick?: any;
  className?: string;
  onHandleRejectClick?: any;
  btnRejectLabel?: string;
  btnApproveLabel?: string;
};

const ConsentModal: React.FC<ConsentModalProps> = ({
  title,
  text,
  className,
  textWithTags,
  onHandleApproveClick,
  onHandleRejectClick,
  btnRejectLabel,
  btnApproveLabel,
}) => {
  return (
    <div className={classNames(className, "consent-modal-body")}>
      <div className="consent-modal-body__info">
        {title && (
          <div className="consent-modal-body__info-title">{_t(title)}</div>
        )}
        {text && <div className="consent-modal-body__info-text">{text}</div>}
        {textWithTags && (
          <div
            className="consent-modal-body__info-text-tags"
            dangerouslySetInnerHTML={{ __html: textWithTags }}
          />
        )}
      </div>
      {(btnApproveLabel || btnRejectLabel) && (
        <div className="consent-modal-body__btn-group">
          {btnApproveLabel && (
            <div
              onClick={onHandleApproveClick}
              className="consent-modal-body__btn-wrap"
            >
              <Btn label={_t(btnApproveLabel)} type="transparent-bordered" />
            </div>
          )}
          {btnRejectLabel && (
            <div
              onClick={onHandleRejectClick}
              className="consent-modal-body__btn-wrap"
            >
              <Btn label={_t(btnRejectLabel)} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ConsentModal;
