import React, { Suspense } from "react";
import { useTfaProcess } from "../../apiHooks";
import { tfaProcessRequestKeys } from "../../constants";
import Loader from "../../../../components/atoms/Loader";

const TfaProcessForm = React.lazy(() => import("../forms/TfaProcessForm"));

const TfaProcessModal = ({ redirectTo, showOnSuccess }) => {
  const [tfaProcessHandler, { isLoading }] = useTfaProcess({
    redirectTo,
    showOnSuccess,
  });
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append(tfaProcessRequestKeys.code, values.code);
    tfaProcessHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <TfaProcessForm onSubmit={onSubmit} isLoading={isLoading} />
    </Suspense>
  );
};

export default TfaProcessModal;
