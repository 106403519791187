import { useCallback, useContext } from "react";
import ModalContext from "./ModalContext";
import { useHistory } from "react-router-dom";
import { hashHelper } from "./helpers";
import { loginModalWithRedirect } from ".";
import { routes } from "./routes";
import { useDepositViewVersion } from "../cashbox/hooks";

export const useModal = () => {
  const defaultModal = "default";
  const modal: any = useContext(ModalContext);
  const showModal = (params: {}, name = defaultModal) =>
    modal.showModal(params, name);
  const updateModal = (params: {}, name = defaultModal) =>
    modal.updateModal(params, name);
  const hideModal = (name = defaultModal) => modal.hideModal(name);

  return { showModal, hideModal, updateModal };
};

export const useCheckModal = () => {
  const history = useHistory();
  const { showModal } = useModal();
  const { version } = useDepositViewVersion();

  return useCallback((location) => {
    if (
      (routes(version)[location.hash] &&
        !routes(version)[location.hash]?.restricted) ||
      (routes(version)[location.hash] &&
        typeof routes(version)[location.hash]?.restricted === "function" &&
        !routes(version)[location.hash]?.restricted())
    ) {
      showModal(routes(version)[location.hash]?.modal);
      hashHelper(location.hash);
    } else if (
      !!routes(version)[location.hash]?.restricted &&
      routes(version)[location.hash]?.restricted()
    ) {
      history.push({
        ...location,
        state: "openLogin",
      });
      showModal(
        loginModalWithRedirect({
          redirectTo: location.pathname + location.search + location.hash,
          showOnSuccess: false,
        })
      );
    }
  }, []);
};
