import { formatLanguages } from "./helper";
import {
  CHANGE_LANGUAGE,
  GET_AVAILABLE_LANGUAGES_SUCCESS,
  GET_COUNTRY_INFO_SUCCESS,
  GET_PWA_DOMAIN_SUCCESS,
  GET_PWA_DOMAIN_ERROR,
  SET_BLACKBOX,
  generalReducerType,
} from "./constants";
import config from "../../system/config";

const defaultCurrencyInfo = {
  isDefault: null,
  isoCode: "",
  name: "",
  numberDecimalPoint: null,
  numericCode: null,
  symbol: "",
  symbolCode: null,
};

const defaultCountryInfo = {
  iso2Code: "",
  name: "",
  phonePrefix: "",
};

const initialState: generalReducerType = {
  selectedLang: "",
  languages: [],
  availableLangs: [],
  countryInfo: defaultCountryInfo,
  currencyInfo: defaultCurrencyInfo,
  pwaDomain: "",
  blackbox: "",
};

const reducer = (state: generalReducerType = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLang: action.val,
      };
    case GET_AVAILABLE_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload?.languages,
        availableLangs: formatLanguages(action.payload),
      };
    case GET_COUNTRY_INFO_SUCCESS:
      const resGeo = action?.resHeaders && action?.resHeaders["x-geo-info"];
      const countryCode =
        resGeo && resGeo?.split(" ")[1] ? resGeo.split(" ")[1] : "CA";
      const { country_to_currency } = config;

      return {
        ...state,
        countryInfo: {
          iso2Code: countryCode,
        },
        currencyInfo: {
          isoCode: country_to_currency[countryCode],
        },
      };
    case GET_PWA_DOMAIN_SUCCESS:
      return {
        ...state,
        pwaDomain: action.payload,
      };
    case GET_PWA_DOMAIN_ERROR:
      return {
        ...state,
        pwaDomain: config.pwa.defaultDomain,
      };
    case SET_BLACKBOX:
      return {
        ...state,
        blackbox: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
