import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "./userHelper";
import { getLanguage } from "../translations/helper";
import config from "../config";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const lang = getLanguage() || config.defaultLanguage;
  const host = window.location.hostname;
  const publicDomains =
    host.includes("localhost") ||
    host.includes("bettilt") ||
    host.includes("pwa") ||
    host.includes("localhost") ||
    host.includes("webcache");
  const checkProfile = rest.profile && !isLoggedIn();
  const checkRestriction = publicDomains ? !checkProfile : isLoggedIn();
  const redirectTo = publicDomains ? "/#login" : `/${lang}/sign-in`;
  return (
    <Route
      {...rest}
      render={(props) =>
        checkRestriction ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};

export default PrivateRoute;
