import {
  LOGIN_SUCCESS,
  USER_INFO_SUCCESS,
  userReducerType,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  LOGIN_START,
  LOGIN_ERROR,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_TFA_METHODS,
} from "./constants";

const updatedUser = () => Date.now();

const initialState: userReducerType = {
  info: {
    lastUpdated: updatedUser(),
    isLoading: false,
    user: {
      sessionKey: "",
      firstName: "",
      lastName: "",
      balance: {
        totalBalance: "0.00",
        withdrawableBalance: "0.00",
        bonusBalance: "0.00",
        currency: "",
        loyaltyPoint: "0",
        currentVIPLevel: "",
        currentVIPLevelTrans: "",
      },
      partyId: "",
      username: "NOT_LOGGED_IN",
      lastLogin: "",
      termsUpdatedAt: "",
      quickSignupComplete: false,
      registrationStatus: "",
      isFirstLogin: "",
      address: "",
      balanceNeedToRefresh: false,
      birthDate: "",
      bonusCode: "",
      city: "",
      country: "",
      currency: "",
      currencySymbol: "",
      depositStorage: null,
      email: "",
      failedAttempts: 0,
      kycStatus: "",
      language: "",
      loyaltyPointsStatusNeedToRefresh: false,
      mobilePhone: "",
      nickname: "",
      password: null,
      pendingLimitConfirmation: "",
      pendingWithdrawals: [],
      phone: "",
      postalCode: "",
      province: "",
      receiveEmail: "",
      regDate: "",
      repeatPassword: null,
      signupStatusNeedToRefresh: false,
      subscribeNeedToRefresh: false,
      subscribed: false,
      twoFactorAuthCode: "",
      twoFactorAuthPassed: false,
      twoFactorAuthMethods: {
        none: true,
        sms: false,
        email: false,
      },
      userNeedToRefresh: false,
      vipStatus: "",
      withdrawalStorage: null,
      withdrawalsNeedToRefresh: false,
      loyaltyPointsStatus: {
        accumulatedLoyaltyPoint: "",
        currentLoyaltyPoint: "",
        currentVIPLevel: "",
        currentVIPLevelTrans: "",
        nextVIPLevel: "",
        nextVIPLevelTrans: "",
        remainingPointsForNextLevel: "",
        totalPointsForNextLevel: "",
      },
      isFullProfile: false,
      isFullProfileWithdrawal: false,
    },
    data: {
      PHPSESSID: "",
    },
    status: "",
    form: "",
  },
};

const reducer = (state: userReducerType = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_START:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: true,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          ...payload,
          user: {
            ...state.info.user,
            ...payload.user,
          },
          lastUpdated: updatedUser(),
          isLoading: false,
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        info: {
          ...state.info,
          isLoading: false,
        },
      };
    case LOGOUT_SUCCESS:
    case LOGOUT_ERROR:
      return {
        ...state,
        ...initialState,
      };
    case USER_INFO_SUCCESS:
      const isFullProfile =
        !!payload?.user?.firstName &&
        !!payload?.user?.email &&
        !!payload?.user?.mobilePhone;
      const isFullProfileWithdrawal =
        !!payload?.user?.firstName &&
        !!payload?.user?.email &&
        !!payload?.user?.mobilePhone &&
        !!payload?.user?.address &&
        !!payload?.user?.city;
      return {
        ...state,
        info: {
          ...state.info,
          user: {
            ...state.info.user,
            ...payload?.user,
            balance: {
              ...state.info.user.balance,
              ...payload?.user?.balance,
            },
            loyaltyPointsStatus: {
              ...state.info.user.loyaltyPointsStatus,
              ...payload?.user?.loyaltyPointsStatus,
            },
            isFullProfile: isFullProfile,
            isFullProfileWithdrawal: isFullProfileWithdrawal,
          },
          lastUpdated: updatedUser(),
        },
      };

    case UPDATE_BALANCE_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          user: {
            ...state.info.user,
            ...payload?.user,
            balance: {
              ...state.info.user.balance,
              ...payload?.user?.balance,
            },
            loyaltyPointsStatus: {
              ...state.info.user.loyaltyPointsStatus,
              ...payload?.user?.loyaltyPointsStatus,
            },
          },
          lastUpdated: updatedUser(),
        },
      };
    case UPDATE_TFA_METHODS:
      return {
        ...state,
        info: {
          ...state.info,
          user: {
            ...state.info.user,
            twoFactorAuthMethods: {
              none: payload?.none,
              sms: payload?.sms,
              email: payload?.email,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
