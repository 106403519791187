import { toast, ToastContent, ToastOptions } from "react-toastify";

const defaultOptions = {
  autoClose: 6000,
};

export const addToast = (content: ToastContent, options: ToastOptions) => {
  return toast(content, { ...defaultOptions, ...options });
};

export const removeToast = (id: any) => toast.dismiss(id);