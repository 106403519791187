import React from "react";
import { useGetInfo } from "../apiHooks";
import Loader from "../../../components/atoms/Loader";
import FormattedText from "../../../components/atoms/FormattedText";

type InfoPageProps = {
  slug: string;
};

const InfoPage: React.FC<InfoPageProps> = ({ slug }) => {
  const { isLoading, data } = useGetInfo(slug);

  if (isLoading) {
    return <Loader center minHeight={300} />;
  }

  return (
    <div className={"info-page"}>
      <h2 className={"info-page__title"}>{data?.object?.name}</h2>
      <FormattedText
        className={"info-page__body"}
        html={data?.object?.description}
      />
    </div>
  );
};

export default InfoPage;
