import React, { useContext, createContext } from "react";
import { injectIntl, IntlShape } from "react-intl";
import texts from "./i18n_keys";

export const IntlContext = createContext<IntlShape | null>(null);

export const InjectIntlContext: React.FC = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
});

type translationParams = {
  [key: string]: any;
};

export type transType = (key: string, param?: translationParams) => string;

export const makeKey = (...keys: (string | number)[]): string => {
  return keys
    .reduce((array, key) => (key ? [...array, key] : array), [])
    .join("_")
    .replace(/\s/g, "_")
    .toLowerCase();
};

export const _t: transType = (key, param = {}) => {
  const intl = useContext(IntlContext);
  const devMode = process.env.NODE_ENV === "development";
  const textVal: any = (texts as { [key: string]: object })[key];
  const intlMessagesVal: any = (intl.messages as { [key: string]: object })[
    key
  ];
  if (!intl) {
    return `#${key}#`;
  }

  if (!devMode) {
    delete textVal?.defaultMessage;
  }

  let transResult: string = "";
  if (textVal) {
    transResult = intl!.formatMessage(textVal, param);
  } else if (intlMessagesVal) {
    transResult = intlMessagesVal;
  } else {
    transResult = `#${key}#`;
  }
  // textVal ? intl!.formatMessage(textVal, param) : key ? `#${key}#` : ""
  return transResult;
};
