import {
  getTranslationsAction,
  getAvailableLangAction,
  getCountryInfoAction,
  getPwaDomainAction,
  getBootstrapAction,
} from "./apiActions";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getLanguage } from "../../system/translations/helper";

const LanguagesList = "languages-list";
const PwaDomain = "pwa-domain";
const CountryInfoList = "country-info-list";
const BootstrapInfo = "bootstrap-info";

export const useGetTranslations = () => {
  const dispatch = useDispatch();
  return useMutation((locale: string = "en") =>
    getTranslationsAction(locale)(dispatch)
  );
};

export const useGetLanguages = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([LanguagesList, lang], () =>
    getAvailableLangAction()(dispatch)
  );
};

export const useGetCountryInfo = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([CountryInfoList, lang], () =>
    getCountryInfoAction()(dispatch)
  );
};
export const useGetBootstrap = () => {
  const dispatch = useDispatch();
  return useQuery(BootstrapInfo, () => getBootstrapAction()(dispatch));
};

export const useGetPwaDomain = () => {
  const dispatch = useDispatch();
  return useQuery(PwaDomain, () => getPwaDomainAction()(dispatch));
};
