import React, { useCallback, useMemo, useRef } from "react";
import { useToggle } from "../../../modules/hub2/general/hooks";
import { getFirstObjInArray } from "../../../modules/hub2/general/helpers";
import { SelectProps, SelectItem } from "./types";
import {
  Container,
  Label,
  Inner,
  Selected,
  Img,
  Arrow,
  List,
  ListItem,
  Error,
} from "./styles";

const Select: React.FC<SelectProps> = ({
  className,
  value,
  options,
  label,
  placeholder,
  error,
  disabled,
  onChange,
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [opened, setOpen] = useToggle(selectRef);
  const selectedOption = useMemo<SelectItem>(
    () => getFirstObjInArray(options, value),
    [value, options]
  );

  const toggleOpen = useCallback(() => {
    if (!disabled) {
      setOpen(!opened);
    }
  }, [opened, disabled, setOpen]);

  const handleClick = useCallback(
    (value) => {
      onChange(value);
      setOpen(false);
    },
    [setOpen, onChange]
  );

  return (
    <Container className={className}>
      <Inner ref={selectRef} onClick={toggleOpen}>
        <Selected
          disabled={disabled}
          opened={opened}
          empty={!selectedOption}
          hasError={!!error}
        >
          <Label
            small={!!selectedOption?.label}
            active={opened}
            hasError={!!error}
          >
            {label || placeholder}
          </Label>
          {selectedOption?.img && <Img src={selectedOption.img} />}
          {selectedOption?.label}
          <Arrow flipped={opened} />
        </Selected>
        {opened && (
          <List>
            {options.map((item) => (
              <ListItem
                key={item.value}
                onClick={() => handleClick(item.value)}
                selected={item.value === selectedOption?.value}
              >
                {item?.img && <Img src={item.img} />}
                {item.label}
              </ListItem>
            ))}
          </List>
        )}
      </Inner>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Select;
