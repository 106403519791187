import React, { useEffect, useState } from "react";
import intervalToDuration from "date-fns/intervalToDuration";
import { fromUnixTime } from "date-fns";

const useCountDown = (active = true, timeToCount) => {
  const [timeLeft, setTimeLeft] = useState(
    timeToCount.initialValue ? timeToCount.initialValue : timeToCount
  );
  const [isActive, setActiveStatus] = useState(active);

  const timer = React.useRef(null);

  const startTimer = React.useCallback(() => {
    timer.current = setTimeout(() => {
      if (typeof timeToCount === "object") {
        if (Object.keys(timeLeft).every((v) => !timeLeft[v])) {
          setActiveStatus(false);
        }
        setTimeLeft(
          intervalToDuration({
            start: new Date(),
            end: fromUnixTime(timeToCount.expirationDate),
          })
        );
      } else {
        if (!timeLeft) {
          setActiveStatus(false);
        }
        setTimeLeft((timeLeft) => timeLeft - 1);
      }
    }, 1000);
  }, [timeLeft]);

  useEffect(() => {
    if (isActive) {
      startTimer();
    } else {
      clearTimeout(timer.current);
    }
    return () => clearTimeout(timer.current);
  }, [timeLeft, isActive]);

  return { timeLeft, setTimeLeft, isActive, setActiveStatus };
};

export default useCountDown;
