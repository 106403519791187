import React, { useRef, useState } from "react";
import classNames from "classnames";
import LazyImg from "../LazyImg";
import MobileAppIcon from "../../../images/menu-icons/install-app.svg";
import MobileAppIconActive from "../../../images/menu-icons/install-app-active.svg";
import MobileAppIconSidebar from "../../../images/menu-icons/install-app-sidebar.svg";
import { isMobile } from "react-device-detect";
import { _t } from "../../../system/translations/InjectIntlContext";
import { useGetApp } from "../../../modules/pwa/hooks";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../system/reducers";
import { checkPwaDomain } from "../../../modules/pwa/helpers";
import { ADDITIONAL_PWA_BUTTON_WIDTH } from "./constants";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../../system/router/constants";
import { path } from "../../../system/helpers/pathHelper";

export type mobileAppProps = {
  className?: string;
};

const MobileApp: React.FC<mobileAppProps> = ({ className }) => {
  const location = useLocation();
  const isLandingPage = APP_ROUTES.startPage.some((route) =>
    path(route).includes(location.pathname)
  );
  const labelRef = useRef(null);
  const { getApp } = useGetApp();
  const [isActive, setIsActive] = useState(false);
  const mobileAppIcon = isMobile
    ? MobileAppIconSidebar
    : isActive || isLandingPage
    ? MobileAppIconActive
    : MobileAppIcon;
  const { pwaDomain } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const isPwaDomain = checkPwaDomain(pwaDomain);
  return isPwaDomain ? null : (
    <div
      style={{
        width:
          isActive &&
          labelRef?.current.offsetWidth + ADDITIONAL_PWA_BUTTON_WIDTH,
      }}
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
      onClick={() => {
        getApp();
      }}
      className={classNames("install-app", `install-app__${className}`)}
    >
      {MobileAppIcon && (
        <div className={`install-app__img-wrap`}>
          <LazyImg
            imgSrc={mobileAppIcon}
            className={"install-app__icon-img"}
            alt={"icon"}
            dontObserve={true}
          />
        </div>
      )}
      <div
        ref={labelRef}
        className={classNames(
          isMobile ? `install-app__label` : `install-app__label-lg`
        )}
      >
        {_t("mobile_app")}
      </div>
    </div>
  );
};

export default MobileApp;
