import { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const useFingerprint = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visitorId, setVisitorId] = useState<string>("");

  useEffect(() => {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        setVisitorId(result.visitorId);
        setIsLoading(false);
      });
  }, []);

  return { isLoading, visitorId };
};
