import { generatePath } from "react-router";
import { getLanguage } from "../../system/translations/helper";
import {
  gamesPayloadData,
  categoryGamesPayloadData,
  profileGamesPayloadData,
  providersPayloadData,
  allProvidersPayloadData,
  categoriesPayloadData,
  gameTagCategoriesPayloadData,
  categoryMorePopularGamesPayloadData,
} from "./constants";
import { transformGamesResult } from "./zing/helpers";

export const GET_GAMES = "GET_GAMES";
export const GET_MORE_POPULAR_GAMES = "GET_MORE_POPULAR_GAMES";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const GET_ALL_PROVIDERS = "GET_ALL_PROVIDERS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SEARCH_GAMES = "GET_SEARCH_GAMES";
export const GET_ALL_GAMES = "GET_ALL_GAMES";
export const GET_GAMETAG_CATEGORIES = "GET_GAMETAG_CATEGORIES";
export const GET_TAG_GAMES = "GET_TAG_GAMES";
export const GET_RECENTLY_PLAYED_GAMES = "GET_RECENTLY_PLAYED_GAMES";

const GET_GAMES_URL =
  "/api/v3/:lang/games/by/category/:category.json?filter[limit]=:limit&filter[offset]=:offset&filter[country]=:country";
const GET_MOST_POPULAR_GAMES_URL =
  "/:lang/casino/stat/hottest?size=:size&days=:days";
const GET_SEARCH_GAMES_URL =
  "/:lang/games/search.json?filter[country]=:country&filter[name]=:name&filter[limit]=9999";
const GET_ALL_GAMES_URL =
  "/:lang/games/search.json?filter[country]=:country&filter[limit]=9999";
const GET_GAMETAG_CATEGORIES_URL = "/:lang/casino/gametag/categories";
const GET_TAG_GAMES_URL =
  "/:lang/games/tag/:gameTag.json?filter[country]=:country";
const GET_PROVIDERS_URL = "/:lang/casino/category-platforms/list.json";
const GET_ALL_PROVIDERS_URL =
  "/api/v3/:lang/platforms/available/:countryCode.json";
const GET_CATEGORIES_URL = "/:lang/games/category/list.json";
const GET_RECENTLY_PLAYED_GAMES_URL = "/:lang/profile/recently-played.json";

export const getGamesAction = (category: string, countryCode: string) => (
  dispatch: any
): categoryGamesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_GAMES,
    request: {
      url: generatePath(GET_GAMES_URL, {
        limit: "9999",
        offset: "0",
        country: countryCode,
        lang,
        category,
      }),
      method: "GET",
    },
  }).then(
    (data: any) =>
      new Promise((resolve) => {
        const res = transformGamesResult(data.payload, data.payload?.games, "");
        resolve(res);
      })
  );
};

export const getMostPopularGamesAction = (countryCode: string) => (
  dispatch: any
): categoryMorePopularGamesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_MORE_POPULAR_GAMES,
    request: {
      url: generatePath(GET_MOST_POPULAR_GAMES_URL, {
        size: "100",
        days: "20",
        country: countryCode,
        lang,
      }),
      method: "GET",
    },
  }).then(
    (data: any) =>
      new Promise((resolve) => {
        const res = transformGamesResult(
          data.payload,
          data.payload?.hottestGames,
          "most_popular",
          "hottestGames"
        );
        resolve(res);
      })
  );
};

export const getSearchGamesAction = (name: string, countryCode: string) => (
  dispatch: any
): gamesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_SEARCH_GAMES,
    request: {
      url: generatePath(GET_SEARCH_GAMES_URL, {
        country: countryCode,
        name,
        lang,
      }),
      method: "GET",
    },
  }).then(
    (data: any) =>
      new Promise((resolve) => {
        const res = transformGamesResult(
          data.payload,
          data.payload?.casino,
          "search",
          "casino"
        );
        resolve(res);
      })
  );
};

export const getAllGamesAction = (countryCode: string) => (
  dispatch: any
): gamesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_ALL_GAMES,
    request: {
      url: generatePath(GET_ALL_GAMES_URL, {
        country: countryCode,
        lang,
      }),
      method: "GET",
    },
  }).then(
    (data: any) =>
      new Promise((resolve) => {
        const res = transformGamesResult(
          data.payload,
          data.payload?.casino,
          "all_games_by_provider",
          "casino"
        );
        resolve(res);
      })
  );
};

export const getProvidersAction = () => (
  dispatch: any
): providersPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_PROVIDERS,
    request: {
      url: generatePath(GET_PROVIDERS_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const getAllProvidersAction = (countryCode) => (
  dispatch: any
): allProvidersPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_ALL_PROVIDERS,
    request: {
      url: generatePath(GET_ALL_PROVIDERS_URL, { lang, countryCode }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const getCategoriesAction = () => (
  dispatch: any
): categoriesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_CATEGORIES,
    request: {
      url: generatePath(GET_CATEGORIES_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const getGametagCategoriesAction = () => (
  dispatch: any
): gameTagCategoriesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_GAMETAG_CATEGORIES,
    request: {
      url: generatePath(GET_GAMETAG_CATEGORIES_URL, { lang }),
      method: "GET",
      notifyMessage: {
        error: "ERROR_GET_GAMETAG_CATEGORIES",
      },
    },
  }).then((data: any) => data.payload);
};

export const getTagGamesAction = (gameTag: string, country: string) => (
  dispatch: any
): gamesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_TAG_GAMES,
    request: {
      url: generatePath(GET_TAG_GAMES_URL, { lang, gameTag, country }),
      method: "GET",
      notifyMessage: {
        error: "ERROR_GET_TAG_GAMES",
      },
    },
  }).then(
    (data: any) =>
      new Promise((resolve) => {
        const res = transformGamesResult(
          data.payload,
          data.payload?.casino,
          gameTag
        );
        resolve(res);
      })
  );
};

export const getRecentlyPlayedGamesAction = () => (
  dispatch: any
): profileGamesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_RECENTLY_PLAYED_GAMES,
    request: {
      url: generatePath(GET_RECENTLY_PLAYED_GAMES_URL, { lang }),
      method: "GET",
      notifyMessage: {
        error: "error recently games",
      },
    },
  }).then((data: any) => data.payload);
};
