import {
  translationResponse,
  languagesResponse,
  countryInfoResponse,
  bootstrapResponse,
} from "./constants";
import config from "../../system/config";

const TRANSLATION_URL = (locale) => `${locale}/translation`;
const COUNTRY_INFO_URL = `/ping.json?${new Date().getTime()}`;
const AVAILABLE_LANGUAGES_URL = "languages.json";
const GET_BOOTSTRAP = "/share/bootstrap";

export const getTranslationsAction = (locale) => (
  dispatch: any
): Promise<translationResponse> => {
  return dispatch({
    type: "TRANSLATION",
    request: {
      url: TRANSLATION_URL(locale),
      method: "GET",
      withCredentials: false,
      transformRequest: (data: any, headers: object) => {
        for (let key in headers) {
          delete headers[key];
        }
      },
    },
  }).then((data: any) => {
    return data.payload;
  });
};
export const getBootstrapAction = () => (
  dispatch: any
): Promise<bootstrapResponse> => {
  return dispatch({
    type: "BOOTSTRAP",
    request: {
      url: GET_BOOTSTRAP,
      method: "GET",
      withPrefix: false,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getAvailableLangAction = () => (
  dispatch: any
): languagesResponse => {
  return dispatch({
    type: "GET_AVAILABLE_LANGUAGES",
    request: {
      url: AVAILABLE_LANGUAGES_URL,
      method: "GET",
      withCredentials: false,
      transformRequest: (data: any, headers: object) => {
        for (let key in headers) {
          delete headers[key];
        }
      },
    },
  }).then((data: any) => data.payload);
};

export const getCountryInfoAction = () => (
  dispatch: any
): Promise<countryInfoResponse> => {
  return dispatch({
    type: "GET_COUNTRY_INFO",
    request: {
      url: COUNTRY_INFO_URL,
      method: "GET",
      withCredentials: false,
      transformRequest: (data: any, headers: object) => {
        for (let key in headers) {
          delete headers[key];
        }
      },
    },
  }).then((data: any) => {
    return data;
  });
};

export const getPwaDomainAction = () => (dispatch: any) => {
  const { getPwaUrl } = config.pwa;

  return dispatch({
    type: "GET_PWA_DOMAIN",
    request: {
      url: getPwaUrl,
      method: "GET",
      responseType: "text",
      withCredentials: false,
      transformRequest: (data: any, headers: object) => {
        for (let key in headers) {
          delete headers[key];
        }
      },
    },
  });
};
