import {
  methodsPayloadData,
  bonusesPayloadData,
  processDetailsPayload,
  getAmountPayload,
  methodProcessPayload,
  postMethodAmountPayload,
  methodWithdrawalProcessPayload,
} from "./constants";
import { generatePath } from "react-router-dom";
import { getLanguage } from "../../system/translations/helper";

/*const GET_METHOD_PROCESS = (paymentMethod) =>
  `/api/v3/en/profile/deposit/${paymentMethod}/process`;
const POST_METHOD_PROCESS = (paymentMethod) =>
  `/api/v3/en/profile/deposit/${paymentMethod}/process.json`;*/

const GET_CASHBOX_METHODS_URL = `/api/v3/:lang/profile/:type/methods.json`;
const GET_METHOD_AMOUNT_DETAILS =
  "/api/v3/:lang/profile/:type/:method/amount.json";
const POST_METHOD_AMOUNT_DETAILS = `/api/v3/:lang/profile/:type/:method/amount`;
const GET_METHOD_BONUSES = `/api/v3/:lang/profile/deposit/:method/bonus`;
const POST_METHOD_BONUSES = `/api/v3/:lang/profile/deposit/:method/bonus/select/:bonusId`;
const GET_WITHDRAWAL_PROCESS = `/api/v3/:lang/profile/withdrawal/process/:method`;
const POST_WITHDRAWAL_PROCESS = `/api/v3/:lang/profile/withdrawal/process/:method`;

export const getMethodsAction = (type) => (
  dispatch: any
): methodsPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_CASHBOX_METHODS",
    request: {
      url: generatePath(GET_CASHBOX_METHODS_URL, {
        type,
        lang,
      }),
      method: "GET",
      withCredentials: false,
      transformRequest: (data, headers) => {
        delete headers["X-Requested-With"];
      },
    },
  }).then((data: any) => data.payload);
};

export const getMethodAmountAction = (method, type) => (
  dispatch: any
): Promise<getAmountPayload> => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_METHOD_AMOUNT",
    request: {
      url: generatePath(GET_METHOD_AMOUNT_DETAILS, {
        method,
        type,
        lang,
      }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const postMethodAmountAction = (data, method, type) => (
  dispatch: any
): Promise<postMethodAmountPayload> => {
  const lang = getLanguage();
  return dispatch({
    type: "POST_METHOD_AMOUNT",
    request: {
      url: generatePath(POST_METHOD_AMOUNT_DETAILS, {
        method,
        type,
        lang,
      }),
      method: "POST",
      data: data,
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
      },
    },
  }).then((data: any) => data.payload);
};

export const getMethodBonusesAction = (method) => (
  dispatch: any
): Promise<bonusesPayloadData> => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_METHOD_BONUSES",
    request: {
      url: generatePath(GET_METHOD_BONUSES, {
        method,
        lang,
      }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const postMethodBonusesAction = (method, bonusId) => (
  dispatch: any
): any => {
  const lang = getLanguage();
  return dispatch({
    type: "POST_METHOD_AMOUNT",
    request: {
      url: generatePath(POST_METHOD_BONUSES, {
        method,
        bonusId,
        lang,
      }),
      method: "POST",
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
      },
    },
  }).then((data: any) => data.payload);
};

export const getMethodProcessAction = (method, processUrl) => (
  dispatch: any
): Promise<processDetailsPayload> => {
  const url = processUrl.split("/backend");
  return dispatch({
    type: "GET_METHOD_PROCESS",
    request: {
      url: url[1],
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const postMethodProcessAction = (data, submitUrl) => (
  dispatch: any
): Promise<methodProcessPayload> => {
  const url = submitUrl.split("/backend");
  return dispatch({
    type: "POST_METHOD_AMOUNT",
    request: {
      url: url[1],
      method: "POST",
      data: data,
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
      },
    },
  }).then((data: any) => data.payload);
};

export const getWithdrawalProcessAction = (method) => (
  dispatch: any
): Promise<processDetailsPayload> => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_WITHDRAWAL_PROCESS",
    request: {
      url: generatePath(GET_WITHDRAWAL_PROCESS, {
        method,
        lang,
      }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const postWithdrawalProcessAction = (data, method) => (
  dispatch: any
): Promise<methodWithdrawalProcessPayload> => {
  const lang = getLanguage();
  return dispatch({
    type: "POST_WITHDRAWAL_PROCESS",
    request: {
      url: generatePath(POST_WITHDRAWAL_PROCESS, {
        method,
        lang,
      }),
      method: "POST",
      data: data,
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
      },
    },
  }).then((data: any) => data.payload);
};
