import React, { ReactElement, useEffect, useRef, useState } from "react";
import Btn from "../../atoms/Btn";
import FormattedText from "../../atoms/FormattedText";
import LazyImg from "../../atoms/LazyImg";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import {
  DELAY_ACTIVE_SCROLL,
  MIN_SCROLL_HEIGHT,
  MIN_SCROLL_HEIGHT_DEVICE,
  SCROLL_STEP,
} from "./constants";
import { _t } from "../../../system/translations/InjectIntlContext";

type DescriptionModalProps = {
  text: string;
  img: string;
  linkTo: string;
  btnText: string;
  btnIcon?: ReactElement;
  handleClick: any;
};

const PromoModal: React.FC<DescriptionModalProps> = ({
  text,
  img,
  linkTo,
  btnText,
  btnIcon,
  handleClick,
}) => {
  const refWrap = useRef(null);
  const refText = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isPadding, setIsPadding] = useState(false);
  const [isActiveScrollText, setIsActiveScrollText] = useState(false);

  const makeScroll = () => {
    refText?.current.scrollTo(0, SCROLL_STEP);
    setIsActive(true);
    if (
      refText?.current?.scrollHeight < MIN_SCROLL_HEIGHT ||
      (isMobile && refText?.current?.scrollHeight < MIN_SCROLL_HEIGHT_DEVICE)
    ) {
      setIsPadding(true);
    }
    setIsActiveScrollText(true);
    setTimeout(() => {
      setIsScrollable(true);
    }, DELAY_ACTIVE_SCROLL);
  };
  const makeScrollBack = () => {
    if (refText?.current.scrollTop < SCROLL_STEP) {
      setIsScrollable(false);
      setIsActive(false);
      setIsActiveScrollText(false);
    }
  };

  useEffect(() => {
    refText?.current?.addEventListener("scroll", makeScrollBack);
    return () => {
      refText?.current?.removeEventListener("scroll", makeScrollBack);
    };
  }, []);
  useEffect(() => {
    refWrap?.current?.addEventListener("scroll", makeScroll);
    return () => {
      refWrap?.current?.removeEventListener("scroll", makeScroll);
    };
  }, []);

  return (
    <div className={"promo-modal-wrap"}>
      <div
        ref={refWrap}
        className={classNames(isActive && "promo-modal__active", "promo-modal")}
      >
        <div
          className={classNames(
            "promo-modal__img-wrap",
            isActive && "promo-modal__img-wrap-active"
          )}
        >
          {img && (
            <LazyImg imgSrc={img} alt={""} className={"promo-modal__img"} />
          )}
        </div>
        {text && (
          <FormattedText
            className={classNames(
              "promo-modal__text",
              isActive && "promo-modal__text-active",
              isActiveScrollText && "promo-modal__text-active-scroll",
              isPadding && "promo-modal__text-wrap",
              !isScrollable && "promo-modal__text-scrollable",
              (linkTo || btnText) && "promo-modal__text-with-cta-btn"
            )}
            forwardedRef={refText}
            html={text}
          />
        )}
      </div>
      {(linkTo || btnText) && (
        <div className={"promo-modal__btn"}>
          <div>
            <Btn
              icon={btnIcon}
              label={_t(btnText)}
              size={"lg"}
              type={"action"}
              linkTo={linkTo}
              onClick={handleClick}
              block
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PromoModal;
