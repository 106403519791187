import React, { useState } from "react";
import { Link } from "react-router-dom";
import LazyImg from "../LazyImg";
import arrowRight from "../../../images/icons/arrow-right-sb.svg";
import arrowRightActive from "../../../images/icons/arrow-right-sb-active.svg";
import classNames from "classnames";
import { path } from "../../../system/helpers/pathHelper";
import { useModal } from "../../../modules/modals/hooks";

export type SportLinkProps = {
  url: string;
  image: string;
  imageActive: string;
  name: string;
  onClick: (e: React.MouseEvent<HTMLElement>, url: string) => any;
};

const SportLink: React.FC<SportLinkProps> = ({
  url,
  image,
  imageActive,
  name,
  onClick,
}) => {
  const [isActiveLink, setIsActiveLink] = useState(false);
  const icon = isActiveLink ? arrowRightActive : arrowRight;
  const { hideModal } = useModal();
  const handleClick = () => hideModal();
  return (
    <div
      className={classNames(
        isActiveLink && "sport-link__wrap-active",
        "sport-link__wrap"
      )}
      onClick={(e) => onClick(e, url)}
    >
      <Link
        to={path(url)}
        className={"sport-link"}
        onClick={handleClick}
        onMouseEnter={() => {
          setIsActiveLink(true);
        }}
        onMouseLeave={() => {
          setIsActiveLink(false);
        }}
      >
        <LazyImg
          style={{ display: !isActiveLink && "none" }}
          className={classNames("sport-link__img")}
          imgSrc={imageActive}
          dontObserve
        />
        <LazyImg
          style={{ display: isActiveLink && "none" }}
          className={classNames("sport-link__img")}
          imgSrc={image}
          dontObserve
        />

        <span
          className={classNames(
            isActiveLink && "sport-link__name-active",
            "sport-link__name"
          )}
        >
          {name}
        </span>
        <LazyImg
          className={"sport-link__icon"}
          imgSrc={icon}
          dontObserve={true}
        />
      </Link>
    </div>
  );
};

export default SportLink;
