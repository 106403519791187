import { getInfoAction } from "./apiActions";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getLanguage } from "../../system/translations/helper";

export const useGetInfo = (infoPage: string) => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(["info", infoPage, lang], () =>
    getInfoAction(infoPage)(dispatch)
  );
};
