import { isMobile } from "react-device-detect";

export const DATE_FORMAT = "yyyy-MM-dd";
export const DISPLAY_DATE_FORMAT = "dd/MM/yyyy";
export const PRIVACY_WAIT_PERIOD = 7;

export type gameItem = {
  desktop: number;
  desktopIdentifier: string;
  gameTags: [];
  imageBackgroundUrl: string;
  imagePlatformUrl: string;
  imageUrl: string;
  jackpotAmount: string;
  mobile: number;
  mobileIdentifier: string;
  name: string;
  platformCode: string;
  platformName: string;
  playForFunText: string;
  playForFunUrl: string;
  playForFunUrlMobile: string;
  playForRealText: string;
  playForRealUrl: string;
  playForRealUrlMobile: string;
  popularIndex: number;
  ratio: string;
  seoDescription: string;
  seoText: string;
  showPlatformImage: number;
  status: string;
};

export type seoLinkTypes = {
  seoTitle: string;
  seoDescription: string;
  seoText: string;
  imageUrl: string;
};

export type gamesPayloadData = {
  casino: gameItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const USER_INFO_START = "USER_INFO_START";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_ERROR = "USER_INFO_ERROR";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const UPDATE_BALANCE_START = "UPDATE_BALANCE_START";
export const UPDATE_BALANCE_SUCCESS = "UPDATE_BALANCE_SUCCESS";
export const UPDATE_BALANCE_ERROR = "UPDATE_BALANCE_ERROR";

export const UPDATE_TFA_METHODS = "UPDATE_TFA_METHODS";

export type userBalanceType = {
  bonusBalance: string | number;
  currency: string;
  currentVIPLevel: string;
  nextVIPLevelTrans?: string;
  currentVIPLevelTrans: string;
  loyaltyPoint: string;
  totalBalance: string | number;
  withdrawableBalance: string;
};
export type loyaltyPointsStatusType = {
  accumulatedLoyaltyPoint: string;
  currentLoyaltyPoint: string;
  currentVIPLevel: string;
  currentVIPLevelTrans: string;
  nextVIPLevel: string;
  nextVIPLevelTrans: string;
  remainingPointsForNextLevel: string;
  totalPointsForNextLevel: string;
};

export type userInfoType = {
  firstName: string;
  isFirstLogin: string | boolean;
  lastLogin: string;
  lastName: string;
  partyId: string;
  quickSignupComplete: boolean;
  registrationStatus: string;
  sessionKey: string;
  termsUpdatedAt: string;
  username: string;
  balance: userBalanceType;
  address: string;
  balanceNeedToRefresh: boolean;
  birthDate: string;
  bonusCode: string;
  city: string;
  country: string;
  currency: string;
  currencySymbol: string;
  depositStorage: any;
  email: string;
  failedAttempts: number;
  kycStatus: string;
  language: string;
  loyaltyPointsStatusNeedToRefresh: boolean;
  mobilePhone: string;
  nickname: string;
  password: any;
  pendingLimitConfirmation: string;
  pendingWithdrawals: [];
  phone: string;
  postalCode: string;
  province: string;
  receiveEmail: string;
  regDate: string;
  repeatPassword: any;
  signupStatusNeedToRefresh: boolean;
  subscribeNeedToRefresh: boolean;
  subscribed: boolean;
  twoFactorAuthCode: string;
  twoFactorAuthPassed: boolean;
  twoFactorAuthMethods: {
    none: boolean;
    sms: boolean;
    email: boolean;
  };
  userNeedToRefresh: boolean;
  vipStatus: string;
  withdrawalStorage: any;
  withdrawalsNeedToRefresh: boolean;
  loyaltyPointsStatus: loyaltyPointsStatusType;
  isFullProfile: boolean;
  isFullProfileWithdrawal: boolean;
};

export type userInfoData = {
  isLoading: boolean;
  form: string;
  status: string;
  user: userInfoType;
  twoFactorAuth?: boolean;
  lastUpdated?: number;
  data: {
    PHPSESSID: string;
  };
};

export type userShareLoyaltyStatusType = {
  current_loyalty_points: string;
  current_vip_level: string;
  total_points_for_next_level: string;
  remaining_points_for_next_level: string;
  accumulated_loyalty_points: string;
};

export type verificationStatusType = {
  phone: string;
  email: string;
};

export type userShareInfoType = {
  party_id: string;
  phone: string;
  balance: userBalanceType;
  loyalty_status: userShareLoyaltyStatusType;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  country: string;
  currency: string;
  language: string;
  birth_date: string;
  reg_date: string;
  last_login: string;
  nickname: string;
  address: string;
  city: string;
  postal_code: string;
  mobile_phone: string;
  mobile_country_code: string;
  mobile_local_number: string;
  vip_status: string;
  kyc_status: string;
  marketing_sms: boolean;
  marketing_email: boolean;
  level: string;
  user_type: number;
  is_autopay: boolean;
  registration_status: string;
  session_key: string | null;
  extra_info: string[];
  gender: string;
  email_verification_status: string | number;
  readonly_fields: string;
  agent_code: string;
  bonus_code: string;
  building: number;
  contact_preference: string;
  doc_type: string;
  doc_number: string;
  google_search_id: string;
  nationality: string;
  profession: string;
  receive_email: boolean;
  referral_id: string;
  secondary_currencies?: string[];
  security_answer: string;
  user_tracking_codes?: string[];
  socket: {
    user: string;
    brand: string;
  };
  access_token?: {
    token: string;
  };
  isFullProfile: boolean;
  isFullProfileWithdrawal: boolean;
  tfa: {
    enabled: boolean;
    type: string | null;
  };
  verification: verificationStatusType;
  context?: {
    type: string;
    value: any;
  };
};

export type userReducerType = {
  info: userInfoData;
};

export type playHistoryItemType = {
  id: string;
  date: string;
  tranType: string;
  platformCode: string;
  gameId: number;
  gameName: string;
  gameTranId: number;
  amount: string;
  postBalance: string;
  amountReal: string | number;
  postBalanceReal: string;
  amountBonus: string;
  postBalanceBonus: string;
  action: string;
  actionId: number;
  status: string;
};

export type paymentHistoryItemType = {
  amount: string;
  action: string;
  paymentId: string;
  actionId: string;
  status: string;
  requestDate: string;
  processDate: string;
  actionType: string;
  imageUrl: string;
  statusCode: string;
  method: string;
  balance: string;
};

export type ChangePasswordTypes = {
  oldPassword: string;
  password: string;
  repeatPassword: string;
};

export type bonusItem = {
  statusCode: string;
  id: string;
  bonusPlanName: string;
  status: string;
  triggerDate: string;
  expiryDate: string;
  releaseDate: string;
  amount: string;
  wagerRequirement: string;
  amountWagered: string;
};

export type FillOutProfileStepProps = {
  currentStep: number;
  formik: any;
  isLoading: boolean;
  isCropped?: boolean;
  setStep: (step) => void;
};

export type FillOutProfileFormTypes = {
  onSubmit: (values: any) => any;
  isLoading?: boolean;
  formData?: any;
  isCropped?: boolean;
};

export type bonusesPayloadData = {
  bonuses: bonusItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type cashbackItem = {
  amount: number;
  apply_before: string;
  bonus_plan_id: number;
  bonus_plan_name: string;
  id: number;
  release_date: string;
  release_id: number;
};

export type cashbackItems = {
  [key: string]: cashbackItem;
};

export type cashbackPayloadData = {
  cashbacks: cashbackItems;
  currency: string;
};

export type allowedCashbacksData = {
  isAllowedCasinoCashback: boolean;
  isAllowedSportCashback: boolean;
};

export type betDetailsPayloadData = {
  history: {
    description: string;
    orders: string[];
    responseCode: string;
    signature: string;
    timestamp: string;
  };
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export const regRequestKeys = {
  username: "gamingtec_quick_signup[username]",
  phone: "gamingtec_quick_signup[mobilePhone]",
  email: "gamingtec_quick_signup[email]",
  password: "gamingtec_quick_signup[password]",
  country: "gamingtec_quick_signup[country]",
  currency: "gamingtec_quick_signup[currency]",
  receiveEmail: "gamingtec_quick_signup[receiveEmail]",
  agreeTermAndConditions: "gamingtec_quick_signup[agreeTermAndConditions]",
  recaptcha: "g-recaptcha-response",
  override_flag: "override_flag",
};

export const loginRequestKeys = {
  username: "login[_username]",
  password: "login[_password]",
  blackbox: "login[_blackbox]",
  fingerprintId: "login[_fpid]",
};

export const phoneVerifyKeys = {
  code: "code",
};

export const resendPhoneVerifyCode = {
  phone: "phone",
};

export const changePasswordKeys = {
  oldPassword: "gamingtec_password[oldPassword]",
  password: "gamingtec_password[password]",
  repeatPassword: "gamingtec_password[repeatPassword]",
};

export const fillOutProfileKeys = {
  firstName: "gamingtec_quick_signup_finalize[firstName]",
  lastName: "gamingtec_quick_signup_finalize[lastName]",
  mobilePhone: "gamingtec_quick_signup_finalize[mobilePhone]",
  gender: "gamingtec_quick_signup_finalize[gender]",
  birthDateDay: "gamingtec_quick_signup_finalize[birthDate][day]",
  birthDateMonth: "gamingtec_quick_signup_finalize[birthDate][month]",
  birthDateYear: "gamingtec_quick_signup_finalize[birthDate][year]",
  country: "gamingtec_quick_signup_finalize[country]",
  city: "gamingtec_quick_signup_finalize[city]",
  postalCode: "gamingtec_quick_signup_finalize[postalCode]",
  address: "gamingtec_quick_signup_finalize[address]",
  tokenCompletion: "gamingtec_signup_completion[_token]",
};

export const changeTfaDataKeys = {
  email: "gamingtec_quick_signup_finalize[email]",
  mobilePhone: "gamingtec_quick_signup_finalize[mobilePhone]",
};

export const forgotPasswordRequestKeys = {
  email: "gamingtec_forgot_password_step_1_and_2[email]",
};

export const resetPasswordRequestKeys = {
  email: "gamingtec_forgot_password_step_3[email]",
  resetPasswordKey: "gamingtec_forgot_password_step_3[resetPasswordKey]",
  password: "gamingtec_forgot_password_step_3[password]",
  repeatPassword: "gamingtec_forgot_password_step_3[repeatPassword]",
};

export const kycRequestKeys = {
  frontSideCreditCardScan: "gamingtec_kyc[frontSideCreditCardScan]",
  backSideCreditCardScan: "gamingtec_kyc[backSideCreditCardScan]",
  idType: "gamingtec_kyc[idType]",
  idTypeScan: "gamingtec_kyc[idTypeScan]",
  idTypeScanBack: "gamingtec_kyc[idTypeScanBack]",
  documentType: "gamingtec_kyc[documentType]",
  documentTypeScan: "gamingtec_kyc[documentTypeScan]",
};

export const profileEditRequestKeys = {
  username: "gamingtec_full_update[username]",
  firstName: "gamingtec_full_update[firstName]",
  lastName: "gamingtec_full_update[lastName]",
  email: "gamingtec_full_update[email]",
  mobilePhone: "gamingtec_full_update[mobilePhone]",
  birthDate: {
    day: "gamingtec_full_update[birthDate][day]",
    month: "gamingtec_full_update[birthDate][month]",
    year: "gamingtec_full_update[birthDate][year]",
  },
  address: "gamingtec_full_update[address]",
  city: "gamingtec_full_update[city]",
  country: "gamingtec_full_update[country]",
  postalCode: "gamingtec_full_update[postalCode]",
  subscribe: "gamingtec_full_update[subscribe]",
  method: "_method",
};

export const playHistoryRequestKeys = {
  date: "gamingtec_history_play[date]",
  hour: "gamingtec_history_play[hour]",
};

export const paymentHistoryRequestKeys = {
  startDate: "gamingtec_history_payment[startDate]",
  endDate: "gamingtec_history_payment[endDate]",
};

export const tfaRequestKeys = {
  methods: "gamingtec_two_fa_methods[methods][]",
  token: "gamingtec_two_fa_methods[_token]",
};

export const tfaProcessRequestKeys = {
  code: "gamingtec_two_fa[code]",
};

export const dontAllowSmsTfaByCountry = ["JP"];

export type updateTfaMethodPayload = {
  none: boolean;
  sms: boolean;
  email: boolean;
};

export type tfaMethodsEnums = "none" | "sms" | "email";

export const excludedCountriesList = ["US"];
export const countriesWithRequiredTfa = ["TR"];

export const welcomePromoSlug = "welcome-popup";

export const settingSlider = {
  slidesToShow: isMobile ? 1.5 : 3,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
};

export const settingSliderPromo = {
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1.5,
      },
    },
  ],
};

export type linkItemAfterLogin = {
  classLink: string;
  desktop: boolean;
  mobile: boolean;
  name: string;
  seoDescription: string;
  seoText: string;
  seoTitle: string;
  target: string;
  url: string;
  imageName: string;
  imageActiveName: string;
};

export const countriesListNotRequiredVerification = ["TR"];

export const currencyListForReplacing = { INR: true };

export type requiredKYCFieldsTypes = {
  frontSideCreditCardScan?: boolean;
  backSideCreditCardScan?: boolean;
  idType: boolean;
  idTypeScan?: boolean;
  idTypeScanBack?: boolean;
  documentType: boolean;
  documentTypeScan: boolean;
};

export const PAYMENT_HISTORY_LIST = "payment_history_list";

export const VERIFICATION_STATUS_VERIFIED = "verified";
export const VERIFICATION_STATUS_UNVERIFIED = "unverified";
export const VERIFICATION_STATUS_REQUESTED = "requested";

export const RESEND_CODE_TIME_LEFT = 59;
export const SIX_DIGIT_CODE_MASK = "999 999";

export const minCancelBonusesAmount = {
  EUR: 5,
  TRY: 50,
  USD: 5,
  INR: 500,
  CAD: 5,
  BRL: 30,
  NOK: 50,
  JPY: 800,
  CPL: 5000,
  PLN: 25,
  BDT: 500,
};

export const minAllowedCashbackAmount = {
  EUR: 4.9,
  TRY: 49,
  USD: 4.9,
  INR: 149,
  CAD: 4.9,
  BRL: 49,
  NOK: 49,
  JPY: 790,
  CPL: 490,
  PLN: 49,
  BDT: 490,
};

export const onlyDigitsRegExp = /[^\d]/g;

export const excludedPrivacyCountry = ["TR", "IN", "BR"];
export const excludedCookieCountry = ["BR"];
