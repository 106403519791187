import React, { useMemo, useState, Suspense } from "react";
import MainLogo from "../../atoms/MainLogo";
import { footerNavItemsMobile, headerNavItems } from "./constants";
import { NavItemPropsType } from "../../atoms/NavItem";
import { ReactComponent as HamburgerLogo } from "../../../images/elements/hamburger-menu.svg";
import { isMobile } from "react-device-detect";
import { isLoggedIn } from "../../../system/helpers/userHelper";
import { path } from "../../../system/helpers/pathHelper";
import {
  dataLayerHeaderLogo,
  dataLayerHeaderMenu,
  dataLayerFooterNavPanel,
} from "../../../system/dataLayer";
import { CHAT_CLASS_LINK } from "../../../modules/chat/constants";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { linkClick } from "../../../system/helpers/urlHelper";
import MobileApp from "../../atoms/InstallApp";
import { useChat } from "../../../modules/chat/hooks";

const UserInfo = React.lazy(() => import("../../molecules/UserInfo"));
const NotLoggedInfo = React.lazy(() => import("./NotLoggedInfo"));
const NavBar = React.lazy(() => import("../../molecules/NavBar"));
const SidebarWrap = React.lazy(
  () =>
    new Promise((resolve, reject) => {
      return setTimeout(
        () =>
          import("../../../modules/sidebar/components").then((res: any) => {
            resolve(res);
          }),
        2000
      );
    })
);

type HeaderProps = {
  links?: NavItemPropsType[];
  linksNavMob?: NavItemPropsType[];
  linksLoaded?: boolean;
};

const Header: React.FC<HeaderProps> = ({ links, linksNavMob, linksLoaded }) => {
  const [toggleSidebar, setToggleSidebar] = useState(null);
  const handleSidebarChange = (sidebarStateSetter) =>
    setToggleSidebar(() => sidebarStateSetter);
  const isUser = isLoggedIn();
  const location = useLocation();
  const history = useHistory();
  const { visible: chatVisible, openChat } = useChat();

  const isSportsBookOrInPlayRoute =
    location.pathname.includes("sportsbook") ||
    location.pathname.includes("in-play");

  const headerNavMobLinksWithIcons = useMemo(
    () =>
      linksNavMob
        ?.map((link) => ({
          ...link,
          ...footerNavItemsMobile[link.classLink],
        }))
        ?.filter(
          ({ classLink }) =>
            (classLink === CHAT_CLASS_LINK && chatVisible) ||
            classLink !== CHAT_CLASS_LINK
        ),
    [linksNavMob, chatVisible]
  );

  let desktopLinks = useMemo(
    () =>
      links?.map((link, idx) => {
        let result = {
          ...link,
          ...headerNavItems[link.classLink],
        };
        if (!!headerNavItems[link.classLink]) {
          result.iconPosition = "left";
        }

        return result;
      }),
    [links, headerNavItems]
  );

  const onClickItemHandler = (item, event) => {
    const { url, classLink } = item;
    if (isMobile) {
      dataLayerFooterNavPanel(url);
    } else {
      dataLayerHeaderMenu(url);
    }

    if (classLink === CHAT_CLASS_LINK) {
      openChat();
    }

    linkClick(
      url,
      event,
      history,
      "sportsbook_link",
      url.includes("sportsbook") || url.includes("in-play")
    );
  };

  return (
    <div className={classNames("header")}>
      {isMobile && (
        <div
          className="hamburger-menu"
          onClick={() => toggleSidebar && toggleSidebar(true)}
        >
          <HamburgerLogo />
        </div>
      )}
      <Suspense fallback={<div />}>
        <SidebarWrap
          handleSidebarChange={handleSidebarChange}
          toggleSidebar={toggleSidebar}
        />
      </Suspense>
      <MainLogo
        className="main-logo"
        linkTo={path("/")}
        onClick={() => dataLayerHeaderLogo()}
      />
      <Suspense fallback={<div />}>
        <NavBar
          routeShouldMatch
          items={!isMobile ? desktopLinks : headerNavMobLinksWithIcons}
          className={
            isMobile &&
            classNames("footer-navigation-panel", {
              hidden: isSportsBookOrInPlayRoute,
            })
          }
          withTrans={!linksLoaded}
          onClickItem={onClickItemHandler}
          addLocaleToLink
        />
      </Suspense>
      <div className={"header__info-wrap"}>
        {!isMobile && linksLoaded && <MobileApp className={"lg"} />}
        {isUser ? (
          <Suspense fallback={<div />}>
            <UserInfo />
          </Suspense>
        ) : (
          <Suspense fallback={<div />}>
            <NotLoggedInfo />
          </Suspense>
        )}
      </div>
    </div>
  );
};

Header.defaultProps = {
  links: [],
  linksNavMob: [],
};
export default Header;
