import React, { Suspense, useEffect, useState } from "react";
import { usePhoneVerify, usePhoneVerifyRequest } from "../../apiHooks";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../system/reducers";
import useCountDown from "../../../../system/helpers/countDownHook";
import Loader from "../../../../components/atoms/Loader";
import { onlyDigitsRegExp, RESEND_CODE_TIME_LEFT } from "../../constants";
import { prepareCode } from "../../helpers";
const VerificationPhoneForm = React.lazy(
  () => import("../forms/VerificationPhoneForm")
);
const VerificationPhoneCheckForm = React.lazy(
  () => import("../forms/VerificationPhoneCheckForm")
);

const VerificationPhoneModal: React.FC = () => {
  const [step, setStep] = useState(1);

  const { mobilePhone, birthDate, sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  const [phone, setPhone] = useState(mobilePhone);
  const [
    phoneVerifyRequestHandler,
    { isLoading: isLoadingPhoneVerifyRequest, isSuccess: isPhoneSent },
  ] = usePhoneVerifyRequest();
  const [phoneVerifyHandler, { isLoading }] = usePhoneVerify();
  const onSubmit = async (values) => {
    const code = prepareCode(values.code);
    phoneVerifyHandler({ code, sessionKey, phone, birthDate });
  };

  const { timeLeft, setTimeLeft, isActive, setActiveStatus } = useCountDown(
    false,
    RESEND_CODE_TIME_LEFT
  );
  useEffect(() => {
    if (isPhoneSent) {
      setStep(2);
      setActiveStatus(true);
    }
  }, [isPhoneSent]);

  const getSmsCodeHandler = (phone) => {
    phoneVerifyRequestHandler({ phone, sessionKey });
  };

  const resendCode = async () => {
    setActiveStatus(true);
    setTimeLeft(RESEND_CODE_TIME_LEFT);
    getSmsCodeHandler(phone);
  };

  const phoneCheckHandler = async (values) => {
    const phoneFormatted = values.phone.replace(onlyDigitsRegExp, "");
    setPhone(phoneFormatted);
    getSmsCodeHandler(phoneFormatted);
  };

  const onCancel = () => {
    setActiveStatus(false);
    setTimeLeft(RESEND_CODE_TIME_LEFT);
    setStep(1);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      {step === 1 && (
        <VerificationPhoneCheckForm
          onSubmit={phoneCheckHandler}
          isLoading={isLoadingPhoneVerifyRequest}
          hiddenPhone={mobilePhone}
        />
      )}
      {step === 2 && (
        <VerificationPhoneForm
          resendCode={resendCode}
          onSubmit={onSubmit}
          isLoading={isLoading}
          hiddenPhone={phone}
          timerOn={isActive}
          timeLeft={timeLeft}
          onCancel={onCancel}
        />
      )}
    </Suspense>
  );
};

export default VerificationPhoneModal;
