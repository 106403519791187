import {
  loginModal,
  regModal,
  forgotPasswordModal,
  pwaPromoModal,
  depositModal,
  regModalBonus,
} from "./index";
import { isLoggedIn } from "../../system/helpers/userHelper";
import { ModalDataProps } from "./types";

type routesType = {
  [key: string]: { modal: ModalDataProps; restricted?: any };
};

export const routes = (depositVersion): routesType => ({
  "#login": { modal: loginModal },
  "#registration": { modal: regModal },
  "#registration-bonus": { modal: regModalBonus },
  "#forgot-password": { modal: forgotPasswordModal },
  "#get-app": { modal: pwaPromoModal },
  "#cashier": {
    modal: depositModal(depositVersion),
    restricted: () => !isLoggedIn(),
  },
});
