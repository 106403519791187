import React from "react";
import config from "../config";
import { getLanguage } from "../translations/helper";

export const currencies = [
  {
    value: "EUR",
    symbol: "€",
  },
  {
    value: "USD",
    symbol: "$",
  },
  {
    value: "BRL",
    symbol: "R$",
  },
  {
    value: "NOK",
    symbol: "kr",
  },
  // {
  //   value: "SEK",
  //   symbol: "kr",
  // },
  {
    value: "JPY",
    symbol: "¥",
  },
  {
    value: "TRY",
    symbol: "₺",
  },
  {
    value: "INR",
    symbol: "₹",
  },
  {
    value: "CAD",
    symbol: "С$",
  },
  {
    value: "CLP",
    symbol: "$",
  },
  {
    value: "PLN",
    symbol: "zł",
  },
  {
    value: "BDT",
    symbol: "৳",
  },
];

export const toFixedStrict = (num, fixed = 2) => {
  // formats number or a string representation of number to fixed-point notation without rounding
  return parseFloat(`${num}`).toFixed(2);
  /*  return (Math.trunc(num * Math.pow(10, fixed)) / Math.pow(10, fixed)).toFixed(
    fixed
  );*/
};

export const balanceFormat = (amount, userCurrency) => {
  const locale =
    config.currency_to_balance_format[userCurrency] ||
    config.currency_to_balance_format["default"];
  return (+amount).toLocaleString(locale);
};

export const getBalance = (num, userCurrency) =>
  balanceFormat(toFixedStrict(num), userCurrency);

export const getCurrencySign = (val) => {
  const currency = currencies.find((currency) => currency.value === val);
  if (!!currency) {
    return currency.symbol;
  } else {
    return "";
  }
};

const renderLabel = (symbol, value) => (
  <div className="currency-select-label">
    <span className="currency-select-label__symbol-icon">{symbol}</span>
    <span className="currency-select-label__value">{value}</span>
  </div>
);
export const currencyOptions = () =>
  currencies.map(({ value, symbol }) => ({
    value: value,
    label: renderLabel(symbol, value),
  }));
