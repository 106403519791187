import React from "react";
import TournamentList, {
  ItemType,
} from "../../../../components/atoms/TournamentList";

type TournamentHowToPlayModalProps = {
  rules: ItemType[];
};

const TournamentHowToPlayModal: React.FC<TournamentHowToPlayModalProps> = ({
  rules,
}) => (
  <div className={"tournament-how-to-play-modal-body"}>
    <TournamentList variant={"timeline"} items={rules} />
  </div>
);

export default TournamentHowToPlayModal;
