import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
const LoyaltySystemTemplate = React.lazy(
  () => import("../../pages/loyaltySystem/LoyaltySystemTemplate")
);

const LoyaltySystemPage = () => {
  return (
    <>
      <Helmet>
        <title>{_t("loyalty_system_page_title")}</title>
        <meta
          name="description"
          content={_t("loyalty_system_page_description")}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--loyalty-system"}
        seoPanelProps={{ keySlug: "vip_levels" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <LoyaltySystemTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default LoyaltySystemPage;
