import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
const GameTemplate = React.lazy(() => import("./GameTemplate"));

const GamePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{_t("game_page_title")}</title>
        <meta name="description" content={_t("game_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--game"}
        fixedHeader={false}
        withoutFooter
        withoutSeoPanel
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <GameTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default GamePage;
