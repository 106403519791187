import React from "react";
import config from "../../system/config";
import { ReactComponent as WhatsappIcon } from "../../images/chat/whatsapp.svg";
import { ReactComponent as TelegramIcon } from "../../images/chat/telegram.svg";

export const chatClick = () => {
  if (window.LC_API && !!window.LC_API?.open_chat_window) {
    window.LC_API?.open_chat_window();
  } else if (window.zendeskChat) {
    window.zendeskChat.activate();
  } else {
    window.location.href = `mailto:${config.support_email}`;
  }
};

export const getChatIcon = (classLink: string) => {
  if (classLink === "whatsapp") {
    return <WhatsappIcon />;
  }

  if (classLink === "telegram") {
    return <TelegramIcon />;
  }

  return null;
};
