import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../../../components/atoms/Loader";
import { useModal } from "../../../modals/hooks";

type postMessageData = {
  data?: {
    type: string;
    data: {
      method: string;
    };
  };
};

const ProcessModal = ({ url }) => {
  const { hideModal } = useModal();
  const [isLoadingIframe, setLoad] = useState(true);
  const onLoadIframe = () => {
    setLoad(false);
  };

  const checkMessage = useCallback(
    ({ data: messageData }: postMessageData) => {
      const { type } = messageData || {};

      if (type === "close") {
        hideModal();
      }
    },
    [hideModal]
  );

  useEffect(() => {
    window.addEventListener("message", checkMessage);

    return () => {
      window.removeEventListener("message", checkMessage);
    };
  }, [checkMessage]);

  return (
    <>
      {isLoadingIframe && <Loader center overflow />}
      <iframe
        src={url}
        title={"process-iframe"}
        width={"100%"}
        height={"100%"}
        allow={"clipboard-write *; clipboard-read *"}
        onLoad={onLoadIframe}
      />
    </>
  );
};

export default ProcessModal;
