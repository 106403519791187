import { full_width_games } from "./full_width_games";
import prodConfig from "./prod";
import stageConfig from "./stage";
const CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV || "";

const config = {
  sportbook: {},
  gtm_id: "GTM-W5DK74",
  licence: {
    antillephone_id: "07028267-4be2-4a34-98e3-c2010972db69",
    em_id: "fe251718-90bb-4e9c-9dc6-43d95bc2db70",
  },
  recaptcha: {
    v2: {
      siteKey: "6Len4twZAAAAANeLyOxOTfhIarcPbMyRUSRkyvVE",
    },
    v3: {
      siteKey: "6Le5utMaAAAAAE5xcHASHUAAq5QjCi7esXHRErve",
    },
  },
  phone: {
    defaultMask: "+999 999 999 999 99",
  },
  user: {
    updateBalanceDelay: 30000, //in miliseconds
    redirectAfterLogin: null,
    tfaResendCodeInterval: 10, //in sec
  },
  pwa: {
    defaultDomain: "www.bttltpwa.com",
    getPwaUrl: "https://kv.dogt.xyz/pwa/bettilt",
  },
  sentry_dsn:
    "https://0b6b54f081434c289388c62babcfb001@sentry.go-prod.dogt.xyz/4",
  country_to_locale: {
    JP: "ja",
    PT: "pt",
    IT: "it",
    ES: "es",
    SE: "sv",
    FI: "fi",
    TR: "tr",
    DE: "de",
    PL: "ca",
    IN: "en",
    CA: "ca",
    CL: "es",
  },
  country_to_currency: {
    PT: "EUR",
    JP: "USD",
    IT: "EUR",
    ES: "EUR",
    SE: "SEK",
    FI: "EUR",
    TR: "TRY",
    IN: "INR",
    BR: "BRL",
    NO: "NOK",
    MX: "USD",
    DE: "EUR",
    CZ: "EUR",
    PL: "PLN",
    HU: "EUR",
    CA: "EUR",
    CL: "CLP",
  },
  exchangeRates: {
    EUR: { lp: 100, cash: 1, minConversionAmount: 1000 },
    USD: { lp: 100, cash: 1, minConversionAmount: 1000 },
    TRY: { lp: 100, cash: 10, minConversionAmount: 1000 },
    JPY: { lp: 100, cash: 1, minConversionAmount: 1000 },
    INR: { lp: 100, cash: 100, minConversionAmount: 1000 },
    CLP: { lp: 100, cash: 100, minConversionAmount: 1000 },
    PLN: { lp: 100, cash: 5, minConversionAmount: 1000 },
  },
  deposit_amount: {
    EUR: 15,
    TRY: 150,
  },
  country_to_phone_code: {
    PT: { code: "+351" },
    JP: { code: "+81" },
    IT: { code: "+39" },
    ES: { code: "+34" },
    SE: { code: "+46" },
    FI: { code: "+358" },
    TR: { code: "+90", mask: "+90 999 999 99 99", numberOfDigits: 12 },
    IN: { code: "+91" },
    BR: { code: "+55" },
    NO: { code: "+47" },
    MX: { code: "+52" },
    DE: { code: "+49" },
    CZ: { code: "+420" },
    PL: { code: "+48" },
    HU: { code: "+36" },
    CA: { code: "+1" },
    CL: { code: "+56" },
  },
  currency_to_balance_format: {
    default: "en-GB",
    INR: "en-IN",
  },
  cryptopay: {
    mainCurreny: "BTC",
    defaultRate: "BTCEUR",
  },
  support_email: "support@bettilt.com",
  chat: {
    livechat: {
      name: "livechat",
      id: "livechat",
      src: "https://cdn.livechatinc.com/tracking.js",
      default: {
        name: "livechat",
        license: "4707951",
      },
      ja: {
        name: "livechat",
        license: "4707951",
        lang: "en",
      },
    },
    zendesk: {
      name: "zendesk",
      license: "065cad0b-9a1e-456a-b325-146218de7d91",
    },
  },
  full_width_games: full_width_games,
  bet_games: {
    partner: "Bahsegel",
    server: "https://game2.betgames.tv",
    src: "https://game2.betgames.tv/design/client/js/betgames.js",
  },
  google_optimize_id: "OPT-NWLNVHD",
  euro2020: {
    spreadsheetId: "1BTJMFRUfLLXWubVKGriTehUsyqjah2e0K31wjWOXa8M",
    accessToken: "AIzaSyDiPRTL_6KGgO3UPyOWk9CYEGb7pHRF53g",
  },
  leagueBettilt: {
    prefixTrans: "tennis_leaderboard",
    header: {
      show: true,
      title: true,
      description: false,
      messageWithCtaBtn: {
        btnType: "primary", // or "transparent-bordered"
      },
      twoCtaButtons: false,
    },
    timerTitle: false,
    leaderboard: {
      title: false,
      isTableView: true, // or pyramid view
      tableThemeTransparent: true,
      showPaging: true,
      showMoreBtn: false,
      rowsAmount: 20,
    },
  },
  vipBettiltIndia: {
    videoUrl:
      "https://s3.bttmedia.com/hub/b3/files/KPqs9RAObEDPPc0t2IhngSqruheAEgMD7j95apLe.mp4",
  },
  firebaseConfig: {
    apiKey: "AIzaSyDIXHvUeXEdhI4kHyn2-0t22Q4sM5KzkIw",
    authDomain: "bt-pwa-b6530.firebaseapp.com",
    projectId: "bt-pwa-b6530",
    storageBucket: "bt-pwa-b6530.appspot.com",
    messagingSenderId: "682872233145",
    appId: "1:682872233145:web:31f0becd701ded3b3ecf68",
    measurementId: "G-EN4Q9FTFWP",
  },
  appcues: {
    apiKey: "3a64552a-abc8-4654-8212-cff3dce3c017",
    src: "https://fast.appcues.com/94928.js",
  },
  abExperimentIds: {
    captcha: "jMWXQNwUQNm9PTXu_ZD05A",
  },
  viptTitleKey: "vipt_title",
  isShowAfterLoginModal: false,
  hiddenFillOutProfile: ["PT"],
  defaultLanguage: "en",
};

const configByEnv = CONFIG_ENV === "stage" ? stageConfig : prodConfig;

export default { ...config, ...configByEnv };
