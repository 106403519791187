import { useLoadScript } from "./loadScriptHook";
import config from "../config";
import { dataLayerOptimizeActivate } from "../dataLayer";
import { useState } from "react";
import { UseABtestRes } from "./types";

export const useABtest = (experimentId): UseABtestRes => {
  const [variant, setVariant] = useState("0");
  function gtag() {
    window.dataLayer.push(arguments);
  }
  const getExperimentData = (value, name) => {
    setVariant(value);
  };

  useLoadScript(
    {
      id: "google-optimize-script",
      src: `https://www.googleoptimize.com/optimize.js?id=${config.google_optimize_id}`,
    },
    () => {
      dataLayerOptimizeActivate();
      // @ts-ignore
      gtag("event", "optimize.callback", {
        name: experimentId,
        callback: getExperimentData,
      });
    },
    0
  );
  return { variant };
};

export const useSportVariant = () => {
  let variant = 0;
  /*  if (window.location.host.includes("vipt")) {
    variant = 1;
  }*/
  return variant;
};
