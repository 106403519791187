import React, { lazy } from "react";
import { _t } from "../../../../system/translations/InjectIntlContext";
const QRCode: any = lazy(() => import("qrcode.react"));

type PwaInstallModalProps = {
  qrValue: string;
};

const PwaInstallModal: React.FC<PwaInstallModalProps> = ({ qrValue }) => (
  <div className={"pwa-qr-modal-body"}>
    <QRCode
      className={"pwa-qr-modal-body__qr"}
      value={qrValue}
      size={200}
      renderAs={"svg"}
      includeMargin
    />
    <div className="pwa-qr-modal-body__footer-text">
      {_t("browser_install")}
    </div>
  </div>
);

export default PwaInstallModal;
