import React from "react";
import classNames from "classnames";

type FormattedTextProps = {
  className?: string;
  html: string;
  forwardedRef?: any;
};

const FormattedText = React.forwardRef<HTMLDivElement, FormattedTextProps>(
  ({ className, html, forwardedRef }) => {
    return (
      <div
        ref={forwardedRef}
        className={classNames("formatted-text", className)}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
);

export default FormattedText;
