import { useState, useEffect } from "react";

export const useCheckClickOutside = (element, initialState) => {
  const [active, setActive] = useState(initialState);
  const [overIframe, setOverIframe] = useState(false);

  useEffect(() => {
    const iFrames = document.getElementsByTagName("iframe");
    const iFrameContainers: any[] = Array.prototype.map.call(
      iFrames,
      (iframe: HTMLIFrameElement): HTMLElement => iframe.parentElement
    );
    if (iFrameContainers.length && active) {
      iFrameContainers.map((el: HTMLElement) => {
        el.addEventListener("mouseover", () => setOverIframe(true));
        el.addEventListener("mouseout", () => setOverIframe(false));
      });

      return () => {
        iFrameContainers.map((el: HTMLElement) => {
          el.addEventListener("mouseover", () => setOverIframe(true));
          el.addEventListener("mouseout", () => setOverIframe(false));
        });
      };
    }
  }, [active, overIframe]);

  useEffect(() => {
    const clickEvent = (e) => {
      if (element.current !== null && !element.current.contains(e.target)) {
        setActive(!active);
      }
    };

    if (active) {
      window.addEventListener("click", clickEvent);
      window.addEventListener("blur", () => overIframe && setActive(!active));
    }

    return () => {
      window.removeEventListener("click", clickEvent);
      window.addEventListener("blur", () => overIframe && setActive(!active));
    };
  }, [active, overIframe]);

  return [active, setActive];
};
