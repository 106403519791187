const notify = (type: string, message: string) =>
  console.log("type", type, message);

type requestStatusesType = {
  [key: string]: string;
};

export const requestStatuses = (
  type: string | undefined,
  types: string[] | undefined
) => {
  let statuses: requestStatusesType = {
    start: "",
    success: "",
    error: "",
  };

  Object.keys(statuses).forEach((item, idx) => {
    if (!!types && Array.isArray(types) && types.length) {
      statuses[item] = types[idx];
    } else {
      statuses[item] = `${type}_${item.toUpperCase()}`;
    }
  });

  return statuses;
};

type notifyMessageTypes = {
  [key: string]: string;
};

type HttpMethodTypes =
  | "GET"
  | "DELETE"
  | "HEAD"
  | "OPTIONS"
  | "POST"
  | "PUT"
  | "PATCH"
  | "LINK"
  | "UNLINK";

export type requestConfig = {
  body: any;
  url: string;
  withPrefix: boolean;
  method: HttpMethodTypes;
  notifyMessage: notifyMessageTypes;
};

export const validateRequest = (config: requestConfig) => {
  if (!config.hasOwnProperty("url") || !config.hasOwnProperty("method")) {
    throw new Error("Do not valid request config");
  }
};

export const responseNotify = (
  type: string,
  notifyMessage: notifyMessageTypes
) => {
  if (type && notifyMessage && notifyMessage[type]) {
    notify(type, notifyMessage[type]);
  }
};
