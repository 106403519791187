import React from "react";
import classNames from "classnames";
import Loader from "../../../components/atoms/Loader";
import CustomSlider from "../../../components/molecules/CustomSlider";
import { sliderSettings } from "./constants";
import SportLink from "../../atoms/SportLink";
import { linkClick } from "../../../system/helpers/urlHelper";
import { history } from "../../../system/store";
import { sportLinksPayloadData } from "../../../modules/sportbook/constants";

type SportLinksProps = {
  data: sportLinksPayloadData;
  isLoading: boolean;
  className?: string;
  getSliderRef?: (ref: React.MutableRefObject<any>) => void;
};

const SportLinks: React.FC<SportLinksProps> = ({
  data,
  isLoading,
  className,
  getSliderRef,
}) => {
  const onClickLinkHandle = (e: any, url: string) => {
    linkClick(
      url,
      e,
      history,
      "sportsbook_link",
      url.includes("sportsbook") || url.includes("in-play")
    );
  };
  return (
    <div className={classNames("sport-links", className)}>
      <div className="sport-links__body">
        <CustomSlider getSliderRef={getSliderRef} settings={sliderSettings}>
          {isLoading ? (
            <Loader />
          ) : (
            data?.links.map((item) => (
              <SportLink
                onClick={onClickLinkHandle}
                url={item.url}
                image={item.imageName}
                imageActive={item.imageActiveName}
                name={item.name}
              />
            ))
          )}
        </CustomSlider>
      </div>
    </div>
  );
};

export default SportLinks;
