export const jsonReplacer = (
  key: string,
  value: number | boolean | string
): string =>
  typeof value === "number" || typeof value === "boolean"
    ? String(value)
    : value;

export const transformGamesResult = (
  gamesData,
  games,
  category = "",
  resKeyName = "games"
) => {
  const modifiedGames = (games, isSortedByZing = false) =>
    games.map((item, idx) => ({
      ...item,
      position: idx,
      category: gamesData.category || category,
      isSortedByZing,
    }));
  const sortedGames = (games = []) =>
    games.slice().sort((a, b) => b?.zingScore - a?.zingScore);
  const result = {
    ...gamesData,
    [resKeyName]: modifiedGames(games),
    sortedGames: modifiedGames(sortedGames(games), true),
  };
  return result;
};
