import {
  dataLayerLoginModal,
  dataLayerRegModal,
  dataLayerDepositModal,
} from "../../system/dataLayer";

export const hashHelper = (hash: string) => {
  if (hash === "#registration") {
    dataLayerRegModal("hash");
  } else if (hash === "#login") {
    dataLayerLoginModal("hash");
  } else if (hash === "#cashier") {
    dataLayerDepositModal("hash");
  }
};
