import { gameItem } from "../casino/constants";

export type RouteParams = {
  lang: string;
  id: string;
};

export enum TournamentStatus {
  Active = "ACTIVE",
  Awarded = "AWARDED",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Pending = "PENDING",
}

export enum TournamentType {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export enum AwardType {
  LoyaltyPoint = "LOYALTY POINT",
}

export enum RankType {
  TournamentPoint = "TOURNAMENT POINT",
}

export type TournamentAwardRule = {
  id: number;
  rankStart: number;
  rankEnd: number;
  rankType: RankType;
  tournamentId: number;
  tournamentAwardId: number;
};

export type TournamentAward = {
  id: number;
  noOfFreespin: any;
  type: AwardType;
  loyaltyPoints: number;
  description: string;
  bonusPlanId: any;
  tournamentId: number;
  tournamentAwardRule: TournamentAwardRule[];
};

export type TournamentGame = {
  id: number;
  name: string;
};

export type TournamentUser = {
  id: number;
  code: string;
  accepted: boolean;
  acceptedDate: any;
  createdDate: number;
  lastModifiedDate: null | number;
  tournamentId: number;
  partyid: number;
  invited: boolean;
};

export interface TournamentHubGames extends gameItem {
  gameIdentifierCore: string;
  desktopGameIdentifierCore: string;
}

export type OmegaTournament = {
  tournamentId: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  status: TournamentStatus;
  currency: string;
  code: string;
  type: TournamentType;
  tournamentAwards: TournamentAward[];
  tournamentGames: TournamentGame[];
  tournamentUsers: TournamentUser[];
  tournamentHubGames: TournamentHubGames[];
};

export type OmegaTournamentRanking = {
  id: number;
  tournamentId: number;
  partyid: number;
  points: number;
  ranking: number;
  rankingLast: number;
  rankingDate: string;
  userid: string;
};

export type TournamentItemData = {
  id: number;
  prizePool: string;
  coreTournamentId: number;
  imageDesktop: string;
  imageMobile: string;
  description: string;
  omegaTournament: OmegaTournament;
  omegaTournamentRanking: OmegaTournamentRanking[];
};

export type TournamentsPayloadData = {
  tournaments: TournamentItemData[];
};

export type TournamentInfoPayloadData = {
  tournament: TournamentItemData;
};
