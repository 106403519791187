import React from "react";
import classNames from "classnames";
import TextLink from "../../atoms/TextLink";
import { _t } from "../../../system/translations/InjectIntlContext";
import { useModal } from "../../../modules/modals/hooks";
import { ModalParams } from "../../../modules/modals/types";

type OpenModalLinkProps = {
  label: string;
  modal?: ModalParams;
  position?: string;
  text?: string;
  onClick?: () => void;
  stopPropagation?: boolean;
};

const OpenModalLink: React.FC<OpenModalLinkProps> = ({
  label,
  modal,
  position,
  text,
  onClick,
  stopPropagation,
}) => {
  const { showModal } = useModal();
  const handleClick = () => {
    showModal(modal);
    onClick && onClick();
  };

  return (
    <div
      className={classNames(
        "modal-link",
        position && `modal-link--${position}`
      )}
    >
      {text && <span>{_t(text) + "\u00A0"}</span>}
      <TextLink
        label={_t(label)}
        onClick={handleClick}
        stopPropagation={stopPropagation}
      />
    </div>
  );
};

export default OpenModalLink;
