import { pwaInstallError, regModalWithRedirect } from "../modals";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../system/reducers";
import { isLoggedIn } from "../../system/helpers/userHelper";
import { getPwaLanding } from "./helpers";
import { isIOS } from "react-device-detect";
import { useModal } from "../modals/hooks";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt({
  onPrompt,
  onAccepted,
  onDismissed,
}): [BeforeInstallPromptEvent | null, () => void] {
  const { showModal } = useModal();
  const promptToInstall = () => {
    if (window.installAppPrompt) {
      window.installAppPrompt?.prompt();
      onPrompt && onPrompt();

      window.installAppPrompt?.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          onAccepted && onAccepted();
        } else {
          onDismissed && onDismissed();
        }
      });
    } else {
      showModal(pwaInstallError);
    }
  };

  /*  useEffect(() => {
    const ready = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", ready as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
    };
  }, []);*/

  return [window.installAppPrompt, promptToInstall];
}

export const useGetApp = () => {
  const { pwaDomain } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const { showModal } = useModal();
  const isUser = isLoggedIn();
  const pwaLandingUrl = getPwaLanding(pwaDomain);

  const getApp = () => {
    if (isIOS && !isUser) {
      showModal(
        regModalWithRedirect({
          redirectTo: pwaLandingUrl,
        })
      );
    } else {
      window.location.assign(pwaLandingUrl);
    }
  };
  return { getApp };
};
