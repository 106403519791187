import React, { lazy, ReactElement, useEffect, useRef } from "react";
import { Settings as slickSettings } from "react-slick";
import SliderArrow from "../../atoms/SliderArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as SlickDots } from "../../../images/icons/slider/slick_dots.svg";
import PlaceholderSlide from "./placeholderSlide";
import Loader from "../../atoms/Loader";
const Slider: any = lazy(() => import("react-slick"));

export interface SliderProps {
  children: ReactElement | ReactElement[];
  settings?: slickSettings;
  getSliderRef?: (ref: React.MutableRefObject<any>) => void;
  customPaging?: any;
  nextArrow?: any;
  nexArrow?: string;
  prevArrow?: any;
  isLoading?: boolean;
  loaderMinHeight?: number;
}

const CustomSlider: React.FC<SliderProps> = ({
  settings,
  getSliderRef,
  children,
  customPaging,
  nextArrow,
  prevArrow,
  loaderMinHeight,
  isLoading,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    getSliderRef && getSliderRef(ref);
  });

  if (isLoading) {
    return <Loader center minHeight={loaderMinHeight} />;
  }

  if (Array.isArray(children) && children.length === 0) {
    children = <PlaceholderSlide />;
  }

  return (
    <Slider
      ref={ref}
      nextArrow={nextArrow}
      prevArrow={prevArrow}
      customPaging={customPaging}
      {...settings}
    >
      {children}
    </Slider>
  );
};

CustomSlider.defaultProps = {
  nextArrow: <SliderArrow additionalClass={"custom-next-arrow"} />,
  prevArrow: <SliderArrow additionalClass={"custom-prev-arrow"} />,
  customPaging: function () {
    return (
      <a className="slick-dots" href="#">
        <SlickDots />
      </a>
    );
  },
  loaderMinHeight: 280,
} as Partial<SliderProps>;

export default CustomSlider;
