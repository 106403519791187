import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../reducers";

const isOdd = (num: number) => num % 2 === 1;

export const useIsOddPartyId = (): boolean => {
  const { partyId } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  return isOdd(parseInt(partyId));
};
