import styled, { css } from "styled-components/macro";
import Btn from "../../../../components/v2/Btn";
import LazyImg from "../../../../components/atoms/LazyImg";
import Select from "../../../../components/v2/Select";
import { color, misc, mediaMax } from "../../../../system/styles/constants";
import { rgba } from "../../../../system/styles/helpers";
import { AnswerButtonProps } from "./types";

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
`;

export const QuizImg = styled(LazyImg)`
  width: 115px;
  margin: 0 auto 22px;
`;

export const QuestionImg = styled(LazyImg)`
  width: calc(100% + 10px * 2);
  height: 220px;
  margin: -40px -10px 22px;
  object-fit: cover;
  border-radius: ${misc.borderRadius};

  ${mediaMax.m} {
    height: 180px;
  }
`;

export const Label = styled.div`
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: ${color.secondaryLight};
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${color.white};
`;

export const Desc = styled.div`
  margin-top: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: ${color.secondaryLight};
`;

export const RoundSelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
`;

export const Question = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Answers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
  margin-top: 20px;
`;

export const AnswerButton = styled.button<AnswerButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: ${color.white};
  text-align: left;
  background-color: ${color.dark50};
  box-shadow: 0px 0px 10px rgba(109, 109, 109, 0.1);
  border: 1px solid transparent;
  border-radius: 8px;

  &:not(:disabled) {
    cursor: pointer;
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: ${color.blue};
    `};

  ${(props) =>
    props.success &&
    css`
      background: ${color.greenGradient};
    `};

  ${(props) =>
    props.error &&
    css`
      background-color: ${rgba(color.white, 0.1)};
      border-color: ${color.white};

      &::before {
        border: 2px solid ${color.white};
      }

      &::after {
        background-color: ${color.white};
        opacity: 1;
      }
    `};
`;

export const AnswerButtonText = styled.span`
  display: inline;
`;

export const AnswerLabel = styled.span`
  padding: 2px 6px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  border: 1px solid rgba(255, 255, 255, .3);
  border-radius: 4px;
`;

export const Button = styled(Btn)`
  width: 100%;

  path {
    stroke: ${color.white};
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
`;
