const config = {
  sportbook: {
    url: "https://bettilt-sportsbook-prod.gtdevteam.xyz",
    license: "",
    licenseImage: "",
    platformCode: "BETTECH",
  },
  sportbook_betby: {
    platformCode: "BETTECH",
    iframe_url: "https://bettilt.sptpub.com",
    betslip_script: "https://bettilt.sptpub.com/bt-renderer.min.js",
    brand_id: "1699812984232222720",
    theme_name: "bettilt",
    theme_name_classic: "bettilt-classic",
  },
};

export default config;
