import React, { useState, useMemo, useCallback } from "react";
import Loader from "../../../../components/atoms/Loader";
import { _t } from "../../../../system/translations/InjectIntlContext";
import { useGetImageSrc } from "../../general/hooks";
import { useGetQuizInfo, usePostAnswers } from "../api/apiHooks";
import { Question, Answer } from "../types";
import { QuizModalProps } from "./types";
import {
  ModalBody,
  ModalFooter,
  QuizImg,
  QuestionImg,
  Label,
  Title,
  Desc,
  Answers,
  AnswerButton,
  Button,
} from "./styles";

const QuizModal: React.FC<QuizModalProps> = ({ slug }) => {
  const getImageUrl = useGetImageSrc();
  const { data: quiz, isLoading: isQuizLoading } = useGetQuizInfo(slug);
  const [mutate, { isLoading }] = usePostAnswers(quiz?.id);
  const [started, setStarted] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [data, setData] = useState<{ [id: number]: number }>({});
  const question = useMemo<Question>(() => quiz?.questions?.[step], [
    quiz,
    step,
  ]);
  const isLastStep = useMemo<boolean>(
    () => step === quiz?.questions?.length - 1,
    [quiz, step]
  );

  const sendAnswers = useCallback(() => mutate({ map: data }), [data, mutate]);
  const selectAnswer = useCallback(
    (question: Question, answer: Answer) =>
      setData((prev) => ({ ...prev, [question.id]: answer.id })),
    []
  );

  if (isQuizLoading) {
    return <Loader center />;
  }

  return (
    <ModalBody>
      {!started ? (
        <>
          {quiz?.image && <QuizImg imgSrc={getImageUrl(quiz?.image)} />}
          <Title>{quiz?.title}</Title>
          <Desc
            dangerouslySetInnerHTML={{
              __html: quiz?.description,
            }}
          />
        </>
      ) : (
        <>
          {question?.image && (
            <QuestionImg imgSrc={getImageUrl(question?.image)} />
          )}
          <Label>{question?.title}</Label>
          {question?.description && (
            <Title
              dangerouslySetInnerHTML={{
                __html: question?.description,
              }}
            />
          )}
          <Answers>
            {question?.answers.map((answer) => (
              <AnswerButton
                key={answer?.title}
                selected={data?.[question?.id] === answer?.id}
                onClick={() => selectAnswer(question, answer)}
              >
                {answer?.title}
              </AnswerButton>
            ))}
          </Answers>
        </>
      )}
      <ModalFooter>
        {started ? (
          <>
            {step > 0 && (
              <Button
                size={"lg"}
                variant={"outline"}
                label={_t("quiz_modal_prev")}
                onClick={() => setStep((prev) => prev - 1)}
              />
            )}
            {isLastStep ? (
              <Button
                size={"lg"}
                variant={"outline"}
                label={_t("quiz_modal_finish")}
                disabled={!data?.[question?.id] || isLoading}
                loading={isLoading}
                onClick={sendAnswers}
              />
            ) : (
              <Button
                size={"lg"}
                variant={"outline"}
                label={_t("quiz_modal_next")}
                disabled={!data?.[question?.id]}
                onClick={() => setStep((prev) => prev + 1)}
              />
            )}
          </>
        ) : (
          <Button
            size={"lg"}
            variant={"primary"}
            label={_t("quiz_modal_start")}
            onClick={() => setStarted(true)}
          />
        )}
      </ModalFooter>
    </ModalBody>
  );
};

export default QuizModal;
