import * as Yup from "yup";
import subYears from "date-fns/subYears";
import { transType } from "../translations/InjectIntlContext";

export const noCyrillicChar = (str: string) => !/[\u0400-\u04ff]+/.test(str);
export const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

export const isValidEmail = (_t: transType) =>
  Yup.string()
    .email(_t("invalid_email"))
    .matches(/^[A-z+0-9+\+\-_^.+@+.]+$/, _t("invalid_email"));

export const isValidUsername = (_t: transType) =>
  Yup.string()
    .min(8, _t("login_must_be_longer"))
    .max(30, _t("login_must_be_shorter"))
    .matches(/^[A-z+0-9+]+$/, _t("invalid_username"));

export const isValidLoginUsername = (_t: transType) =>
  Yup.string()
    .min(6, _t("login_must_be_longer"))
    .max(50, _t("login_must_be_shorter"))
    .matches(/^[A-z+0-9+\@._+-]+$/, _t("invalid_username"));

export const isValidFirstLastName = (_t: transType) =>
  Yup.string().matches(
    /^[-a-zA-Z.' \u2E80-\u9FFF\u0900-\u097F\u00E1\u00E9\u00ED\u00F3\u00FA\u00E0\u00E2\u00EA\u00F4\u00E3\u00F5\u00FC\u00E7\u00C1\u00C9\u00CD\u00D3\u00DA\u00C0\u00C2\u00CA\u00D4\u00C3\u00D5\u00DC\u00C7\u011E\u011F\u00D6\u00F6\u015E\u015F\u0131\u0130\u00F1\u00D1\u00E8\u00EC\u00EE\u00F9\u00F2\u00C8\u00CC\u00CE\u00D2\u00D9]+$/,
    _t("wrong_name_format")
  );

export const isValidCity = (_t: transType) =>
  Yup.string().matches(
    /^[-A-Za-z.' \u2E80-\u9FFF\u0900-\u097F\u00E1\u00E9\u00ED\u00F3\u00FA\u00E0\u00E2\u00EA\u00F4\u00E3\u00F5\u00FC\u00E7\u00C1\u00C9\u00CD\u00D3\u00DA\u00C0\u00C2\u00CA\u00D4\u00C3\u00D5\u00DC\u00C7\u011E\u011F\u00D6\u00F6\u015E\u015F\u0131\u0130\u00F1\u00D1\u00E8\u00EC\u00EE\u00F9\u00F2\u00C8\u00CC\u00CE\u00D2\u00D9]+$/,
    _t("wrong_city_format")
  );

export const isValidAddress = (_t: transType) =>
  Yup.string().matches(
    /^[-A-Za-z\d.,/' \u2E80-\u9FFF\u0900-\u097F\u00E1\u00E9\u00ED\u00F3\u00FA\u00E0\u00E2\u00EA\u00F4\u00E3\u00F5\u00FC\u00E7\u00C1\u00C9\u00CD\u00D3\u00DA\u00C0\u00C2\u00CA\u00D4\u00C3\u00D5\u00DC\u00C7\u011E\u011F\u00D6\u00F6\u015E\u015F\u0131\u0130\u00F1\u00D1\u00E8\u00EC\u00EE\u00F9\u00F2\u00C8\u00CC\u00CE\u00D2\u00D9]+$/,
    _t("wrong_address_format")
  );

export const isValidPassword = (_t: transType) =>
  Yup.string()
    .min(8, _t("password_must_be_longer"))
    .max(30, _t("password_must_be_shorter"))
    .matches(
      /^(?=.*?[0-9]).{8,}$/,
      _t("password_must_contain_at_least_one_number")
    )
    .test(
      "no-cyrillic-characters",
      _t("should_not_match_cyrillic_characters"),
      (value) => noCyrillicChar(value)
    );

export const isValidPhone = (_t: transType, numberOfDigits: number = 8) =>
  Yup.string()
    .typeError(_t("must_be_a_number"))
    .transform((value) => value.replace(/[^\d]/g, ""))
    .min(numberOfDigits, _t("mob_phone_must_be_longer"))
    .max(14, _t("mob_phone_must_be_shorter"));

export const isValidRecaptcha = (_t: transType) =>
  Yup.string().nullable().required(_t("required"));

export const isValidBirthDate = (_t: transType) =>
  Yup.date().max(
    subYears(new Date(), 18),
    _t("birth_date_limit", {
      min_years_old: 18,
    })
  );

export const isValidPostalCode = (_t: transType) =>
  Yup.string()
    .required(_t("required"))
    .matches(/^[-A-Za-z\d.]{3,10}$/, _t("wrong_postal_code_format"));
