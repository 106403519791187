import React, { Suspense, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t, makeKey } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
import { useSportVariant } from "../../system/helpers/hooks";
import { getPageTitle } from "../../system/helpers/titleHelper";
import config from "../../system/config";
const SportTemplate = React.lazy(() => import("./SportTemplate"));

const SportPage = () => {
  const matchRoute = useRouteMatch<{ slug: string }>("/:lang/:slug");
  const matchSlug = matchRoute?.params?.slug;
  const slug = matchSlug !== "sportsbook" && matchSlug;
  const variant = useSportVariant();
  const mainTemplateProps = useMemo(() => {
    if (variant === 1) {
      return {
        fixedHeader: false,
      };
    }
    return {
      withoutFooter: true,
      withoutSeoPanel: true,
      fixedHeader: true,
    };
  }, [variant]);

  return (
    <>
      <Helmet>
        <title>
          {_t(
            getPageTitle(makeKey("sport_page_title", slug), config.viptTitleKey)
          )}
        </title>
        <meta
          name="description"
          content={_t(makeKey("sport_page_description", slug))}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--sport"}
        seoPanelProps={{ keySlug: makeKey("sport", slug) }}
        {...mainTemplateProps}
      >
        <Suspense fallback={<Loader fullHeight minHeight={1000} />}>
          <SportTemplate variant={variant} />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default SportPage;
