import subYears from "date-fns/subYears";
import {
  birthDateType,
  formDataType,
  initialValuesType,
} from "./components/forms/constants";
import { onlyDigitsRegExp } from "./constants";
import config from "../../system/config";

export const statusMap = (statusCode: string) => {
  switch (statusCode) {
    case "COMPLETED":
    case "RELEASED":
    case "ACTIVE":
      return "approved";

    case "FAILED":
    case "CANCELLED":
      return "alert";

    case "PENDING":
    case "QUEUED":
      return "pending";

    default:
      return "default";
  }
};

export const transformBirthDate = (date: birthDateType) => {
  return date.year && date.month && date.day
    ? new Date(+date.year, +date.month - 1, +date.day)
    : subYears(new Date(), 19);
};

export const transformData = (data: formDataType): initialValuesType => ({
  ...data,
  birthDate: transformBirthDate(data.birthDate),
});

export const phoneCode = (
  iso2Code: string
): { code: string; mask: string; numberOfDigits: number } => {
  const specs = config.country_to_phone_code[iso2Code];

  if (specs) {
    return {
      code: specs.code,
      mask: specs.mask || config.phone.defaultMask,
      numberOfDigits: specs.numberOfDigits,
    };
  }

  return {
    code: "",
    mask: config.phone.defaultMask,
    numberOfDigits: 8,
  };
};

export const hideString = (stg: string): string =>
  stg
    .split("")
    .map((value, index) => (index > 2 && value !== " " ? "*" : value))
    .join("");

export const isRequiredVerificationField = (countryList, currentCountry) =>
  !countryList.find((item) => item === currentCountry);

export const prepareCode = (code) => code.replace(onlyDigitsRegExp, "");

export const transformDaysToMilliseconds = (amountDays: number): number =>
  amountDays * 24 * 60 * 60 * 1000;

export const calcTimeLeft = (
  period: number,
  dateStartTimestamp: number
): boolean =>
  Date.now() - transformDaysToMilliseconds(period) >= dateStartTimestamp;
