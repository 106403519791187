import React from "react";
import LoginModal from "../../modules/user/components/modals/LoginModal";
import OpenModalLink from "../../components/molecules/OpenModalLink";
import { forgotPasswordModal } from "../../modules/modals";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
import { isPublicDomains } from "../../system/helpers/urlHelper";
import config from "../../system/config";

const SignInPage = () => {
  return (
    <>
      {!isPublicDomains() && (
        <Helmet>
          <title>{_t(config.viptTitleKey)}</title>
        </Helmet>
      )}
      <div className={"sign-in"}>
        <div className={"sign-in__form"}>
          <div className={"sign-in__form__title"}>{_t("sign_in_welcome")}</div>
          <div className={"sign-in__form__description"}>
            {_t("sign_in_description")}
          </div>
          <LoginModal redirectTo={""} />
          <OpenModalLink
            label={"forgot_your_password"}
            modal={forgotPasswordModal}
            position={"left"}
          />
        </div>
      </div>
    </>
  );
};

export default SignInPage;
