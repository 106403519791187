import React from "react";
import { _t } from "../../../system/translations/InjectIntlContext";

const PlaceholderSlide = () => (
  <div className={"placeholder-slide"}>
    <h2 className={"placeholder-slide__title"}>{_t("slides_not_loaded")}</h2>
  </div>
);

export default PlaceholderSlide;
