import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { Helmet } from "react-helmet";
import { _t } from "../../system/translations/InjectIntlContext";
import { getPageTitle } from "../../system/helpers/titleHelper";
import config from "../../system/config";
const StartPageTemplate = React.lazy(() => import("./StartPageTemplate"));

const StartPage = () => {
  const title = _t(getPageTitle("landing_page_title", config.viptTitleKey));
  const description = _t("landing_page_description");
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--landing"}
        seoPanelProps={{ keySlug: "landing" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <StartPageTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default StartPage;
