import { useCallback, useMemo } from "react";
import { useMutation, useQuery, queryCache } from "react-query";
import { useDispatch } from "react-redux";
import { apiErrors } from "../../system/helpers/apiErrorHelper";
import {
  loginAction,
  logOutAction,
  getUserInfoAction,
  kycAction,
  playHistoryAction,
  paymentHistoryAction,
  getBetDetailsAction,
  getKycFormAction,
  getPendingWithdrawalsAction,
  cancelWithdrawalAction,
  regAction,
  changePasswordAction,
  getBonusListAction,
  sendBonusCodeAction,
  sendProfileData,
  sendPhoneVerifyAction,
  sendPhoneVerifyRequestAction,
  resendPhoneVerifyCodeAction,
  forgotPasswordAction,
  forgotPasswordFormAction,
  resetPasswordAction,
  resetPasswordFormAction,
  profileEditAction,
  profileEditFormAction,
  updateBalanceAction,
  getTfaAction,
  setTfaAction,
  tfaProcessAction,
  tfaResendCodeAction,
  getTfaTokenAction,
  getFavoriteGamesAction,
  addFavoriteGameAction,
  removeFavoriteGameAction,
  sendCashInLoyaltyAction,
  getCashbackAmountAction,
  applyCashbackAction,
  getShareUserProfileAction,
  cancelBonusAction,
  getShareUserInfoAction,
} from "./apiActions";
import { updateTfaMethodsAction } from "./actions";
import {
  regRequestKeys,
  loginRequestKeys,
  tfaRequestKeys,
  fillOutProfileKeys,
  PAYMENT_HISTORY_LIST,
} from "./constants";
import { addToast } from "../../system/helpers/toast";
import { removeCookie, setCookie } from "../../system/helpers/cookie";
import { history } from "../../system/store";
import {
  loginModal,
  tfaProcessModal,
  fillOutProfileModalSuccess,
  depositModal,
  forgotPasswordModalSuccess,
  changePasswordModalSuccess,
  afterLoginModal,
  // tfaAfterRegModal,
  applyCashbackSuccessModal,
  resetPasswordSuccessModal,
} from "../modals";
import {
  dataLayerLoginError,
  dataLayerLoginSuccess,
  dataLayerRegSuccess,
} from "../../system/dataLayer";
import { isExternalURL, isStg } from "../../system/helpers/urlHelper";
import { getLanguage } from "../../system/translations/helper";
import { _t } from "../../system/translations/InjectIntlContext";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../system/reducers";
import { useRouteMatch } from "react-router-dom";
import { APP_ROUTES } from "../../system/router/constants";
import { isLoggedIn } from "../../system/helpers/userHelper";
import { useModal } from "../modals/hooks";
import { useDepositViewVersion } from "../cashbox/hooks";

const pendingWithdrawalsQuery = "pending_withdrawals";
const CashbackAmount = "cashback_amount";
const ShareUserProfile = "share_user_profile";
const BonusList = "bonus_list";

export const useOnSuccessLogin = () => {
  const dispatch = useDispatch();
  const { hideModal, showModal } = useModal();
  const matchRoute = useRouteMatch<{ lang: string }>(APP_ROUTES.signIn);
  const lang = getLanguage();

  return useCallback(
    (data: any, redirectTo: string = "", showOnSuccess: boolean = true) => {
      const redirect = redirectTo || history.location.pathname;
      setCookie("isLoggedIn", true);
      getUserInfoAction()(dispatch);
      hideModal();
      if (isExternalURL(redirect)) {
        window.location.assign(redirect);
      } else {
        if (matchRoute) {
          history.push(`/${lang}/`);
        } else {
          history.push(redirect);
        }
      }
      showOnSuccess && showModal(afterLoginModal);
      //  showTfaAfterReg && showModal(tfaAfterRegModal);
      dataLayerLoginSuccess(data.user.username);
      localStorage.removeItem("hasFastDeposit");
    },
    []
  );
};

export const useLogin = (
  redirectTo: string = "",
  showOnSuccess: boolean = false
  // showTfaAfterReg: boolean = false
) => {
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const onSuccessLogin = useOnSuccessLogin();
  return useMutation((data: any) => loginAction(data)(dispatch), {
    onSuccess: (data) => {
      if (!!data.twoFactorAuth) {
        showModal(tfaProcessModal({ redirectTo, showOnSuccess }));
      } else {
        onSuccessLogin(data, redirectTo, showOnSuccess);
      }
    },
    onError: (err: any) => {
      let message = "useLogin error message!";
      try {
        apiErrors(err, "notify");
        const message = err?.error?.data?.systemMessages?.errors;
        message && dataLayerLoginError(message);
      } catch (e) {
        addToast(message, {
          type: "error",
        });
      }
    },
  });
};

export const useReg = (
  redirectTo: string = "",
  showOnSuccess: boolean = false
) => {
  const dispatch = useDispatch();
  const [loginHandler] = useLogin(redirectTo, showOnSuccess);
  const { blackbox } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );

  return useMutation((data: any) => regAction(data)(dispatch), {
    onSuccess: (data, requestData) => {
      window.parent && window.parent.postMessage("userRegistered", "*");
      const formData = new FormData();
      formData.append(
        loginRequestKeys.username,
        requestData.get(regRequestKeys.username)
      );
      formData.append(
        loginRequestKeys.password,
        requestData.get(regRequestKeys.password)
      );
      const notStg = !isStg();
      if (notStg) {
        formData.append(loginRequestKeys.blackbox, blackbox);
      }
      removeCookie("btag", {
        path: "/",
        domain: encodeURIComponent(window.location.host),
      });
      dataLayerRegSuccess();
      loginHandler(formData);
    },
    onError: (err: any) => {
      let message = "Registration undefined error";
      try {
        if (err?.error.data.systemMessages.hasOwnProperty("errors")) {
          message = err?.error.data.systemMessages?.errors[0].message;
        } else if (
          err?.error.data.systemMessages.hasOwnProperty("registration")
        ) {
          message = err?.error.data.systemMessages?.registration[0].message;
        } else {
          throw "reg error";
        }
        addToast(message, {
          type: "error",
        });
      } catch (e) {
        addToast(message, {
          type: "error",
        });
      }
    },
  });
};

export const useLogOut = () => {
  const dispatch = useDispatch();
  const { hideModal } = useModal();
  const lang = getLanguage();

  return useMutation(
    (redirectTo: string = "/#login") =>
      logOutAction(redirectTo, hideModal)(dispatch),
    {
      onSuccess: () => {
        queryCache.removeQueries(["cashbox_methods_deposit", lang]);
        queryCache.removeQueries(["cashbox_methods_withdrawal", lang]);
      },
    }
  );
};

export const useChangePassword = () => {
  const dispatch = useDispatch();
  const { hideModal, showModal } = useModal();

  return useMutation((data: any) => changePasswordAction(data)(dispatch), {
    onSuccess: () => {
      showModal(
        changePasswordModalSuccess({
          handleClick: hideModal,
        })
      );
    },
    onError: (err: any) => {
      apiErrors(err, "notify");
      /*      err?.error.data.systemMessages.errors.forEach((error) =>
        addToast(error.message, {
          type: "error",
        })
      );*/
    },
  });
};

export const useGetUserInfo = () => {
  const dispatch = useDispatch();
  return useMutation(() => getUserInfoAction()(dispatch));
};

export const useKyc = () => {
  const dispatch = useDispatch();
  return useMutation((data: any) => kycAction(data)(dispatch), {
    onSuccess: (data, requestData) => {
      try {
        const message = data?.systemMessages.accountVerification[0].message;
        addToast(message, {
          type: "success",
        });
      } catch (e) {}
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("kyc some error", {
          type: "error",
        });
      }
    },
  });
};

export const usePlayHistory = () => {
  const dispatch = useDispatch();
  return useMutation((data: any) => playHistoryAction(data)(dispatch), {
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const usePaymentHistory = () => {
  const dispatch = useDispatch();
  return useMutation((data: any) => paymentHistoryAction(data)(dispatch), {
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const usePaymentHistoryQuery = (data, enabled = true) => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(
    [PAYMENT_HISTORY_LIST, lang],
    () => paymentHistoryAction(data)(dispatch),
    {
      enabled,
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetBetDetails = () => {
  const dispatch = useDispatch();
  return useMutation((data: any) => getBetDetailsAction(data)(dispatch), {
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const usePendingWithdrawals = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(
    [pendingWithdrawalsQuery, lang],
    () => getPendingWithdrawalsAction()(dispatch),
    {
      staleTime: 0,
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useCancelWithdrawal = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  const message = _t("withdrawal_successfully_canceled");
  return useMutation((data: any) => cancelWithdrawalAction(data)(dispatch), {
    onSuccess: () => {
      addToast(message, {
        type: "success",
      });
      queryCache.invalidateQueries([pendingWithdrawalsQuery, lang]);
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useGetKycForm = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(["kyc_form", lang], () => getKycFormAction()(dispatch), {
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useBonusList = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([BonusList, lang], () => getBonusListAction()(dispatch), {
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useBonusCode = () => {
  const dispatch = useDispatch();
  return useMutation((data: any) => sendBonusCodeAction(data)(dispatch), {
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useCashInLoyaltyPoints = () => {
  const { hideModal } = useModal();
  const [updateUserBalance] = useUpdateBalance();
  const dispatch = useDispatch();
  const message = _t("loyalty_point_cash_in_successful");
  return useMutation((data: any) => sendCashInLoyaltyAction(data)(dispatch), {
    onSuccess: () => {
      updateUserBalance();
      addToast(message, {
        type: "success",
      });
      hideModal();
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useFillOutProfile = (showOnSuccessModal = true) => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const [getUserInfo] = useGetUserInfo();
  let { refetch } = useGetProfileEditForm();
  const { version } = useDepositViewVersion();

  const profileCompletedMessage = _t("profile_successfully_completed");

  return useMutation((data: any) => sendProfileData(data)(dispatch), {
    onSuccess: () => {
      getUserInfo();
      if (showOnSuccessModal) {
        showModal(
          fillOutProfileModalSuccess({
            handleClick: showModal(depositModal(version)),
          })
        );
      } else {
        addToast(profileCompletedMessage, {
          type: "success",
        });
      }
      refetch();
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useChangeTfaData = (afterReg: boolean = false) => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const [getUserInfo] = useGetUserInfo();
  const { refetch } = useGetProfileEditForm();

  return useMutation((data: any) => sendProfileData(data)(dispatch), {
    onSuccess: () => {
      if (afterReg) {
        showModal(afterLoginModal);
      }

      getUserInfo();
      refetch();
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const usePhoneVerify = () => {
  const dispatch = useDispatch();
  const { hideModal } = useModal();
  return useMutation((data: any) => sendPhoneVerifyAction(data)(dispatch), {
    onSuccess: (_, variables) => {
      const formData = new FormData();
      const birthDateDay = new Date(variables.birthDate).getDate().toString();
      const birthDateMonth = (
        new Date(variables.birthDate).getMonth() + 1
      ).toString();
      const birthDateYear = new Date(variables.birthDate)
        .getFullYear()
        .toString();
      formData.append(fillOutProfileKeys.mobilePhone, variables.phone);
      formData.append(fillOutProfileKeys.birthDateDay, birthDateDay);
      formData.append(fillOutProfileKeys.birthDateMonth, birthDateMonth);
      formData.append(fillOutProfileKeys.birthDateYear, birthDateYear);
      formData.append("_method", "PATCH");

      sendProfileData(formData)(dispatch);
      queryCache.invalidateQueries(ShareUserProfile);
      hideModal();
    },
    onError: (err: any) => {
      try {
        addToast(err?.error?.data?.message, {
          type: "error",
        });
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const usePhoneVerifyRequest = () => {
  const dispatch = useDispatch();
  return useMutation(
    (data: any) => sendPhoneVerifyRequestAction(data)(dispatch),
    {
      onError: (err: any) => {
        try {
          addToast(err?.error?.data?.message, {
            type: "error",
          });
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useResendCodePhoneVerify = () => {
  const dispatch = useDispatch();
  return useMutation((data: any) =>
    resendPhoneVerifyCodeAction(data)(dispatch)
  );
};

export const useForgotPassword = () => {
  const dispatch = useDispatch();
  const { hideModal, showModal } = useModal();
  const handleClick = () => {
    history.push("/");
    hideModal();
  };

  return useMutation((data: any) => forgotPasswordAction(data)(dispatch), {
    onSuccess: () => {
      showModal(
        forgotPasswordModalSuccess({
          handleClick,
        })
      );
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useGetForgotPasswordForm = () => {
  const dispatch = useDispatch();
  return useQuery("forgot_password_form", () =>
    forgotPasswordFormAction()(dispatch)
  );
};

export const useResetPassword = () => {
  const dispatch = useDispatch();
  const { hideModal, showModal } = useModal();
  const handleClick = () => {
    history.push("/");
    hideModal();
  };

  return useMutation((data: any) => resetPasswordAction(data)(dispatch), {
    onSuccess: () =>
      showModal(
        resetPasswordSuccessModal({
          handleClick,
        })
      ),
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useGetResetPasswordForm = (email: string, resetCode: string) => {
  const dispatch = useDispatch();
  const { hideModal } = useModal();
  return useQuery(
    "reset_password_form",
    () => resetPasswordFormAction(email, resetCode)(dispatch),
    {
      onSuccess: (data: any) => {
        if (data?.systemMessages?.errors?.length) {
          history.push("/");
          apiErrors({ error: { data } }, "notify");
          hideModal();
        }
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useProfileEdit = (successMsg: string) => {
  const dispatch = useDispatch();
  const [getUserInfo] = useGetUserInfo();
  return useMutation((data: any) => profileEditAction(data)(dispatch), {
    onSuccess: (data, requestData) => {
      getUserInfo();
      addToast(successMsg, {
        type: "success",
      });
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("editing some error", {
          type: "error",
        });
      }
    },
  });
};

export const useGetProfileEditForm = () => {
  const dispatch = useDispatch();
  return useQuery(
    ["profile_edit_form"],
    () => profileEditFormAction()(dispatch),
    { enabled: false }
  );
};

export const useUpdateBalance = () => {
  const dispatch = useDispatch();
  return useMutation(() => updateBalanceAction()(dispatch));
};

export const useGetTfa = () => {
  const dispatch = useDispatch();
  return useMutation(() => getTfaAction()(dispatch), {
    onSuccess: (data) => {
      const methods = data?._form?.methods;
      updateTfaMethodsAction({
        none: methods[0],
        sms: methods[1],
        email: methods[2],
      })(dispatch);
    },
  });
};

export const useSetTfa = () => {
  const dispatch = useDispatch();
  return useMutation((data: any) => setTfaAction(data)(dispatch), {
    onSuccess: (data, requestData) => {
      const methods = requestData.getAll(tfaRequestKeys.methods);
      updateTfaMethodsAction({
        none: methods.includes("none"),
        sms: methods.includes("sms"),
        email: methods.includes("email"),
      })(dispatch);
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useTfaProcess = ({ redirectTo = "", showOnSuccess }) => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const onSuccessLogin = useOnSuccessLogin();
  return useMutation((data: any) => tfaProcessAction(data)(dispatch), {
    onSuccess: (data) => {
      if (data.user) {
        onSuccessLogin(data, redirectTo, showOnSuccess);
      }
      if (data.twoFactorAuthFail) {
        showModal(loginModal);
      }
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useTfaResendCode = () => {
  const dispatch = useDispatch();
  return useMutation(() => getTfaTokenAction()(dispatch), {
    onSuccess: (data) => {
      tfaResendCodeAction(data?._token)(dispatch);
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useGetFavoriteGames = () => {
  const dispatch = useDispatch();
  const isUser = isLoggedIn();
  const data = useQuery(
    ["get_favoriteGames"],
    () => getFavoriteGamesAction()(dispatch),
    {
      enabled: isUser,
    }
  );
  return {
    ...data,
    dataModified: useMemo(() => {
      const dataModified = {};
      data?.data?.favoriteGames.forEach((item) => {
        item.isFavorite = true;
        dataModified[item.v3.game.slug] = item;
      });
      return dataModified;
    }, [data?.data?.favoriteGames]),
  };
};

export const useMatchingGames = (games, favoriteGames) => {
  return useMemo(() => {
    if (games && !Array.isArray(games)) {
      if (favoriteGames[games?.v3.game.slug]) {
        games.isFavorite = true;
        return { ...games };
      }
    } else if (games) {
      games?.map((item) => {
        item.isFavorite = !!favoriteGames[item.v3.game.slug];
        return item;
      });
      return games;
    }
  }, [games, favoriteGames]);
};

export const useAddFavoriteGame = () => {
  const dispatch = useDispatch();

  return useMutation(
    (data: any) => {
      const favoriteGames = queryCache.getQueryData("get_favoriteGames");
      queryCache.setQueryData("get_favoriteGames", {
        favoriteGames: [data?.gameData, ...favoriteGames["favoriteGames"]],
      });
      return addFavoriteGameAction(data?.data)(dispatch);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["get_favoriteGames"]);
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useRemoveFavoriteGame = () => {
  const dispatch = useDispatch();

  return useMutation(
    (data: any) => {
      const favoriteGames = queryCache.getQueryData("get_favoriteGames");
      const favoriteGamesModified = favoriteGames["favoriteGames"].filter(
        (item) =>
          item.v3.game.slug !== data?.gameData.v3.game.slug ||
          item.v3.game.platform.gameLaunchPlatformCode !==
            data?.gameData.v3.game.platform.gameLaunchPlatformCode
      );
      queryCache.setQueryData("get_favoriteGames", {
        favoriteGames: favoriteGamesModified,
      });
      return removeFavoriteGameAction(data?.data)(dispatch);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(["get_favoriteGames"]);
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetCashbackAmount = (data) => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery(
    [CashbackAmount, lang],
    () => getCashbackAmountAction(data)(dispatch),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetShareUserProfile = (data) => {
  const dispatch = useDispatch();
  return useQuery(
    [ShareUserProfile, data.sessionKey],
    () => getShareUserProfileAction(data)(dispatch),
    {
      enabled: !!data.sessionKey,
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useCancelBonus = () => {
  const dispatch = useDispatch();

  return useMutation((data: any) => cancelBonusAction(data)(dispatch), {
    onSuccess: () => {
      queryCache.invalidateQueries(BonusList);
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useGetShareUserInfo = () => {
  const dispatch = useDispatch();
  return useMutation((data) => getShareUserInfoAction(data)(dispatch), {
    onSuccess: (data: any, variables: any) => {
      localStorage.removeItem("agree_with_policy_date");
      queryCache.invalidateQueries([ShareUserProfile, variables?.sessionKey]);
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
  });
};

export const useApplyCashback = () => {
  const dispatch = useDispatch();
  const { hideModal, showModal } = useModal();
  const lang = getLanguage();
  const [updateUserBalance] = useUpdateBalance();
  const handleClick = () => {
    hideModal();
  };

  return useMutation((data: any) => applyCashbackAction(data)(dispatch), {
    onSuccess: (dataSuccess, variables) => {
      updateUserBalance();
      queryCache.invalidateQueries([CashbackAmount, lang]);
      showModal(
        applyCashbackSuccessModal({
          handleClick,
          data: variables?.cashbackAmount,
        })
      );
    },
    onError: (err: any) => {
      try {
        apiErrors(err, "notify");
      } catch (e) {
        addToast("Some error occurred", {
          type: "error",
        });
      }
    },
    onSettled: (data: any, error: any, variables: any) => {
      variables.setPendingClaimButton(false);
    },
  });
};
