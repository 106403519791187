export const GAMES_FEEDBACK = "GAMES_FEEDBACK";
export const GAMES_FEEDBACK_URL = "/:lang/games/feedback";

export enum FeedbackAction {
  GameOpen = "casino-game-open",
}

export type BlockShownParams = {
  blockName: string;
  uid: string;
  sessionKey: string;
  playerId: string;
  timestamp: number;
};

export type CardShownParams = {
  gameId: string;
  gameProviderId: string;
  sessionKey: string;
  playerId: string;
  isRecommended: boolean;
  category: string;
  position: number;
  timestamp: number;
  uid: string;
  prevUid: string;
  filters: string;
};

export type GameOpenParams = {
  gameId: string;
  gameProviderId: string;
  sessionKey: string;
  playerId: string;
  category: string;
  timestamp: number;
  position: number | string;
};

export type GamesFeedbackUri = {
  recommendationId: string | null;
};

export type GamesFeedbackParams =
  | BlockShownParams
  | CardShownParams
  | GameOpenParams;

export type GamesFeedbackAction = {
  action: FeedbackAction;
  uri: GamesFeedbackUri;
  parameters: GamesFeedbackParams;
};

export const RecommendedGames = "recommended_games";
export const RECOMMENDED_GAMES = "RECOMMENDED_GAMES";
export const GET_RECOMMENDED_GAMES = "/:lang/games/recommended.json";
