import { useMemo, useEffect, useState, RefObject } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "../../../system/helpers/toast";
import { useGetBootstrap } from "../../general/apiHooks";
import { isExternalURL } from "../../../system/helpers/urlHelper";
import { _t } from "../../../system/translations/InjectIntlContext";
import { useLocation, useParams } from "react-router-dom";
import { useGetInfo } from "../info/api/apiHooks";

export const useApiRequest = () => {
  const dispatch: any = useDispatch();
  const apiRequest = (params) =>
    dispatch(params).then((data: any) => data.payload);

  return [apiRequest];
};

export type ErrorType = {
  error: {
    config: {
      url: string;
      method: string;
      headers: {};
      baseURL: string;
      transformRequest: [];
    };
    data: {
      errors: [];
      code: number;
      message: string;
    };

    message: string;
    status: number | string;
    statusText: string;
    meta: any;
    type: string;
  };
};

export const useApiErrors = (error?: any) => {
  const apiErrors = (err, notify = true) => {
    const errors = err?.error?.data?.errors;
    const errMessage = err?.error?.data?.message;

    const toastMessage = (msg) =>
      addToast(msg, {
        type: "error",
      });

    if (notify) {
      if (!!errors) {
        Object.keys(errors).forEach((error) => {
          errors[error][0] = _t(errors[error][0]);
          toastMessage(errors[error][0]);
        });
      } else if (!!errMessage) {
        toastMessage(_t(errMessage));
      } else if (!!err) {
        toastMessage("Some error occurred");
      }
    }
    return errors;
  };
  const responseError = useMemo(() => {
    if (error) {
      return apiErrors(error, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return { apiErrors, responseError };
};

export const useGetImageSrc = () => {
  const { data } = useGetBootstrap();

  return (url) => {
    let imageSrc = null;

    url = typeof url === "string" ? url : url?.normal;

    if (url) {
      if (url?.includes("/static/media")) {
        imageSrc = url;
      } else {
        imageSrc = !!isExternalURL(url) ? url : data?.cdn + url;
      }
    }

    return imageSrc;
  };
};

type Handler = (event: MouseEvent | FocusEvent) => void;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" = "mousedown"
): void => {
  useEffect(() => {
    const mouseHandler = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    const blurHandler = (event: FocusEvent) => {
      if (
        document.activeElement.tagName === "IFRAME" ||
        document.activeElement === document.body
      ) {
        handler(event);
      }
    };

    document.addEventListener(mouseEvent, mouseHandler);
    window.addEventListener("blur", blurHandler);

    return () => {
      document.removeEventListener(mouseEvent, mouseHandler);
      window.removeEventListener("blur", blurHandler);
    };
  }, [ref, handler, mouseEvent]);
};

export const useToggle = (
  ref: RefObject<HTMLElement>
): [boolean, (value: boolean | ((prev: boolean) => boolean)) => void] => {
  const [active, setActive] = useState<boolean>(false);

  useOnClickOutside(ref, () => {
    setActive(false);
  });

  return [active, setActive];
};

export const useGetPageByRoute = () => {
  const { lang } = useParams<{ lang: string }>();
  const { pathname, search } = useLocation();

  const data = useGetInfo();
  const originData = data?.originData;

  const matchPath = pathname.concat(search).replace(`/${lang}`, "");
  const pageByRoute = originData?.filter((item) => item.url === matchPath)[0];

  return { pageByRoute };
};
