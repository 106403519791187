import React, { useMemo } from "react";
import FinishedTournamentItem, {
  PlaceType,
} from "../../../components/atoms/TournamentPrizes";
import { TournamentAward } from "../constants";
import { useGetPrizePool } from "../hooks";

type TournamentPrizesContainerProps = {
  awards: TournamentAward[];
};

const TournamentPrizesContainer: React.FC<TournamentPrizesContainerProps> = ({
  awards,
}) => {
  const prizePool = useGetPrizePool(awards);
  const prizes = useMemo<PlaceType[]>(
    () =>
      prizePool?.splice(0, 3)?.map((item) => ({
        value: item?.value,
        text: item?.name,
      })),
    [prizePool]
  );

  return <FinishedTournamentItem prizes={prizes} />;
};

export default TournamentPrizesContainer;
