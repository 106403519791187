import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { isExternalURL } from "../../../system/helpers/urlHelper";
import { BtnProps } from "./types";
import { Button, Label, Icon, Loader } from "./styles";

const Btn: React.FC<BtnProps> = ({
  className,
  icon,
  label,
  size,
  type,
  variant,
  loading,
  disabled,
  fullWidth,
  linkTo,
  onClick,
}) => {
  const history = useHistory();
  const handleClick = useCallback(() => {
    if (linkTo) {
      if (isExternalURL(linkTo)) {
        window.location.assign(linkTo);
      } else {
        history.push(linkTo);
      }
    }
    onClick();
  }, [history, linkTo, onClick]);

  return (
    <Button
      className={className}
      size={size}
      type={type}
      variant={variant}
      loading={loading}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={handleClick}
    >
      {icon && <Icon>{icon}</Icon>}
      {label && <Label>{label}</Label>}
      {loading && <Loader />}
    </Button>
  );
};

Btn.defaultProps = {
  size: "md",
  type: "button",
  variant: "default",
  onClick: () => true,
};

export default Btn;
