import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { isExternalURL } from "../../../system/helpers/urlHelper";

type TextLinkProps = {
  label: string;
  prefixClass?: string;
  stopPropagation?: boolean;
  linkTo?: string;
  beforeText?: any;
  onClick?: () => any;
};

const TextLink: React.FC<TextLinkProps> = ({
  linkTo,
  stopPropagation,
  label,
  prefixClass,
  onClick,
  beforeText,
}) => {
  const history = useHistory();
  const clickHandler = (e) => {
    stopPropagation && e.stopPropagation();
    linkTo && isExternalURL(linkTo)
      ? window.location.assign(linkTo)
      : history.push(linkTo);

    onClick && onClick();
  };

  return (
    <div
      onClick={clickHandler}
      className={classNames("text-link", prefixClass)}
    >
      {beforeText && (
        <span className={"text-link__before-label"}>{beforeText}</span>
      )}
      <span className={"text-link__label"}>{label}</span>
    </div>
  );
};

TextLink.defaultProps = {
  prefixClass: "",
};

export default TextLink;
