import React, { useState } from "react";
import { providerItem } from "../constants";

const Context = React.createContext(null);

const CasinoContextProvider = ({ children }) => {
  const [providers, setProviders] = useState<providerItem[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

  return (
    <Context.Provider
      value={{
        providers,
        setProviders,
        selectedProviders,
        setSelectedProviders,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
export { CasinoContextProvider };
