import React from "react";
import classNames from "classnames";
import Btn from "../../atoms/Btn";
import LazyImg from "../../atoms/LazyImg";
import Loader from "../../atoms/Loader";
import { ReactComponent as ArrowIcon } from "../../../images/icons/arrow-right.svg";

type ModalBannerProps = {
  className?: string;
  img?: string;
  title?: string;
  desc?: string;
  linkTo?: string;
  btnText?: string;
  isLoading?: boolean;
  size?: string;
};

const ModalBanner: React.FC<ModalBannerProps> = ({
  className,
  img,
  title,
  desc,
  linkTo,
  btnText,
  isLoading,
  size,
}) => {
  return (
    <div
      className={classNames(
        "modal-banner",
        !img && `modal-banner-background-${size}`,
        `modal-banner-${size}`,
        className
      )}
    >
      {img && <LazyImg imgSrc={img} className={"modal-banner__img"} alt={""} />}
      <div className={"modal-banner__body"}>
        {title && (
          <h3
            className={`modal-banner__title ${`modal-banner__title-${size}`}`}
          >
            {title}
          </h3>
        )}
        {desc && (
          <p className={`modal-banner__desc ${`modal-banner__desc-${size}`}`}>
            {desc}
          </p>
        )}
        {linkTo && btnText && (
          <Btn
            className={`modal-banner__btn ${`modal-banner__btn-${size}`}`}
            icon={<ArrowIcon />}
            label={btnText}
            linkTo={linkTo}
            type={"transparent"}
          />
        )}
      </div>
      {isLoading && <Loader center />}
    </div>
  );
};

export default ModalBanner;
