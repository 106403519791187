import { path } from "./pathHelper";

export const isExternalURL = (url: string) => url.match(/http[s]?\:\/\//i);
export const getQueryParams = (query: string) =>
  !!window.URLSearchParams
    ? new URLSearchParams(query)
    : {
        get: (par) => null,
        set: (par, par1) => null,
      };

export const isDev = () => {
  const host = window.location.hostname;
  return host.includes("localhost") || host.includes("dev.");
};

export const isStg = () => {
  return window.location.hostname.includes("stg3.");
};

export const isWebCache = () => {
  const host = window.location.hostname;
  return host.includes("webcache");
};

/*
  Function `linkClick` serves to redirect the user
  to the specified `url` with the transfer of
  a certain `state` for further manipulations
  on the updated route.
*/

export const linkClick = (url, event, history, state, targetRouteCondition) => {
  if (targetRouteCondition) {
    event.preventDefault();
    history.push(path(url), state);
  }
};

export const redirectTo = (link, history) => {
  if (!link) return;
  isExternalURL(link) ? window.location.assign(link) : history.push(path(link));
};

export const isPublicDomains = () => {
  const host = window.location.hostname;
  return (
    host.includes("bettilt") ||
    host.includes("pwa") ||
    host.includes("webcache") ||
    host.includes("localhost")
  );
};
