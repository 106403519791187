import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";

const TournamentsTemplate = React.lazy(() => import("./TournamentsTemplate"));

const TournamentsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{_t("tournaments_page_title")}</title>
        <meta name="description" content={_t("tournaments_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--tournaments"}
        seoPanelProps={{ keySlug: "tournaments" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <TournamentsTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default TournamentsPage;
