import { Link } from "react-router-dom";
import LazyImg from "../LazyImg";
import React from "react";
import classNames from "classnames";
import { useModal } from "../../../modules/modals/hooks";

type AfterLoginPromoItemProps = {
  className: string;
  link: string;
  image: string;
  title?: string;
  teaser?: string;
  isAdditionalClassName?: boolean;
};

const AfterLoginPromoItem: React.FC<AfterLoginPromoItemProps> = ({
  className,
  link,
  image,
  title,
  teaser,
  isAdditionalClassName,
}) => {
  const { hideModal } = useModal();
  const handleClick = () => hideModal();

  return (
    <div className={`${className}__block-container`}>
      <Link
        className={classNames(isAdditionalClassName && `${className}__wrap`)}
        onClick={handleClick}
        to={link}
      >
        <LazyImg className={`${className}__img`} imgSrc={image} bg />
        {title && teaser && (
          <div className={"promotions__desc"}>
            {title && <span className={"promotions__desc-title"}>{title}</span>}
            {teaser && (
              <span className={"promotions__desc-teaser"}>{teaser}</span>
            )}
          </div>
        )}
      </Link>
    </div>
  );
};

export default AfterLoginPromoItem;

