import React, { useRef } from "react";
import ModalContainer from "./components/ModalContainer";

const Context: any = React.createContext(null);

const ModalContextProvider = ({ children }: any) => {
  const childRef: any = useRef(null);
  const showModal = (params = {}, name: string = "default") => {
    childRef.current.modalOpen(name, params);
  };
  const updateModal = (params = {}, name: string) => {
    childRef.current.modalUpdate(name, params);
  };
  const hideModal = (name: string = "default") =>
    childRef.current.modalClose(name);

  return (
    <Context.Provider value={{ showModal, hideModal, updateModal }}>
      {children}
      <ModalContainer ref={childRef} hideModal={hideModal} />
    </Context.Provider>
  );
};

export default Context;
export { ModalContextProvider };
