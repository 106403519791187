import React from "react";
import Btn from "../../../../components/atoms/Btn";
import { _t } from "../../../../system/translations/InjectIntlContext";
import { ReactComponent as IconApproved } from "../../../../images/elements/approved-check.svg";

interface SuccessModalProps {
  modalClassName: string;
  title: string;
  content: string | { key: string; value: any };
  btnType: string;
  btnLabel: string;
  handleClick: any;
  additionalContent?: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  modalClassName,
  title,
  content,
  additionalContent,
  btnType,
  btnLabel,
  handleClick,
}) => {
  return (
    <div className={`${modalClassName}__successful`}>
      <IconApproved />
      <span className={`${modalClassName}__successful-title`}>{_t(title)}</span>
      <div className={`${modalClassName}__successful-content`}>
        {typeof content === "string"
          ? _t(content)
          : _t(content.key, content.value)}
      </div>
      {additionalContent ? (
        <div className={`${modalClassName}__successful-subcontent`}>
          {_t(additionalContent)}
        </div>
      ) : null}
      <Btn onClick={handleClick} label={_t(btnLabel)} type={btnType} />
    </div>
  );
};

export default SuccessModal;
