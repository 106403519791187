import React, { Suspense } from "react";
import Loader from "../../../components/atoms/Loader";
import { changeTfaDataKeys } from "../constants";
import { useChangeTfaData } from "../apiHooks";
import { initialValuesType } from "./forms/ChangeTfaDataForm";
import { apiErrors } from "../../../system/helpers/apiErrorHelper";

const ChangeTfaDataForm = React.lazy(() => import("./forms/ChangeTfaDataForm"));

type TfaWithChangeDataProps = {
  afterReg?: boolean;
};

const TfaWithChangeData: React.FC<TfaWithChangeDataProps> = ({ afterReg }) => {
  const [chanfeTfaDataHandler, { isLoading, error }] = useChangeTfaData(
    afterReg
  );
  const responseError = apiErrors(error);

  const onSubmit = (values: initialValuesType) => {
    const formData = new FormData();

    formData.append(changeTfaDataKeys.email, values.email);
    formData.append(changeTfaDataKeys.mobilePhone, values.mobilePhone);
    formData.append("_method", "PATCH");

    chanfeTfaDataHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <ChangeTfaDataForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        responseError={responseError}
        afterReg={afterReg}
      />
    </Suspense>
  );
};

export default TfaWithChangeData;
