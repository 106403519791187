import React, { Suspense } from "react";
import { useRouteMatch } from "react-router-dom";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
import { APP_ROUTES } from "../../system/router/constants";
import { RouteParams } from "../../modules/tournaments/constants";
import { getPageTitle } from "../../system/helpers/titleHelper";
import config from "../../system/config";

const TournamentInfoTemplate = React.lazy(
  () => import("./TournamentInfoTemplate")
);

const TournamentInfoPage: React.FC = () => {
  const match = useRouteMatch<RouteParams>(APP_ROUTES.tournamentInfo);
  const tournamentId = match?.params?.id;

  return (
    <>
      <Helmet>
        <title>
          {_t(getPageTitle("tournament_info_page_title", config.viptTitleKey))}
        </title>
        <meta
          name="description"
          content={_t("tournament_info_page_description")}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--tournament-info"}
        seoPanelProps={{ keySlug: "tournament_info" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <TournamentInfoTemplate id={tournamentId} />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default TournamentInfoPage;
