import { useQuery } from "react-query";
import { generatePath } from "react-router";
import { getLanguage } from "../../../../system/translations/helper";
import { useApiRequest } from "../../general/hooks";
import { apiDataWithSlug } from "../../general/helpers";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../system/reducers";
import {
  INFO_LIST,
  INFO_URL,
  infoItemData,
  infoTypesArr,
  INFO_BY_SLUG,
  INFO_BY_SLUG_URL,
  infoPageBySlugPayloadData,
  infoPagesPayloadData,
} from "../constants";

export const useGetInfo = (enabled = true) => {
  const lang = getLanguage();
  const [apiRequest] = useApiRequest();
  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  const { data } = useQuery<infoItemData[]>(
    [INFO_LIST, lang],
    () =>
      apiRequest({
        type: "GET_INFO",
        request: {
          url: INFO_URL,
          method: "GET",
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
          },
          withPrefix: false,
        },
      }),
    { enabled: enabled }
  );

  const infoBySlug = apiDataWithSlug(infoTypesArr, data);

  return {
    originData: data,
    infoBySlug,
  } as infoPagesPayloadData;
};

export const useGetInfoBySlug = (slug: string) => {
  const lang = getLanguage();
  const [apiRequest] = useApiRequest();
  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  const { data } = useQuery<infoItemData>(
    [INFO_BY_SLUG, slug, lang],
    () =>
      apiRequest({
        type: "GET_INFO_BY_SLUG",
        request: {
          url: generatePath(INFO_BY_SLUG_URL, { slug }),
          method: "GET",
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
          },
          withPrefix: false,
        },
      }),
    { enabled: !!slug }
  );

  return {
    originData: data,
  } as infoPageBySlugPayloadData;
};
