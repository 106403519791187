import { gameItem } from "../casino/constants";
import { gameLink } from "../casino/helpers";
import { PROVIDERS_REAL_ONLY } from "./constants";

export const hasFun = (game: gameItem) => {
  return (
    game &&
    !!gameLink(game).fun &&
    !PROVIDERS_REAL_ONLY.includes(game?.v3?.game?.platform?.code)
  );
};

type gameResizerType = {
  width: number;
  height: number;
};

export const gameResizer = (
  width: number,
  height: number,
  ratio: number
): gameResizerType => {
  if (width / height >= ratio) {
    return { width: height * ratio, height };
  } else {
    return { height: width / ratio, width };
  }
};
