import { linkItem } from "../header/constants";

export const CHAT_CLASS_LINK = "support";
export const CHAT_LOAD_TIMEOUT = 6000;
export const SHOW_CHAT_BTN_TIMEOUT = 10000;

export type UseChatResult = {
  links: linkItem[];
  multiple: boolean;
  visible: boolean;
  loaded: boolean;
  openChat: () => void;
};
