import React, { useEffect } from "react";
import classNames from "classnames";
import { _t } from "../../../system/translations/InjectIntlContext";
import { ModalDataProps } from "../../../modules/modals/types";

interface ModalProps extends ModalDataProps {
  keyValue?: string;
  style?: React.CSSProperties;
  isOpen?: boolean;
  handleOnClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  keyValue,
  style,
  isOpen,
  className,
  modalSize,
  body,
  title = "",
  desc,
  closeText = "",
  componentProps,
  component: CustomComponent,
  contentClass,
  showBanner,
  bannerPosition,
  bannerComponent,
  transparentBg,
  modalFooter,
  modalAfterFooter,
  doNotClose,
  handleOnClose,
}) => {
  // const [pageY, setPageY] = useState<number>(0);
  const handleOverlayClick = (event: any) => {
    if (event.target.className === "modal__overlay" && !doNotClose) {
      handleOnClose();
    }
  };

  // const handleScroll = useCallback(() => {
  //   if (!isOpen) {
  //     setPageY(window.pageYOffset);
  //   }
  // }, [isOpen]);

  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.style.top = `-${pageY}px`;
  //     document.body.style.left = "0";
  //     document.body.style.right = "0";
  //     document.body.style.position = "fixed";
  //   } else {
  //     document.body.style.top = "";
  //     document.body.style.left = "";
  //     document.body.style.right = "";
  //     document.body.style.position = "";
  //     window.scrollTo({ top: pageY });
  //   }
  //
  //   document.body.classList.toggle("modal-is-open", isOpen);
  // }, [isOpen, pageY]);

  useEffect(() => {
    document.body.classList.toggle("modal-is-open", isOpen);
  }, [isOpen]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isOpen, handleScroll]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      key={keyValue}
      style={style}
      className={classNames("modal", className, {
        "modal--with-banner": showBanner,
      })}
    >
      <div className={"modal__overlay"} onClick={handleOverlayClick}>
        <div
          tabIndex={-1}
          role={"dialog"}
          className={classNames(
            "modal__content",
            `modal__content-${modalSize}`,
            `modal-${modalSize}`,
            {
              [`modal-wrapper-with-banner-${bannerPosition}`]: showBanner,
            }
          )}
        >
          <div
            className={classNames(
              "modal-content",
              `modal-content-${modalSize}`,
              contentClass,
              {
                "modal-content--transparent-bg": transparentBg,
                "modal-content--with-after-footer": modalAfterFooter,
              }
            )}
          >
            <div className={"modal-header"}>
              {title && <h2 className={"modal-title"}>{_t(title)}</h2>}
              {desc && <p className={"modal-desc"}>{_t(desc)}</p>}
              {!doNotClose &&
                (!!closeText ? (
                  <span
                    className={"modal-header__close-text"}
                    onClick={handleOnClose}
                  >
                    {_t(closeText)}
                  </span>
                ) : (
                  <button
                    className={"modal-header__close"}
                    onClick={handleOnClose}
                  />
                ))}
            </div>
            <div className={"modal-body"}>
              {CustomComponent ? <CustomComponent {...componentProps} /> : body}
            </div>
            {modalFooter && <div className={"modal-footer"}>{modalFooter}</div>}
            {modalAfterFooter && (
              <div className={"modal-after-footer"}>{modalAfterFooter}</div>
            )}
          </div>
          {bannerComponent && (
            <div
              className={classNames(
                "modal__banner",
                `modal__banner-${modalSize}`,
                `modal__banner--${bannerPosition}`
              )}
            >
              {bannerComponent}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  modalSize: "sm",
  bannerPosition: "left",
};

export default Modal;
