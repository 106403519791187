import React from "react";
import LogoDesktop from "../../../images/logos/desktop.svg";
import LogoMobile from "../../../images/logos/mobile.svg";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

type MainLogoProps = {
  linkTo?: string;
  onClick?: () => void;
  className?: string;
};

const MainLogo: React.FC<MainLogoProps> = ({ linkTo, onClick, className }) => {
  const history = useHistory();
  const imgClick = () => {
    linkTo && history.push(linkTo);
    onClick && onClick();
  };
  const Logo = isMobile ? LogoMobile : LogoDesktop;
  return <img src={Logo} alt="logo" onClick={imgClick} className={className} />;
};

export default MainLogo;
