import { getLanguage } from "../../system/translations/helper";
import { generatePath } from "react-router";
import {
  GET_SPORT_LINKS,
  GET_SPORT_LINKS_URL,
  sportLinksPayloadData,
} from "./constants";

export const getSbQuickLinksAction = () => (
  dispatch: any
): sportLinksPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: GET_SPORT_LINKS,
    request: {
      url: generatePath(GET_SPORT_LINKS_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};
