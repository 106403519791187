import React, { Suspense } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t } from "../../system/translations/InjectIntlContext";
import { Helmet } from "react-helmet";
const PromoTemplate = React.lazy(() => import("./PromoTemplate"));

const PromoPage = () => {
  return (
    <>
      <Helmet>
        <title>{_t("promo_page_title")}</title>
        <meta name="description" content={_t("promo_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--promo"}
        seoPanelProps={{ keySlug: "promo" }}
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <PromoTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default PromoPage;
