import React from "react";
import { isMobile } from "react-device-detect";
import { _t } from "../../system/translations/InjectIntlContext";
import LoginModal from "../user/components/modals/LoginModal";
import TfaProcessModal from "../user/components/modals/TfaProcessModal";
import TfaWithChangeData from "../user/components/TfaWithChangeData";
import checkmark from "../../images/icons/approved_checkmark_modal.svg";
import exclamation from "../../images/icons/warning_exclamation_modal.svg";
import AfterLoginModal from "../user/components/modals/AfterLoginModal";
import TournamentPrizesModal from "../tournaments/components/modals/TournamentPrizesModal";
import TournamentHowToPlayModal from "../tournaments/components/modals/TournamentHowToPlayModal";
import InfoPageModal from "../info/components/modals/InfoPageModal";
import GiftRulesModal from "../info/components/modals/GiftRulesModal";
import NotificationBlock from "../../components/molecules/NotificationBlock";
import RegModal from "../user/components/modals/RegModal";
import ChangePassword from "../user/components/ChangePassword";
import ForgotPasswordModal from "../user/components/modals/ForgotPasswordModal";
import ResetPasswordModal from "../user/components/modals/ResetPasswordModal";
import FillOutProfile from "../user/components/FillOutProfile";
import VerificationPhoneModal from "../user/components/modals/VerificationPhoneModal";
import PwaAddToHomeModal from "../landing/components/modals/PwaAddToHomeModal";
import PwaQrModal from "../landing/components/modals/PwaQrModal";
import PwaPromoModal from "../pwa/components/PwaPromoModal";
import ConfirmModal from "../../components/molecules/ConfirmModal";
import DepositModal from "../cashbox/components/modals/DepositModal";
import SignUpModalBanner from "../banners/components/SignUpModalBanner";
import SuccessModal from "../user/components/modals/SuccessModal";
import DescriptionModal from "../../components/organisms/Modal/DescriptionModal";
import ComplatedPaymentModal from "../../components/molecules/ComplatedPaymentModal";
import DisablePopupBlockerModal from "../../components/molecules/DisablePopupBlockerModal";
import GetGiftModal from "../../components/molecules/GetGiftModal";
import BonusExchangeModal, {
  bonusExchangeModalProps,
} from "../../components/molecules/BonusExchangeModal";
import {
  DontHaveAnAccountModalLink,
  AlreadyHaveAnAccountModalLink,
  ForgotYourPasswordModalLink,
} from "./links";
import ProcessModal from "../cashbox/components/modals/ProcessModal";
import PromoModal from "../../components/organisms/Modal/PromoModal";
import GiveawayModal from "../../components/organisms/Modal/GiveawayModal";
import InfoPage from "../info/components/InfoPage";
import { ModalParams } from "./types";
import EventCalendarModal from "../../components/organisms/Modal/EventCalendarModal";
import ChatModal from "../chat/components/ChatModal";
import AttentionModal from "../user/components/modals/AttentionModal";
import ConsentModal from "../general/components/modals/ConsentModal";
import QuizModal from "../hub2/quiz/components/QuizModal";
import QuizHistoryModal from "../hub2/quiz/components/QuizHistoryModal";
import { Quiz } from "../hub2/quiz/types";

export const forgotPasswordModal: ModalParams = {
  className: "forgot-password-modal",
  component: ForgotPasswordModal,
};

export const forgotPasswordModalSuccess = ({ handleClick }): ModalParams => ({
  className: "forgot-password-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "forgot-password-modal",
    title: "modal_forgot_password_reset_link_send",
    content: "modal_forgot_password_content",
    additionalContent: "modal_forgot_password_content_cant_find_email",
    btnLabel: "modal_forgot_password_success_ok_btn",
    btnType: "transparent-bordered",
    handleClick,
  },
  modalSize: "sm",
});

export const resetPasswordModal = ({
  email,
  confirmationCode,
  beforeClose,
}): ModalParams => ({
  title: "modal_reset_password_title",
  desc: "",
  component: ResetPasswordModal,
  beforeClose: beforeClose,
  componentProps: {
    email,
    confirmationCode,
  },
});

export const resetPasswordSuccessModal = ({ handleClick }): ModalParams => ({
  className: "reset-password-success-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "reset-password-success-modal",
    title: "modal_reset_success",
    content: "modal_reset_success_content",
    btnLabel: "modal_reset_success_btn",
    btnType: "transparent-bordered",
    handleClick,
  },
  modalSize: "sm",
});

export const changeUserCountryAttentionModal = ({
  handleClick,
}): ModalParams => ({
  className: "change-user-country-attention-modal",
  component: AttentionModal,
  componentProps: {
    modalClassName: "change-user-country-attention-modal",
    title: _t("modal_change_country_attention_title"),
    content: _t("modal_change_country_attention_content"),
    btnLabel: _t("modal_change_country_attention_btn"),
    btnType: "primary",
    handleClick: handleClick,
  },
  modalSize: "sm",
});

export const applyCashbackSuccessModal = ({
  handleClick,
  data,
}): ModalParams => ({
  className: "apply-cashback-success-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "apply-cashback-success-modal",
    title: "apply_cashback_success",
    content: { key: "apply_cashback_success_content_{data}", value: { data } },
    btnLabel: "apply_cashback_success_btn",
    btnType: "primary",
    handleClick,
  },
  modalSize: "sm",
});

export const afterLoginModal: ModalParams = {
  className: "after-login-modal",
  component: AfterLoginModal,
  modalSize: "lg",
  transparentBg: true,
};

export const verifyPhoneModal: ModalParams = {
  className: "verify-phone-modal",
  component: VerificationPhoneModal,
  title: "verification_phone_modal_title",
};

export const regModalWithRedirect = ({ redirectTo }): ModalParams => ({
  ...regModal,
  componentProps: { redirectTo },
});

export const regModalTermsAndCond: ModalParams = {
  className: "terms-and-cond__reg-modal",
  component: InfoPage,
  modalSize: "md",
  componentProps: {
    slug: "terms-and-conditions",
  },
};

export const changePasswordModal: ModalParams = {
  className: "change-password-modal",
  title: "modal_change_password",
  component: ChangePassword,
};

export const changePasswordModalSuccess = ({ handleClick }): ModalParams => ({
  className: "change-password-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "change-password-modal",
    title: "change_password_form_success_password_updated",
    content: "change_password_form_success_content",
    btnLabel: "change_password_form_success_ok_btn",
    btnType: "transparent-bordered",
    handleClick: handleClick,
  },
  modalSize: "sm",
});

export const loginModal: ModalParams = {
  title: "modal_login_title",
  component: LoginModal,
  modalFooter: <ForgotYourPasswordModalLink />,
  modalAfterFooter: <DontHaveAnAccountModalLink />,
};

export const tfaProcessModal = ({
  redirectTo,
  showOnSuccess,
}): ModalParams => ({
  className: "tfa-process-modal",
  title: "modal_tfa_process_title",
  desc: "modal_tfa_process_desc",
  component: TfaProcessModal,
  componentProps: { redirectTo, showOnSuccess },
});

export const tfaAfterRegModal: ModalParams = {
  className: "tfa-after-reg-modal",
  title: "modal_tfa_after_reg_title",
  component: TfaWithChangeData,
  componentProps: { afterReg: true },
  doNotClose: true,
};

export const regModal: ModalParams = {
  title: "modal_registration_title",
  className: "sign-up-modal",
  component: RegModal,
  showBanner: true,
  bannerPosition: isMobile ? "bottom" : "right",
  bannerComponent: <SignUpModalBanner slug={"sign_up_img"} size={"large"} />,
  modalSize: "lg",
  modalAfterFooter: <AlreadyHaveAnAccountModalLink />,
};

export const tournamentPrizesModal = ({ awards, linkTo }): ModalParams => ({
  title: "tournament_prizes_title",
  className: "tournament-prizes-modal",
  component: TournamentPrizesModal,
  componentProps: {
    awards,
    linkTo,
  },
});

export const regModalBonus: ModalParams = {
  title: "modal_registration_title",
  className: "sign-up-modal",
  component: RegModal,
  showBanner: true,
  bannerPosition: isMobile ? "bottom" : "left",
  bannerComponent: <SignUpModalBanner slug={"sign_up_bonus"} size={"small"} />,
  modalSize: isMobile ? "lg" : "sm",
  modalAfterFooter: <AlreadyHaveAnAccountModalLink />,
};

export const tournamentHowToPlayModal = ({ rules }): ModalParams => ({
  title: "tournament_how_to_play_title",
  className: "tournament-how-to-play-modal",
  component: TournamentHowToPlayModal,
  componentProps: {
    rules,
  },
});

export const infoPageModal = ({
  slug,
  closeText = "",
  className = "info-page-modal",
}): ModalParams => {
  return {
    closeText: closeText,
    className: className,
    component: InfoPageModal,
    componentProps: {
      slug,
    },
  };
};

export const giveawayModal = ({
  slug,
  title,
  desc,
  img,
  winners,
}): ModalParams => ({
  className: `giveaway-modal giveaway-${slug}-modal`,
  component: GiveawayModal,
  componentProps: {
    title,
    desc,
    img,
    winners,
  },
});

export const giftsRulesModal = ({ slug }): ModalParams => ({
  className: "gifts-rules-page-modal",
  component: GiftRulesModal,
  componentProps: {
    slug,
  },
});

export const eventCalendarModal = ({
  slug,
  date,
  name,
  desc,
}): ModalParams => ({
  className: `event-calendar-modal event-calendar-${slug}-modal`,
  component: EventCalendarModal,
  componentProps: {
    date,
    name,
    desc,
  },
});

/*
export const resetLinkModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "reset_link_sent",
    textKey: "check_your_email",
    italicKey: "check_your_spam",
    btnLabelKey: "ok",
    btnType: "transparent-bordered",
  },
};
*/

export const pwaInstallError: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: exclamation,
    titleMod: "yellow",
    titleKey: "install_pwa_error_title",
    textKey: "install_pwa_error_text",
    italicKey: "install_pwa_error_bottom_text",
    btnLabelKey: "install_pwa_error_btn_text",
    btnType: "primary",
  },
};
/*
export const passwordUpdatedModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "password_updated",
    textKey: "password_changed",
    textMod: "dim",
    btnLabelKey: "ok",
    btnType: "transparent-bordered",
  },
};*/

/*export const requestCreatedModal = (refNum: string | number): ModalParams => ({
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "request_created",
    textKey: "request_submitted",
    refNum: refNum,
    textMod: "dim",
    btnLabelKey: "transactions",
    btnType: "primary",
    btnBlock: true,
    //todo: link: "to_transactions_page"
  },
});*/

export const paymentCompletedModal = (params = {}): ModalParams => {
  return {
    className: "notification-modal",
    component: ComplatedPaymentModal,
    componentProps: {
      modalSize: "sm",
      ...params,
    },
  };
};

export const depositSuccessfulModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "deposit_successful",
    textKey: "transaction_successfully_completed",
    textMod: "dim",
    btnLabelKey: "deposit_successful_btn_ok",
    btnType: "primary",
    btnBlock: true,
    //todo: link: "casino" ?
  },
};

export const depositCompletedModal = (params = {}): ModalParams => ({
  className: "notification-modal",
  component: ComplatedPaymentModal,
  componentProps: {
    modalSize: "sm",
    ...params,
  },
});
export const withdrawalSuccessfulModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "withdrawal_successful_title",
    textKey: "withdrawal_successfully_completed",
    textMod: "dim",
    btnLabelKey: "withdrawal_successful_btn_ok",
    btnType: "primary",
    btnBlock: true,
  },
};

export const processIframeModal = (params = {}): ModalParams => {
  return {
    modalSize: "md",
    className: "process-modal",
    component: ProcessModal,
    componentProps: params,
  };
};

export const cookieModal = (params = {}): ModalParams => {
  return {
    modalSize: "sm",
    className: "consent-modal",
    component: ConsentModal,
    componentProps: {
      title: "we_use_cookie",
      btnApproveLabel: "cookies_allow",
      btnRejectLabel: "cookies_decline",
      className: "cookie-modal",
      ...params,
    },
    doNotClose: true,
  };
};

export const privacyModal = (params = {}): ModalParams => {
  return {
    modalSize: "sm",
    className: "consent-modal",
    component: ConsentModal,
    componentProps: {
      title: "privacy_statements_title",
      btnApproveLabel: "privacy_confirm",
      btnRejectLabel: "privacy_decline",
      className: "privacy-modal",
      ...params,
    },
    doNotClose: true,
  };
};

/*export const profileCompletedModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "profile_completed",
    textKey: "info_saved",
    textMod: "dim",
    btnLabelKey: "make_a_deposit",
    btnType: "primary",
    btnBlock: true,
    //todo: link: "link_to_deposit_page"
  },
};*/

/*export const verifyAccountModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: exclamation,
    titleKey: "verify_account",
    titleMod: "yellow",
    textKey: "acc_needs_verification",
    textMod: "dim",
    btnLabelKey: "upload_docs",
    btnType: "primary",
    btnBlock: true,
    //todo: link: "document_upload_page"
  },
};*/

export const confirmModal = ({
  title,
  desc,
  onConfirm,
  onCancel,
}): ModalParams => ({
  className: "confirmation-modal",
  component: ConfirmModal,
  componentProps: {
    title,
    desc,
    onConfirm,
    onCancel,
  },
});

export const depositModal = (version): ModalParams => ({
  modalSize: "md",
  className: `deposit-modal deposit-modal--${version}`,
  title: "modal_deposit_title",
  component: DepositModal,
});

export const fillOutProfileModal: ModalParams = {
  className: "fill-out-profile-modal",
  component: FillOutProfile,
  componentProps: {},
  modalSize: "md",
};

export const fillOutProfileModalSuccess = ({ handleClick }): ModalParams => ({
  className: "fill-out-profile-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "fill-out-profile-modal",
    title: "modal_fill_out_profile_profile_completed",
    content: "modal_fill_out_profile_info_saved",
    btnLabel: "modal_fill_out_profile_make_deposit_btn",
    btnType: "action",
    handleClick: handleClick,
  },
  modalSize: "md",
});

export const pwaAddToHomeModal: ModalParams = {
  className: "pwa-add-to-home-modal",
  component: PwaAddToHomeModal,
};

export const pwaQrModal = (qrValue: string): ModalParams => ({
  className: "pwa-qr-modal",
  title: "scan_qr",
  desc: "use_camera",
  component: PwaQrModal,
  componentProps: { qrValue },
});

export const pwaPromoModal: ModalParams = {
  className: "pwa-promo-modal",
  component: PwaPromoModal,
};

export const chatModal: ModalParams = {
  className: "chat-modal",
  component: ChatModal,
};

export const descriptionModal = ({
  className,
  img,
  description,
  linkTo,
  btnText,
  handleClick,
  handleOnClose,
}): ModalParams => ({
  className: className,
  component: DescriptionModal,
  componentProps: {
    img: img,
    text: description,
    linkTo: linkTo,
    btnText: btnText,
    handleClick: handleClick,
  },
  beforeClose: handleOnClose,
  modalSize: "md",
});

export const promoModal = ({
  className,
  img,
  description,
  btnText = "",
  handleClick = null,
  handleOnClose,
  linkTo = "",
}): ModalParams => ({
  className: className,
  component: PromoModal,
  componentProps: {
    img,
    linkTo,
    btnText,
    handleClick,
    text: description,
  },
  beforeClose: handleOnClose,
  modalSize: "md",
});

export const disablePopupBlockerModal = ({ link }): ModalParams => ({
  className: "disable-popup-blocker-modal",
  component: DisablePopupBlockerModal,
  componentProps: {
    link,
  },
});

export const getGiftModal = ({
  name,
  desc,
  text,
  isDeposit,
  onClick,
}): ModalParams => ({
  className: "get-gift-modal",
  component: GetGiftModal,
  componentProps: {
    name,
    desc,
    text,
    isDeposit,
    onClick,
  },
});

export const loginModalWithRedirect = ({
  onClose = () => {},
  redirectTo,
  showOnSuccess,
}: {
  onClose?: () => void;
  redirectTo?: string;
  showOnSuccess?: boolean;
}): ModalParams => ({
  ...loginModal,
  componentProps: { redirectTo, showOnSuccess },
  beforeClose: onClose,
});

export const bonusExchangeModal = (
  modalProps: bonusExchangeModalProps
): ModalParams => ({
  modalSize: "sm",
  className: "bonus-exchange-modal",
  title: "modal_bonus_exchange_title",
  component: BonusExchangeModal,
  componentProps: {
    ...modalProps,
  },
});

export const quizModal = (slug: string): ModalParams => ({
  className: "quiz-modal",
  component: QuizModal,
  componentProps: {
    slug,
  },
});

export const quizHistoryModal = (quizzes: Quiz[]): ModalParams => ({
  className: "quiz-modal",
  title: "quiz_history_title",
  component: QuizHistoryModal,
  componentProps: {
    quizzes,
  },
});

export const quizSuccessModal: ModalParams = {
  className: "quiz-modal notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "quiz_success_title",
    titleAlign: "center",
    textKey: "quiz_success_text",
    textMod: "dim",
    btnLabelKey: "quiz_success_btn_text",
    btnType: "primary",
    btnBlock: true,
  },
};
