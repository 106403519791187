import React, { FunctionComponent } from "react";
import { _t } from "../../../system/translations/InjectIntlContext";
import TextLink from "../TextLink";

type CashboxMethodAdditionalInfoProps = {
  title?: string;
  linkTo?: string;
  label?: string;
};

const CashboxMethodAdditionalInfo: FunctionComponent<CashboxMethodAdditionalInfoProps> = ({
  title,
  linkTo,
  label,
}) => {
  const handleClick = (linkTo: string) => {
    window.open(linkTo, "_blank");
  };
  return (
    <div>
      <span className={"cashbox-fields__top__text"}>{_t(title)}</span>
      {linkTo && (
        <div>
          <TextLink
            onClick={() => {
              handleClick(linkTo);
            }}
            label={_t(label)}
          />
        </div>
      )}
    </div>
  );
};

export default CashboxMethodAdditionalInfo;
