import { css } from "styled-components/macro";
import {
  rgba,
  mediaQueryMax,
  mediaQueryMin,
  mediaQueryMinMax,
} from "./helpers";

export const size = {
  desktop: 922,
  tablet: 768,
  mobile: 576,

  xs: 320,
  s: 480,
  m: 812,
  l: 1024,
  xl: 1200,
  xxl: 1920,
};

export const pwaSectionSizes = {
  topHeight: "572px",
  topHeightMob: "564px",
  botHeight: "588px",
  botHeightMob: "544px",
  midMobHeight: "376px",
  midSideMargin: "20px",
};

const media = (type) => {
  const mediaFn = type === "max" ? mediaQueryMax : mediaQueryMin;

  return {
    desktop: mediaFn(size.desktop),
    tablet: mediaFn(size.tablet),
    mobile: mediaFn(size.mobile),

    xs: mediaFn(size.xs),
    s: mediaFn(size.s),
    m: mediaFn(size.m),
    l: mediaFn(size.l),
    xl: mediaFn(size.xl),
    xxl: mediaFn(size.xxl),
  };
};

export const mediaMax = media("max");
export const mediaMin = media("min");

export const mediaMinMax = (min, max) => mediaQueryMinMax(min, max);

export const color = {
  white: "#fff",
  black: "#000",

  default: "#ffffff",
  default90: `${rgba("#ffffff", 0.9)}`,
  default70: `${rgba("#ffffff", 0.7)}`,
  default40: "#767a85",

  borderColorLightGrey: "rgba(223, 234, 240, 1)",
  borderColorDarkGrey: "#4f576a",

  primary: "#ffd60e",
  primaryHover: "#ffe669",
  primaryBgGradient:
    "linear-gradient(90deg, #ffc700 0%, #ffea21 100%), #ff9e2c",

  azure: "#5bd0ee",
  azureGradient: "linear-gradient(90deg, #5BD0EE 0.03%, #26B1D4 68.1%)",

  secondary: "#232939",
  secondaryOpacity90: "rgba(29, 34, 47, 0.9)",
  secondary100: "#232939",
  secondary60: "#353d52",
  secondary60Transparent: "rgba(35, 41, 57, 0.6)",
  secondary50: "#3b4457",
  secondary40: "#3d455a",
  secondary30: "#5c6682",
  secondary0: "#97a4c3",
  secondaryLight: "#b9c5e1",
  secondaryLightWithOpacity40: "rgba(185, 197, 225, 0.4)",
  secondaryLightWithOpacity15: "rgba(185, 197, 225, 0.15)",
  secondaryLightWithOpacity: "rgba(185, 197, 225, 0.15)",
  secondaryLight50: `${rgba("#b9c5e1", 0.5)}`,
  secondaryLight30: "#485167",
  secondaryLight20: "#4f586f",
  secondaryDark: "#505970",

  darkBlue: "#1c4f5b",
  darkBlueOpacity15: `${rgba("#1c4f5b", 0.15)}`,

  dark160: "#171a24",
  dark150: "#1D222F",
  dark100: "#232939",
  dark50: "#353D52",
  dark45: "#3D455A",
  dark40: "#343b4f",

  darkBackground80: "rgba(53, 61, 82, 0.8)",
  darkBackground10: "rgba(29, 34, 47, 0.1)",

  grey: "#B9C5E1",

  yellow: "#FFD60E",
  yellowLight: "#FFE669",

  green: "#3ccc87",
  blue: "#0026F5",
  deepBlue: "#001479",

  yellowGradient: "linear-gradient(90deg, #FFC700 0%, #FFEA21 100%), #FF9E2C",
  greenGradient: "linear-gradient(81.25deg, #2DD078 -0.52%, #00AE8B 99.56%)",
  greyGradient: "linear-gradient(136.18deg, #292F41 28.25%, #464F66 98.65%)",

  success500: "#3C9B81",
  success40: "#DFE9E6",

  danger500: "#F45353",
  danger40: "#623641",

  toastSuccess: "linear-gradient(81.25deg, #2dd078 -0.52%, #00ae8b 99.56%)",
  toastError: "#f45353",
  toastCloseBtnBg: "rgba(35, 41, 57, 0.5)",
  toastCloseBtnBgHover: "rgba(35, 41, 57, .7)",

  // legacy

  yellowDark: "#DFBE19",

  greenLight: "#88E91F",
  greenDark: "#6FB81F",

  blue500: "#182128",
  blue400: "#32363d",
  blue300: "#42484F",
  blue200: "#646B73",
  blue100: "#5E6977",
  blue40: "#5E6977",

  vipMiddleSectionGradientBg:
    "linear-gradient(180deg, rgba(0, 40, 255, 0) 0.01%, rgba(12,43,214, 1) 6.77%,  rgba(12,43,214, 1) 15%, rgba(7,22,104, .2) 100%)",
  vipBottomSectionGradientBg:
    "linear-gradient(180deg, #0424e7 0%, rgba(0, 40, 255, .5) 100%)",
  vipBenefitGradient: "linear-gradient(327.83deg, #BF95FE 0%, #2AB2F6 82.56%)",
  vipBtnPrimaryGradient: "linear-gradient(180deg, #FFE810 26.3%, #FFAA0F 100%)",
  blueActive: "#6EBAFA",

  tournamentThemeTableRowColor1: "#1930a5",
  tournamentThemeTableRowColor2: "rgba(#1930a5, 0.5)",
  tournamentThemeLightColor: "#cbd4ff",
  tournamentThemeBGGradient:
    "linear-gradient(180deg, rgba(1, 19, 102, 0) 0%, rgba(2, 20, 104, 0.75) 30.21%, #021468 57.14%, #021468 100%)",

  grey80: "#8E8E8E",
  grey70: "#AFAFAF",
  grey50: "#D8D8D8",
  grey40: "#E8E8E8",
  grey30: "#EEEEEE",

  gradientDark:
    "linear-gradient(to bottom, #2a2f38, #191b20 30%, #191b20 70%, #2a2f38)",
  gradientGold:
    "linear-gradient(to right, rgba(255, 217, 29, 0), #FFD91D 30%, #FFD91D 70%, rgba(255, 217, 29, 0))",
  shadow: "0 30px 40px -20px rgb(0 0 0 / 80%)",
};

export const misc = {
  mainPadding: {
    desktop: "20px",
    mobile: "16px",
  },
  headerHeight: {
    desktop: "72px",
    mobile: "52px",
  },
  sliderGap: "10px",
  mobileNavBarHeight: "50px",
  borderRadius: {
    small: "8px",
    medium: "12px",
    large: "17px",
  },
  transition: "0.25s cubic-bezier(.78, .14, .15, .86)",
};

export const mixin = {
  blueGlass: css`
    background-color: ${rgba(color.dark100, 0.95)};
    backdrop-filter: blur(5px);
  `,
};
