import { generatePath } from "react-router";
import {
  bonusesPayloadData,
  userInfoData,
  betDetailsPayloadData,
  userShareInfoType,
} from "./constants";
import { removeCookie } from "../../system/helpers/cookie";
import { history } from "../../system/store";
import { getLanguage } from "../../system/translations/helper";
import { transformGamesResult } from "../casino/zing/helpers";
import { useModal } from "../modals/hooks";

const LOGIN_URL = "/:lang/login_check";
const REG_URL = "/:lang/b-register-make";
const LOGOUT_URL = "/:lang/logout";
const USER_INFO_URL = "/:lang/profile/show.json";
const KYC_URL = "/:lang/profile/kyc/form.json";
const BONUSES_URL = "/:lang/profile/bonus/index.json";
const SEND_BONUS_CODE_URL = "/:lang/profile/bonus/redeem";
const SEND_CASH_IN_LOYALTY_URL = "/:lang/profile/cash-in-loyalty.json";
const FILL_OUT_PROFILE_URL = "/:lang/profile/quick-signup-finalize/submit";
const PLAY_HISTORY_URL = "/:lang/profile/history/play/select.json";
const PAYMENT_HISTORY_URL = "/:lang/profile/history/payment/select.json";
const PENDING_WITHDRAWALS_URL = "/:lang/profile/withdrawal/pending";
const CANCEL_WITHDRAWAL_URL =
  "/:lang/profile/withdrawal/:paymentId/cancel.json";
const BET_DETAILS_URL =
  "/ps/ips/getGameRoundHistory?platformCode=:platformCode&gameTranId=:gameTranId&land=:lang";
const CANCEL_BONUS_URL = "/:lang/profile/bonus/cancel.json";
const CASHBACK_AMOUNT = "/share/cashbox/cashback";
const CASHBACK_APPLY = "/share/cashbox/cashback/apply";
const FORGOT_PASSWORD_URL = "/:lang/forgotPassword";
const FORGOT_PASSWORD_FORM_URL = "/:lang/forgotPassword.json";
const RESET_PASSWORD_URL = "/api/v3/:lang/reset-password";
const RESET_PASSWORD_FORM_URL =
  "/api/v3/:lang/reset-password/:email/:resetCode";
const RESEND_PHONE_VERIFICATION_CODE_URL = `/:lang/users/phone/request`;
const CHANGE_PASSWORD_URL = `:lang/profile/password/submit`;
const PROFILE_EDIT_URL = "/:lang/profile/editing/submit";
const PROFILE_EDIT_FORM_URL = "/:lang/profile/editing/edit.json";
const UPDATE_BALANCE_URL = "/:lang/profile/update-balance";
const TFA_URL = "/:lang/profile/preferences/two-factor-auth";
const TFA_FORM_URL = "/:lang/profile/preferences/two-factor-auth.json";
const TFA_PROCESS_URL = "/:lang/profile/process-two-fa";
const TFA_TOKEN_URL = "/:lang/profile/two-fa-token";
const TFA_RESEND_CODE_URL = "/:lang/profile/resend-two-fa-code";
const GET_FAVORITE_GAMES = "/:lang/profile/game/get-favorite-list";
const GET_ADD_FAVORITE_FORM = "/:lang/profile/game/add-favorite-form";
const ADD_FAVORITE_GAME_ACTION = "/:lang/profile/game/add-favorite-action";
const REMOVE_FAVORITE_GAME_ACTION =
  "/:lang/profile/game/remove-favorite-action";
const SHARE_USER_PROFILE = "/share/user/profile";
const SHARE_USER_INFO = "/share/user/info";
const SHARE_PHONE_VERIFICATION_URL = `/share/user/phone-verification/request`;
const SHARE_PHONE_VERIFICATION_URL_CONFIRM_URL =
  "/share/user/phone-verification/confirm";

export const loginAction = (data) => (dispatch: any): Promise<userInfoData> => {
  const lang = getLanguage();
  return dispatch({
    type: "LOGIN",
    request: {
      url: generatePath(LOGIN_URL, { lang }),
      method: "POST",
      data: data,
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
      },
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const regAction = (data) => (dispatch: any): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "REGISTRATION",
    request: {
      url: generatePath(REG_URL, { lang }),
      method: "POST",
      data: data,
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
      },
    },
  }).then((data: any) => data.payload);
};

export const kycAction = (data: any) => (dispatch: any): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "KYC",
    request: {
      url: generatePath(KYC_URL, { lang }),
      method: "POST",
      data: data,
      headers: {
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
      },
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const changePasswordAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "CHANGE_PASSWORD",
    request: {
      url: generatePath(CHANGE_PASSWORD_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const playHistoryAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "PLAY_HISTORY",
    request: {
      url: generatePath(PLAY_HISTORY_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const paymentHistoryAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "PAYMENT_HISTORY",
    request: {
      url: generatePath(PAYMENT_HISTORY_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getBetDetailsAction = (data: any) => (
  dispatch: any
): Promise<betDetailsPayloadData> => {
  const lang = getLanguage();
  return dispatch({
    type: "BET_DETAILS",
    request: {
      url: generatePath(BET_DETAILS_URL, {
        platformCode: data.platformCode,
        gameTranId: data.gameTranId,
        lang,
      }),
      withPrefix: false,
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getPendingWithdrawalsAction = () => (dispatch: any): any => {
  const lang = getLanguage();
  return dispatch({
    type: "PENDING_WITHDRAWALS",
    request: {
      url: generatePath(PENDING_WITHDRAWALS_URL, { lang }),
      method: "GET",
      notifyMessage: {
        error: "ERROR_PENDING_WITHDRAWALS",
      },
    },
  }).then((data: any) => data.payload);
};

export const cancelWithdrawalAction = (paymentId: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "CANCEL_WITHDRAWAL",
    request: {
      url: generatePath(CANCEL_WITHDRAWAL_URL, { lang, paymentId }),
      method: "POST",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getKycFormAction = () => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_KYC_FORM",
    request: {
      url: generatePath(KYC_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getUserInfoAction = () => (
  dispatch: any
): Promise<userInfoData> => {
  const lang = getLanguage();
  return dispatch({
    type: "USER_INFO",
    request: {
      url: generatePath(USER_INFO_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const logOutAction = (redirectTo, hideModal = null) => (
  dispatch: any
): Promise<any> => {
  if (hideModal) {
    hideModal("privacy-modal");
  }

  removeCookie("isLoggedIn");
  const lang = getLanguage();
  const redirect = redirectTo || history.location.pathname;
  history.push(redirect);
  return dispatch({
    type: "LOGOUT",
    request: {
      url: generatePath(LOGOUT_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const sendProfileData = (data: any) => (dispatch: any): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "SEND_PROFILE_DATA",
    request: {
      url: generatePath(FILL_OUT_PROFILE_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getBonusListAction = () => (dispatch: any): bonusesPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_BONUS_LIST",
    request: {
      url: generatePath(BONUSES_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const sendBonusCodeAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "SEND_BONUS_CODE",
    request: {
      url: generatePath(SEND_BONUS_CODE_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const sendCashInLoyaltyAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "SEND_CASH_IN_LOYALTY",
    request: {
      url: generatePath(SEND_CASH_IN_LOYALTY_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const sendPhoneVerifyAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const { sessionKey, ...restData } = data;
  const lang = getLanguage();
  return dispatch({
    type: "SEND_PHONE_VERIFY",
    request: {
      url: SHARE_PHONE_VERIFICATION_URL_CONFIRM_URL,
      method: "POST",
      data: restData,
      headers: {
        "X-User-Outside": sessionKey,
        "X-Locale": lang,
      },
      withPrefix: false,
    },
  }).then((data: any) => {
    return data.payload;
  });
};
export const sendPhoneVerifyRequestAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const { sessionKey, ...restData } = data;
  const lang = getLanguage();
  return dispatch({
    type: "SEND_PHONE_VERIFICATION",
    request: {
      url: SHARE_PHONE_VERIFICATION_URL,
      method: "POST",
      data: restData,
      headers: {
        "X-User-Outside": sessionKey,
        "X-Locale": lang,
      },
      withPrefix: false,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const forgotPasswordAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "FORGOT_PASSWORD",
    request: {
      url: generatePath(FORGOT_PASSWORD_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const resendPhoneVerifyCodeAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "RESEND_PHONE_VERIFICATION_CODE",
    request: {
      url: generatePath(RESEND_PHONE_VERIFICATION_CODE_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const forgotPasswordFormAction = () => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "FORGOT_PASSWORD_FORM",
    request: {
      url: generatePath(FORGOT_PASSWORD_FORM_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const resetPasswordAction = (data: any) => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "RESET_PASSWORD",
    request: {
      url: generatePath(RESET_PASSWORD_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const resetPasswordFormAction = (email: string, resetCode: string) => (
  dispatch: any
) => {
  const lang = getLanguage();
  return dispatch({
    type: "RESET_PASSWORD_FORM",
    request: {
      url: generatePath(RESET_PASSWORD_FORM_URL, { lang, email, resetCode }),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const profileEditAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "PROFILE_EDIT",
    request: {
      url: generatePath(PROFILE_EDIT_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const profileEditFormAction = () => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "PROFILE_EDIT_FORM",
    request: {
      url: generatePath(PROFILE_EDIT_FORM_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const updateBalanceAction = () => (dispatch: any): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "UPDATE_BALANCE",
    request: {
      url: generatePath(UPDATE_BALANCE_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const getTfaAction = () => (dispatch: any): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "TFA_FORM",
    request: {
      url: generatePath(TFA_FORM_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const setTfaAction = (data: any) => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "SET_TFA",
    request: {
      url: generatePath(TFA_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => data.payload);
};

export const tfaProcessAction = (data: any) => (
  dispatch: any
): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "TFA_PROCESS",
    request: {
      url: generatePath(TFA_PROCESS_URL, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => data.payload);
};

export const getTfaTokenAction = () => (dispatch: any): Promise<any> => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_TFA_TOKEN",
    request: {
      url: generatePath(TFA_TOKEN_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const tfaResendCodeAction = (token: string) => (dispatch: any) => {
  const lang = getLanguage();
  const formData = new FormData();
  formData.append("_token", token);
  return dispatch({
    type: "TFA_RESEND_CODE",
    request: {
      url: generatePath(TFA_RESEND_CODE_URL, { lang }),
      method: "POST",
      data: formData,
    },
  }).then((data: any) => data.payload);
};

export const getFavoriteGamesAction = () => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_FAVORITE_GAMES",
    request: {
      url: generatePath(GET_FAVORITE_GAMES, { lang }),
      method: "GET",
    },
  }).then(
    (data: any) =>
      new Promise((resolve) => {
        const res = transformGamesResult(
          data.payload,
          data.payload?.favoriteGames,
          "favorites",
          "favoriteGames"
        );
        resolve(res);
      })
  );
};

export const addFavoriteFormAction = () => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "ADD_FAVORITE_FORM",
    request: {
      url: generatePath(GET_ADD_FAVORITE_FORM, { lang }),
      method: "GET",
    },
  }).then((data: any) => data.payload);
};

export const addFavoriteGameAction = (data: any) => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "ADD_FAVORITE_GAME",
    request: {
      url: generatePath(ADD_FAVORITE_GAME_ACTION, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => data.payload);
};

export const removeFavoriteGameAction = (data: any) => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "REMOVE_FAVORITE_GAME",
    request: {
      url: generatePath(REMOVE_FAVORITE_GAME_ACTION, { lang }),
      method: "POST",
      data: data,
    },
  }).then((data: any) => data.payload);
};

export const getCashbackAmountAction = (data: any) => (dispatch: any) => {
  const { sessionKey } = data;
  return dispatch({
    type: "CASHBACK_AMOUNT",
    request: {
      url: generatePath(CASHBACK_AMOUNT),
      headers: {
        "X-User-Outside": sessionKey,
      },
      enabled: sessionKey,
      withPrefix: false,
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const applyCashbackAction = (data: any) => (dispatch: any) => {
  const { sessionKey } = data;
  const formData = new FormData();
  formData.append("id", data.id);
  return dispatch({
    type: "APPLY_CASHBACK",
    request: {
      url: generatePath(CASHBACK_APPLY),
      headers: {
        "X-User-Outside": sessionKey,
      },
      withPrefix: false,
      data: formData,
      method: "POST",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const cancelBonusAction = (data: any) => (dispatch: any) => {
  const lang = getLanguage();
  return dispatch({
    type: "CANCEL_BONUS",
    request: {
      url: generatePath(CANCEL_BONUS_URL, { lang }),
      data: data,
      method: "POST",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getShareUserProfileAction = (data: any) => (
  dispatch: any
): Promise<userShareInfoType> => {
  const { sessionKey } = data;
  return dispatch({
    type: "SHARE_USER_PROFILE",
    request: {
      url: SHARE_USER_PROFILE,
      headers: {
        "X-User-Outside": sessionKey,
      },
      withPrefix: false,
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};

export const getShareUserInfoAction = (data: any) => (
  dispatch: any
): Promise<userShareInfoType> => {
  const { sessionKey, ...payloadData } = data;
  return dispatch({
    type: "SHARE_USER_INFO",
    request: {
      url: SHARE_USER_INFO,
      headers: {
        "X-User-Outside": sessionKey,
      },
      withPrefix: false,
      method: "PUT",
      data: payloadData,
    },
  }).then((data: any) => {
    return data.payload;
  });
};
