export const sortDisplayPosition = (a, b, filter) =>
  a.display_position[filter] - b.display_position[filter];

const apiDataBySlugReducer = (data, slug) =>
  data
    ?.filter((item) => item.display_position?.hasOwnProperty(slug))
    .sort((a, b) => sortDisplayPosition(a, b, slug));

export const apiDataWithSlug = (allowedSlugArr, data) => {
  const transformedData = {};

  allowedSlugArr.forEach((slug) => {
    transformedData[slug] = apiDataBySlugReducer(data, slug);
  });

  return transformedData;
};

export const getFirstObjInArray = (arr, val) =>
  arr.filter((el) => el.value === val).shift();
