import { defineMessages } from "react-intl";

const I18n_keys = defineMessages({
  main_href_domain: {
    id: "main_href_domain",
    defaultMessage: "stg3.bettilt.com",
  },
  one_signal_app_id: {
    id: "one_signal_app_id",
    defaultMessage: "9c094a49-39b0-4ddb-af2c-5bd5e36366f8",
  },
  load_more: {
    id: "load_more",
    defaultMessage: "Load more",
  },
  required: {
    id: "required",
    defaultMessage: "Required",
  },
  profile: {
    id: "profile",
    defaultMessage: "Profile",
  },
  firstName_required: {
    id: "firstName_required",
    defaultMessage: "First name is required",
  },
  lastName_required: {
    id: "lastName_required",
    defaultMessage: "Last name is required",
  },
  gender_required: {
    id: "gender_required",
    defaultMessage: "Gender is required",
  },
  birthDateDay_required: {
    id: "birthDateDay_required",
    defaultMessage: "Birth day is required",
  },
  birthDateMonth_required: {
    id: "birthDateMonth_required",
    defaultMessage: "Birth mont is required",
  },
  birthDateYear_required: {
    id: "birthDateYear_required",
    defaultMessage: "Birth year is required",
  },
  country_required: {
    id: "country_required",
    defaultMessage: "Country is required",
  },
  city_required: {
    id: "city_required",
    defaultMessage: "City is required",
  },
  postalCode_required: {
    id: "postalCode_required",
    defaultMessage: "Postal code is required",
  },
  address_required: {
    id: "address_required",
    defaultMessage: "Address is required",
  },
  apply: {
    id: "apply",
    defaultMessage: "Apply",
  },
  from: {
    id: "from",
    defaultMessage: "From",
  },
  email: {
    id: "email",
    defaultMessage: "E-mail",
  },
  username: {
    id: "username",
    defaultMessage: "Username",
  },
  first_name: {
    id: "first_name",
    defaultMessage: "First Name",
  },
  last_name: {
    id: "last_name",
    defaultMessage: "Last Name",
  },
  mobile_phone: {
    id: "mobile_phone",
    defaultMessage: "Mobile Phone",
  },
  birth_date: {
    id: "birth_date",
    defaultMessage: "Date of birth",
  },
  address: {
    id: "address",
    defaultMessage: "Address",
  },
  postal_code: {
    id: "postal_code",
    defaultMessage: "Postal Code",
  },
  city: {
    id: "city",
    defaultMessage: "City",
  },
  country: {
    id: "country",
    defaultMessage: "Country",
  },
  save_changes: {
    id: "save_changes",
    defaultMessage: "Save Changes",
  },
  change_password: {
    id: "change_password",
    defaultMessage: "Change password",
  },
  submit: {
    id: "submit",
    defaultMessage: "Submit",
  },
  to: {
    id: "to",
    defaultMessage: "To",
  },
  not_found_title: {
    id: "not_found_title",
    defaultMessage: "Not found",
  },
  no_data: {
    id: "no_data",
    defaultMessage: "No data",
  },
  drop_zone_text: {
    id: "drop_zone_text",
    defaultMessage: "Drop file here or",
  },
  drop_zone_button: {
    id: "drop_zone_button",
    defaultMessage: "browse",
  },
  upload: {
    id: "upload",
    defaultMessage: "Upload",
  },
  prize_places: {
    id: "prize_places",
    defaultMessage: "Prize places",
  },
  details: {
    id: "details",
    defaultMessage: "Details",
  },
  hide: {
    id: "hide",
    defaultMessage: "Hide",
  },
  hour: {
    id: "hour",
    defaultMessage: "Hour",
  },
  days: {
    id: "days",
    defaultMessage: "Days",
  },
  hours: {
    id: "hours",
    defaultMessage: "Hours",
  },
  minuteShort: {
    id: "minutesShort",
    defaultMessage: "Min",
  },
  secondShort: {
    id: "secondShort",
    defaultMessage: "Sec",
  },
  time_period: {
    id: "time_period",
    defaultMessage: "Time period",
  },
  min: {
    id: "min",
    defaultMessage: "min",
  },
  max: {
    id: "max",
    defaultMessage: "max",
  },
  play_for_fun: {
    id: "play_for_fun",
    defaultMessage: "Play for fun",
  },
  play_for_real: {
    id: "play_for_real",
    defaultMessage: "Play for real",
  },
  play_now: {
    id: "play_now",
    defaultMessage: "Play now",
  },
  casino_search_input_placeholder: {
    id: "casino_search_input_placeholder",
    defaultMessage: "Search",
  },
  casino_load_more_text: {
    id: "casino_load_more_text_{shown}_{total}",
    defaultMessage: "You've viewed {shown} of {total} games",
  },
  casino_favorites_title: {
    id: "casino_favorites_title",
    defaultMessage: "Favorites",
  },
  casino_providers_title: {
    id: "casino_providers_title",
    defaultMessage: "Provider",
  },
  casino_providers_show_games: {
    id: "casino_providers_show_games_{count}",
    defaultMessage: "Show ({count}) games",
  },
  casino_providers_select_all: {
    id: "casino_providers_select_all",
    defaultMessage: "Select all",
  },
  casino_providers_clear_all: {
    id: "casino_providers_clear_all",
    defaultMessage: "Clear all",
  },
  casino_provider_item_count: {
    id: "casino_provider_item_count_{count}",
    defaultMessage: "{count} games",
  },
  game_header_deposit: {
    id: "game_header_deposit",
    defaultMessage: "Deposit",
  },
  game_header_support: {
    id: "game_header_support",
    defaultMessage: "Support",
  },
  game_deposit_title: {
    id: "game_deposit_title",
    defaultMessage: "Fast deposit",
  },
  game_deposit_multibanco: {
    id: "game_deposit_multibanco",
    defaultMessage: "Multibanco",
  },
  game_deposit_other_methods: {
    id: "game_deposit_other_methods",
    defaultMessage: "Other methods",
  },
  game_sidebar_tabs_fast_deposit: {
    id: "game_sidebar_tabs_fast_deposit",
    defaultMessage: "Fast deposit",
  },
  game_sidebar_tabs_winners: {
    id: "game_sidebar_tabs_winners",
    defaultMessage: "Winners",
  },
  game_sidebar_tabs_games: {
    id: "game_sidebar_tabs_games",
    defaultMessage: "Games",
  },
  game_sidebar_games_tabs_hot: {
    id: "game_sidebar_games_tabs_hot",
    defaultMessage: "Hot games",
  },
  game_sidebar_games_tabs_favorites: {
    id: "game_sidebar_games_tabs_favorites",
    defaultMessage: "Favorites",
  },
  game_sidebar_games_tabs_recent: {
    id: "game_sidebar_games_tabs_recent",
    defaultMessage: "Recent",
  },
  game_sidebar_games_tabs_search: {
    id: "game_sidebar_games_tabs_search",
    defaultMessage: "Search",
  },
  how_to_play: {
    id: "how_to_play",
    defaultMessage: "How to play",
  },
  user_total_balance: {
    id: "user_total_balance",
    defaultMessage: "Total balance",
  },
  user_real_balance_exceeded: {
    id: "user_total_balance",
    defaultMessage: "Real balance exceeded",
  },
  user_info_deposit: {
    id: "user_info_deposit",
    defaultMessage: "Deposit",
  },
  user_bonus_balance: {
    id: "user_bonus_balance",
    defaultMessage: "Bonus balance",
  },
  user_loyalty_point: {
    id: "user_loyalty_point",
    defaultMessage: "Loyalty point",
  },
  user_real_balance: {
    id: "user_real_balance",
    defaultMessage: "Real balance",
  },
  user_log_out: {
    id: "user_log_out",
    defaultMessage: "Log Out",
  },
  user_balance: {
    id: "user_balance",
    defaultMessage: "Balance",
  },
  user_current_level: {
    id: "user_current_level",
    defaultMessage: "Current VIP level",
  },
  user_progres_level_completed: {
    id: "user_progres_level_completed",
    defaultMessage: "completed",
  },
  user_next_level: {
    id: "user_next_level",
    defaultMessage: "to next level",
  },
  sidebar_sign_up: {
    id: "sidebar_sign_up",
    defaultMessage: "Sign Up",
  },
  sidebar_login: {
    id: "sidebar_login",
    defaultMessage: "Login",
  },
  header_sport: {
    id: "header_sport",
    defaultMessage: "Sport",
  },
  header_in_play: {
    id: "header_in_play",
    defaultMessage: "In play",
  },
  header_virtual_sports: {
    id: "header_virtual_sports",
    defaultMessage: "Virtual sports",
  },
  header_casino: {
    id: "header_casino",
    defaultMessage: "Casino",
  },
  header_live_casino: {
    id: "header_live_casino",
    defaultMessage: "Live casino",
  },
  header_tournaments: {
    id: "header_tournaments",
    defaultMessage: "Tournaments",
  },
  header_promotions: {
    id: "header_promotions",
    defaultMessage: "Promotions",
  },
  casino_recommended: {
    id: "casino_recommended",
    defaultMessage: "Recommended",
  },
  casino_editors_choice: {
    id: "casino_editors_choice",
    defaultMessage: "Editors Choice",
  },
  casino_new_product: {
    id: "casino_new_product",
    defaultMessage: "New Product",
  },
  casino_most_popular: {
    id: "casino_most_popular",
    defaultMessage: "Most Popular",
  },
  casino_features: {
    id: "casino_features",
    defaultMessage: "Feature games",
  },
  casino_poker: {
    id: "casino_poker",
    defaultMessage: "Poker",
  },
  casino_blackjack: {
    id: "casino_blackjack",
    defaultMessage: "Blackjack",
  },
  casino_live: {
    id: "casino_live",
    defaultMessage: "Live Casino",
  },
  casino_poker_description: {
    id: "casino_poker_description",
    defaultMessage: "A curated list of the best games in our editorial opinion",
  },
  casino_show_all: {
    id: "casino_show_all",
    defaultMessage: "Show All",
  },
  show_more: {
    id: "show_more",
    defaultMessage: "Show More",
  },
  casino_jackpot: {
    id: "casino_jackpot",
    defaultMessage: "Jackpot",
  },
  casino_favorites: {
    id: "casino_favorites",
    defaultMessage: "Favorites",
  },
  casino_search_title: {
    id: "casino_search_title",
    defaultMessage: "Search",
  },
  casino_search_not_found_desc: {
    id: "casino_search_not_found_desc",
    defaultMessage:
      "Make sure the spelling is correct or try some different keyword.",
  },
  footer_site_desc_title: {
    id: "footer_site_desc_title",
    defaultMessage: "Bettilt.com - online sports betting casino",
  },
  footer_site_desc: {
    id: "footer_site_desc",
    defaultMessage:
      "Bettilt.com is operated by Abudantia B.V., a company registered under the laws of Curacao under registration number 126608 and having its registered address at Heelsumstraat 51 E-Commerce Park, Curacao. Abudantia B.V. holds a sublicense # 8048/JAZ2014-034 issued by Antillephone N.V., which has been established under the laws of Curaçao and holds a Gaming License # 8048/JAZ. Payment agent is Striking Stingray Cyprus Limited which is a subsidiary of Abudantia N.V. and has been established under the laws of Cyprus under registration number HE 398271 and having its registered address at Chytron, 30, 2nd floor, Flat/Office A22, 1075, Nicosia, Cyprus.",
  },
  modal_login_desc: {
    id: "modal_login_desc",
    defaultMessage: "Login with credentials",
  },
  modal_registration_title: {
    id: "modal_registration_title",
    defaultMessage: "Sign Up",
  },
  modal_reset_password_title: {
    id: "modal_reset_password_title",
    defaultMessage: "Enter your new password",
  },
  login_form_username: {
    id: "login_form_username",
    defaultMessage: "username",
  },
  login_form_password: {
    id: "login_form_password",
    defaultMessage: "password",
  },
  login_form_log_in_btn: {
    id: "login_form_log_in_btn",
    defaultMessage: "Log in",
  },
  after_login_modal_hello: {
    id: "after_login_modal_hello",
    defaultMessage: "Hello",
  },
  after_login_modal_welcome: {
    id: "after_login_modal_welcome",
    defaultMessage: "Welcome back to the Bettilt Family",
  },
  after_login_modal_recently_played: {
    id: "after_login_modal_recently_played",
    defaultMessage: "Recently Played",
  },
  after_login_modal_recommends: {
    id: "after_login_modal_recommends",
    defaultMessage: "Bettilt Recommends",
  },
  after_login_modal_to_games: {
    id: "after_login_modal_to_games",
    defaultMessage: "To our games",
  },
  modal_change_password: {
    id: "modal_change_password",
    defaultMessage: "Change Password",
  },
  change_password_form_old_password: {
    id: "change_password_form_old_password",
    defaultMessage: "Old password",
  },
  change_password_form_new_password: {
    id: "change_password_form_new_password",
    defaultMessage: "New password",
  },
  change_password_form_repeat_password: {
    id: "change_password_form_repeat_password",
    defaultMessage: "Confirm new password",
  },
  change_password_form_btn: {
    id: "change_password_form_btn",
    defaultMessage: "Change password",
  },
  change_password_form_success_password_updated: {
    id: "change_password_form_success_password_updated",
    defaultMessage: "Password updated",
  },
  change_password_form_success_content: {
    id: "change_password_form_success_content",
    defaultMessage:
      "Your password was successfully changed. Your can now use your new password \n" +
      "to sign in to your account.",
  },
  change_password_form_success_ok_btn: {
    id: "change_password_form_success_ok_btn",
    defaultMessage: "OK",
  },
  modal_fill_out_profile_title: {
    id: "modal_fill_out_profile_title",
    defaultMessage: "Fill out profile",
  },
  modal_fill_out_profile_address_and_phone_title: {
    id: "modal_fill_out_profile_address_and_phone_title",
    defaultMessage: "Address & phone",
  },
  modal_fill_out_profile_content: {
    id: "modal_fill_out_profile_content",
    defaultMessage:
      "You need to fill out your profile in order to make deposits.",
  },
  modal_fill_out_profile_first_name: {
    id: "modal_fill_out_profile_first_name",
    defaultMessage: "First Name",
  },
  modal_fill_out_profile_last_name: {
    id: "modal_fill_out_profile_last_name",
    defaultMessage: "Last Name",
  },
  modal_fill_out_profile_select_gender: {
    id: "modal_fill_out_profile_select_gender",
    defaultMessage: "Select gender",
  },
  modal_fill_out_profile_gender_male: {
    id: "modal_fill_out_profile_gender_male",
    defaultMessage: "Male",
  },
  modal_fill_out_profile_gender_female: {
    id: "modal_fill_out_profile_gender_female",
    defaultMessage: "Female",
  },
  modal_fill_out_profile_date_of_birth_label: {
    id: "modal_fill_out_profile_date_of_birth_label",
    defaultMessage: "Date of birth",
  },
  modal_fill_out_profile_back_btn: {
    id: "modal_fill_out_profile_back_btn",
    defaultMessage: "Back",
  },
  modal_fill_out_profile_confirm_btn: {
    id: "modal_fill_out_profile_confirm_btn",
    defaultMessage: "Confirm",
  },
  modal_fill_out_profile_phone: {
    id: "modal_fill_out_profile_phone",
    defaultMessage: "Phone",
  },
  fill_out_profile_country_placeholder: {
    id: "fill_out_profile_country_placeholder",
    defaultMessage: "Country",
  },
  fill_out_profile_city_placeholder: {
    id: "fill_out_profile_city_placeholder",
    defaultMessage: "City",
  },
  fill_out_profile_address_placeholder: {
    id: "fill_out_profile_address_placeholder",
    defaultMessage: "Address",
  },
  fill_out_profile_postal_code_placeholder: {
    id: "fill_out_profile_postal_code_placeholder",
    defaultMessage: "Postal Code",
  },
  modal_fill_out_profile_continue_btn: {
    id: "modal_fill_out_profile_continue_btn",
    defaultMessage: "Continue",
  },
  modal_fill_out_profile_profile_completed: {
    id: "modal_fill_out_profile_profile_completed",
    defaultMessage: "Profile completed",
  },
  modal_fill_out_profile_info_saved: {
    id: "modal_fill_out_profile_info_saved",
    defaultMessage:
      "All information has been saved. Now you are able to play games for real money.",
  },
  modal_fill_out_profile_make_deposit_btn: {
    id: "modal_fill_out_profile_make_deposit_btn",
    defaultMessage: "Make a Deposit",
  },
  modal_forgot_password_title: {
    id: "modal_forgot_password_title",
    defaultMessage: "Forgot password?",
  },
  modal_forgot_password_submit_button: {
    id: "modal_forgot_password_submit_button",
    defaultMessage: "Send reset link",
  },
  modal_forgot_password_desc: {
    id: "modal_forgot_password_desc",
    defaultMessage:
      "Enter the email registered to your account and we will send you a password reset link.",
  },
  modal_forgot_password_reset_link_send: {
    id: "modal_forgot_password_reset_link_send",
    defaultMessage: "Reset link is sent",
  },
  modal_forgot_password_content: {
    id: "modal_forgot_password_content",
    defaultMessage: "Please check your email and follow the confirmation link.",
  },
  modal_forgot_password_content_cant_find_email: {
    id: "modal_forgot_password_content_cant_find_email",
    defaultMessage:
      "Can’t find the email in your inbox? Check your spam/ junk folder or contact our support team.",
  },
  modal_forgot_password_success_ok_btn: {
    id: "modal_forgot_password_success_ok_btn",
    defaultMessage: "OK",
  },
  modal_reset_success: {
    id: "modal_reset_success",
    defaultMessage: "Success",
  },
  modal_reset_success_content: {
    id: "modal_reset_success_content",
    defaultMessage: "You can try to login",
  },
  modal_reset_success_btn: {
    id: "modal_reset_success_btn",
    defaultMessage: "Ok",
  },
  error_404_page_status_code: {
    id: "404_page_status_code",
    defaultMessage: "404",
  },
  error_404_page_text: {
    id: "error_404_page_text",
    defaultMessage: "Page not found",
  },
  error_404_page_description: {
    id: "error_404_page_description",
    defaultMessage:
      "Oops, it looks like the page you request could not be found",
  },
  error_404_btn_text: {
    id: "error_404_btn_text",
    defaultMessage: "Main Page",
  },
  some_error_page_text: {
    id: "some_error_page_text",
    defaultMessage: "Sorry.. there was an error",
  },
  some_error_page_description: {
    id: "some_error_page_description",
    defaultMessage: "Please reload page or contact with support",
  },
  promo_page_title: {
    id: "promo_page_title",
    defaultMessage: "Promotions",
  },
  kyc_file_limit: {
    id: "kyc_file_limit",
    defaultMessage: "Maximum file size 5 MB, JPG or PNG",
  },
  kyc_ccv_title: {
    id: "kyc_ccv_title",
    defaultMessage: "Credit card",
  },
  kyc_ccv_note: {
    id: "kyc_ccv_note",
    defaultMessage:
      "This may be a photo from the payment method you use to fund your casino account.",
  },
  kyc_ccv_front_label: {
    id: "kyc_ccv_front_label",
    defaultMessage: "Front side",
  },
  kyc_ccv_front_desc: {
    id: "kyc_ccv_front_desc",
    defaultMessage: "Upload front side",
  },
  kyc_ccv_back_label: {
    id: "kyc_ccv_back_label",
    defaultMessage: "Back side",
  },
  kyc_ccv_back_desc: {
    id: "kyc_ccv_back_desc",
    defaultMessage: "Upload back side",
  },
  kyc_idv_title: {
    id: "kyc_idv_title",
    defaultMessage: "Identification",
  },
  kyc_idv_label: {
    id: "kyc_idv_label",
    defaultMessage: "Select ID type:",
  },
  kyc_idv_identity_card: {
    id: "kyc_idv_identity_card",
    defaultMessage: "Identity Card",
  },
  kyc_idv_passport: {
    id: "kyc_idv_passport",
    defaultMessage: "Passport",
  },
  kyc_idv_driving: {
    id: "kyc_idv_driving",
    defaultMessage: "Driving License",
  },
  kyc_doc_title: {
    id: "kyc_doc_title",
    defaultMessage: "Address",
  },
  kyc_doc_label: {
    id: "kyc_doc_label",
    defaultMessage: "Any confirmation of your current place of residence:",
  },
  kyc_doc_bill: {
    id: "kyc_doc_bill",
    defaultMessage: "Utility bill",
  },
  kyc_doc_bank: {
    id: "kyc_doc_bank",
    defaultMessage: "Bank statement",
  },
  kyc_doc_certificate: {
    id: "kyc_doc_certificate",
    defaultMessage: "Certificate of residence",
  },
  kyc_status_pass: {
    id: "kyc_status_pass",
    defaultMessage: "Your identity and address document is approved",
  },
  kyc_not_verified: {
    id: "kyc_not_verified",
    defaultMessage: "Not verified",
  },
  security_settings: {
    id: "security_settings",
    defaultMessage: "Security settings",
  },
  tfa_title: {
    id: "tfa_title",
    defaultMessage: "Two-Factor Authentication",
  },
  tfa_default_message: {
    id: "tfa_default_message",
    defaultMessage:
      "For successful authorization, you will need to enter a code that will be sent to your phone or email.",
  },
  tfa_sms_message: {
    id: "tfa_sms_message_{phone}",
    defaultMessage:
      "For successful authorization, you will need to enter a code that will be sent to your phone <bold>{phone}</bold>.",
  },
  tfa_email_message: {
    id: "tfa_email_message_{email}",
    defaultMessage:
      "For successful authorization, you will need to enter a code that will be sent to your email <bold>{email}</bold>.",
  },
  tfa_sms_code: {
    id: "tfa_sms_code",
    defaultMessage: "Sms Code",
  },
  tfa_email_code: {
    id: "tfa_email_code",
    defaultMessage: "Email Code",
  },
  tfa_process_form_code: {
    id: "tfa_process_form_code",
    defaultMessage: "Code",
  },
  tfa_process_form_timer: {
    id: "tfa_process_form_timer_{value}",
    defaultMessage: "Resend the code after <bold>{value}</bold> seconds",
  },
  tfa_process_form_resend_code: {
    id: "tfa_process_form_resend_code",
    defaultMessage: "Resend Code",
  },
  tfa_process_form_submit: {
    id: "tfa_process_form_submit",
    defaultMessage: "Confirm",
  },
  change_password_title: {
    id: "change_password_title",
    defaultMessage: "Change password",
  },
  tournaments: {
    id: "tournaments",
    defaultMessage: "Tournaments",
  },
  tournament_finished: {
    id: "tournament_finished",
    defaultMessage: "tournament finished",
  },
  tournament_ends_in: {
    id: "tournament_ends_in",
    defaultMessage: "Tournament ends in:",
  },
  rules_and_conditions: {
    id: "rules_and_conditions",
    defaultMessage: "Rules and Conditions",
  },
  time_left_before_finish: {
    id: "time_left_before_finish",
    defaultMessage: "time left before finish",
  },
  back: {
    id: "back",
    defaultMessage: "back",
  },
  seo_panel_title_sportsbook: {
    id: "seo_panel_title",
    defaultMessage: "Welcome to a new way of getting entertained",
  },
  seo_panel_subtitle_sportsbook: {
    id: "seo_panel_subtitle_sportsbook",
    defaultMessage:
      "Bettilt an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  },
  seo_panel_text_sportsbook: {
    id: "seo_panel_text_sportsbook",
    defaultMessage:
      "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86.\n" +
      "\n" +
      "⦿     «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. \n" +
      "\n" +
      "⦿     «Account» is an account opened by You on the website after accepting and agreeing to this «Terms».\n" +
      "\n" +
      "⦿     «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bettilt.\n" +
      "\n" +
      "⦿     «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  },
  seo_panel_title_promo: {
    id: "seo_panel_title",
    defaultMessage: "Welcome to a new way of getting entertained",
  },
  seo_panel_subtitle_promo: {
    id: "seo_panel_subtitle_promo",
    defaultMessage:
      "Bettilt an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  },
  seo_panel_text_promo: {
    id: "seo_panel_text_promo",
    defaultMessage:
      "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86.\n" +
      "\n" +
      "⦿     «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. \n" +
      "\n" +
      "⦿     «Account» is an account opened by You on the website after accepting and agreeing to this «Terms».\n" +
      "\n" +
      "⦿     «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bettilt.\n" +
      "\n" +
      "⦿     «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  },
  history_filter_title: {
    id: "history_filter_title",
    defaultMessage: "Filter",
  },
  forgot_your_password: {
    id: "forgot_your_password",
    defaultMessage: "Forgot your password?",
  },
  password_updated: {
    id: "password_updated",
    defaultMessage: "Password updated",
  },
  password_changed: {
    id: "password_changed",
    defaultMessage:
      "Your password was successfully changed. Your can now use your new password to sign in to your account.",
  },
  reset_new_password: {
    id: "reset_new_password",
    defaultMessage: "New password",
  },
  repeat_reset_new_password: {
    id: "repeat_reset_new_password",
    defaultMessage: "Confirm your new password",
  },
  submit_reset_password: {
    id: "submit_reset_password",
    defaultMessage: "Update your password",
  },
  reset_link_sent: {
    id: "reset_link_sent",
    defaultMessage: "Reset link is sent",
  },
  check_your_email: {
    id: "check_your_email",
    defaultMessage: "Please check your email and follow the confirmation link.",
  },
  check_your_spam: {
    id: "check_your_spam",
    defaultMessage:
      "Can’t find the email in your inbox? Check your spam/ junk folder or contact our support team.",
  },
  no: {
    id: "no",
    defaultMessage: "No",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
  },
  ok: {
    id: "ok",
    defaultMessage: "OK",
  },
  request_created: {
    id: "request_created",
    defaultMessage: "Request created",
  },
  request_submitted: {
    id: "request_submitted",
    defaultMessage: "Your request was successfully submitted for processing.",
  },
  transactions_tab: {
    id: "transactions_tab",
    defaultMessage: "Transactions",
  },
  deposit_successful: {
    id: "deposit_successful",
    defaultMessage: "Deposit successful",
  },
  transaction_successfully_completed: {
    id: "transaction_successfully_completed",
    defaultMessage: "Your transaction has been successfully completed",
  },
  top_games: {
    id: "top_games",
    defaultMessage: "Top Games",
  },
  recently_played: {
    id: "recently_played",
    defaultMessage: "Recently Played",
  },
  favorites: {
    id: "favorites",
    defaultMessage: "Favorites",
  },
  play_and_bet: {
    id: "play_and_bet",
    defaultMessage: "Play and Bet",
  },
  profile_completed: {
    id: "profile_completed",
    defaultMessage: "Profile completed",
  },
  info_saved: {
    id: "info_saved",
    defaultMessage:
      "All information has been saved. Now you are able to play games for real money.",
  },
  make_a_deposit: {
    id: "make_a_deposit",
    defaultMessage: "Make a Deposit",
  },
  verify_account: {
    id: "verify_account",
    defaultMessage: "Verify your account",
  },
  acc_needs_verification: {
    id: "acc_needs_verification",
    defaultMessage:
      "We need to verify your account for you to be able to make withdrawals.",
  },
  upload_docs: {
    id: "upload_docs",
    defaultMessage: "Upload documents",
  },
  bonuses_my_bonuses_title: {
    id: "bonuses_my_bonuses_title",
    defaultMessage: "My Bonuses",
  },
  bonus_cancel_title: {
    id: "bonus_cancel",
    defaultMessage: "Cancel Bonus?",
  },
  bonus_cancel_confirmation: {
    id: "bonus_cancel_confirmation",
    defaultMessage: "Are you sure you want to cancel the bonus?",
  },
  bonuses_bonus_code_label: {
    id: "bonuses_bonus_code_label",
    defaultMessage: "Bonus Code",
  },
  bonuses_loyalty_points_label: {
    id: "bonuses_loyalty_points_label",
    defaultMessage: "Loyalty Points",
  },
  bonuses_available_for_exchange: {
    id: "bonuses_available_for_exchange",
    defaultMessage: "Available for eexchange",
  },
  bonuses_exchange_btn: {
    id: "bonuses_exchange_btn",
    defaultMessage: "Exchange",
  },
  modal_bonus_exchange_title: {
    id: "modal_bonus_exchange_title",
    defaultMessage: "Exchange",
  },
  exchange_rate: {
    id: "exchange_rate",
    defaultMessage: "Exchange Rate",
  },
  exchange_rate_invalid: {
    id: "exchange_rate_invalid",
    defaultMessage: "Invalid Exchange Rate",
  },
  exchange_lp_btn: {
    id: "exchange_lp_btn",
    defaultMessage: "Exchange",
  },
  exchange_form_bot_message: {
    id: "exchange_form_bot_message_{minAmount}",
    defaultMessage: "Minimum amount {minAmount} LP",
  },
  min_amount_exchange_error: {
    id: "min_amount_exchange_error_{minAmount}",
    defaultMessage: "Minimum exchange amount is {minAmount} LP",
  },
  bonuses_send_code_btn: {
    id: "bonuses_send_code_btn",
    defaultMessage: "Send",
  },
  bonuses_enter_bonus_code_placeholder: {
    id: "bonuses_enter_bonus_code_placeholder",
    defaultMessage: "Enter bonus code",
  },
  bonuses_active_bonuses_title: {
    id: "bonuses_active_bonuses_title",
    defaultMessage: "Active Bonuses",
  },
  bonuses_available_bonuses_title: {
    id: "bonuses_available_bonuses_title",
    defaultMessage: "Available Bonuses",
  },
  bonuses_bonus_history_title: {
    id: "bonuses_bonus_history_title",
    defaultMessage: "Bonus History",
  },
  bonus_table_bonus: {
    id: "bonus_table_bonus",
    defaultMessage: "Bonus",
  },
  bonus_cancellation_not_available: {
    id: "bonus_cancellation_not_available_{bonusLinkToChat}",
    defaultMessage:
      "Please write a message to our support to cancel this bonus. {bonusLinkToChat}",
  },
  bonus_cancellation_title: {
    id: "bonus_cancellation_title",
    defaultMessage: "Cancel this bonus",
  },
  bonus_cancellation_support: {
    id: "bonus_cancellation_support",
    defaultMessage: "Support",
  },
  bonus_table_status: {
    id: "bonus_table_status",
    defaultMessage: "Status",
  },
  bonus_table_expire: {
    id: "bonus_table_expire",
    defaultMessage: "Expire",
  },
  bonus_table_amount: {
    id: "bonus_table_amount",
    defaultMessage: "Amount",
  },
  bonus_table_wagering_requirement: {
    id: "bonus_table_wagering_requirement",
    defaultMessage: "Wagering requirement",
  },
  bonus_table_amount_wagered: {
    id: "bonus_table_amount_wagered",
    defaultMessage: "Amount wagered",
  },
  bonuses_type_a_promo_code_and_get_discount: {
    id: "bonuses_type_a_promo_code_and_get_discount",
    defaultMessage: "Type a promo code and get discount",
  },
  bonuses_exchange_your_loyalty_bonuses_for_real_money: {
    id: "bonuses_exchange_your_loyalty_bonuses_for_real_money",
    defaultMessage: "Exchange your loyalty bonuses for real money",
  },
  bonuses_active_bonuses_table_empty_content: {
    id: "bonuses_active_bonuses_table_empty_content",
    defaultMessage: "No active bonuses found",
  },
  bonuses_available_bonuses_table_empty_content: {
    id: "bonuses_available_bonuses_table_empty_content",
    defaultMessage: "No available bonuses found",
  },
  bonuses_bonus_history_table_empty_content: {
    id: "bonuses_bonus_history_table_empty_content",
    defaultMessage: "No old bonuses found",
  },
  invalid_username: {
    id: "invalid_username",
    defaultMessage: "Invalid username",
  },
  required_username: {
    id: "required_username",
    defaultMessage: "Required username",
  },
  login_must_be_longer: {
    id: "login_must_be_longer",
    defaultMessage: "Login must be longer",
  },
  login_must_be_shorter: {
    id: "login_must_be_shorter",
    defaultMessage: "Login must be shorter",
  },
  invalid_password: {
    id: "invalid_password",
    defaultMessage: "Invalid password",
  },
  required_password: {
    id: "required_password",
    defaultMessage: "Required password",
  },
  password_must_be_longer: {
    id: "password_must_be_longer",
    defaultMessage: "Password must be longer",
  },
  password_must_be_shorter: {
    id: "password_must_be_shorter",
    defaultMessage: "Password must be shorter",
  },
  passwords_must_match: {
    id: "passwords_must_match",
    defaultMessage: "Passwords must match",
  },
  passwords_must_not_match: {
    id: "passwords_must_not_match",
    defaultMessage: "Passwords must not match",
  },
  password_must_contain_at_least_one_number: {
    id: "password_must_contain_at_least_one_number",
    defaultMessage: "Password must contain at least one number",
  },
  should_not_match_cyrillic_characters: {
    id: "should_not_match_cyrillic_characters",
    defaultMessage: "Should not match cyrillic characters",
  },
  time: {
    id: "time",
    defaultMessage: "Time",
  },
  ref_number: {
    id: "ref_number",
    defaultMessage: "Ref#",
  },
  type: {
    id: "type",
    defaultMessage: "Type",
  },
  payment_method: {
    id: "payment_method",
    defaultMessage: "Payment Method",
  },
  status: {
    id: "status",
    defaultMessage: "Status",
  },
  sum: {
    id: "sum",
    defaultMessage: "Sum",
  },
  date_and_time: {
    id: "date_and_time",
    defaultMessage: "Date and Time",
  },
  bet_id: {
    id: "bet_id",
    defaultMessage: "Bet ID",
  },
  bet_type: {
    id: "bet_type",
    defaultMessage: "Bet Type",
  },
  bet_amount: {
    id: "bet_amount",
    defaultMessage: "Bet Amount",
  },
  game_name: {
    id: "game_name",
    defaultMessage: "Game Name",
  },
  win_amount: {
    id: "win_amount",
    defaultMessage: "Win Amount",
  },
  bonus_amount: {
    id: "bonus_amount",
    defaultMessage: "Bonus Amount",
  },
  amount: {
    id: "amount",
    defaultMessage: "Amount",
  },
  invalid_email: {
    id: "invalid_email",
    defaultMessage: "Invalid email",
  },
  required_email: {
    id: "required_email",
    defaultMessage: "Required email",
  },
  terms_and_conditions_link: {
    id: "terms_and_conditions_link",
    defaultMessage: "Terms and conditions",
  },
  learn_more: {
    id: "learn_more",
    defaultMessage: "Learn more",
  },
  reg_form_username: {
    id: "reg_form_username",
    defaultMessage: "reg_form_username",
  },
  invalid_phone: {
    id: "invalid_phone",
    defaultMessage: "Invalid phone",
  },
  required_phone: {
    id: "required_phone",
    defaultMessage: "Required phone",
  },
  reg_form_email: {
    id: "reg_form_email",
    defaultMessage: "reg_form_email",
  },
  reg_form_phone: {
    id: "reg_form_phone",
    defaultMessage: "reg_form_phone",
  },
  reg_form_password: {
    id: "reg_form_password",
    defaultMessage: "reg_form_password",
  },
  reg_form_submit: {
    id: "reg_form_submit",
    defaultMessage: "Create Account",
  },
  reg_form_currency_placeholder: {
    id: "reg_form_currency_placeholder",
    defaultMessage: "Currency",
  },
  i_agree_to_the_therms_label: {
    id: "i_agree_to_the_{thermsLink}",
    defaultMessage: "I agree to the {thermsLink}",
  },
  read_privacy_policy_label: {
    id: "read_privacy_policy_{policyLink}",
    defaultMessage:
      "Please read our {policyLink} to understand how we plan to use your personal information",
  },
  cookies_policy_label: {
    id: "cookies_policy_label_{linkToCookiePolicy}",
    defaultMessage:
      "We use cookies to ensure that we give you the best experience on our website. {linkToCookiePolicy}.",
  },
  cookies_allow: {
    id: "cookies_allow",
    defaultMessage: "Allow cookies",
  },
  cookies_decline: {
    id: "cookies_decline",
    defaultMessage: "Decline",
  },
  privacy_statements_title: {
    id: "privacy_statements_title",
    defaultMessage: "Privacy Statements",
  },
  privacy_statements_text: {
    id: "privacy_statements_text_{privacyStatementsTitle}",
    defaultMessage:
      "{privacyStatementsTitle} I agree to receive information about special promotions and special events by email.",
  },
  privacy_confirm: {
    id: "privacy_confirm",
    defaultMessage: "Confirm",
  },
  privacy_decline: {
    id: "privacy_decline",
    defaultMessage: "Not now",
  },
  pending_withdrawals: {
    id: "pending_withdrawals",
    defaultMessage: "Pending Withdrawals",
  },
  transactions_history: {
    id: "transactions_history",
    defaultMessage: "Transactions History",
  },
  table_details_winner_status: {
    id: "table_details_winner_status",
    defaultMessage: "Winner Status",
  },
  table_details_order_status: {
    id: "table_details_order_status",
    defaultMessage: "Order Status",
  },
  table_details_date: {
    id: "table_details_date",
    defaultMessage: "Date",
  },
  table_details_event_name: {
    id: "table_details_event_name",
    defaultMessage: "Event Name",
  },
  table_details_amount: {
    id: "table_details_amount",
    defaultMessage: "Amount",
  },
  table_details_win_amount: {
    id: "table_details_win_amount",
    defaultMessage: "Win Amount",
  },
  table_details_max_win_amount: {
    id: "table_details_max_win_amount",
    defaultMessage: "Max Win Amount",
  },
  table_details_factor: {
    id: "table_details_factor",
    defaultMessage: "Factor",
  },
  table_details_win_status_finished: {
    id: "table_details_win_status_finished",
    defaultMessage: "finished",
  },
  table_details_win_status_pending: {
    id: "table_details_win_status_pending",
    defaultMessage: "pending",
  },
  table_details_win_status_canceled: {
    id: "table_details_win_status_canceled",
    defaultMessage: "canceled",
  },
  table_details_win_status_won: {
    id: "table_details_win_status_won",
    defaultMessage: "won",
  },
  table_details_result: {
    id: "table_details_result",
    defaultMessage: "Result",
  },
  table_details_win_status: {
    id: "table_details_win_status",
    defaultMessage: "Win status",
  },
  table_details_order_amount: {
    id: "table_details_order_amount",
    defaultMessage: "Order amount",
  },
  table_details_no_details_available: {
    id: "table_details_no_details_available",
    defaultMessage: "No details available",
  },
  thematic_categories: {
    id: "thematic_categories",
    defaultMessage: "Thematic Categories",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  dropdown_select_date_of_birth_year: {
    id: "dropdown_select_date_of_birth_year",
    defaultMessage: "Year",
  },
  dropdown_select_date_of_birth_month: {
    id: "dropdown_select_date_of_birth_month",
    defaultMessage: "Month",
  },
  dropdown_select_date_of_birth_day: {
    id: "dropdown_select_date_of_birth_day",
    defaultMessage: "Day",
  },
  error_dont_accept_terms: {
    id: "error_dont_accept_terms",
    defaultMessage: "Must Accept Terms and Conditions",
  },
  must_be_a_number: {
    id: "must_be_a_number",
    defaultMessage: "Must be a number",
  },
  big_win_with: {
    id: "big_win_with",
    defaultMessage: "big win with",
  },
  jackpots: {
    id: "jackpots",
    defaultMessage: "jackpots",
  },
  your_favourite: {
    id: "your_favourite",
    defaultMessage: "your favourite",
  },
  online_casino: {
    id: "online_casino",
    defaultMessage: "online casino",
  },
  go_bet_my_friend: {
    id: "go_bet_my_friend",
    defaultMessage: "Go bet my friend",
  },
  on_sports: {
    id: "on_sports",
    defaultMessage: "on sports!",
  },
  get_it_on: {
    id: "get_it_on",
    defaultMessage: "Get it on",
  },
  only_chrome: {
    id: "only_chrome",
    defaultMessage: "Chrome browser only",
  },
  bettilt_application: {
    id: "bettilt_application",
    defaultMessage: "Bettilt application",
  },
  get_it_now: {
    id: "get_it_now",
    defaultMessage: "Get it now on your phone",
  },
  ios: {
    id: "ios",
    defaultMessage: "iOS",
  },
  android: {
    id: "android",
    defaultMessage: "Android",
  },
  desktop: {
    id: "desktop",
    defaultMessage: "Desktop",
  },
  chrome_browser_only: {
    id: "chrome_browser_only",
    defaultMessage: "Chrome browser only",
  },
  more_advantages: {
    id: "more_advantages",
    defaultMessage: "More advantages in mobile app",
  },
  secure_data: {
    id: "secure_data",
    defaultMessage: "Secure data",
  },
  permanent_access: {
    id: "permanent_access",
    defaultMessage: "Permanent access",
  },
  no_space_req: {
    id: "no_space_req",
    defaultMessage: "No space requirement",
  },
  make_a_bet: {
    id: "make_a_bet",
    defaultMessage: "Make a bet right now",
  },
  get_access_to_bettilt: {
    id: "get_access_to_bettilt",
    defaultMessage: "Get access to Bettilt quickly and easily.",
  },
  get_it_on_android: {
    id: "get_it_on_android",
    defaultMessage: "Get it on Android",
  },
  install_app: {
    id: "install_app",
    defaultMessage: "Play anytime, anywhere! Ease of installation and use.",
  },
  install_app_mob: {
    id: "install_app_mob",
    defaultMessage: "Ease of installation and use",
  },
  install: {
    id: "install",
    defaultMessage: "Install",
  },
  install_now: {
    id: "install_now",
    defaultMessage: "Install now",
  },
  verification_phone_modal_title: {
    id: "verification_phone_modal_title",
    defaultMessage: "We just sent an SMS",
  },
  verification_phone_modal_placeholder: {
    id: "verification_phone_modal_placeholder",
    defaultMessage: "Enter the 4-digit code",
  },
  verification_phone_modal_content: {
    id: "verification_phone_modal_content",
    defaultMessage:
      "A 6-digit SMS code was sent to: {phone}. Please enter the code in the field below to confirm:",
  },
  verification_phone_modal_verify_btn: {
    id: "verification_phone_modal_verify_btn",
    defaultMessage: "Verify",
  },
  verification_phone_modal_resend_code_btn: {
    id: "verification_phone_modal_resend_code_btn",
    defaultMessage: "Resend Code",
  },
  verify_phone_require_four_digit_code: {
    id: "verify_phone_require_four_digit_code",
    defaultMessage: "Code should be 4-digit",
  },
  verify_phone_code_required: {
    id: "verify_phone_code_required",
    defaultMessage: "Code is required",
  },
  sign_up: {
    id: "sign_up",
    defaultMessage: "Sign Up",
  },
  dont_have_an_account: {
    id: "dont_have_an_account",
    defaultMessage: "Don't have an account?",
  },
  no_bonus: {
    id: "no_bonus",
    defaultMessage: "No bonus",
  },
  take_no_bonuses: {
    id: "take_no_bonuses",
    defaultMessage: "Take no bonuses",
  },
  available_bonuses: {
    id: "available_bonuses",
    defaultMessage: "available bonuses",
  },
  scan_qr: {
    id: "scan_qr",
    defaultMessage: "Scan QR code to Install App",
  },
  use_camera: {
    id: "use_camera",
    defaultMessage: "Use your phone’s camera to scan",
  },
  browser_install: {
    id: "browser_install",
    defaultMessage:
      "App Store is not allowing casino apps, so we use an innovative installation right from your browser.",
  },
  how_add_to_home: {
    id: "how_add_to_home_{icon}",
    defaultMessage: "Just tap {icon} then «Add to Home Screen»",
  },
  get_app_installer: {
    id: "get_app_installer",
    defaultMessage: "Get App Installer",
  },
  get_it_on_ios: {
    id: "get_it_on_ios",
    defaultMessage: "Get it on IOS",
  },
  slides_not_loaded: {
    id: "slides_not_loaded",
    defaultMessage: "Slides not loaded",
  },
  website_under_maintenance: {
    id: "website_under_maintenance",
    defaultMessage: "The website is currently under maintenance",
  },
  wont_be_long: {
    id: "wont_be_long",
    defaultMessage:
      "We are working towards creating something better. We won't be long.",
  },
  unavailable_in_your_country: {
    id: "unavailable_in_your_country",
    defaultMessage: "Sorry, but Bettilt is not available in your country",
  },
  got_questions: {
    id: "got_questions",
    defaultMessage: "Should you have any questions please address them to",
  },
  seo_panel_title_landing: {
    id: "seo_panel_title_landing",
    defaultMessage: "Welcome to a new way of getting entertained",
  },
  seo_panel_subtitle_landing: {
    id: "seo_panel_subtitle_landing",
    defaultMessage:
      "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  },
  seo_panel_text_landing: {
    id: "seo_panel_text_landing",
    defaultMessage:
      "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  },
  seo_panel_title_pwalanding: {
    id: "seo_panel_title_pwalanding",
    defaultMessage: "Welcome to a new way of getting entertained",
  },
  seo_panel_subtitle_pwalanding: {
    id: "seo_panel_subtitle_pwalanding",
    defaultMessage:
      "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  },
  seo_panel_text_pwalanding: {
    id: "seo_panel_text_pwalanding",
    defaultMessage:
      "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  },
  seo_panel_title_sport: {
    id: "seo_panel_title_sport",
    defaultMessage: "Welcome to a new way of getting entertained",
  },
  seo_panel_subtitle_sport: {
    id: "seo_panel_subtitle_sport",
    defaultMessage:
      "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  },
  seo_panel_text_sport: {
    id: "seo_panel_text_sport",
    defaultMessage:
      "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  },
  seo_panel_title_casino: {
    id: "seo_panel_title_casino",
    defaultMessage: "Welcome to a new way of getting entertained",
  },
  seo_panel_subtitle_casino: {
    id: "seo_panel_subtitle_casino",
    defaultMessage:
      "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  },
  seo_panel_text_casino: {
    id: "seo_panel_text_casino",
    defaultMessage:
      "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  },
  seo_panel_title_tournaments: {
    id: "seo_panel_title_tournaments",
    defaultMessage: "Welcome to a new way of getting entertained",
  },
  seo_panel_subtitle_tournaments: {
    id: "seo_panel_subtitle_tournaments",
    defaultMessage:
      "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  },
  seo_panel_text_tournaments: {
    id: "seo_panel_text_tournaments",
    defaultMessage:
      "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  },
  cashbox_methods_title: {
    id: "cashbox_methods_title",
    defaultMessage: "Choose deposit method",
  },
  wallet: {
    id: "wallet",
    defaultMessage: "Wallet",
  },
  betting_history: {
    id: "betting_history",
    defaultMessage: "Betting history",
  },
  my_bonuses: {
    id: "my_bonuses",
    defaultMessage: "My bonuses",
  },
  verification: {
    id: "verification",
    defaultMessage: "Verification",
  },
  personal_information: {
    id: "personal_information",
    defaultMessage: "Personal information",
  },
  deposit_tab: {
    id: "deposit_tab",
    defaultMessage: "Deposit",
  },
  withdrawal_tab: {
    id: "withdrawal_tab",
    defaultMessage: "Withdrawal",
  },
  sport: {
    id: "sport",
    defaultMessage: "Sport",
  },
  casino: {
    id: "casino",
    defaultMessage: "Casino",
  },
  file_too_large: {
    id: "file_too_large",
    defaultMessage: "File too large",
  },
  unsupported_format: {
    id: "unsupported_format",
    defaultMessage: "Unsupported format",
  },
  all_games: {
    id: "all_games",
    defaultMessage: "All Games",
  },
  "amount_{minVal}_error": {
    id: "amount_{minVal}_error",
    defaultMessage: "Amount {minVal} error",
  },
  "amount_{maxVal}_error": {
    id: "amount_{maxVal}_error",
    defaultMessage: "Amount {maxVal} error",
  },
  already_have_an_account: {
    id: "already_have_an_account",
    defaultMessage: "Already have an account?",
  },
  log_in: {
    id: "log_in",
    defaultMessage: "Log In",
  },
  modal_deposit_title: {
    id: "modal_deposit_title",
    defaultMessage: "Deposit",
  },
  cahsbox_sum: {
    id: "cahsbox_sum",
    defaultMessage: "Deposit sum",
  },
  cashbox_change_method: {
    id: "cashbox_change_method",
    defaultMessage: "Change method",
  },
  cashbox_bonus_dropdown_no_bonus: {
    id: "cashbox_bonus_dropdown_no_bonus",
    defaultMessage: "I don't need a bonus",
  },
  fast_deposit_banner_title: {
    id: "fast_deposit_banner_title",
    defaultMessage: "Fast deposit",
  },
  fast_deposit_banner_title_is_cepbank: {
    id: "fast_deposit_banner_title",
    defaultMessage: "Fast deposit via Multibanco",
  },
  withdrawal_is_communitybanking_3: {
    id: "withdrawal_is_communitybanking_3",
    defaultMessage: "®Trademark of Interac Corp. Used under license.",
  },
  withdrawal_is_mbwayhp: {
    id: "withdrawal_is_mbwayhp",
    defaultMessage: "®Trademark of Interac Corp. Used under license.",
  },
  deposit_is_cashlib: {
    id: "withdrawal_is_cashlib",
    defaultMessage:
      "POINT66 is a point service based Local Payment & Credit Card.",
  },
  deposit_is_cashlib_text_link: {
    id: "withdrawal_is_cashlib_text_link",
    defaultMessage: "Don't have an account? Sign Up Now! [point66.com.",
  },
  cashbox_details: {
    id: "cashbox_details",
    defaultMessage: "Details",
  },
  cashbox_next: {
    id: "cashbox_next",
    defaultMessage: "Next",
  },
  cashbox_continue_without_bonus: {
    id: "cashbox_continue_without_bonus",
    defaultMessage: "Continue without bonus",
  },
  cashbox_deposit: {
    id: "cashbox_deposit",
    defaultMessage: "Deposit",
  },
  deposit_bonus: {
    id: "deposit_bonus",
    defaultMessage: "Deposit bonus",
  },
  tran_type: {
    id: "tran_type",
    defaultMessage: "Transaction type",
  },
  profile_update_successful: {
    id: "profile_update_successful",
    defaultMessage: "Profile updated successfuly.",
  },
  no_casino_link_to_show: {
    id: "no_casino_link_to_show",
    defaultMessage: "Casino link failed to load",
  },
  loading: {
    id: "loading",
    defaultMessage: "Loading",
  },
  install_app_title: {
    id: "install_app_title",
    defaultMessage: "Bettilt app",
  },
  cashbox_sum: {
    id: "cashbox_sum",
    defaultMessage: "Amount Sum",
  },
  available_to_cashout: {
    id: "available_to_cashout",
    defaultMessage: "Available to cash out",
  },
  the_lowest_amount: {
    id: "the_lowest_amount",
    defaultMessage: "The lowest amount to withdraw is",
  },
  reject_active_bonuses_title: {
    id: "reject_active_bonuses_title",
    defaultMessage: "reject_active_bonuses_title",
  },
  reject_active_bonuses_description: {
    id: "reject_active_bonuses_description",
    defaultMessage: "reject_active_bonuses_description",
  },
  reject_active_bonuses_btn: {
    id: "reject_active_bonuses_btn",
    defaultMessage: "reject_active_bonuses_btn",
  },
  cashbox_withdrawal: {
    id: "cashbox_withdrawal",
    defaultMessage: "cashbox_withdrawal",
  },
  modal_login_title: {
    id: "modal_login_title",
    defaultMessage: "Sign In",
  },
  modal_tfa_process_title: {
    id: "modal_tfa_process_title",
    defaultMessage: "Enter code",
  },
  modal_tfa_process_desc: {
    id: "modal_tfa_process_desc",
    defaultMessage: "We’ve sent you with the code to your phone or email.",
  },
  header_login: {
    id: "header_login",
    defaultMessage: "Login",
  },
  header_sign_up: {
    id: "header_sign_up",
    defaultMessage: "Sign Up",
  },
  withdrawal_successful_title: {
    id: "withdrawal_successful_title",
    defaultMessage: "Withdrawal successful",
  },
  withdrawal_successfully_completed: {
    id: "withdrawal_successfully_completed",
    defaultMessage: "Withdrawal successfully completed",
  },
  withdrawal_successful_btn_ok: {
    id: "withdrawal_successful_btn_ok",
    defaultMessage: "Ok",
  },
  deposit_successful_btn_ok: {
    id: "deposit_successful_btn_ok",
    defaultMessage: "Ok",
  },
  deposit_now: {
    id: "deposit_now",
    defaultMessage: "Deposit Now",
  },
  read_more: {
    id: "read_more",
    defaultMessage: "Read More",
  },
  bankName: {
    id: "bankName",
    defaultMessage: "Bank name",
  },
  GarantiBankasi: {
    id: "GarantiBankasi",
    defaultMessage: "GarantiBankasi",
  },
  AkbankHavale: {
    id: "AkbankHavale",
    defaultMessage: "AkbankHavale",
  },
  IsBankasiHavale: {
    id: "IsBankasiHavale",
    defaultMessage: "IsBankasiHavale",
  },
  YapikrediHavale: {
    id: "YapikrediHavale",
    defaultMessage: "YapikrediHavale",
  },
  VakifBankHavale: {
    id: "VakifBankHavale",
    defaultMessage: "VakifBankHavale",
  },
  ZiraatbankHavale: {
    id: "ZiraatbankHavale",
    defaultMessage: "ZiraatbankHavale",
  },
  FinansBank: {
    id: "FinansBank",
    defaultMessage: "FinansBank",
  },
  DenizBankHavale: {
    id: "DenizBankHavale",
    defaultMessage: "DenizBankHavale",
  },
  INGBankHavale: {
    id: "INGBankHavale",
    defaultMessage: "INGBankHavale",
  },
  TEBBankHavale: {
    id: "TEBBankHavale",
    defaultMessage: "TEBBankHavale",
  },
  HalkBankHavale: {
    id: "HalkBankHavale",
    defaultMessage: "HalkBankHavale",
  },
  DigerBankHavale: {
    id: "DigerBankHavale",
    defaultMessage: "DigerBankHavale",
  },
  Papara: {
    id: "Papara",
    defaultMessage: "Papara",
  },
  "Kuveyt Türk": {
    id: "Kuveyt Türk",
    defaultMessage: "Kuveyt Türk",
  },
  accountNumber: {
    id: "accountNumber",
    defaultMessage: "accountNumber",
  },
  cellPhone: {
    id: "cellPhone",
    defaultMessage: "cellPhone",
  },
  ibanNumber: {
    id: "ibanNumber",
    defaultMessage: "ibanNumber",
  },
  tcIdentNumber: {
    id: "tcIdentNumber",
    defaultMessage: "tcIdentNumber",
  },
  footer_menu_title: {
    id: "footer_menu_title",
    defaultMessage: "Menu",
  },
  footer_info_title: {
    id: "footer_info_title",
    defaultMessage: "Info",
  },
  menu: {
    id: "menu",
    defaultMessage: "menu",
  },
  info: {
    id: "info",
    defaultMessage: "info",
  },
  casino_favorites_description: {
    id: "casino_favorites_description",
    defaultMessage: "casino_favorites_description",
  },
  casino_new_product_description: {
    id: "casino_new_product_description",
    defaultMessage: "casino_new_product_description",
  },
  casino_most_popular_description: {
    id: "casino_most_popular_description",
    defaultMessage: "casino_most_popular_description",
  },
  casino_features_description: {
    id: "casino_features_description",
    defaultMessage: "casino_features_description",
  },
  casino_live_casino_description: {
    id: "casino_live_casino_description",
    defaultMessage: "casino_live_casino_description",
  },
  casino_jackpot_description: {
    id: "casino_jackpot_description",
    defaultMessage: "casino_jackpot_description",
  },
  casino_blackjack_description: {
    id: "casino_blackjack_description",
    defaultMessage: "casino_blackjack_description",
  },
  swift: {
    id: "swift",
    defaultMessage: "swift",
  },
  iban: {
    id: "iban",
    defaultMessage: "iban",
  },
  branchCode: {
    id: "branchCode",
    defaultMessage: "branchCode",
  },
  bankAddress: {
    id: "bankAddress",
    defaultMessage: "bankAddress",
  },
  deposit_completed_btn_ok: {
    id: "deposit_completed_btn_ok",
    defaultMessage: "Continue",
  },
  deposit_completed_description: {
    id: "deposit_completed_description",
    defaultMessage: "Deposit completed description",
  },
  deposit_completed_title: {
    id: "deposit_completed_title",
    defaultMessage: "Request created",
  },
  deposit_completed_text: {
    id: "deposit_completed_text",
    defaultMessage: "Your transaction number",
  },
  january: {
    id: "january",
    defaultMessage: "January",
  },
  february: {
    id: "february",
    defaultMessage: "February",
  },
  march: {
    id: "march",
    defaultMessage: "March",
  },
  april: {
    id: "april",
    defaultMessage: "April",
  },
  may: {
    id: "may",
    defaultMessage: "May",
  },
  june: {
    id: "june",
    defaultMessage: "June",
  },
  july: {
    id: "july",
    defaultMessage: "July",
  },
  august: {
    id: "august",
    defaultMessage: "August",
  },
  september: {
    id: "september",
    defaultMessage: "September",
  },
  october: {
    id: "october",
    defaultMessage: "October",
  },
  november: {
    id: "november",
    defaultMessage: "November",
  },
  december: {
    id: "december",
    defaultMessage: "December",
  },
  monday_s: {
    id: "monday_s",
    defaultMessage: "mon",
  },
  tuesday_s: {
    id: "tuesday_s",
    defaultMessage: "tues",
  },
  wednesday_s: {
    id: "wednesday_s",
    defaultMessage: "wed",
  },
  thursday_s: {
    id: "thursday_s",
    defaultMessage: "thurs",
  },
  friday_s: {
    id: "friday_s",
    defaultMessage: "fri",
  },
  saturday_s: {
    id: "saturday_s",
    defaultMessage: "sat",
  },
  sunday_s: {
    id: "sunday_s",
    defaultMessage: "sun",
  },
  tran_type_all: {
    id: "tran_type_all",
    defaultMessage: "All",
  },
  tran_type_game_bet: {
    id: "tran_type_game_bet",
    defaultMessage: "Game Bet",
  },
  tran_type_game_win: {
    id: "tran_type_game_win",
    defaultMessage: "Game Win",
  },
  accountId: {
    id: "accountId",
    defaultMessage: "accountId",
  },
  error_bankName_to_short: {
    id: "error_bankName_to_short",
    defaultMessage: "error_bankName_to_short",
  },
  error_bankName_to_long: {
    id: "error_bankName_to_long",
    defaultMessage: "error_bankName_to_long",
  },
  error_bankAddress_to_short: {
    id: "error_bankAddress_to_short",
    defaultMessage: "error_bankAddress_to_short",
  },
  error_bankAddress_to_long: {
    id: "error_bankAddress_to_long",
    defaultMessage: "error_bankAddress_to_long",
  },
  error_branchCode_must_be_number: {
    id: "error_branchCode_must_be_number",
    defaultMessage: "error_branchCode_must_be_number",
  },
  error_swift_no_match_pattern: {
    id: "error_swift_no_match_pattern",
    defaultMessage: "error_swift_no_match_pattern",
  },
  error_iban_invalid: {
    id: "error_iban_invalid",
    defaultMessage: "error_iban_invalid",
  },
  reg_banner_title: {
    id: "reg_banner_title",
    defaultMessage: "title",
  },
  reg_banner_description: {
    id: "reg_banner_description",
    defaultMessage: "Description",
  },
  birth_date_limit: {
    id: "birth_date_limit_{min_years_old}",
    defaultMessage: "You must be over {min_years_old} years old",
  },
  install_pwa_error_title: {
    id: "install_pwa_error_title",
    defaultMessage: "install_pwa_error_title",
  },
  install_pwa_error_text: {
    id: "install_pwa_error_text",
    defaultMessage: "install_pwa_error_text",
  },
  install_pwa_error_bottom_text: {
    id: "install_pwa_error_bottom_text",
    defaultMessage: "install_pwa_error_bottom_text",
  },
  install_pwa_error_btn_text: {
    id: "install_pwa_error_btn_text",
    defaultMessage: "install_pwa_error_btn_text",
  },
  sport_page_title: {
    id: "sport_page_title",
    defaultMessage: "sport_page_title",
  },
  landing_page_title: {
    id: "landing_page_title",
    defaultMessage: "landing_page_title",
  },
  landing_page_title_without_locale: {
    id: "landing_page_title_without_locale",
    defaultMessage: "landing_page_title_without_locale",
  },
  euro2020_header_title: {
    id: "euro2020_header_title",
    defaultMessage: "EURO 2020",
  },
  euro2020_header_desc: {
    id: "euro2020_header_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis etiam ultricies turpis sit pellentesque eget lectus tortor.",
  },
  euro2020_header_join: {
    id: "euro2020_header_join",
    defaultMessage: "How to join",
  },
  euro2020_header_leaderboard: {
    id: "euro2020_header_leaderboard",
    defaultMessage: "Leaderboard",
  },
  euro2020_how_to_join_title: {
    id: "euro2020_how_to_join_title",
    defaultMessage: "Become a participant of this promotion",
  },
  euro2020_item_1_title: {
    id: "euro2020_item_1_title",
    defaultMessage: "Congue suspendisse tortor.",
  },
  euro2020_item_1_desc: {
    id: "euro2020_item_1_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing magna libero at id eget placerat rhoncus donec sed pretium.",
  },
  euro2020_item_2_title: {
    id: "euro2020_item_2_title",
    defaultMessage: "Massa hendrerit sagittis eu.",
  },
  euro2020_item_2_desc: {
    id: "euro2020_item_2_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit turpis adipiscing.",
  },
  euro2020_item_3_title: {
    id: "euro2020_item_3_title",
    defaultMessage: "Bibendum felis id dui.",
  },
  euro2020_item_3_desc: {
    id: "euro2020_item_3_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing magna libero at id eget placerat rhoncus donec sed pretium.",
  },
  euro2020_prizes_title: {
    id: "euro2020_prizes_title",
    defaultMessage: "Pool of prizes",
  },
  euro2020_prize_1_title: {
    id: "euro2020_prize_1_title",
    defaultMessage:
      "Travel to final of <highlight>EURO 2020</highlight> in London",
  },
  euro2020_prize_1_desc: {
    id: "euro2020_prize_1_desc",
    defaultMessage: "Fly tickets, Hotel and 2 tickets for final VIP places",
  },
  euro2020_prize_2_title: {
    id: "euro2020_prize_2_title",
    defaultMessage: "iPhone 12 Pro",
  },
  euro2020_prize_3_title: {
    id: "euro2020_prize_3_title",
    defaultMessage: "PlayStation 5 + FIFA2020",
  },
  euro2020_prize_4_title: {
    id: "euro2020_prize_4_title",
    defaultMessage: "National team jersey",
  },
  euro2020_prize_4_desc: {
    id: "euro2020_prize_4_desc",
    defaultMessage: "One per winner",
  },
  euro2020_prize_5_title: {
    id: "euro2020_prize_5_title",
    defaultMessage: "Custom T-shirt Bettilt",
  },
  euro2020_prize_5_desc: {
    id: "euro2020_prize_5_desc",
    defaultMessage:
      "Produced by Alexandre Santos (Ambassador Bettilt in Portugal)",
  },
  euro2020_places_points: {
    id: "euro2020_places_points",
    defaultMessage: "Points",
  },
  euro2020_places_place: {
    id: "euro2020_places_place",
    defaultMessage: "place",
  },
  euro2020_leaderboard_title: {
    id: "euro2020_leaderboard_title",
    defaultMessage: "Leaderboard",
  },
  euro2020_leaderboard_place: {
    id: "euro2020_leaderboard_place",
    defaultMessage: "Place",
  },
  euro2020_leaderboard_username: {
    id: "euro2020_leaderboard_username",
    defaultMessage: "Username",
  },
  euro2020_leaderboard_points: {
    id: "euro2020_leaderboard_points",
    defaultMessage: "Points",
  },
  euro2020_leaderboard_username_points: {
    id: "euro2020_leaderboard_username_points",
    defaultMessage: "Username / Points",
  },
  euro2020_leaderboard_prize: {
    id: "euro2020_leaderboard_prize",
    defaultMessage: "Prize",
  },
  euro2020_leaderboard_show_more: {
    id: "euro2020_leaderboard_show_more",
    defaultMessage: "Show more",
  },
  tournament_timer_title: {
    id: "tournament_timer_title",
    defaultMessage: "Time left before finish:",
  },
  tournament_timer_link: {
    id: "tournament_timer_link",
    defaultMessage: "Play now",
  },
  tournament_item_view_prizes: {
    id: "tournament_item_view_prizes",
    defaultMessage: "View prizes",
  },
  tournament_timer_button: {
    id: "tournament_timer_button",
    defaultMessage: "How to play",
  },
  tournament_table_place: {
    id: "tournament_table_place",
    defaultMessage: "Place",
  },
  tournament_table_player: {
    id: "tournament_table_player",
    defaultMessage: "Player",
  },
  tournament_table_points: {
    id: "tournament_table_points",
    defaultMessage: "Points",
  },
  tournament_table_prize: {
    id: "tournament_table_prize",
    defaultMessage: "Prize",
  },
  finished_tournament_item_label: {
    id: "finished_tournament_item_label",
    defaultMessage: "Tournaments Finished",
  },
  tournament_hero_live: {
    id: "tournament_hero_live",
    defaultMessage: "Live",
  },
  tournament_hero_inactive: {
    id: "tournament_hero_inactive",
    defaultMessage: "Inactive",
  },
  tournament_info_back: {
    id: "tournament_info_back",
    defaultMessage: "Back",
  },
  tournament_info_rules: {
    id: "tournament_info_rules",
    defaultMessage: "Rules and Conditions:",
  },
  tournament_info_prizes: {
    id: "tournament_info_prizes",
    defaultMessage: "Prize places:",
  },
  tournament_info_games: {
    id: "tournament_info_games",
    defaultMessage: "Tournament Games",
  },
  tournament_info_show_more: {
    id: "tournament_info_show_more",
    defaultMessage: "Show more",
  },
  tournament_prize_loyalty_point: {
    id: "tournament_prize_loyalty_point_{value}",
    defaultMessage: "{value} LP Bonus Gift",
  },
  tournaments_page_title: {
    id: "tournaments_page_title",
    defaultMessage: "Tournaments",
  },
  tournaments_current: {
    id: "tournaments_current",
    defaultMessage: "Current",
  },
  tournaments_uncoming: {
    id: "tournaments_uncoming",
    defaultMessage: "Uncoming",
  },
  tournaments_finished: {
    id: "tournaments_finished",
    defaultMessage: "Finished",
  },
  tournaments_not_found: {
    id: "tournaments_not_found",
    defaultMessage: "Not found",
  },
  tournament_prizes_title: {
    id: "tournament_prizes_title",
    defaultMessage: "Prize places",
  },
  tournament_how_to_play_title: {
    id: "tournament_how_to_play_title",
    defaultMessage: "How to play",
  },
  tournament_rule_1_title: {
    id: "tournament_rule_1_title",
    defaultMessage: "Availability And Restrictions",
  },
  tournament_rule_1_text: {
    id: "tournament_rule_1_text",
    defaultMessage:
      "Check the counter if enough time to join and if there are any restrictions to join.",
  },
  tournament_rule_2_title: {
    id: "tournament_rule_2_title",
    defaultMessage: "Win Mechanics",
  },
  tournament_rule_2_text: {
    id: "tournament_rule_2_text",
    defaultMessage:
      "Amount - the more you bet, the higher your tournament position. The more you win, the higher you are.",
  },
  tournament_rule_3_title: {
    id: "tournament_rule_3_title",
    defaultMessage: "Bets",
  },
  tournament_rule_3_text: {
    id: "tournament_rule_3_text",
    defaultMessage:
      "Bet limitations are described in the tournament rules. If you bet less than stated, your bets won to be counted.",
  },
  tournament_rule_4_title: {
    id: "tournament_rule_4_title",
    defaultMessage: "Tournament Games",
  },
  tournament_rule_4_text: {
    id: "tournament_rule_4_text",
    defaultMessage:
      "Tournament games are presented on the tournament page. If you bet in other games, these bets will not be counted.",
  },
  tournament_rule_5_title: {
    id: "tournament_rule_5_title",
    defaultMessage: "Tournament Places",
  },
  tournament_rule_5_text: {
    id: "tournament_rule_5_text",
    defaultMessage:
      "Defeat your opponents by following the tournament rules. Take one of the prize places to get a reward.",
  },
  tournament_widget_title: {
    id: "tournament_widget_title",
    defaultMessage: "Tournaments",
  },
  tournament_widget_link: {
    id: "tournament_widget_link",
    defaultMessage: "Show All",
  },
  fifa_calendar_page_title: {
    id: "fifa_calendar_page_title",
    defaultMessage: "FIFA Gift Calendar",
  },
  fifa_calendar_page_desc: {
    id: "fifa_calendar_page_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis etiam ultricies turpis sit pellentesque eget lectus tortor.",
  },
  fifa_calendar_page_btn: {
    id: "fifa_calendar_page_btn",
    defaultMessage: "How it works",
  },
  fifa_gift_2_name: {
    id: "fifa_gift_2_name",
    defaultMessage: "Prize name in two lines or three",
  },
  fifa_gift_2_desc: {
    id: "fifa_gift_2_desc",
    defaultMessage: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  gift_card_label: {
    id: "gift_card_label",
    defaultMessage: "Available on",
  },
  gift_card_expired: {
    id: "gift_card_expired",
    defaultMessage: "Prize expired",
  },
  gift_countdown_label: {
    id: "gift_countdown_label",
    defaultMessage: "Time left:",
  },
  gift_card_secret: {
    id: "gift_card_secret",
    defaultMessage: "Not available now",
  },
  gift_card_btn: {
    id: "gift_card_btn",
    defaultMessage: "Claim",
  },
  gift_card_read_more: {
    id: "gift_card_read_more",
    defaultMessage: "Read More",
  },
  get_gift_modal_title: {
    id: "get_gift_modal_title",
    defaultMessage: "How to reiceve bonus",
  },
  get_gift_modal_desc: {
    id: "get_gift_modal_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In interdum purus massa egestas",
  },
  get_gift_modal_chat: {
    id: "get_gift_modal_chat",
    defaultMessage: "Live Chat",
  },
  get_gift_modal_deposit: {
    id: "get_gift_modal_deposit",
    defaultMessage: "Deposit Now",
  },
  christmas_calendar_date_start: {
    id: "christmas_calendar_date_start",
    defaultMessage: "2021-11-01",
  },
  christmas_calendar_date_end: {
    id: "christmas_calendar_date_end",
    defaultMessage: "2021-11-24",
  },
  christmas_calendar_gift_15: {
    id: "christmas_calendar_gift_15",
    defaultMessage: "Prize name in one or two lines",
  },
  christmas_modal_close_btn_text: {
    id: "christmas_modal_close_btn_text",
    defaultMessage: "Close X",
  },
  events_calendar_page_title: {
    id: "events_calendar_page_title",
    defaultMessage: "BNP Paribas Open",
  },
  events_calendar_page_desc: {
    id: "events_calendar_page_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis etiam ultricies turpis sit pellentesque eget lectus tortor.",
  },
  events_calendar_page_btn: {
    id: "events_calendar_page_btn",
    defaultMessage: "Tearms and conditions",
  },
  events_calendar_page_mobile_btn: {
    id: "events_calendar_page_mobile_btn",
    defaultMessage: "T&C",
  },
  event_card_live: {
    id: "event_card_live",
    defaultMessage: "Live Now",
  },
  event_card_disabled_btn: {
    id: "event_card_disabled_btn",
    defaultMessage: "Match ended",
  },
  event_card_btn: {
    id: "event_card_btn",
    defaultMessage: "Make an odd",
  },
  bnp_pb_open_calendar_date_start: {
    id: "bnp_pb_open_calendar_date_start",
    defaultMessage: "2022-03-07",
  },
  bnp_pb_open_calendar_date_end: {
    id: "bnp_pb_open_calendar_date_end",
    defaultMessage: "2022-03-10",
  },
  bnp_pb_open_calendar_event_1_name_1: {
    id: "bnp_pb_open_calendar_event_1_name_1",
    defaultMessage: "N. Djokovic",
  },
  bnp_pb_open_calendar_event_1_odds_1: {
    id: "bnp_pb_open_calendar_event_1_odds_1",
    defaultMessage: "Won",
  },
  bnp_pb_open_calendar_event_1_name_2: {
    id: "bnp_pb_open_calendar_event_1_name_2",
    defaultMessage: "R. Nadal",
  },
  bnp_pb_open_calendar_event_1_odds_2: {
    id: "bnp_pb_open_calendar_event_1_odds_2",
    defaultMessage: "Lost",
  },
  bnp_pb_open_calendar_event_1_desc: {
    id: "bnp_pb_open_calendar_event_1_desc",
    defaultMessage:
      "Lorem ipsum is the text we use for preparing text content template",
  },
  bnp_pb_open_calendar_event_2_name_1: {
    id: "bnp_pb_open_calendar_event_2_name_1",
    defaultMessage: "N. Djokovic",
  },
  bnp_pb_open_calendar_event_2_odds_1: {
    id: "bnp_pb_open_calendar_event_2_odds_1",
    defaultMessage: "1.75",
  },
  bnp_pb_open_calendar_event_2_name_2: {
    id: "bnp_pb_open_calendar_event_2_name_2",
    defaultMessage: "R. Nadal",
  },
  bnp_pb_open_calendar_event_2_odds_2: {
    id: "bnp_pb_open_calendar_event_2_odds_2",
    defaultMessage: "0.25",
  },
  bnp_pb_open_calendar_event_2_desc: {
    id: "bnp_pb_open_calendar_event_2_desc",
    defaultMessage:
      "Lorem ipsum is the text we use for preparing text content template",
  },
  bnp_pb_open_calendar_event_2_live: {
    id: "bnp_pb_open_calendar_event_2_live",
    defaultMessage: "live",
  },
  bnp_pb_open_calendar_event_3_name_1: {
    id: "bnp_pb_open_calendar_event_3_name_1",
    defaultMessage: "N. Djokovic",
  },
  bnp_pb_open_calendar_event_3_odds_1: {
    id: "bnp_pb_open_calendar_event_3_odds_1",
    defaultMessage: "1.75",
  },
  bnp_pb_open_calendar_event_3_name_2: {
    id: "bnp_pb_open_calendar_event_3_name_2",
    defaultMessage: "R. Nadal",
  },
  bnp_pb_open_calendar_event_3_odds_2: {
    id: "bnp_pb_open_calendar_event_3_odds_2",
    defaultMessage: "0.25",
  },
  bnp_pb_open_calendar_event_3_desc: {
    id: "bnp_pb_open_calendar_event_3_desc",
    defaultMessage:
      "Lorem ipsum is the text we use for preparing text content template",
  },
  bnp_pb_open_calendar_event_3_link: {
    id: "bnp_pb_open_calendar_event_3_link",
    defaultMessage: "/sportsbook?page=/event/2112909935355502592?isLive=true",
  },
  bnp_pb_open_calendar_event_4_name_1: {
    id: "bnp_pb_open_calendar_event_4_name_1",
    defaultMessage: "N. Djokovic",
  },
  bnp_pb_open_calendar_event_4_odds_1: {
    id: "bnp_pb_open_calendar_event_4_odds_1",
    defaultMessage: "1.75",
  },
  bnp_pb_open_calendar_event_4_name_2: {
    id: "bnp_pb_open_calendar_event_4_name_2",
    defaultMessage: "R. Nadal",
  },
  bnp_pb_open_calendar_event_4_odds_2: {
    id: "bnp_pb_open_calendar_event_4_odds_2",
    defaultMessage: "0.25",
  },
  bnp_pb_open_calendar_event_4_desc: {
    id: "bnp_pb_open_calendar_event_4_desc",
    defaultMessage:
      "Lorem ipsum is the text we use for preparing text content template",
  },
  bnp_pb_open_calendar_event_1_date: {
    id: "bnp_pb_open_calendar_event_1_date",
    defaultMessage: "2022-03-14",
  },
  bnp_pb_open_calendar_event_4_date: {
    id: "bnp_pb_open_calendar_event_4_date",
    defaultMessage: "2022-03-10",
  },
  winners_title: {
    id: "winners_title",
    defaultMessage: "Latest big wins",
  },
  winners_item_text: {
    id: "winners_item_{playerName}_{amount}_{gameName}",
    defaultMessage: "{playerName} just won {amount} in {gameName}",
  },
  last_deposit_item_text: {
    id: "last_deposit_item_text_{name}_{amount}",
    defaultMessage: "{amount} deposit made via {name}",
  },
  how_it_works_btn_label: {
    id: "how_it_works_btn_label",
    defaultMessage: "How it works",
  },
  how_it_works_description_text: {
    id: "how_it_works_description_text",
    defaultMessage: "How it works description",
  },
  status_winner: {
    id: "status_winner",
    defaultMessage: "Winner",
  },
  main_prize: {
    id: "main_prize",
    defaultMessage: "Main prize",
  },
  top_three_winners: {
    id: "top_three_winners",
    defaultMessage: "Top 3 winners",
  },
  tournament_message: {
    id: "tournament_message",
    defaultMessage: "Tournament message",
  },
  modal_tfa_after_reg_title: {
    id: "modal_tfa_after_reg_title",
    defaultMessage: "Two-factor authentication",
  },
  change_tfa_data_form_continue_btn: {
    id: "change_tfa_data_form_continue_btn",
    defaultMessage: "Continue",
  },
  change_tfa_data_form_save_btn: {
    id: "change_tfa_data_form_save_btn",
    defaultMessage: "Save",
  },
  disable_popup_blocker_modal_hero_title: {
    id: "disable_popup_blocker_modal_hero_title",
    defaultMessage: "You will be redirected to the payment system website",
  },
  disable_popup_blocker_modal_hero_btn: {
    id: "disable_popup_blocker_modal_hero_btn",
    defaultMessage: "Continue",
  },
  disable_popup_blocker_modal_title: {
    id: "disable_popup_blocker_modal_title",
    defaultMessage: "Allow your browser to open popups",
  },
  disable_popup_blocker_modal_desc: {
    id: "disable_popup_blocker_modal_desc",
    defaultMessage: "for stable operation of deposit and withdrawal",
  },
  disable_popup_blocker_modal_ios: {
    id: "disable_popup_blocker_modal_ios",
    defaultMessage: "iOS (iPhone)",
  },
  disable_popup_blocker_modal_android: {
    id: "disable_popup_blocker_modal_android",
    defaultMessage: "Android",
  },
  disable_popup_blocker_modal_chrome: {
    id: "disable_popup_blocker_modal_chrome",
    defaultMessage: "Google Chrome",
  },
  disable_popup_blocker_modal_safari: {
    id: "disable_popup_blocker_modal_safari",
    defaultMessage: "Safari",
  },
  disable_popup_blocker_modal_tutorial_safari_title: {
    id: "disable_popup_blocker_modal_tutorial_safari_title",
    defaultMessage:
      "Disable the pop-up blocker in the Safari web browser on your iPhone",
  },
  disable_popup_blocker_modal_tutorial_safari_step_1: {
    id: "disable_popup_blocker_modal_tutorial_safari_step_1",
    defaultMessage: "Launch Settings",
  },
  disable_popup_blocker_modal_tutorial_safari_step_2: {
    id: "disable_popup_blocker_modal_tutorial_safari_step_2",
    defaultMessage: "Tap Safari",
  },
  disable_popup_blocker_modal_tutorial_safari_step_3: {
    id: "disable_popup_blocker_modal_tutorial_safari_step_3",
    defaultMessage:
      "Under the General section, click the toggle next to Block Pop-ups to disable the pop-up blocker",
  },
  disable_popup_blocker_modal_tutorial_chrome_ios_title: {
    id: "disable_popup_blocker_modal_tutorial_chrome_ios_title",
    defaultMessage: "Allow pop-ups in Chrome on your iPhone",
  },
  disable_popup_blocker_modal_tutorial_chrome_ios_step_1: {
    id: "disable_popup_blocker_modal_tutorial_chrome_ios_step_1",
    defaultMessage: "Open the Chrome app",
  },
  disable_popup_blocker_modal_tutorial_chrome_ios_step_2: {
    id: "disable_popup_blocker_modal_tutorial_chrome_ios_step_2",
    defaultMessage: "To the right of the address bar, tap More > Settings",
  },
  disable_popup_blocker_modal_tutorial_chrome_ios_step_3: {
    id: "disable_popup_blocker_modal_tutorial_chrome_ios_step_3",
    defaultMessage:
      "Tap Content Settings and then Block Pop-ups. Turn Block Pop-ups off",
  },
  disable_popup_blocker_modal_tutorial_chrome_android_title: {
    id: "disable_popup_blocker_modal_tutorial_chrome_android_title",
    defaultMessage: "Allow pop-ups in Chrome on your Android device",
  },
  disable_popup_blocker_modal_tutorial_chrome_android_step_1: {
    id: "disable_popup_blocker_modal_tutorial_chrome_android_step_1",
    defaultMessage: "Open the Chrome app",
  },
  disable_popup_blocker_modal_tutorial_chrome_android_step_2: {
    id: "disable_popup_blocker_modal_tutorial_chrome_android_step_2",
    defaultMessage: "To the right of the address bar, tap More",
  },
  disable_popup_blocker_modal_tutorial_chrome_android_step_3: {
    id: "disable_popup_blocker_modal_tutorial_chrome_android_step_3",
    defaultMessage:
      "Tap Site settings and then Pop-ups and redirects. Turn Pop-ups and redirects on",
  },
  bet_games_nav_name: {
    id: "bet_games_nav_name",
    defaultMessage: "Betgames.tv",
  },
  kiron_nav_name: {
    id: "kiron_nav_name",
    defaultMessage: "Kiron",
  },
  cryptopay_buy_rate: {
    id: "cryptopay_buy_rate_{value}",
    defaultMessage: "buy_rate: {value}",
  },
  cryptopay_sell_rate: {
    id: "cryptopay_sell_rate_{value}",
    defaultMessage: "sell_rate: {value}",
  },
  "apply_cashback_success_content_{data}": {
    id: "apply_cashback_success_content_{data}",
    defaultMessage: "{data} was credited on your account",
  },
  cashback_claim: {
    id: "cashback_claim",
    defaultMessage: "Claim",
  },
  cashback_total_earned: {
    id: "cashback_total_earned",
    defaultMessage: "Earned",
  },
  cashback_casino_label: {
    id: "cashback_casino_label",
    defaultMessage: "Casino Cashback",
  },
  cashback_sport_label: {
    id: "cashback_sport_label",
    defaultMessage: "Sportsbook Cashback",
  },
  pwa_promo_modal_label: {
    id: "pwa_promo_modal_label",
    defaultMessage: "Make a bet right now",
  },
  pwa_promo_modal_title: {
    id: "pwa_promo_modal_title",
    defaultMessage: "Bettilt Application",
  },
  pwa_promo_modal_desc: {
    id: "pwa_promo_modal_desc",
    defaultMessage: "Get access to Bettilt quickly and easily.",
  },
  pwa_promo_modal_btn: {
    id: "pwa_promo_modal_btn",
    defaultMessage: "Install now",
  },
  bet_state_new: {
    id: "bet_state_new",
    defaultMessage: "New",
  },
  bet_state_won: {
    id: "bet_state_won",
    defaultMessage: "Won",
  },
  bet_state_lost: {
    id: "bet_state_lost",
    defaultMessage: "Lost",
  },
  bet_state_canceled: {
    id: "bet_state_canceled",
    defaultMessage: "Canceled",
  },
  bet_state_cashout: {
    id: "bet_state_cashout",
    defaultMessage: "Cashout",
  },
  bet_state_cashback: {
    id: "bet_state_cashback",
    defaultMessage: "Cashback",
  },
  bet_state_expressBonus: {
    id: "bet_state_expressBonus",
    defaultMessage: "ExpressBonus",
  },
  wrong_city_format: {
    id: "wrong_city_format",
    defaultMessage: "Wrong city format",
  },
  wrong_name_format: {
    id: "wrong_name_format",
    defaultMessage: "Wrong name format",
  },
  wrong_postal_code_format: {
    id: "wrong_postal_code_format",
    defaultMessage: "Wrong postal code format",
  },
  wrong_address_format: {
    id: "wrong_address_format",
    defaultMessage: "Wrong address format",
  },
  wager_tracker_tooltip: {
    id: "wager_tracker_tooltip",
    defaultMessage:
      "You have used {progress}% of the bonus. To withdraw the bonus amount, progress must be 100%.",
  },
  continue_without_bonus: {
    id: "continue_without_bonus",
    defaultMessage: "Continue without bonus",
  },
  change_amount: {
    id: "change_amount",
    defaultMessage: "Change amount",
  },
  accept_amount_change_message: {
    id: "accept_amount_change_message",
    defaultMessage: "We have automatically changed amount to 15 EUR.",
  },
  get_bonus_message_info: {
    id: "get_bonus_message_info",
    defaultMessage: "To get bonus, you need to deposit more than",
  },
  leagueBettilt_header_title: {
    id: "leagueBettilt_header_title",
    defaultMessage: "League Bettilt",
  },
  leagueBettilt_header_description: {
    id: "leagueBettilt_header_description",
    defaultMessage: "League Bettilt Description",
  },
  league_bettilt_leaderboard_title: {
    id: "league_bettilt_leaderboard_title",
    defaultMessage: "Leaderboard",
  },
  league_bettilt_popular_leagues: {
    id: "league_bettilt_popular_leagues",
    defaultMessage: "Popular leagues",
  },
  league_bettilt_show_all: {
    id: "league_bettilt_show_all",
    defaultMessage: "Show all",
  },
  league_bettilt_show_all_leagues: {
    id: "league_bettilt_show_all_leagues",
    defaultMessage: "Show all leagues",
  },
  league_bettilt_show_matches: {
    id: "league_bettilt_show_matches",
    defaultMessage: "Show matches",
  },
  custom_leaderboard_show_more: {
    id: "custom_leaderboard_show_more",
    defaultMessage: "Show More",
  },
  loyalty_point_cash_in_successful: {
    id: "loyalty_point_cash_in_successful",
    defaultMessage: "Loyalty point cash in successfully complete!",
  },
  not_enough_loyalty_points: {
    id: "not_enough_loyalty_points",
    defaultMessage: "Not enough Loyalty Points",
  },
  loyalty_info_current_level: {
    id: "loyalty_info_current_level",
    defaultMessage: "Your level",
  },
  loyalty_system_title: {
    id: "loyalty_system_title",
    defaultMessage: "Loyalty system",
  },
  loyalty_info_new_level: {
    id: "loyalty_info_new_level",
    defaultMessage: "New",
  },
  loyalty_info_bronze_level: {
    id: "loyalty_info_bronze_level",
    defaultMessage: "Bronze",
  },
  loyalty_info_silver_level: {
    id: "loyalty_info_silver_level",
    defaultMessage: "Silver",
  },
  loyalty_info_gold_level: {
    id: "loyalty_info_gold_level",
    defaultMessage: "Gold",
  },
  loyalty_info_platinum_level: {
    id: "loyalty_info_platinum_level",
    defaultMessage: "Platinum",
  },
  loyalty_info_diamond_level: {
    id: "loyalty_info_diamond_level",
    defaultMessage: "Diamond",
  },
  landing_page_main_reg_title: {
    id: "landing_page_main_reg_title",
    defaultMessage: "Ready to start winning?",
  },
  landing_page_latest_winners: {
    id: "landing_page_latest_winners",
    defaultMessage: "Latest winners",
  },
  landing_page_casino_most_popular_games_title: {
    id: "landing_page_casino_most_popular_games_title",
    defaultMessage: "Most popular",
  },
  landing_page_most_popular_show_all: {
    id: "landing_page_most_popular_show_all",
    defaultMessage: "Show all",
  },
  landing_page_casino_editors_choice: {
    id: "landing_page_casino_editors_choice",
    defaultMessage: "Editor`s choice",
  },
  landing_page_casino_editors_choice_description: {
    id: "landing_page_casino_editors_choice_description",
    defaultMessage: "A curated list of the best games in our editorial opinion",
  },
  landing_page_casino_show_all: {
    id: "landing_page_casino_show_all",
    defaultMessage: "Show all",
  },
  landing_page_popular_methods: {
    id: "landing_page_popular_methods",
    defaultMessage: "Popular methods",
  },
  landing_page_show_methods: {
    id: "landing_page_show_methods",
    defaultMessage: "Show all",
  },
  landing_page_casino_jackpot_title: {
    id: "landing_page_casino_jackpot_title",
    defaultMessage: "Jackpot",
  },
  landing_page_providers_title: {
    id: "landing_page_providers_title",
    defaultMessage: "Play with Popular Game Providers",
  },
  landing_page_view_all_games_btn: {
    id: "landing_page_view_all_games_btn",
    defaultMessage: "View all games",
  },
  landing_page_sb_quick_links: {
    id: "landing_page_view_all_games_btn",
    defaultMessage: "Sports Quick Links",
  },
  giveaway_page_title_top: {
    id: "giveaway_page_title_top",
    defaultMessage: "Summer",
  },
  giveaway_page_title_bottom: {
    id: "giveaway_page_title_bottom",
    defaultMessage: "Give Away",
  },
  giveaway_page_desc: {
    id: "giveaway_page_desc",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  giveaway_page_rules_btn: {
    id: "giveaway_page_rules_btn",
    defaultMessage: "How It Works",
  },
  giveaway_page_section_1_title: {
    id: "giveaway_page_section_1_title",
    defaultMessage: "Every Week",
  },
  giveaway_page_section_2_title: {
    id: "giveaway_page_section_2_title",
    defaultMessage: "Grand Final",
  },
  vip_bet_enjoy_the_luxury: {
    id: "vip_bet_enjoy_the_luxury",
    defaultMessage: "Enjoy the luxury",
  },
  vip_bet_experience_of_being_our: {
    id: "vip_bet_experience_of_being_our",
    defaultMessage: "Experience of being our",
  },
  vip_bet_premium_member: {
    id: "vip_bet_premium_member",
    defaultMessage: "Premium Member",
  },
  vip_bet_play_for_pleasure_or_improve: {
    id: "vip_bet_play_for_pleasure_or_improve",
    defaultMessage: "Play for pleasure or improve",
  },
  vip_bet_your_skills_your_loyalty: {
    id: "vip_bet_your_skills_your_loyalty",
    defaultMessage: "your skills, your loyalty",
  },
  vip_bet_will_be_rewarded: {
    id: "vip_bet_will_be_rewarded",
    defaultMessage: "will be rewarded",
  },
  vip_bet_secure_payments: {
    id: "vip_bet_secure_payments",
    defaultMessage: "Secure payments",
  },
  vip_bet_personal_vip_manager: {
    id: "vip_bet_personal_vip_manager",
    defaultMessage: "Personal VIP manager",
  },
  vip_bet_exclusive_bonuses: {
    id: "vip_bet_exclusive_bonuses",
    defaultMessage: "Exclusive bonuses",
  },
  vip_bet_personal_approach: {
    id: "vip_bet_personal_approach",
    defaultMessage: "Personal approach for every customer",
  },
  vip_bet_fast_payouts: {
    id: "vip_bet_fast_payouts",
    defaultMessage: "Fast payouts",
  },
  vip_bet_top_experience: {
    id: "vip_bet_top_experience",
    defaultMessage: "Top quality Live Casino and Sport bet experience",
  },
  vip_bet_customised_cashback_bonuses: {
    id: "vip_bet_customised_cashback_bonuses",
    defaultMessage: "Customised cashback and reload bonuses",
  },
  vip_bet_special_gifts: {
    id: "vip_bet_special_gifts",
    defaultMessage: "Special gifts",
  },
  "quiz_round_title_{day}": {
    id: "quiz_round_title_{day}",
    defaultMessage: "Round {day}",
  },
  "quiz_result_info_{points}_{place}": {
    id: "quiz_result_info_{points}_{place}",
    defaultMessage: "You have {points} points and you are in {place} place",
  },
  "quiz_points_{value}": {
    id: "quiz_points_{value}",
    defaultMessage: "{value} points",
  },
});

export default I18n_keys;
