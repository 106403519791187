import React from "react";
import { BtnLoaderProps } from "./types";

const BtnLoader: React.FC<BtnLoaderProps> = ({ className }) => (
  <svg viewBox="0 0 50 50" className={className}>
    <circle cx="25" cy="25" r="20" fill="none" />
  </svg>
);

export default BtnLoader;
