import { useQuery } from "react-query";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../system/reducers";
import {
  getGamesAction,
  getSearchGamesAction,
  getAllGamesAction,
  getProvidersAction,
  getAllProvidersAction,
  getCategoriesAction,
  getGametagCategoriesAction,
  getTagGamesAction,
  getRecentlyPlayedGamesAction,
  getMostPopularGamesAction,
} from "./apiActions";
import { getLanguage } from "../../system/translations/helper";
import { isLoggedIn } from "../../system/helpers/userHelper";

const GamesList = "games";
const MostPopularGamesList = "most-popular-games";
const SearchGamesList = "search_games";
const ProvidersList = "providers";
const CategoriesList = "categories";
const GametagCategoriesList = "gametag_categories";
const TagGamesList = "tag_games";
const RecentlyPlayedGamesList = "recently_played_games";

export const useGetGames = (categorySlug: string, enabled = true) => {
  const dispatch = useDispatch();
  const isUser = isLoggedIn();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const lang = getLanguage();
  return useQuery(
    [GamesList, categorySlug, lang, isUser],
    () => getGamesAction(categorySlug, countryInfo.iso2Code)(dispatch),
    {
      enabled: enabled,
    }
  );
};

export const useGetMostPopularGames = (enabled = true) => {
  const dispatch = useDispatch();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const lang = getLanguage();
  return useQuery(
    [MostPopularGamesList, lang],
    () => getMostPopularGamesAction(countryInfo.iso2Code)(dispatch),
    {
      enabled: enabled,
    }
  );
};

export const useGetSearchGames = (value: string) => {
  const dispatch = useDispatch();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const lang = getLanguage();
  return useQuery([SearchGamesList, value, lang], () =>
    getSearchGamesAction(value, countryInfo.iso2Code)(dispatch)
  );
};

export const useGetAllGames = () => {
  const dispatch = useDispatch();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const lang = getLanguage();
  return useQuery([SearchGamesList, lang], () =>
    getAllGamesAction(countryInfo.iso2Code)(dispatch)
  );
};

export const useGetProviders = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([ProvidersList, lang], () => getProvidersAction()(dispatch));
};

export const useGetAllProviders = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  const countryCode = countryInfo?.iso2Code?.toLowerCase() || "pt";
  return useQuery([ProvidersList, lang, countryCode], () =>
    getAllProvidersAction(countryCode)(dispatch)
  );
};

export const useGetCategories = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([CategoriesList, lang], () =>
    getCategoriesAction()(dispatch)
  );
};

export const useGetGametagCategories = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([GametagCategoriesList, lang], () =>
    getGametagCategoriesAction()(dispatch)
  );
};

export const useGetTagGames = (gameTag: string) => {
  const isUser = isLoggedIn();
  const dispatch = useDispatch();
  const lang = getLanguage();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  return useQuery([TagGamesList, gameTag, lang, isUser], () =>
    getTagGamesAction(gameTag, countryInfo?.iso2Code)(dispatch)
  );
};

export const useGetRecentlyPlayedGames = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([RecentlyPlayedGamesList, lang], () =>
    getRecentlyPlayedGamesAction()(dispatch)
  );
};
