import { generatePath } from "react-router";
import { linksPayloadData } from "./constants";
import { getLanguage } from "../../system/translations/helper";

const HEADER_LINKS_URL = (lang) =>
  `/${lang}/menu/combined/desc-header-menu.json`;
const HEADER_NAV_MOB_LINKS_URL = (lang) =>
  `/${lang}/menu/combined/mobile_bottom_bar.json`;
const MENU_LINKS_URL = "/:lang/menu/combined/:slug.json";

export const getHeaderLinksAction = () => (dispatch: any): linksPayloadData => {
  return dispatch({
    type: "GET_HEADER_LINKS",
    request: {
      url: HEADER_LINKS_URL(getLanguage()),
      method: "GET",
      withCredentials: false,
      transformRequest: (data: any, headers: object) => {
        for (let key in headers) {
          delete headers[key];
        }
      },
    },
  }).then((data: any) => data.payload);
};

export const getHeaderNavMobLinksAction = () => (
  dispatch: any
): linksPayloadData => {
  return dispatch({
    type: "GET_HEADER_LINKS",
    request: {
      url: HEADER_NAV_MOB_LINKS_URL(getLanguage()),
      method: "GET",
      withCredentials: false,
      transformRequest: (data: any, headers: object) => {
        for (let key in headers) {
          delete headers[key];
        }
      },
    },
  }).then((data: any) => data.payload);
};

export const getMenuLinksAction = (slug: string) => (
  dispatch: any
): linksPayloadData => {
  const lang = getLanguage();
  return dispatch({
    type: "GET_MENU_LINKS",
    request: {
      url: generatePath(MENU_LINKS_URL, { lang, slug }),
      method: "GET",
      withCredentials: false,
      transformRequest: (data: any, headers: object) => {
        for (let key in headers) {
          delete headers[key];
        }
      },
    },
  }).then((data: any) => data.payload);
};
