import {
  UPDATE_TFA_METHODS,
  updateTfaMethodPayload,
  LOGOUT_SUCCESS,
} from "./constants";

export const updateTfaMethodsAction = (payload: updateTfaMethodPayload) => (
  dispatch: any
) => dispatch({ type: UPDATE_TFA_METHODS, payload });

export const logoutSuccessAction = () => (dispatch: any) =>
  dispatch({ type: LOGOUT_SUCCESS });
