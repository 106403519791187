import { isMobile, osName, getUA } from "react-device-detect";
import { generatePath } from "react-router-dom";
import { APP_ROUTES } from "../../system/router/constants";
import { getLanguage } from "../../system/translations/helper";

type deviceDataType = {
  isMobile: boolean;
  isPWA: boolean;
  userAgent: string;
  osName: string;
};

export const checkPwaDomain = (pwaDomain: string): boolean =>
  window.location.hostname === pwaDomain;

export const getPwaLandingPath = (): string => {
  const lang = getLanguage() || "en";
  return generatePath(APP_ROUTES.pwaLanding, { lang });
};

export const getPwaLanding = (pwaDomain: string): string => {
  const landingPath = getPwaLandingPath();
  return `https://${pwaDomain}${landingPath}`;
};

export const pwaDetector = (): boolean =>
  window.matchMedia("(display-mode: standalone)").matches;

export const getDeviceData = (): deviceDataType => {
  const isPWA = pwaDetector();

  return {
    isMobile: isMobile,
    isPWA: isPWA,
    osName: osName,
    userAgent: getUA,
  };
};
