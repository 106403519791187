import { useDispatch } from "react-redux";
import { getLanguage } from "../../system/translations/helper";
import { useQuery } from "react-query";
import { getSbQuickLinksAction } from "./apiActions";
import { SportLinks } from "./constants";

export const useGetSportLinks = () => {
  const dispatch = useDispatch();
  const lang = getLanguage();
  return useQuery([SportLinks, lang], () => getSbQuickLinksAction()(dispatch));
};
