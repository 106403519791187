import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "../../../components/organisms/Modal";
import { ModalParams, ModalTypes } from "../types";

const ModalContainer: React.FC<ModalTypes> = forwardRef(
  ({ hideModal }, ref) => {
    const [visible, setVisible] = useState<any>({});
    const [params, setParams] = useState<{ [key: string]: ModalParams }>({});
    const [focus, setFocus] = useState("default");

    useImperativeHandle(ref, () => ({
      modalOpen(name: string, param: any) {
        setParams({ ...params, [name]: param });
        setVisible({ ...visible, [name]: true });
        setFocus(name);
      },
      modalUpdate(name: string, param: any) {
        setParams({ ...params, [name]: { ...params[name], ...param } });
      },
      modalClose(name: any) {
        const modalParams = { ...params };
        delete modalParams[name];
        setParams(modalParams);
        setVisible({ ...visible, [name]: false });
      },
    }));

    return (
      <>
        {Object.keys(visible).map((name, idx) => {
          const {
            component,
            componentProps,
            style,
            className,
            modalSize,
            body,
            title,
            desc,
            contentClass,
            showBanner,
            bannerPosition,
            bannerComponent,
            transparentBg,
            modalFooter,
            modalAfterFooter,
            closeText,
            doNotClose,
            beforeClose,
          } = params?.[name] || {};

          const modalStyle = {
            zIndex: name === focus ? 9001 : 9000,
            ...style,
          };

          const handleOnClose = () => {
            beforeClose && beforeClose();
            doNotClose || hideModal(name);
          };

          return (
            <Modal
              keyValue={name}
              style={{ ...modalStyle }}
              isOpen={visible?.[name]}
              className={className}
              closeText={closeText}
              modalSize={modalSize}
              body={body}
              title={title}
              desc={desc}
              component={component}
              componentProps={componentProps}
              contentClass={contentClass}
              showBanner={showBanner}
              bannerPosition={bannerPosition}
              bannerComponent={bannerComponent}
              transparentBg={transparentBg}
              modalFooter={modalFooter}
              modalAfterFooter={modalAfterFooter}
              doNotClose={doNotClose}
              handleOnClose={handleOnClose}
            />
          );
        })}
      </>
    );
  }
);

export default ModalContainer;
